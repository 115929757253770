import React, { useEffect } from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  setFormName,
  selectFormName,
  setFormUrl,
  selectFormUrl,
  clearActiveSettings,
} from '../../../../../store/reducers/forms';
import styles from './OverallSettings.module.scss';
import StyleSettings from './StyleSettings';
import { ReactComponent as Close } from '../../../../../assets/icons/close.svg';

const OverallSettings = ({ activeSettings }) => {
  const dispatch = useDispatch();
  const formName = useSelector(selectFormName);
  const formUrl = useSelector(selectFormUrl);

  const generateRandomString = () => {
    const chars = 'abcdefghjkmnpqrstuvwxyz23456789';
    let result = '';
    for (let i = 0; i < 5; i += 1) {
      const randomIndex = Math.floor(Math.random() * chars.length);
      result += chars[randomIndex];
    }
    return result;
  };

  useEffect(() => {
    if (!formUrl || formUrl === '') {
      dispatch(setFormUrl(generateRandomString()));
    }
  }, [formUrl, dispatch]);

  return (
    <div className={`${styles.wrapper} h-100`}>
      <div
        className="d-flex flex-column justify-content-between h-100"
      >
        <div>
          <div className={`${styles.title} d-flex align-items-center gap-2`}>
            Settings
          </div>
          <div className={`${styles.close} d-flex justify-content-end position-absolute`}>
            <Close
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => dispatch(clearActiveSettings())}
            />
          </div>
        </div>

        <div className="d-flex flex-column w-100 gap-1 mb-3">
          <div className={styles.title_input}>
            Form name
          </div>
          <div className={`${styles.text_input}`}>
            <input
              placeholder="Type a value"
              type="text"
              className="w-100 p-2"
              value={formName}
              onChange={(e) => {
                dispatch(setFormName(e.currentTarget.value));
              }}
            />
          </div>
        </div>

        <div className="d-flex flex-column w-100 gap-1 mb-3">
          <div className={styles.title_input}>
            Form URL
          </div>
          <div className="d-flex align-items-center">
            <div className={styles.text_input}>
              <span>
                www.wrm.link/
              </span>
              <input
                placeholder="Type a value"
                type="text"
                className="w-100 p-2"
                style={{ border: 'none', outline: 'none' }}
                value={formUrl}
                maxLength={20}
                onChange={(e) => {
                  const userInput = e.currentTarget.value;
                  if (userInput.length <= 20 && /^[a-z0-9-]+$/.test(userInput)) {
                    dispatch(setFormUrl(userInput));
                  } else if (userInput.length > 20) {
                    alert('URL string can be a maximum of 20 characters.');
                  } else {
                    alert('Invalid URL string. Only use lower-case letters, numbers, and dashes.');
                  }
                }}
              />
            </div>

            {/* <button
              type="button"
              onClick={() => dispatch(setFormUrl(generateRandomString()))}
            >
              Random
            </button> */}
          </div>
        </div>
        <div className="d-flex align-items-center gap-2">
          <StyleSettings activeSettings />
        </div>
      </div>
    </div>
  );
};

export default OverallSettings;
