import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Loader from '../../base/Loader';
import { convertNumber, convertToReadableFormat } from '../../../tools/NumberConverterTool';
import { counter } from '../../../tools/Counter';
import { setSelectedAsset } from '../../../store/reducers/app';
import AssetImg from '../NotableInvestments/AssetImg';
import Table from '../../base/Table';
import styles from './Investments.module.scss';
import tableStyles from '../../base/Table/Table.module.scss';
import { tooltipChainIconPicker } from '../../../tools/ChainIconPicker';
import uniqueId from '../../../utils/uniqueId';
import ItemPreview from '../ItemPreview';

const Investments = ({
  investments, holders, isLoading, page, setPage, setDesc, limit, setLimit,
  desc, setCheckedNft, setCheckedToken,
  checkedToken, checkedNft, order, setOrder,
}) => {
  const dispatch = useDispatch();

  const [tableData, setTableData] = useState([]);

  const header = [
    {
      field: '',
      title: '#',
      width: '32px',
    },
    {
      field: 'name',
      title: 'Name',
      width: '400px',
    },
    {
      field: 'token_qty_decimal',
      title: 'Cumulated Balance',
    },
    {
      field: 'value',
      title: 'Value',
    },
    {
      field: 'number_holders',
      title: 'Number Investors',
    },
    {
      field: 'number_holders',
      title: 'Percent of Audience',
    },
  ];

  const convertBalance = (value, assetType) => {
    if (assetType === 'token') {
      return (convertToReadableFormat(value));
    }
    return convertNumber(value);
  };
  useEffect(() => {
    if (investments?.results) {
      const temp = investments.results.map((elem, index) => ({
        pageNumber: (
          <span className={styles.number}>{(page - 1) * limit + index + 1}</span>
        ),
        name: (
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex gap-2 align-items-center">
              <div
                data-for={elem.asset_type === 'nft' && `preview_${elem.address}_${elem.platform}`}
                data-tip="show"
              >
                <AssetImg
                  logo={elem.logo}
                  type={elem.asset_type}
                  text={elem.asset_type}
                  data-for={elem.asset_type === 'nft' && `preview_${elem.address}_${elem.platform}`}
                  data-tip="show"
                />
              </div>
              <Link
                className={`${styles.content_name} ${tableStyles.underline_on_hover} text-truncate`}
                data-for={elem.asset_type === 'nft' && `preview_${elem.address}_${elem?.blockchains[0] || ''}`}
                data-tip="show"
                onClick={() => {
                  dispatch(setSelectedAsset({
                    type: elem.asset_type,
                    name: elem.name,
                    logo: elem.logo,
                    symbol: elem.symbol,
                    network: elem.platform,
                  }));
                }}
                to={`/${elem.asset_type === 'token' ? 'coins' : 'nfts'}/${elem.asset_type
                !== 'token' ? `${elem?.blockchains[0]}/` : ''}${elem.address || elem.coin_id}`}
              >
                {elem.opensea_slug_contract_count > 1 ? elem.contract_name || elem.name : elem.name}
              </Link>
              {elem.asset_type === 'nft'
                ? (
                  <ItemPreview
                    id={`preview_${elem.address}_${elem.blockchains[0]}`}
                    data={{
                      address: elem.address,
                      type: elem.asset_type,
                      blockchain: elem.blockchains[0],
                      img: elem.logo,
                      name: elem.contract_name || elem.name,
                    }}
                  />
                )
                : null}
            </div>
            <div className={`d-flex align-items-center ${styles.chain}`}>
              <div
                className={`
                ${styles.asset}
                ${elem.asset_type === 'token' ? styles.red : ''}
                px-3 d-flex justify-content-center align-items-center`}
              >
                {elem.asset_type === 'token' ? 'Token' : 'NFT Collection'}
              </div>
              {elem.blockchains && elem.blockchains.length
                ? (
                  <>
                    <div className={`${styles.divider} mx-2`} />
                    <div className={styles.blockchains_block}>
                      {tooltipChainIconPicker(elem.blockchains[0], uniqueId('chain'))}
                      {tooltipChainIconPicker(elem.blockchains[1], uniqueId('chain'))}
                    </div>
                  </>
                )
                : null}
            </div>
          </div>
        ),
        token_qty_decimal: (
          elem.token_qty_decimal !== null
            ? `${convertBalance(+(Math.floor(elem.token_qty_decimal))
              .toLocaleString('fullwide', { useGrouping: false }), elem.asset_type)}
              ${
          elem.asset_type === 'token'
            ? `Token${Math.floor(elem.token_qty_decimal) === 1 ? '' : 's'}`
            : `NFT${Math.floor(elem.token_qty_decimal) === 1 ? '' : 's'}`}`
            : '?'
        ),
        value: elem.value >= 1
          ? `$${convertToReadableFormat(+(Math.floor(elem.value))
            .toLocaleString('fullwide', { useGrouping: false }))}`
          : '<1$',
        number_holders: convertNumber(elem.number_holders),
        holders: holders && elem.number_holders !== null
          ? `${+((elem.number_holders / holders) * 100).toFixed(2)}%`
          : '?',
      }));
      setTableData(temp);
    }
  }, [investments]);

  return (
    <div className={`${styles.wrapper} d-flex w-100 flex-column align-items-center mt-4`}>
      <div className={styles.title}>Investments</div>
      {isLoading && !tableData.length
        ? (
          <div className="d-flex justify-content-center align-items-center p-5 h-75">
            <Loader />
          </div>
        )
        : (
          <>
            <div className="d-flex w-100 justify-content-between align-items-center mb-4">
              <div className={styles.content_info}>
                {counter(investments?.count, page, limit)}
              </div>
              <div className="d-flex gap-3">
                <div className="form-check form-check-inline">
                  <label className="form-check-label" htmlFor="inlineCheckbox1">
                    <input
                      className="form-check-input shadow-none cursor-pointer"
                      type="checkbox"
                      id="inlineCheckbox1"
                      value="option1"
                      checked={checkedToken}
                      onChange={() => {
                        setCheckedToken(!checkedToken);
                        setPage(1);
                      }}
                      disabled={!checkedNft && checkedToken}
                    />
                    Tokens
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <label className="form-check-label" htmlFor="inlineCheckbox2">
                    <input
                      className="form-check-input shadow-none cursor-pointer"
                      type="checkbox"
                      id="inlineCheckbox2"
                      value="option2"
                      checked={checkedNft}
                      onChange={() => {
                        setCheckedNft(!checkedNft);
                        setPage(1);
                      }}
                      disabled={checkedNft && !checkedToken}
                    />
                    NFTs
                  </label>
                </div>
              </div>
            </div>
            <div className="w-100">
              <Table
                total={investments?.count}
                data={tableData}
                desc={desc}
                setDesc={setDesc}
                ordering={order}
                setOrdering={setOrder}
                page={page}
                setPage={setPage}
                limit={limit}
                setLimit={setLimit}
                headers={header}
                isLoading={isLoading}
                min={25}
              />
            </div>
          </>
        )}
    </div>
  );
};

export default Investments;
