import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Investments from '../../../../components/ui/Investments';
import { webSDKApi } from '../../../../api/webSDK';
import { selectWebSDKFilterParam } from '../../../../store/reducers/webSDK';

const InvestPortfolio = ({ id }) => {
  const { network } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(Number(searchParams.get('page')) || 1);
  const [desc, setDesc] = useState(searchParams.get('order') !== 'false');
  const [checkedToken, setCheckedToken] = useState(searchParams.get('sort') !== 'nft');
  const [checkedNft, setCheckedNft] = useState(searchParams.get('sort') !== 'token');
  const [type, setType] = useState(searchParams.get('sort'));
  const [order, setOrder] = useState(searchParams.get('orderBy') || 'number_holders');
  const [limit, setLimit] = useState(searchParams.get('perPage') || 25);
  const filterParams = useSelector(selectWebSDKFilterParam);
  const {
    currentData: walletTabInvestmentPortfolioData,
    isLoading: walletTabInvestmentPortfolioLoading,
    isFetching: walletTabInvestmentPortfolioFetching,
  } = webSDKApi.useGetWebSDKWalletTabInvestmentPortfolioQuery({
    id,
    ...filterParams,
    desc: desc ? '1' : '0',
    asset_type: type || '',
    chain: network,
    limit: +limit,
    order,
    page,
  });

  useEffect(() => {
    if (checkedNft && checkedToken) {
      setType('');
      setSearchParams({
        ...Object.fromEntries([...searchParams]),
        sort: '',
      });
    }
    if (checkedNft && !checkedToken) {
      setType('nft');
      setSearchParams({
        ...Object.fromEntries([...searchParams]),
        sort: 'nft',
      });
    }
    if (!checkedNft && checkedToken) {
      setType('token');
      setSearchParams({
        ...Object.fromEntries([...searchParams]),
        sort: 'token',
      });
    }
  }, [checkedNft, checkedToken]);

  return (
    <div className="row col-12 col-lg-12">
      <Investments
        investments={walletTabInvestmentPortfolioData}
        isLoading={walletTabInvestmentPortfolioLoading || walletTabInvestmentPortfolioFetching}
        limit={limit}
        setLimit={setLimit}
        holders={walletTabInvestmentPortfolioData?.count}
        page={page}
        desc={desc}
        setPage={setPage}
        setDesc={setDesc}
        setCheckedToken={setCheckedToken}
        setCheckedNft={setCheckedNft}
        checkedToken={checkedToken}
        checkedNft={checkedNft}
        order={order}
        setOrder={setOrder}
      />
    </div>
  );
};

export default InvestPortfolio;
