/* eslint-disable react/jsx-indent */
import React, {
  useState,
  useCallback,
  useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select, { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import { ethers } from 'ethers';
import {
  useAccount,
  useProvider,
  useNetwork,
  useSigner,
  useSwitchNetwork,
} from 'wagmi';
import {
  polygon,
  goerli,
  polygonMumbai,
} from 'wagmi/chains';
import { erc721Abi } from '../../../../../../utils/web3/erc721abi';
import { erc165Abi } from '../../../../../../utils/web3/erc165abi';
import styles from './NFTAirdrop.module.scss';
import { showErrorMessage } from '../../../../../../components/base/Notifications';
import RoundSpinner from '../../../../../../components/base/RoundSpinner';
import ProvideNFTsModal from './ProvideNFTsModal';
import { truncateAddress } from '../../../../../../utils/web3/truncateAddress';
import {
  getPendingTransactions,
  fetchPendingTxns,
  clearPendingTxn,
  isPendingTxn,
} from '../../../../../../store/reducers/web3';
import { getGasPrice } from '../../../../../../utils/web3/getGasPrice';
import { toHex } from '../../../../../../utils/web3/toHex';
import { metamaskErrorWrap } from '../../../../../../utils/web3/metamaskErrorWrap';
import { getERC721AirdropContractAddress, loadNFTAidropContract } from '../../../../../../utils/web3/loadContract';
import { coinApi } from '../../../../../../api/coin';
import { userApi } from '../../../../../../api/user';
import { flowsApi } from '../../../../../../api/flows';
import McapLabel from '../../../Components/McapLabel';
import { debounce } from '../../../../../../utils/debounce';
import { isValidContractAddress } from '../../../../../../tools/EtherTool';
import { IconNearby } from '../../../../../../components/base/SelectLabels';
import { getNftData } from '../../../../../../utils/segments/getCoinNftData';
import { getUser } from '../../../../../../store/reducers/user';
import { useDebounce } from '../../../../../../components/hooks/app';
import { setTeamBalanceReducer } from '../../../../../../store/reducers/flows';
import { ReactComponent as Warning } from '../../../../../../assets/icons/warning_rounded.svg';
import { profilesApi } from '../../../../../../api/profiles';

const ControlComponent = ({ props }) => {
  const propsToSet = { ...props };
  if (props.hasValue) {
    propsToSet.innerProps.className = null;
    propsToSet.selectProps.selectProps.head = true;
    delete propsToSet.selectProps.selectProps.description;
  }
  return (
    <div>
      <components.Control {...propsToSet} />
    </div>
  );
};

const inputStyles = {
  valueContainer: (style) => ({
    ...style,
    padding: '0 10px',
    minHeight: '46px',
  }),
  singleValue: (style) => ({
    ...style,
    padding: 0,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    opacity: 1,
  }),
  placeholder: (style) => ({
    ...style,
    fontSize: '14px',
  }),
  option: (style) => ({
    ...style,
    padding: '12px',
    boxShadow: '1px',
    border: '1px solid #F1F4F8',
  }),
  menuList: (style) => ({
    ...style,
    paddingTop: 0,
    paddingBottom: 0,
  }),
};

const blockChainOptions = [
  { label: 'Ethereum', value: 'eth', network: 'ethereum' },
  { label: 'Polygon', value: 'matic', network: 'polygon' },
  { label: 'Goerli', value: 'eth-goerli', network: 'goerli' },
  { label: 'Mumbai', value: 'matic-mumbai', network: 'mumbai' },
];

const nftSourceOptions = [
  { label: 'Wallet owning NFTs', value: 'users_wallet' },
  { label: 'Team balance on Absolute Labs Contract', value: 'team_balance' },
];

const approveForAllText = 'Approve for all tokens';
const approveForAllType = 'approve_for_all_tokens';
const ERC1155InterfaceId = '0xd9b67a26';

const NFTAirdrop = ({
  selectedBlockchain,
  setSelectedBlockchain,
  setAmountToAirdrop,
  register,
  setSelectedItem,
  selectedItem,
  setValue,
  getValues,
  disableChangeStatus,
  openWeb3Modal,
  assetType,
}) => {
  const dispatch = useDispatch();
  const { address } = useAccount();
  const { chain } = useNetwork();
  const { data: signer } = useSigner({ chainId: chain?.id });
  const { chains, switchNetwork } = useSwitchNetwork();
  const [debouncedText] = useDebounce('');
  const pendingTxns = useSelector(getPendingTransactions);
  const [showProvideNFTsModal, setShowProvideNFTsModal] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(selectedItem?.contract_address);
  const [nftSource, setNftSource] = useState('');
  const [nftContract, setNftContract] = useState();
  const [airdropContract, setAirdropContract] = useState();
  const [teamBalance, setTeamBalance] = useState(0);
  const [tokenIds, setTokenIds] = useState([]);
  const [userBalance, setUserBalance] = useState(0);
  const [isERC1155, setIsERC1155] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [nftOptions, setNftOptions] = useState([]);
  const provider = useProvider();
  const user = useSelector(getUser);

  const {
    data: nfts,
    isLoading: isNftLoading,
    isFetching: isNftFetching,
  } = coinApi.useSearchNftQuery(debouncedText, {
    skip: !debouncedText.length,
  });

  const {
    data: top5nfts,
    isLoading: isTop5NFTsLoading,
    isFetching: isTop5NFTsFetching,
  } = coinApi.useSearchNftQuery('', {
    skip: disableChangeStatus,
  });

  const {
    data: teamAssets,
    isLoading: teamAssetsIsLoading,
    isFetching: teamAssetsIsFetching,
    refetch: teamAssetsRefetch,
  } = userApi.useGetAssetsSearchQuery();

  const fetchNftOptions = useCallback(() => {
    const formattedNftData = getNftData(
      teamAssets !== undefined && teamAssets.length
        ? teamAssets.filter((x) => x.asset_type === 'nft') : top5nfts !== undefined ? top5nfts.slice(0, 5) : [],
      nfts !== undefined ? nfts : [],
    );
    setNftOptions(formattedNftData[0] ? formattedNftData[0] : formattedNftData[1]);
  }, [teamAssets, nfts, top5nfts]);

  const getApprovalStatus = useCallback(async (_NftContract, _airdropContract) => {
    if (_NftContract && address && _airdropContract) {
      const approved = await _NftContract.isApprovedForAll(address, _airdropContract.address);
      setIsApproved(approved);
    }
  }, [address]);

  const getTeamBalance = useCallback(async (_isERC1155, airdropContractAddress, _chain = selectedBlockchain) => {
    if (_isERC1155) {
      // await Promise.all(tokenIds.map(async (item) => {
      //   const tokenIdBalance = Number(
      //     await _airdropContract.erc1155Balances(
      //       String(user.team),
      //       selectedAddress || getValues('nft_address'),
      //       item,
      //     ),
      //   );

      //   teamBal += tokenIdBalance;
      // }));
    } else {
      const response = await dispatch(
        flowsApi
          .endpoints
          .getERC721TeamBalanceForAirdrop
          .initiate({
            address: airdropContractAddress,
            blockchain: _chain,
            wallet_address: selectedAddress || getValues('nft_address'),
          }, { forceRefetch: true }),
      );
      if (response?.error) {
        setTeamBalance(0);
        dispatch(setTeamBalanceReducer(null));
      } else {
        const result = response.data?.balance;
        if (!Number.isNaN(result)) {
          setTeamBalance(result);
          dispatch(setTeamBalanceReducer(result));
        }
      }
    }
  }, [selectedAddress, user.team, tokenIds]);

  const getAirdropContract = useCallback(async (_NftContract) => {
    if ((selectedAddress || getValues('nft_address')) && selectedBlockchain && address) {
      try {
        const _airdropContract = loadNFTAidropContract(signer || provider, isERC1155, chain?.id);
        setAirdropContract(_airdropContract);
        if (_airdropContract) {
          if (_airdropContract.address) {
            // Check user approval status for the Airdrop contract
            getApprovalStatus(_NftContract, _airdropContract);
          }
        }
      } catch (err) {
        showErrorMessage('We couldn\'t load the airdrop contract. This blockchain might not be supported.');
      }
    }
  }, [chain?.id, address, signer, provider, selectedAddress, getApprovalStatus, selectedBlockchain, isERC1155]);

  const approveForAll = async (approve) => {
    let approveTx;
    try {
      const gasPrice = await getGasPrice(provider);

      approveTx = await nftContract.setApprovalForAll(airdropContract.address, approve, { gasPrice });

      dispatch(fetchPendingTxns({ txnHash: approveTx.hash, approveForAllText, type: approveForAllType }));
      await approveTx.wait();
    } catch (err) {
      if (approveTx) {
        dispatch(clearPendingTxn(approveTx.hash));
      }
      return metamaskErrorWrap(err, showErrorMessage);
    } finally {
      if (approveTx) {
        dispatch(clearPendingTxn(approveTx.hash));
      }
      setIsApproved(approve);
    }
  };

  const fetchUserBalance = async (cursor, _tokenIds) => {
    if (address) {
      const currSelectedChain = selectedBlockchain === 'mumbai' ? 'polygon mumbai' : selectedBlockchain;
      let chainId = 1;

      switch (currSelectedChain) {
        case polygon.name.toLocaleLowerCase(): chainId = polygon.id; break;
        case polygonMumbai.name.toLocaleLowerCase(): chainId = polygonMumbai.id; break;
        case goerli.name.toLocaleLowerCase(): chainId = goerli.id; break;
      }

      const result = await dispatch(
        profilesApi.endpoints.getWalletNftData.initiate({
          address: address || '',
          chain: toHex(chainId) || '',
          cursor,
          tokenAddresses: nftContract ? nftContract.address : '',
        }, { forceRefetch: true }),
      );

      if (result.status === 'fulfilled') {
        const existedAddress = selectedAddress || getValues('nft_address');
        result.data.result
          .filter((token) => token.token_address === existedAddress.toLowerCase())
          .map((item) => _tokenIds.push(item.token_id));

        if (result.data.cursor) {
          fetchUserBalance(result.data.cursor, _tokenIds);
        } else {
          setTokenIds(_tokenIds);
          setValue('user_token_ids', _tokenIds);
          setUserBalance(_tokenIds.length);
        }
      }
    }
  };

  useEffect(() => {
    // Check if the user is connected and to the right network
    if (selectedBlockchain && chain) {
      const currSelectedChain = selectedBlockchain === 'mumbai' ? 'polygon mumbai' : selectedBlockchain;
      if (currSelectedChain !== chain?.name.toLocaleLowerCase()) {
        switchNetwork(chains.find((c) => c.name.toLocaleLowerCase() === currSelectedChain).id);
      }
    }

    if (selectedBlockchain) {
      let selectedChain = '';
      switch (selectedBlockchain) {
        case 'ethereum': selectedChain = 'homestead'; break;
        case 'polygon': selectedChain = 'matic'; break;
        case 'mumbai': selectedChain = 'maticmum'; break;
        default: selectedChain = selectedBlockchain;
      }
      const _provider = new ethers
        .providers
        .AlchemyProvider(selectedChain, import.meta.env.VITE_APP_ALCHEMY_KEY);
      if (_provider) {
        const checkERC = async () => {
          // Instantiate the NFT contract with ERC165 ABI so we can call the supportsInterface method
          const contract = new ethers.Contract(
            selectedAddress || getValues('nft_address'),
            erc165Abi,
            _provider,
          );
          const _isERC1155 = await contract.supportsInterface(ERC1155InterfaceId);
          setIsERC1155(_isERC1155);
          setValue('is_erc1155', _isERC1155);
        };
        checkERC().then(() => {
          fetchUserBalance('', []);
          const airdropContractAddress = getERC721AirdropContractAddress(selectedBlockchain);
          getTeamBalance(false, airdropContractAddress, selectedBlockchain);
        });
      }
    }
  }, [selectedBlockchain, selectedAddress, chain, signer, getValues('source_of_nfts')]);

  useEffect(() => {
    // Set a default source of NFTs
    if (!getValues('source_of_nfts')) {
      setValue('source_of_nfts', nftSourceOptions[1].value);
    }
    teamAssetsRefetch();
    fetchNftOptions();
  }, [fetchNftOptions, teamAssetsRefetch, getValues, setValue]);

  useEffect(() => {
    // Set a default source of NFTs
    if (nftSource === 'users_wallet') {
      getApprovalStatus(airdropContract);
    }
  }, [nftSource, getApprovalStatus, airdropContract]);

  useEffect(() => {
    if (address) {
      setValue('user_address', address);
    }
  }, [address, setValue]);

  useEffect(() => {
    const nftAddress = selectedAddress || getValues('nft_address');
    // Load NFT contract
    if (signer && nftAddress && (selectedBlockchain || getValues('nft_blockchain'))) {
      // check if NFT contract is ERC721 or ERC1155
      const contract = new ethers.Contract(nftAddress, erc721Abi, signer);
      setNftContract(contract);
      // Load Airdrop contract
      getAirdropContract(contract);
    }
  }, [signer, selectedAddress, selectedBlockchain]);

  const getUserInputData = useCallback(async (val) => {
    if (isValidContractAddress(val)) {
      return ([{
        value: val,
        label: val,
        head: true,
      }]);
    }
    const result = await dispatch(
      coinApi.endpoints.searchNft.initiate(val),
    );

    if (result.status === 'fulfilled') {
      return result.data.map((item) => ({
        ...item,
        value: item.opensea_slug_contract_count > 1
          ? item.contract_name || item.contract_address
          : item.contract_address,
        label: item.opensea_slug_contract_count > 1 ? item.contract_name || item.name : item.name,
      }));
    }
    return null;
  }, [dispatch]);

  const loadUserInputData = useCallback(debounce((val) => getUserInputData(val), 1000), [getUserInputData]);

  const getMcapLabel = (val) => <McapLabel val={val} type={val.type === 'nft' ? 'nft' : ''} />;
  const getChainLabel = useCallback((val) => <IconNearby val={val} />, []);

  return (
    <>
      {
        showProvideNFTsModal
        && (
          <ProvideNFTsModal
            setShowProvideTokenModel={setShowProvideNFTsModal}
            getTeamBalance={getTeamBalance}
            nftContract={nftContract}
            getValues={getValues}
            selectedItem={selectedItem}
            teamBalance={teamBalance}
            userBalance={userBalance}
            isERC1155={isERC1155}
            isApprovedForAll={isApproved}
            approveForAll={approveForAll}
            openWeb3Modal={openWeb3Modal}
            tokenIds={tokenIds}
            assetType={assetType}
          />
        )
      }
      <AsyncSelect
        className="w-100 mb-3"
        styles={inputStyles}
        maxMenuHeight={300}
        isOptionSelected={false}
        loadOptions={loadUserInputData}
        components={
          {
            // eslint-disable-next-line react/no-unstable-nested-components
            Control: (props) => <ControlComponent props={props} />,
          }
        }
        selectProps={selectedItem}
        getOptionLabel={(val) => getMcapLabel(val)}
        onChange={(e) => {
          if (!e.blockchain) {
            setSelectedAddress(e.value);
            setSelectedBlockchain(null);
            setValue('nft_address', e.value);
            setValue('nft_blockchain', null);
            setSelectedItem({
              ...e,
              label: `${e.label.slice(0, 21)}...${e.label.slice(-5)}`,
            });
          } else {
            setSelectedItem(e);
            setSelectedAddress(e.value);
            setValue('nft_address', e.value);
            setValue('nft_blockchain', e.blockchain);
            setValue('asset_name', e.label);
            setSelectedBlockchain(e.blockchain);
          }
        }}
        isLoading={isNftLoading || isNftFetching || teamAssetsIsLoading
          || teamAssetsIsFetching || isTop5NFTsLoading || isTop5NFTsFetching}
        placeholder="NFT collection name or contract address"
        value={selectedItem}
        defaultOptions={nftOptions}
        isDisabled={disableChangeStatus}
      />
      <div className="mb-3">
        {!selectedItem?.image_url && selectedItem?.value && (
          <div className="my-3">
            <div className={`my-1 ${styles.block_title}`}>Select blockchain</div>
            <Select
              {...register('nft_blockchain')}
              className="w-100"
              styles={inputStyles}
              value={
                blockChainOptions.filter((elem) => elem.network === getValues('nft_blockchain'))
              }
              onChange={(val) => {
                setSelectedBlockchain(val.network);
                setValue('nft_blockchain', val.network);
              }}
              getOptionLabel={(val) => getChainLabel(val)}
              options={blockChainOptions}
              isDisabled={disableChangeStatus}
            />
          </div>
        )}
        {getValues('nft_blockchain') && getValues('nft_address') && !isERC1155 && (
          <>
            <div className="my-3">
              <div className={`my-1 ${styles.block_title}`}>Source of the NFTs</div>
              <Select
                {...register('source_of_nfts')}
                className="w-100"
                styles={inputStyles}
                onChange={(val) => {
                  setNftSource(val.value);
                  setValue('source_of_nfts', val.value);
                }}
                value={
                  getValues('source_of_nfts')
                    ? nftSourceOptions.filter((elem) => elem.value === getValues('source_of_nfts'))
                    : nftSourceOptions[1]
                }
                options={nftSourceOptions}
                isDisabled={disableChangeStatus}
              />
            </div>
            {
              (nftSource !== '' || getValues('source_of_nfts') !== '') && (
                (nftSource === 'users_wallet' || getValues('source_of_nfts') === 'users_wallet') ? (
                  <>
                    <div className="d-flex justify-content-between align-items-center mb-1">
                      <div className={styles.block_title}>
                        Balance available in wallet:
                      </div>
                      <div className={styles.balance_count}>
                        {address ? userBalance || 0 : 'Not connected'}
                      </div>
                    </div>
                    <div className="d-flex gap-3">
                      {
                        address ? (
                          !isApproved ? (
                            isPendingTxn(pendingTxns, approveForAllType) ? (
                              <div>
                                <RoundSpinner position="position-absolute" theme="light" />
                                Approving...
                              </div>
                            ) : (
                              <div>
                                <button
                                  type="button"
                                  className={`${styles.wallet_btn} outline-blue-button`}
                                  onClick={() => approveForAll(true)}
                                  disabled={disableChangeStatus}
                                >
                                  Allow Absolute Labs to use the NFTs
                                </button>
                              </div>
                            )
                          ) : (
                            <div>
                              <button
                                type="button"
                                className={`${styles.wallet_btn} outline-blue-button`}
                                onClick={() => openWeb3Modal()}
                                disabled={disableChangeStatus}
                              >
                                {truncateAddress(address)}
                                {' '}
                                linked
                              </button>
                            </div>
                          )
                        ) : (
                          window.ethereum
                            ? (
                              <button
                                type="button"
                                className={`${styles.wallet_btn} outline-blue-button`}
                                onClick={async () => { openWeb3Modal(); }}
                              >
                                  Connect Wallet
                              </button>
                            )
                            : (
                              <div>
                                  It seems like you don&apos;t have any wallet available.
                              </div>
                            )
                        )
                      }
                    </div>
                  </>
                ) : (
                  <>
                    <div className="d-flex justify-content-between align-items-center mb-1">
                      <div className={styles.block_title}>
                        Team balance available for airdrop:
                      </div>
                      <div className={styles.balance_count}>
                        {teamBalance || 0}
                        {' '}
                        {!selectedItem.symbol ? '' : selectedItem?.symbol?.toUpperCase() || ''}
                      </div>
                    </div>
                    {
                      window.ethereum && (
                        <div className="d-flex gap-3">
                          <button
                            type="button"
                            className={`${styles.wallet_btn}
                  outline-blue-button`}
                            onClick={() => { setShowProvideNFTsModal(true); }}
                            disabled={disableChangeStatus}
                          >
                            {`Provide ${!selectedItem.symbol ? '' : selectedItem?.symbol?.toUpperCase() || ''} NFTs`}
                          </button>
                        </div>
                      )
                    }
                  </>
                )
              )
            }
          </>
        )}
      </div>
      {getValues('nft_blockchain')
        && getValues('nft_address')
        && getValues('source_of_nfts') !== ''
        && !isERC1155
        && (
          <div className="mb-3">
            <div className={`${styles.block_title} mb-1`}>
              Number of NFTs to send to each wallets entering
            </div>
            <div className="d-flex align-items-center gap-3">
              <input
                type="number"
                className={styles.input_number}
                {...register('nft_amount')}
                onChange={(e) => {
                  setValue('asset_name', selectedItem.label);
                  setAmountToAirdrop(e.target.value);
                }}
                min="0"
                onWheel={(e) => e.target.blur()}
                disabled={disableChangeStatus}
              />
            </div>
          </div>
        )}
      {isERC1155
        ? (
          <div className={`${styles.warning} d-flex gap-2 align-items-center`}>
            <Warning />
            ERC1155 NFT airdrop is not yet supported
          </div>
        )
        : null}
    </>
  );
};

export default NFTAirdrop;
