import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { userApi } from '../../../../../api/user';
import DropdownMenu from '../../../../base/DropdownMenu';
import { showErrorMessage, showSuccessMessage } from '../../../../base/Notifications';
import Spinner from '../../../../base/Spinner';
import AddedButton from '../../../../../assets/icons/added.svg';
import AddButton from '../../../../../assets/icons/add.svg';
import { handleActionAssetForHeader } from './assetActionsForHeader';
import { isAddedFromSearch } from '../../../../../store/reducers/app';
import styles from './AddAssetsFromHeader.module.scss';

const AddAssetsFromHeader = ({
  type, data, network, name,
}) => {
  const dispatch = useDispatch();
  const [location, setLocation] = useState('none');

  const selectButtonData = {
    assets: {
      img: AddedButton,
      desc: 'Remove from My Assets',
      class: `btn-success ${styles.buttonSecondaryColor}`,
    },
    watchList: {
      img: AddedButton,
      desc: 'Remove from WatchList',
      class: `btn-success ${styles.buttonSecondaryColor}`,
    },
    none: {
      img: AddButton,
      desc: 'Add to Dashboard',
      class: `btn-dark ${styles.buttonPrimaryColor}`,
    },
  };

  const [actionOnAsset, result] = userApi.useActionOnAssetMutation();

  useEffect(() => {
    if (data) {
      if (data[0].in_assets) {
        setLocation('assets');
      } else if (data[0].in_watch_list) {
        setLocation('watchList');
      } else {
        setLocation('none');
      }
    }
  }, [data]);

  useEffect(() => {
    if (result.isSuccess) {
      dispatch(isAddedFromSearch(true));
      if (result.originalArgs.action === 'add') {
        if (result.originalArgs.place_type === 'asset') {
          setLocation('assets');
        }
        if (result.originalArgs.place_type === 'watchlist') {
          setLocation('watchList');
        }
        showSuccessMessage(result.data.detail || result.data.message);
        result.reset();
      }
      if (result.originalArgs.action === 'delete') {
        setLocation('none');
        showSuccessMessage(result?.data?.message || 'The object has been successfully removed');
        result.reset();
      }
    }
    if (result.isError) {
      showErrorMessage(result.error.data.detail || 'Something went wrong');
      result.reset();
    }
  }, [name, result, type]);

  const deleteHandler = location !== 'none'
    ? async () => {
      await actionOnAsset(handleActionAssetForHeader(type, { id: name, network }, selectButtonData[location].desc));
    }
    : () => {};

  const menuData = [
    {
      name: '+ Add to My Assets',
      action: async (elem) => {
        await actionOnAsset(handleActionAssetForHeader(
          type,
          { id: name, network },
          selectButtonData[location].desc,
          elem,
        ));
      },
    },
    {
      name: '+ Add to Watchlist',
      action: async (elem) => {
        await actionOnAsset(handleActionAssetForHeader(
          type,
          { id: name, network },
          selectButtonData[location].desc,
          elem,
        ));
      },
    },
  ];

  return (
    <div
      className={`${styles.row} ${styles.popoverWrapper}`}
    >
      <button
        type="button"
        onClick={() => deleteHandler()}
        className={`btn ${styles.assetButton} d-flex align-items-center justify-content-center
         ${selectButtonData[location].class}`}
      >
        {!data || result.isLoading || result.isFetching ? (
          <div className="d-flex align-items-center">
            <Spinner />
          </div>
        ) : (
          <span
            className={styles.buttonText}
          >
            <img src={selectButtonData[location].img} alt="fu" />
          </span>
        )}
      </button>
      {location === 'none'
        ? (
          <div className={styles.popoverContentButton}>
            <DropdownMenu menuData={menuData} />
          </div>
        )
        : null}
      {location === 'assets'
        ? (
          <div className={styles.popoverContentButton2}>
            <div className={`${styles.contentTooltip} text-center p-2`}>
              Already in your assets.
              <span
                role="presentation"
                onClick={() => deleteHandler()}
                className={`${styles.read} cursor-pointer px-1`}
              >
                Remove
              </span>
            </div>
          </div>
        )
        : null}
      {location === 'watchList'
        ? (
          <div className={styles.popoverContentButton2}>
            <div className={`${styles.contentTooltip} text-center p-2`}>
              Already in your watchlist.
              <span
                role="presentation"
                onClick={() => deleteHandler()}
                className={`${styles.read} cursor-pointer px-1`}
              >
                Remove
              </span>
            </div>
          </div>
        )
        : null}
    </div>
  );
};

export default AddAssetsFromHeader;
