import React from 'react';

const DropDownFilter = ({
  setPage, setCheckedSegment, checkedSegment, checkedList, setCheckedList,
}) => (
  <div className="d-flex flex-column p-2 align-items-start">
    <div
      className="form-check form-check-inline"
      role="presentation"
      onClick={!checkedList && checkedSegment ? () => {} : () => {
        setCheckedSegment(!checkedSegment);
        setPage(1);
      }}
    >
      <label
        className="form-check-label cursor-pointer"
        htmlFor="inlineCheckbox1"
      >
        <input
          className="form-check-input shadow-none cursor-pointer"
          type="checkbox"
          id="inlineCheckbox1"
          defaultChecked={checkedSegment}
          disabled={!checkedList && checkedSegment}
        />
        <div role="presentation" onClick={(e) => e.stopPropagation()}>Segment</div>
      </label>
    </div>
    <div
      className="form-check form-check-inline"
      role="presentation"
      onClick={checkedList && !checkedSegment ? () => {} : () => {
        setCheckedList(!checkedList);
        setPage(1);
      }}
    >
      <label className="form-check-label cursor-pointer" htmlFor="inlineCheckbox2">
        <input
          className="form-check-input shadow-none cursor-pointer"
          type="checkbox"
          id="inlineCheckbox2"
          defaultChecked={checkedList}
          disabled={checkedList && !checkedSegment}
        />
        <div role="presentation" onClick={(e) => e.stopPropagation()}>Custom List</div>
      </label>
    </div>
  </div>
);

export default DropDownFilter;
