import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useDraggable } from '@dnd-kit/core';
import { nanoid } from 'nanoid';
import Tooltip from '../../../../components/ui/Tooltip';
import uniqueId from '../../../../utils/uniqueId';
import styles from './ToolBox.module.scss';
import { useWindowSize } from '../../../../components/hooks/app';
import { staticFields, contactFields, otherFields } from './ToolboxFields';
import { selectCurrPage, selectFieldSettings } from '../../../../store/reducers/forms';
import { ReactComponent as Chevrons } from '../../../../assets/icons/chevrons-left.svg';

export const ToolboxOverlayField = (props) => {
  const {
    field, overlay, isMinimized, setNodeRef,
  } = props;

  let className = 'sidebar-fields';
  if (overlay) {
    className += ' overlay';
  }

  return (
    <div ref={setNodeRef}>
      <div
        className={`
              ${className}
              ${isMinimized ? 'sidebar-fields_min' : ''}
              ${field.disabled ? 'disabled' : ''}
            `}
      >
        {field.component}
        {' '}
        {!isMinimized && field.name}
      </div>
    </div>
  );
};

const DraggableSidebarField = (props) => {
  const { field, ...rest } = props;
  const id = useRef(nanoid());

  const { attributes, listeners, setNodeRef } = useDraggable({
    id: id.current,
    data: {
      field,
      fromSidebar: true,
    },
  });

  return (
    <div
      className="sidebar-field"
      {...listeners}
      {...attributes}
    >
      <ToolboxOverlayField setNodeRef={setNodeRef} field={field} {...rest} />
    </div>
  );
};

const ToolBox = (props) => {
  const { width } = useWindowSize();
  const [isMinimized, setIsMinimized] = useState(localStorage.getItem('toolbox_minimized'));
  const currPage = useSelector(selectCurrPage);
  const fieldSettings = useSelector(selectFieldSettings);

  const disableFieldsAlreadyAdded = (fieldsArray) => {
    // If there's only one or no field in "main-form", return the fields unmodified
    if (!fieldSettings[0]?.fields?.length > 1) {
      return fieldsArray;
    }

    return fieldsArray.map((field) => {
      const isFieldAdded = fieldSettings[0]?.fields?.some((existingField) => existingField.type === field.type);
      return { ...field, disabled: isFieldAdded };
    });
  };

  const updateContactFields = disableFieldsAlreadyAdded(contactFields);
  const updateOtherFields = disableFieldsAlreadyAdded(otherFields);

  if (width <= 700 && !isMinimized) {
    setIsMinimized(true);
    localStorage.setItem('toolbox_minimized', 'true');
  }

  const { fieldsRegKey } = props;

  return (
    <div
      className={`
      ${styles.wrapper}
      ${isMinimized ? styles.wrapper_min : ''}`}
    >
      <div
        className={`
          d-flex
          ${isMinimized ? 'justify-content-center' : 'justify-content-between'}`}
      >
        {isMinimized
          ? null
          : <div className={styles.description}>FIELDS</div>}
        <div className={`
          d-flex
          align-items-center
          gap-3
          ${isMinimized ? 'flex-column-reverse mb-3' : 'd-flex'}`}
        >
          <div
            className={`
              ${styles.minimize}
              ${isMinimized ? styles.minimize_rotate : ''}
              d-flex
              align-items-center
              justify-content-center
              cursor-pointer`}
            role="presentation"
            onClick={() => {
              setIsMinimized(!isMinimized);
              if (isMinimized) {
                localStorage.removeItem('toolbox_minimized');
              } else {
                localStorage.setItem('toolbox_minimized', 'true');
              }
            }}
          >
            <Chevrons />
          </div>
        </div>
      </div>
      <div className={styles.forms_group}>
        {isMinimized
          ? null
          : (
            <div className={styles.forms_group_title}>
              Static
            </div>
          )}
        {staticFields.map((elem) => (
          <div key={uniqueId('entry')}>
            <div
              key={fieldsRegKey}
            >
              <DraggableSidebarField key={elem.type} field={elem} isMinimized={isMinimized} />
            </div>
            {elem.disabled ? <Tooltip id={elem.name} info="Coming soon" position="unset" /> : null}
          </div>
        ))}
      </div>
      <div className={styles.forms_group}>
        {currPage === 'main-form' && (
          <>
            {isMinimized ? <div className={styles.divider} /> : (
              <div className={styles.forms_group_title}>
                Contact Fields
              </div>
            )}
            {updateContactFields.map((elem) => (
              <div key={uniqueId('split')}>
                <div key={fieldsRegKey}>
                  <DraggableSidebarField key={elem.type} field={elem} isMinimized={isMinimized} />
                </div>
                {elem.disabled ? <Tooltip id={elem.name} info="Coming soon" position="unset" /> : null}
              </div>
            ))}
          </>
        )}
      </div>
      <div className={styles.forms_group}>
        {currPage === 'main-form' && (
          <>
            {isMinimized ? <div className={styles.divider} /> : (
              <div className={styles.forms_group_title}>
                Other fields
              </div>
            )}
            {updateOtherFields.map((elem) => (
              <div key={uniqueId('channels')}>
                <div key={fieldsRegKey}>
                  <DraggableSidebarField key={elem.type} field={elem} isMinimized={isMinimized} />
                </div>
                {elem.disabled ? <Tooltip id={elem.name} info="Coming soon" position="unset" /> : null}
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default ToolBox;
