import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearActiveSettings, selectCurrPage, selectFieldSettings, setFieldSettings,
} from '../../../../../../store/reducers/forms';
import styles from './ParagraphSettings.module.scss';
import formStyles from '../../FormSettings.module.scss';
import { ReactComponent as Close } from '../../../../../../assets/icons/close.svg';

const ParagraphSettings = ({ activeSettings }) => {
  const dispatch = useDispatch();
  const currPage = useSelector(selectCurrPage);
  const fieldSettings = useSelector(selectFieldSettings);
  const [content, setContent] = useState('');
  const [align, setAlign] = useState('left');

  const handleContentChange = (value) => {
    setContent(value);
    const obj = {
      name: 'paragraph',
      content: value,
      align,
      type: 'paragraph',
    };
    dispatch(setFieldSettings({ ...obj, field_id: activeSettings.field_id }));
  };

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline',
        { list: 'ordered' }, { list: 'bullet' },
        'link',
        { align: '' }, { align: 'center' }, { align: 'right' }],
    ],
  };

  useEffect(() => {
    const currPageSettings = fieldSettings.find((o) => o.pageName === currPage);
    if (currPageSettings) {
      const config = currPageSettings.fields?.find((x) => x.field_id === activeSettings.field_id);
      if (config) {
        setContent(config.content);
        setAlign(config.align);
      } else {
        handleContentChange(content);
      }
    }
  }, [activeSettings, currPage, fieldSettings]);

  return (
    <div className={`${styles.wrapper} h-100`}>
      <div className="d-flex flex-column justify-content-between h-100">
        <div>
          <div className={`${formStyles.title} d-flex align-items-center gap-2`}>
            Paragraph Properties
          </div>
          <div className={`${styles.close} d-flex justify-content-end position-absolute`}>
            <Close
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => dispatch(clearActiveSettings())}
            />
          </div>
        </div>
        <div className={`${formStyles.title_input}`}>
          Paragraph text
        </div>
        <div className="pb-5">
          <ReactQuill
            className={styles.quillContainer}
            theme="snow"
            value={content}
            onChange={handleContentChange}
            modules={modules}
          />
        </div>
      </div>
    </div>
  );
};

export default ParagraphSettings;
