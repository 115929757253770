import React from 'react';
import Chart from 'react-apexcharts';
import ArrowUp from '../../../../assets/images/asset/arrow_up.png';
import ArrowDown from '../../../../assets/images/asset/arrow_down.png';
import { convertSmallChart } from '../../../../tools/OptionsChart';
import Loader from '../../../base/Loader';
import styles from './Info.module.scss';

const Info = ({ data }) => (
  <div className="d-flex flex-column">
    <div className="d-flex justify-content-between align-items-center pt-2 position-relative">
      <div className="d-flex flex-column justify-content-between">
        <h3 className={styles.data_header}>{data.header}</h3>
        <div className="d-flex gap-2 align-items-center">
          {data.isLoading
            ? (
              <div className={`${styles.loading_data} d-flex position-relative`}>
                <Loader />
              </div>
            )
            : <span className={styles.data_count}>{data.count}</span>}
          {data.id === 1
          && (
            <div className="d-flex align-items-center gap-1">
              {data.active ? (
                <>
                  <img src={data.active > 0 ? ArrowUp : ArrowDown} alt="arrow" width="12" height="8" />
                  <span className={`${data.active > 0 ? styles.data_percent : styles.data_percent_down} m-0 p-0`}>
                    {data.percentage}
                    %
                  </span>
                </>
              ) : '' }
            </div>
          )}
        </div>
      </div>
      {data.id === 1
          && (
            <div className={`${styles.chart} position-absolute`}>
              { data.chart
                ? (
                  <Chart
                    type="area"
                    options={convertSmallChart(data.chart).options}
                    series={convertSmallChart(data.chart).series}
                    height={116}
                  />
                ) : <div className={`${styles.loading_chart} position-absolute`}><Loader /></div>}
            </div>
          )}
    </div>
  </div>
);

export default Info;
