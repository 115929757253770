import React from 'react';
import styles from '../../../components/ui/modals/ConfirmModal/ConfirmModal.module.scss';
import uniqueStyles from './UniqueModal.module.scss';
import CloseModalButton from '../../../components/ui/modals/components/CloseModalButton';
import useKeydown from '../../../components/hooks/app/useKeydown';
import { ReactComponent as Info } from '../../../assets/icons/info_small.svg';

const UniqueModal = ({
  onCancel, onSubmit, uniqueFields, uniqueField, setUniqueField,
}) => {
  useKeydown('Escape', onCancel);

  return (
    <div
      className={`modal modal-dialog-centered ${styles.modal_wrapper}`}
      id="confirmModal"
      tabIndex="-1"
      aria-labelledby="confirmModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-md modal-phone w-100 position-relative">
        <div className={`${styles.body} modal-content position-relative border-0`}>
          <CloseModalButton onCancel={onCancel} />
          <div>
            <div className={`${styles.title} d-flex justify-content-start`}>
              Choose a unique field
            </div>
          </div>
          <div className={`${styles.description}`}>
            <span>
              Your import contains multiple reference fields. For your data to be imported,
              please select which of the following fields should be considered unique in your file.
            </span>
            <div className={`${uniqueStyles.input} w-100 mt-3`}>
              {uniqueFields.map((field) => (
                <div className="d-flex align-items-center gap-1 mb-2" key={field}>
                  <input
                    type="radio"
                    id={field}
                    checked={field === uniqueField}
                    style={{ width: '20px', height: '20px' }}
                    onChange={() => {
                      if (uniqueField !== field) {
                        setUniqueField(field);
                      }
                    }}
                  />
                  <label htmlFor={field}>{field}</label>
                </div>
              ))}
            </div>
            <div className={`${uniqueStyles.info} d-flex gap-2 mt-3`}>
              <div>
                <Info />
              </div>
              <div>
                If you are unsure which field to pick, please reach out to your
                Customer Success Manager who can assist you.
              </div>
            </div>
          </div>
          <div className={`${styles.footer} d-flex justify-content-end`}>
            {onCancel && (
              <button
                type="button"
                className="outline-button border border-1"
                data-bs-dismiss="modal"
                onClick={() => onCancel()}
              >
                Cancel
              </button>
            )}
            <button
              type="button"
              className="regular-button"
              data-bs-dismiss="modal"
              onClick={() => onSubmit()}
              disabled={!uniqueField}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UniqueModal;
