import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { ethers } from 'ethers';
import { ReactComponent as Mail } from '../../../assets/icons/email_white.svg';
import { ReactComponent as Twitter } from '../../../assets/icons/twitter_white.svg';
import { ReactComponent as Discord } from '../../../assets/icons/discord_white.svg';
import { ReactComponent as Phone } from '../../../assets/icons/phone_white.svg';
import { ReactComponent as Airdrop } from '../../../assets/icons/airdrop_white.svg';
import { ReactComponent as Telegram } from '../../../assets/icons/telegram_white.svg';
import { ReactComponent as XMTP } from '../../../assets/icons/xmtp_reach.svg';
import EditField from './EditField';
import uniqueId from '../../../utils/uniqueId';
import { LoadingLines } from '../../../components/ui/modals/SearchModal/LoadingList';
import { profilesApi } from '../../../api/profiles';
import { showErrorMessage, showSuccessMessage } from '../../../components/base/Notifications';
import Spinner from '../../../components/base/Spinner';
import { isInValidData } from '../../../utils/regExp';
import { getIsCustomDataEdit, isCustomDataEdit } from '../../../store/reducers/app';
import styles from './Reach.module.scss';

const Reach = ({ data, isWalletLoading, setReachData }) => {
  const dispatch = useDispatch();
  const [content, setContent] = useState([]);
  const { name } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const isCustomBlockEdit = useSelector(getIsCustomDataEdit);

  const {
    register, unregister, watch, setValue, setFocus, handleSubmit, reset,
  } = useForm();

  const [contactInfo, result] = profilesApi.usePostWalletCustomDataMutation();
  const [hasChanges, setHasChanges] = useState(false);
  const [onAccept, setOnAccept] = useState(false);
  const [latestUpdatedData, setLatestUpdatedData] = useState(null);

  const isWalletAddress = (test) => {
    const pattern = /^\/profile\/0x[a-fA-F0-9]{40}$/;
    return pattern.test(test);
  };

  const onSubmit = useCallback((submitData) => {
    setLatestUpdatedData(submitData);
    if ((Object.keys(submitData).length && !Object.values(submitData).some((elem) => !elem)
      && !isInValidData(submitData)) || !isCustomBlockEdit) {
      contactInfo({ address: ethers.utils.isAddress(name) ? `?address=${name}` : `?alid=${name}`, body: submitData });
      setHasChanges(false);
    }
  }, [contactInfo, isCustomBlockEdit, name, data]);

  useEffect(() => {
    if (result.isSuccess) {
      showSuccessMessage('Contact info was edited successfully');
      dispatch(isCustomDataEdit(true));
      setReachData({
        ...data,
        ...latestUpdatedData,
      });
      reset();
      result.reset();
      if (isWalletAddress(location.pathname) && result.data?.alid) {
        navigate(`/profile/${result.data?.alid}`);
      }
    }
    if (result.isError) {
      if (result?.error?.data?.errors?.length) {
        result.error.data.errors.forEach((error) => showErrorMessage(Object.values(error)[0][0]));
      } else {
        showErrorMessage('Something went wrong');
      }
      result.reset();
    }
  }, [data, dispatch, reset, result, setReachData, latestUpdatedData]);

  useEffect(() => {
    if (data) {
      setContent([
        {
          id: 'email',
          icon: <Mail />,
          info: data.email || '',
          message: 'Invalid e-mail address',
        },
        {
          id: 'twitter_id',
          icon: <Twitter />,
          info: data.twitter_id || '',
          isClick: true,
          message: 'Invalid Twitter username',
        },
        {
          id: 'discord_id',
          icon: <Discord />,
          info: data.discord_id || '',
          message: 'Invalid Discord ID',
          isInvalid: false,
        },
        {
          id: 'telegram_id',
          icon: <Telegram />,
          info: data.telegram_id || '',
          message: 'Invalid Telegram username',
          isInvalid: false,
        },
        {
          id: 'phone',
          icon: <Phone />,
          info: data.phone || '',
          message: 'Invalid phone number',
        },
        {
          icon: <XMTP />,
          info: (
            <div className={`${data.xmtp ? '' : 'opacity-50'} px-1`}>
              {data.xmtp ? data.ensName : 'not enabled'}
            </div>),
        },
        {
          icon: <Airdrop />,
          info: data.airdrop,
        },
      ]);
    }
  }, [data]);

  const downHandler = useCallback((event) => {
    if (event.key === 'Enter' && !isCustomBlockEdit) {
      handleSubmit(onSubmit)();
    }
  }, [handleSubmit, isCustomBlockEdit, onSubmit]);

  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  }, [downHandler, isCustomBlockEdit]);

  useEffect(() => {
    if (data && onAccept) {
      const watchedValues = watch();
      const changed = Object.entries(data).some(
        ([key, value]) => (watchedValues[key] !== undefined
            && watchedValues[key] !== value)
          || (watchedValues[key] === '' && value !== ''),
      );
      setHasChanges(changed);
    }
    setOnAccept(false);
  }, [data, watch, onAccept, setOnAccept]);

  const reachContent = useMemo(() => {
    if (isWalletLoading) {
      return (
        <LoadingLines rows={3} />
      );
    }
    return (
      <>
        <div className={`${styles.channels} mb-3 text-nowrap`}>
          <span className="me-2">{data?.channels}</span>
          {`channel${data?.channels !== 1 ? 's' : ''} identified`}
        </div>
        {content.map((elem) => (
          <div className={`${styles.row} d-flex gap-3 position-relative`} key={uniqueId('reach-info')}>
            <div>
              {elem.icon}
            </div>
            <EditField
              elem={elem}
              register={register}
              unregister={unregister}
              setFocus={setFocus}
              setValue={setValue}
              watch={watch}
              setOnAccept={setOnAccept}
            />
          </div>
        ))}
      </>
    );
  }, [content, data?.channels, isWalletLoading, register, setFocus, setValue, unregister, watch]);

  return (
    <form className={styles.wrapper} onSubmit={handleSubmit(onSubmit)}>
      <div className="d-flex justify-content-between">
        <div>
          <div className={`${styles.title} mb-1`}>
            Reach
          </div>
          <div className={styles.divider} />
        </div>
        {result.isLoading || result.isFetching ? (
          <Spinner />
        ) : hasChanges && !isCustomBlockEdit ? (
          <button
            type="submit"
            className={styles.submit}
            disabled={isInValidData(watch())}
            onKeyPress={downHandler}
          >
            Save changes
          </button>
        ) : null}
      </div>
      {reachContent}
    </form>
  );
};

export default Reach;
