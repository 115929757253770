import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { coinApi } from '../../../api/coin';
import TradersAndInvestComponent from '../../../components/ui/TradersAndInvestComponent';
import NotableInvestments from '../../../components/ui/NotableInvestments';
import SharedInterests from '../../../components/ui/SharedInterests';
import SharePortfolio from '../../../components/ui/SharePortfolio';
import NotSupportedBlockchain from '../../../components/NotSupportedBlockchain';
import styles from './InvestorProfile.module.scss';

const InvestorProfile = ({
  holders, symbol, assetsRefetch, isSupportedBlockchain,
}) => {
  const { pathname } = useLocation();

  const {
    currentData: coinActiveAddresses,
    isLoading: isCoinAddressesLoading,
    isFetching: isCoinActiveAddressesFetching,
  } = coinApi.useGetActiveAddressesCountCoinsQuery(pathname.split('/')[2], {
    skip: !pathname?.includes('coin'),
  });

  const {
    currentData: coinTradersOneDay,
    isLoading: isCoinTradersOneDayLoading,
    isFetching: isCoinTradersFetching,
  } = coinApi.useGetCoinTradersQuery({
    id: pathname.split('/')[2],
    days: 1,
  }, {
    skip: !pathname?.includes('coin'),
  });

  const {
    currentData: notableInvestments,
    isLoading: isNotableInvestmentsLoading,
    isFetching: isNotableInvestmentsFetching,
  } = coinApi.useGetTokenNotableInvestmentsQuery({
    id: pathname.split('/')[2],
    limit: 6,
  }, {
    skip: !pathname?.includes('coin'),
  });

  const {
    currentData: sharedInterestsData,
    isLoading: sharedInterestsIsLoading,
    isFetching: sharedInterestsIsFetching,
  } = coinApi.useGetGroupInterestQuery(pathname.split('/')[2], {
    skip: !pathname?.includes('coin'),
  });

  const {
    currentData: sharePortfolioData,
    isLoading: sharePortfolioIsLoading,
    isFetching: sharePortfolioIsFetching,
  } = coinApi.useGetSharePortfolioQuery(pathname.split('/')[2], {
    skip: !pathname?.includes('coin'),
  });

  return useMemo(() => {
    if (!isSupportedBlockchain) {
      return <NotSupportedBlockchain />;
    }
    return (
      <div className={styles.wrapper}>
        <div className="row">
          <div className="col-12 col-lg-6 col-xl-4">
            <TradersAndInvestComponent
              holders={holders?.holder_count}
              title="Avg Risk Profile"
              data={coinTradersOneDay}
              isFetching={isCoinTradersFetching}
              isLoading={isCoinTradersOneDayLoading}
              info="Risk profile of investors in this token, measured by the type of investments made"
            />
          </div>
          <div className="col-12 col-lg-6 col-xl-4 mt-4 mt-lg-0">
            <TradersAndInvestComponent
              holders={holders?.holder_count}
              title={`Invest. in $${symbol.toUpperCase()}`}
              data={coinActiveAddresses}
              isLoading={isCoinAddressesLoading}
              isFetching={isCoinActiveAddressesFetching}
              info="Typical duration of investment in the token"
            />
          </div>
          <div className="col-12 col-lg-6 col-xl-4 mt-4 mt-xl-0">
            <SharePortfolio
              data={sharePortfolioData}
              isLoading={sharePortfolioIsLoading}
              isFetching={sharePortfolioIsFetching}
            />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12 col-lg-6 col-xl-4 mt-4 mt-xl-0">
            <NotableInvestments
              text="tokens"
              symbol={symbol}
              isNotableInvestmentsLoading={isNotableInvestmentsLoading}
              isNotableInvestmentsFetching={isNotableInvestmentsFetching}
              data={notableInvestments?.tokens}
              holders={holders?.relevant_holder_count}
              info="Tokens most commonly held by holders of this token"
              assetsRefetch={assetsRefetch}
            />
          </div>
          <div className="col-12 col-lg-6 col-xl-4 mt-4 mt-xl-0">
            <NotableInvestments
              text="NFTs"
              symbol={symbol}
              isNotableInvestmentsLoading={isNotableInvestmentsLoading}
              isNotableInvestmentsFetching={isNotableInvestmentsFetching}
              data={notableInvestments?.nfts}
              holders={holders?.relevant_holder_count}
              info="NFTs most commonly held by holders of this token"
              assetsRefetch={assetsRefetch}
            />
          </div>
          <div className="col-12 col-lg-6 col-xl-4 mt-4 mt-xl-0">
            <SharedInterests
              symbol={symbol}
              data={sharedInterestsData}
              isLoading={sharedInterestsIsLoading}
              isFetching={sharedInterestsIsFetching}
              info="Topics of interest for holders of this token"
            />
          </div>
        </div>
      </div>
    );
  }, [
    assetsRefetch,
    coinActiveAddresses,
    coinTradersOneDay,
    holders?.holder_count,
    holders?.relevant_holder_count,
    isCoinActiveAddressesFetching,
    isCoinAddressesLoading,
    isCoinTradersFetching,
    isCoinTradersOneDayLoading,
    isNotableInvestmentsFetching,
    isNotableInvestmentsLoading,
    isSupportedBlockchain,
    notableInvestments?.nfts,
    notableInvestments?.tokens,
    sharePortfolioData,
    sharePortfolioIsFetching,
    sharePortfolioIsLoading, sharedInterestsData, sharedInterestsIsFetching, sharedInterestsIsLoading, symbol]);
};

export default InvestorProfile;
