import React from 'react';
// import { useSelector } from 'react-redux';
import { format } from 'date-fns';
// import { LoadingAvatar, LoadingLines } from '../../modals/SearchModal/LoadingList';
// import DefaultIcon from '../../DefaultIcon';
// import { selectSelectedAsset } from '../../../../store/reducers/app';
import { showInfoMessage } from '../../../base/Notifications';
import { ReactComponent as Copy } from '../../../../assets/icons/copy_outline.svg';
import styles from '../TitleSection.module.scss';

const SingleFormTitle = ({ isLoading, formData }) => {
//   const selectedAsset = useSelector(selectSelectedAsset);

  const data = {
    name: 'This is Test data Form Name',
    status: 'Active',
    created_at: '2021-09-01T12:00:00.000Z',
    created_by: 'John Doe',
    url: 'wrm.link/formcode',
    isLoading,
    formData,
  };

  //   const [data, setData] = useState({});
  //   const [logoError, setLogoError] = useState(false);

  //   useEffect(() => {
  //     if (isWebLoading && !Object.keys(selectedAsset).length) {
  //       setData({});
  //     }
  //     if (Object.keys(selectedAsset).length && !trackedData) {
  //       setData(selectedAsset);
  //     }
  //     if (trackedData) {
  //       setData(trackedData);
  //     }
  //   }, [isWebLoading, selectedAsset, trackedData]);

  return (
    <div className={styles.coinWrapper}>
      <div className={styles.imageContainer}>
        {/* {!data.logo && isWebLoading
          ? <LoadingAvatar size={75} />
          : data.logo && !logoError ? (
            <img
              src={data.logo}
              width="75px"
              height="75px"
              alt="logo"
              onError={() => setLogoError(true)}
            />
          ) : <DefaultIcon type="token" size="75px" />} */}
      </div>
      <div className="d-flex flex-column">
        <div className={styles.row}>
          <div className="d-flex flex-wrap gap-2">
            {/* {isWebLoading
              ? (
                <div className={`${styles.loadingLinesWrapper} w-25`}>
                  <LoadingLines align="start" />
                </div>
              )
              : ( */}
            <div className="d-flex flex-column gap-2">
              <div className={`${styles.title} d-flex gap-2 align-items-center`}>
                {data.name}
                <div className={`${styles.active_pill} px-3 d-flex align-items-center`}>
                  {data.status}
                </div>
              </div>
              <div className={styles.author}>
                Created by
                {' '}
                {data.created_by}
                {' '}
                on
                {' '}
                {format(new Date(data.created_at), 'dd MMM yyyy')}
              </div>
              <div className="d-flex gap-2 align-items-center">
                {data.url}
                <Copy
                  className="cursor-pointer"
                  onClick={() => {
                    navigator.clipboard.writeText(data.url);
                    showInfoMessage('The address was copied to your clipboard');
                  }}
                />
                <button
                  type="button"
                  // className="regular-button text-nowrap"
                  className={`${styles.share} regular-button text-nowrap`}
                >
                  Share
                </button>
              </div>
            </div>
          </div>
          <div className={`${styles.flexAlignRight} d-flex gap-2`}>
            <button
              type="button"
              className="outline-blue-button text-nowrap"
            //   // data-bs-toggle="modal"
            //   // data-bs-target="#exampleModal"
            //   onClick={() => {}}
            //   disabled={isWebLoading}
            >
              Create Flow
            </button>
            <button
              type="button"
              className="regular-button text-nowrap"
            //   // data-bs-toggle="modal"
            //   // data-bs-target="#exampleModal"
            //   onClick={() => {}}
            //   disabled={isWebLoading}
            >
              Create Form
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleFormTitle;
