import React, { useEffect, useState, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { flowsApi } from '../../../api/flows';
import { useWindowSize } from '../../../components/hooks/app';
import { counter } from '../../../tools/Counter';
import { ReactComponent as Trash } from '../../../assets/icons/table/trash.svg';
import { ReactComponent as DisabledTrash } from '../../../assets/icons/disabled_trash.svg';
import { ReactComponent as Copy } from '../../../assets/icons/table/copy.svg';
import { showErrorMessage, showSuccessMessage } from '../../../components/base/Notifications';
import ConfirmModal from '../../../components/ui/modals/ConfirmModal';
import Tooltip from '../../../components/ui/Tooltip';
import Table from '../../../components/base/Table';
import Settings from '../../../assets/images/dashboard/settings.png';
import tableStyles from '../../../components/base/Table/Table.module.scss';
import styles from './FlowsList.module.scss';

const FlowsList = ({
  data, count, refetch, ordering, setOrdering, desc, setDesc, page, setPage, isLoading, limit, setLimit,
}) => {
  const navigate = useNavigate();
  const { width } = useWindowSize();

  const [clickFlow, setClickFlow] = useState();
  const [copy, setCopy] = useState(null);
  const [tableData, setTableData] = useState([]);

  const [deleteTeamFlow, deleteResult] = flowsApi.useDeleteFlowMutation();

  const [copyTeamFlow, copyResult] = flowsApi.useCopyFlowMutation();

  const header = [
    {
      field: 'name',
      title: 'Flow name',
      width: '400px',
    },
    {
      field: 'status',
      title: 'Status',
    },
    {
      field: 'created_at',
      title: 'Creation day',
    },
    {
      field: 'created_by',
      title: 'Creator',
    },
    {
      field: 'updated_at',
      title: 'Edited',
    },
    {
      field: 'modified_by',
      title: 'Last editor',
    },
    {
      title: (
        <div className="d-flex justify-content-center">
          Actions
        </div>
      ),
    },
  ];

  const handleDate = (date) => date.slice(0, 10);

  const onResultReceive = useCallback((response, type) => {
    if (response.isSuccess) {
      showSuccessMessage(`Flow was successfully ${type}`);
      if (type === 'copied') {
        setCopy(null);
      }
      if (type === 'deleted') {
        setClickFlow(null);
      }
      if (data.length === 1 && page > 1) {
        setPage(page - 1);
      } else {
        refetch();
      }
      response.reset();
    }
    if (response.isError) {
      showErrorMessage(Object.keys(response.error.data).length !== 0 && response.error.data.detail);
      response.reset();
    }
  }, [data.length, page, refetch, setPage]);

  useEffect(() => {
    onResultReceive(copyResult, 'copied');
  }, [copyResult, onResultReceive]);

  useEffect(() => {
    onResultReceive(deleteResult, 'deleted');
  }, [deleteResult, onResultReceive]);

  useEffect(() => {
    if (data) {
      const temp = data.map((elem) => ({
        name: (
          <>
            <span
              className={`d-block ${tableStyles.underline_on_hover}`}
              data-for={`Name_${elem.id}`}
              data-tip
            >
              <Link
                role="presentation"
                to={`/add-flow/${elem.id}`}
                className={`${styles.name} text-truncate`}
              >
                {elem.name}
              </Link>
            </span>
            {elem.name.length > width / 50 && (
              <Tooltip
                truncate
                id={`Name_${elem.id}`}
                info={elem.name}
              />
            )}
          </>
        ),
        status: (<span className="text-capitalize">{elem.status}</span>),
        created_at: handleDate(elem.created_at),
        created_by: elem.created_by,
        updated_at: handleDate(elem.updated_at),
        modified_by: elem.modified_by,
        button: (
          <div className={`ms-auto ${tableStyles.popover_wrapper} text-center position-relative cursor-pointer`}>
            <img
              src={Settings}
              className="cursor-pointer"
              height="25"
              width="25"
              alt="settings"
            />
            <div className={`${tableStyles.invisible_dropdown} position-absolute top-0 right-0`}>
              <div className={`${tableStyles.popover_content} position-absolute`}>
                <div
                  className={`
                    d-flex
                    justify-content-start
                    align-items-center
                    gap-1
                    ${tableStyles.modal_row}`}
                  onClick={() => setCopy({ name: elem.name, id: elem.id })}
                  role="presentation"
                >
                  <Copy />
                  Copy
                </div>
                {elem.status === 'running' || elem.status === 'scheduled'
                  ? (
                    <>
                      <Tooltip
                        fixWidth
                        id={`delete_info_${elem.id}`}
                        info={`You can’t delete the “${elem.status}” flow`}
                      />
                      <div
                        className={`
                          d-flex
                          justify-content-start
                          align-items-center
                          gap-1
                          ${tableStyles.modal_row}`}
                        data-for={`delete_info_${elem.id}`}
                        data-tip
                      >
                        <DisabledTrash />
                        Delete
                      </div>
                    </>
                  ) : (
                    <div
                      className={`
                        d-flex
                        justify-content-start
                        align-items-center
                        gap-1
                        ${tableStyles.modal_row}`}
                      onClick={() => {
                        setClickFlow({
                          name: elem.name,
                          id: elem.id,
                        });
                      }}
                      role="presentation"
                    >
                      <Trash />
                      Delete
                    </div>
                  )}
              </div>
            </div>
          </div>),
      }));
      setTableData(temp);
    }
  }, [data]);

  return (
    <div className={`${styles.wrapper} asset-section d-flex flex-column justify-content-between`}>
      <div>
        { copy && (
          <ConfirmModal
            title="Confirm copy"
            description={`Are you sure you want to copy the “${copy.name}” flow with its configurations?`}
            buttonName="Copy"
            onSubmit={() => copyTeamFlow(copy.id)}
            onCancel={() => setCopy(null)}
            loading={copyResult.isLoading || copyResult.isFetching}
          />
        )}
        { clickFlow && (
          <ConfirmModal
            title="Confirm delete"
            description={`Are you sure you want to delete “${clickFlow?.name?.length > 30
              ? `${clickFlow?.name.slice(0, 30)}...` : clickFlow?.name}”?`}
            buttonName="Delete"
            onSubmit={() => deleteTeamFlow(clickFlow.id)}
            onCancel={() => setClickFlow(null)}
            loading={deleteResult.isLoading || deleteResult.isFetching}
          />
        )}
        <div
          className={`d-flex justify-content-between align-items-center ${styles.responsive}`}
        >
          <div className={`${styles.showing} d-flex justify-content-between flex-column mb-3`}>
            <div className="title-h4-bold mb-2">Saved flows</div>
            {counter(count, page, limit)}
          </div>
          <button
            className="regular-button mb-3"
            type="button"
            onClick={() => {
              navigate('/add-flow');
            }}
          >
            Create a Flow
          </button>
        </div>
        <div className="w-100">
          <Table
            total={count}
            data={tableData}
            desc={desc}
            setDesc={setDesc}
            ordering={ordering}
            setOrdering={setOrdering}
            page={page}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
            headers={header}
            isLoading={isLoading}
            min={10}
          />
        </div>
      </div>
    </div>
  );
};

export default FlowsList;
