import React, { useEffect, useState } from 'react';
// import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import {
  Link, useParams, useSearchParams, useLocation,
} from 'react-router-dom';
import { useNavigationType } from 'react-router';
import SingleFormTitle from '../../../components/ui/TitleSection/Titles/SingleFormTitle';
import FormActivityGraph from './FormActivityGraph';
import { selectTabPages, setTabPages } from '../../../store/reducers/forms';
import uniqueId from '../../../utils/uniqueId';
import ResponsesTable from './FormAnalyticsTabs/Responses';
import ComingSoon from '../../../components/ComingSoon';

const FormAnalytics = () => {
//   const { id } = useParams();
  const navType = useNavigationType();
  const tabPages = useSelector(selectTabPages);
  const { pathname, search } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const [isLoaded, setIsLoaded] = useState(false);
  const [itemPage, setItemPage] = useState(searchParams.get('tab')?.replace('+', ' ') || 'Responses');

  const isFetching = false;
  const isLoading = false;
  //   const navType = 'POP';

  //   useEffect(() => {
  //     if (navType !== 'POP') {
  //       refetch();
  //     }
  //   }, [navType, refetch]);

  useEffect(() => () => {
    setIsLoaded(false);
    dispatch(setTabPages([]));
  }, [dispatch]);

  const pages = [
    { label: 'Responses', component: <ResponsesTable />, id: 1 },
    { label: 'Sources', component: <ComingSoon />, id: 2 },
    { label: 'Audiences', component: <ComingSoon />, id: 3 },
    { label: 'Configuration', component: <ComingSoon />, id: 4 },
  ];

  return (
    <div>
      <SingleFormTitle />
      <div className="asset-section mt-3 pb-4">
        <FormActivityGraph isLoading={isLoading || isFetching} />
      </div>
      <div className="row asset-section m-o mt-4">
        <div className="title-gap asset-navigation">
          <ul className="nav nav-pills gap-2">
            {(navType === 'POP' && tabPages?.length ? tabPages : pages).map((elem) => (
              <li
                role="presentation"
                className="nav-item"
                key={uniqueId('segment-analytics-tab')}
                onClick={() => {
                  setItemPage(elem.label);
                  setSearchParams({
                    tab: elem.label,
                    scroll: '',
                  });
                }}
              >
                <Link
                  className={`nav-link ${
                    elem.label === itemPage ? 'active' : ''
                  }`}
                  to={`${pathname}${search}`}
                >
                  {elem.label}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="dropdown-divider w-100" />
        <div className="pb-2 px-4">
          {(navType === 'POP' && tabPages?.length ? tabPages : pages).map((item) => (item.label === itemPage ? (
            <div key={uniqueId('form-analytics-page')}>{item.component}</div>
          ) : null))}
        </div>
      </div>
    </div>
  );
};

export default FormAnalytics;
