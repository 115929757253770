import { audiencesApi } from '../../api/audiences';
import { apiTypes } from './constants';

export const getUrl = (val, type, data) => {
  if (!type || !apiTypes.includes(type)) {
    return null;
  }
  let url;
  let value;
  switch (type) {
    case 'interests':
      url = audiencesApi.endpoints.getInterests; // .initiate( { search: val, offset: 0, limit: 50 },);
      value = { search: val, offset: 0, limit: 50 };
      break;
    case 'custom':
      url = audiencesApi.endpoints.searchCustomData;
      value = { name: data.name, val: val || '' };
      break;
    case 'customList':
      url = audiencesApi.endpoints.getFlowsCustomLists;
      value = val;
      break;
    case 'cexOptions':
      url = audiencesApi.endpoints.getCEXValues;
      value = { value: val || '', limit: 137, offset: 0 };
      break;
    case 'segments':
      url = audiencesApi.endpoints.getFlowsSegments;
      value = val;
      break;
    case 'dapps':
      url = audiencesApi.endpoints.getDappValues;
      value = { value: val || '', offset: 0, limit: 100 };
      break;
  }

  return { url, value };
};

export const parseData = (apiData, type) => {
  if (!type || !apiTypes.includes(type)) return;
  let returnData = null;
  switch (type) {
    case 'interests':
      if (apiData.status === 'fulfilled') {
        returnData = apiData.data.results.map((interest) => ({
          value: interest.id,
          label: interest.name,
        }));
      }
      break;
    case 'custom':
      if (apiData.status === 'fulfilled') {
        returnData = apiData.data.map((item) => ({
          value: item.field_name || 'No value',
          label: item.num_of_addresses,
        }));
      }
      break;
    case 'customList':
      if (Boolean(apiData?.data?.results) && Array.isArray(apiData?.data?.results)) {
        return apiData.data.results.map((item) => ({
          value: item.id,
          label: item.name,
          additionalInfo: item.population,
        }));
      }
      break;
    case 'cexOptions':
      if (apiData.status === 'fulfilled') {
        return apiData.data.results.map((item) => ({
          value: item.id,
          label: item.name,
        }));
      }
      break;
    case 'dapps':
      if (apiData.status === 'fulfilled') {
        return apiData.data.map((item) => ({
          value: item.unique_id,
          label: item.name,
          category: item.category,
          isEth: item.include_eth_value,
          isNft: item.include_nft_transfers,
        }));
      }
      break;
    case 'segments':
      if (apiData.status === 'fulfilled') {
        return apiData.data.results.filter((item) => item?.ready).map((item) => ({
          value: item.id,
          label: item.name,
          additionalInfo: item.population,
        }));
      }
      break;
  }

  return returnData;
};
