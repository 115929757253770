import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { audiencesApi } from '../../../api/audiences';
import { getWalletAge } from '../../../utils/segments';
import { convertToReadableFormat } from '../../../tools/NumberConverterTool';
import { counter } from '../../../tools/Counter';
import Loader from '../../../components/base/Loader';
import uniqueId from '../../../utils/uniqueId';
import DownloadModal from '../../../components/ui/modals/DownloadModal';
import DownloadTooltip from '../../../components/ui/DownloadTooltip';
import { showSuccessMessage, showErrorMessage } from '../../../components/base/Notifications';
import SaveSnapshotModal from '../../../components/ui/modals/SaveSnapshotModal';
import Tooltip from '../../../components/ui/Tooltip';
import Table from '../../../components/base/Table';
import tableStyles from '../../../components/base/Table/Table.module.scss';
import ExportCSV from '../../../components/svgIcons/ExportCSV';
import Snapshot from '../../../components/svgIcons/Snapshot';
import ItemPreview from '../../../components/ui/ItemPreview';
import ConfirmModal from '../../../components/ui/modals/ConfirmModal';
import { tooltipChainIconPicker } from '../../../tools/ChainIconPicker';
import styles from './WalletList.module.scss';
import { aliasData } from '../../../utils/wallets';
import { setSelectedAsset } from '../../../store/reducers/app';

const warningsList = [
  'No more than 50,000 profiles will be downloaded',
  'Make sure the latest segment changes are saved to get up-to-date data',
];

const WalletList = ({ setShowDialog, userSegment }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [field, setField] = useState('total_portfolio_value');
  const [page, setPage] = useState(1);
  const [isDownloaded, setIsDownloaded] = useState(false);
  const [isCSVActive, setIsCSVActive] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [desc, setDesc] = useState(true);
  const [limit, setLimit] = useState(25);
  const [tableData, setTableData] = useState([]);

  const toolTipId = useMemo(() => uniqueId('download'), []);
  const csvToolTipId = useMemo(() => uniqueId('csv'), []);

  const {
    data: walletsData,
    isLoading: isWalletsDataLoading,
    isFetching: isWalletsDataFetching,
    refetch: walletsDataRefetch,
  } = audiencesApi.useGetSegmentWalletsDataQuery(
    {
      userSegment, limit, offset: (page - 1) * limit, sort: desc ? 'desc' : 'asc', sortBy: field,
    },
  );

  const [isActive, setIsActive] = useState(false);
  const [snapshotItem, setSnapshotItem] = useState(false);
  const [snapshotName, setSnapshotName] = useState('');
  const [getWalletCSV, result] = audiencesApi.useGetWalletsCSVMutation();
  const [snapshotSegment, snapshotSegmentResult] = audiencesApi.useSnapshotSegmentsMutation();
  const [snapshotDraftSegment, snapshotDraftSegmentResult] = audiencesApi.useSaveCustomListFromSegmentDraftMutation();
  const [csvWallets, csvWalletsResult] = audiencesApi.useDownloadSegmentWalletsCSVMutation();

  const downloading = () => {
    setShowDialog(false);
    getWalletCSV({
      id,
      wait: true,
    });
  };

  const header = [
    {
      id: 1,
      field: 'address_name',
      title: 'Address Name',
    },
    {
      id: 2,
      field: '',
      title: 'Alias',
    },
    {
      id: 2,
      field: 'total_portfolio_value',
      title: 'Portfolio Value',
    },
    {
      id: 3,
      field: 'first_txn_timestamp',
      title: 'Wallet age',
    },
    {
      id: 4,
      title: 'Active on',
    },
  ];

  useEffect(() => {
    if (walletsData?.profile_data) {
      const temp = walletsData.profile_data.map((elem) => ({
        address_name: (
          <>
            <span
              className={`d-block ${tableStyles.underline_on_hover}`}
              data-for={`Name_${elem.ALID || elem.address}`}
              data-tip
            >
              <Link className="disable-link-styles" to={`/profile/${elem.ALID || elem.address}`}>
                <div
                  role="presentation"
                  className={`${styles.name} text-truncate`}
                  onClick={() => dispatch(setSelectedAsset({}))}
                >
                  {elem.address_name}
                </div>
              </Link>
            </span>
            <ItemPreview
              id={`Name_${elem.ALID || elem.address}`}
              data={{
                id: elem.address,
                alid: elem.ALID || '',
                name: elem.address_name || elem.address,
                type: 'wallet',
              }}
            />
          </>
        ),
        alias: (
          <div className="d-flex gap-2 align-items-baseline">
            {elem?.alias
              ? (
                <>
                  <span>{aliasData[elem.alias_type]}</span>
                  <span>{elem.alias}</span>
                </>
              )
              : '-'}
          </div>
        ),
        total_portfolio_value: `${elem.total_portfolio_value < 0 ? '?'
          : (elem.total_portfolio_value !== null
            ? `${convertToReadableFormat(elem.total_portfolio_value)} USD` : '0 USD')}`,
        first_txn_timestamp: getWalletAge(elem.first_txn_timestamp),
        chain: (
          <div className="d-flex justify-content-center w-50">
            {elem.first_txn_timestamp_eth && tooltipChainIconPicker('ethereum', uniqueId('chain'))}
            {elem.first_txn_timestamp_polygon && tooltipChainIconPicker('polygon', uniqueId('chain'))}
          </div>
        ),
      }));
      setTableData(temp);
    }
  }, [walletsData, dispatch]);

  useEffect(() => {
    walletsDataRefetch();
  }, [walletsDataRefetch, field]);

  useEffect(() => {
    if (result.isLoading || csvWalletsResult.isLoading) {
      setIsOpen(true);
    }
    if ((result.isSuccess && result.data) || (csvWalletsResult.isSuccess && csvWalletsResult.data)) {
      const handleOpen = () => {
        setTimeout(() => {
          if (result.isSuccess && result.data) {
            window.location.replace(result.data[0].link);
          } else {
            window.location.replace(csvWalletsResult.data[0].link);
          }
          setShowDialog(true);
        }, 1000);
      };
      handleOpen();
    }
    if (csvWalletsResult.error?.status === 403) {
      setIsOpen(false);
    }
    return () => clearTimeout();
  }, [csvWalletsResult, result, setShowDialog]);

  useEffect(() => {
    if (snapshotDraftSegmentResult.isSuccess) {
      showSuccessMessage('Segment was successfully saved as Custom List');
      setSnapshotItem(false);
      snapshotDraftSegmentResult.reset();
    }
    if (snapshotDraftSegmentResult.isError) {
      showErrorMessage(snapshotDraftSegmentResult?.error?.data?.detail || 'Something went wrong');
      setSnapshotItem(false);
      snapshotDraftSegmentResult.reset();
    }
  }, [snapshotDraftSegmentResult, navigate]);

  useEffect(() => {
    if (snapshotSegmentResult.isSuccess) {
      showSuccessMessage('Segment was successfully saved as Custom List');
      setSnapshotItem(false);
      snapshotSegmentResult.reset();
    }
    if (snapshotSegmentResult.isError) {
      showErrorMessage(snapshotSegmentResult?.error?.message || 'Something went wrong');
      setSnapshotItem(false);
      snapshotSegmentResult.reset();
    }
  }, [snapshotSegmentResult, navigate]);

  return (
    <div className={styles.wrapper}>
      <div className={`title-h4-bold ${styles.adaptive}`}>
        Audience profiles
      </div>
      {(isWalletsDataLoading || isWalletsDataFetching) && !tableData.length ? <Loader /> : (
        <>
          <div className={`${styles.showing} d-flex d-flex justify-content-between align-items-center my-2`}>
            {counter(walletsData?.profile_count, page, limit)}
            <div className="d-flex gap-2">
              {id ? (
                <button
                  type="button"
                  className="btn d-flex align-items-center justify-content-center shadow-none m-0 p-0"
                  data-for={toolTipId}
                  data-tip
                  onMouseEnter={() => setIsDownloaded(true)}
                  onMouseLeave={() => setIsDownloaded(false)}
                  onClick={() => downloading()}
                >
                  <ExportCSV hovered={isDownloaded} />
                </button>
              ) : (
                <>
                  <button
                    type="button"
                    className="btn d-flex align-items-center justify-content-center shadow-none m-0 p-0"
                    data-for={csvToolTipId}
                    data-tip
                    onMouseEnter={() => setIsCSVActive(true)}
                    onMouseLeave={() => setIsCSVActive(false)}
                    onClick={() => {
                      setShowDialog(false);
                      csvWallets(userSegment);
                    }}
                  >
                    <ExportCSV hovered={isCSVActive} />
                  </button>
                  {isCSVActive && (
                    <DownloadTooltip
                      id={csvToolTipId}
                      header="Export profile list to CSV"
                      warningsList={[warningsList[0]]}
                    />
                  )}
                </>
              )}
              <button
                type="button"
                onMouseEnter={() => setIsActive(true)}
                onMouseLeave={() => setIsActive(false)}
                onClick={() => setSnapshotItem(true)}
                data-for="Snapshot"
                data-tip
                className="btn d-flex align-items-center justify-content-center shadow-none m-0 p-0"
              >
                <Snapshot hovered={isActive} />
              </button>
              {isActive && (
                <Tooltip
                  id="Snapshot"
                  info="Save snapshot as Custom List"
                />
              ) }

            </div>
            {snapshotItem && (
              <SaveSnapshotModal
                value={snapshotName}
                setValue={setSnapshotName}
                onSubmit={() => {
                  try {
                    if (id) {
                      snapshotSegment({ id, name: snapshotName });
                    } else {
                      const body = { ...userSegment, name: snapshotName };
                      snapshotDraftSegment(body);
                    }
                  } catch (err) {
                    showErrorMessage(err);
                  }
                }}
                onCancel={() => setSnapshotItem(false)}
                loading={
                  (snapshotSegmentResult.isLoading || snapshotSegmentResult.isFetching
                    || snapshotDraftSegmentResult.isLoading || snapshotDraftSegmentResult.isFetching)
                }
              />
            )}
            <DownloadTooltip
              id={toolTipId}
              header="Export profile list into CSV"
              warningsList={warningsList}
            />
          </div>
          <Table
            total={walletsData?.profile_count}
            data={tableData}
            desc={desc}
            setDesc={setDesc}
            ordering={field}
            setOrdering={setField}
            page={page}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
            headers={header}
            isLoading={isWalletsDataLoading || isWalletsDataFetching}
            min={25}
          />
          {isOpen && csvWalletsResult?.error?.status !== 403 ? (
            <DownloadModal
              result={result.status !== 'uninitialized' ? result : csvWalletsResult}
              setIsOpen={setIsOpen}
            />
          ) : null}
          {csvWalletsResult?.error?.status === 403 ? (
            <ConfirmModal
              title="We are sorry..."
              description="The feature you’re trying to use has restricted access.
                 Please reach out to your Customer Success Manager."
              buttonName="Okay"
              onSubmit={() => csvWalletsResult.reset()}
            />
          ) : null}
        </>
      )}
    </div>
  );
};

export default WalletList;
