import React, { useEffect, useState, useCallback } from 'react';
import Chart from 'react-apexcharts';
import TitleComponent from '../../base/TitleComponent';
import Loader from '../../base/Loader';
import NoData from '../../base/NoData';
import { ReactComponent as Arrow } from '../../../assets/images/long_arrow.svg';
import styles from './SharedInterests.module.scss';

const SharedInterests = ({
  symbol, data, isLoading, isFetching, info,
}) => {
  const [chartPoints, setChartPoint] = useState([]);
  const [minY, setMinY] = useState(0);
  const [minX, setMinX] = useState(0);
  const [maxX, setMaxX] = useState(0);

  const options = {
    chart: {
      type: 'scatter',
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    grid: {
      show: true,
      borderColor: '#707683',
      position: 'back',
      yaxis: {
        lines: {
          show: false,
        },
      },
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    legend: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
    annotations: {
      points: chartPoints,
    },
    xaxis: {
      type: 'numeric',
      tickAmount: 10,
      min: minX - 2,
      max: maxX + 15,
      axisBorder: {
        show: true,
        color: '#707683',
        offsetX: 0,
        offsetY: 0,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
      },
    },
    yaxis: {
      tickAmount: 10,
      min: minY - (minY / 5),
      axisBorder: {
        show: true,
        color: '#707683',
        offsetX: 0,
        offsetY: 0,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
      },
    },
  };

  const formatData = useCallback(() => {
    const rowHeight = (data[0].total_number / 360) * 17;
    return data.filter((elem, index) => (
      index === 0
      || (index !== 0 && data[index - 1].total_number - elem.total_number >= rowHeight)));
  }, [data]);

  useEffect(() => {
    if (data && data.length) {
      const pointsArr = [];
      formatData().forEach((elem) => {
        pointsArr.push(
          {
            x: +elem.avg_score.toFixed(0),
            y: elem.total_number,
            marker: {
              size: 0,
            },
            label: {
              textAnchor: 'start',
              borderWidth: 0,
              offsetY: 12,
              offsetX: 10,
              text: elem.name,
              style: {
                background: 'transparent',
                color: '#192A3E',
                fontSize: '12px',
                fontWeight: 500,
                fontFamily: 'Poppins',
              },
            },
          },
        );
      });
      setChartPoint(pointsArr);
      setMaxX(pointsArr.reduce((prev, cur) => (cur.x > prev.x ? cur : prev), { x: -Infinity }).x);
      setMinX(pointsArr.reduce((prev, cur) => (cur.x < prev.x ? cur : prev), { x: Infinity }).x);
      setMinY(pointsArr.reduce((prev, cur) => (cur.y < prev.y ? cur : prev), { y: Infinity }).y);
    }
  }, [data, formatData]);

  return (
    <div className={`${styles.wrapper} h-100`}>
      <TitleComponent info={info}>Shared interests</TitleComponent>
      { isLoading || isFetching
        ? (
          <div className="p-5">
            <Loader />
          </div>
        )
        : data?.length ? (
          <div className="position-relative">
            <div className="
            d-flex
            flex-column
            align-items-center
            gap-2
            justify-content-end
            position-absolute
            bottom-0 mb-5"
            >
              <Arrow />
              <div className={styles.strength_title}>Interest strength</div>
            </div>
            <Chart
              options={options}
              series={formatData().map((elem) => ({
                data: [[+elem.avg_score.toFixed(0), elem.total_number]],
                name: elem.name,
              }))}
              type="scatter"
              height={390}
              width="100%"
            />
            <div className={`${styles.holders} d-flex gap-4 position-absolute align-items-center w-100`}>
              <div className={`d-flex text-nowrap gap-1 ${symbol?.length > 25 ? 'w-75' : ''}`}>
                {symbol ? (
                  <div className="text-truncate">
                    {symbol === 'AUDIENCE' || symbol === 'VISITORS' ? symbol : `$${symbol}`}
                  </div>
                ) : null}
                <span>
                  {symbol === 'AUDIENCE' ? 'members interested'
                    : symbol === 'VISITORS' ? 'interested' : 'holders interested'}
                </span>
              </div>
              <div>
                <Arrow />
              </div>
            </div>
          </div>
        ) : (
          <div className="h-100 d-flex align-items-center justify-content-center">
            <NoData />
          </div>
        )}
    </div>
  );
};

export default React.memo(SharedInterests);
