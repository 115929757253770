import React from 'react';
import ToDashboardButton from '../ToDashboardButton';
import styles from '../TitleSection.module.scss';

const SingleBrandTitle = ({ singleBrandData }) => (
  <div className={styles.brandWrapper}>
    <div className={styles.imageContainer}>
      <img src={singleBrandData.logo} width="75" height="75" alt="brand" />
    </div>
    <div className={styles.row}>
      <p
        className={`${styles.title} ${
          singleBrandData.title.length > 10 && styles.titleAdaptive
        } d-flex align-items-center justify-content-center m-0 p-0`}
      >
        {singleBrandData.title}
      </p>
      <div className="d-flex gap-2 align-items-center justify-content-center">
        <p className={`${styles.tokenContainer} m-0 p-0 px-3`}>
          <span className={styles.tokenContainerText}>
            {singleBrandData.symbol}
          </span>
        </p>
      </div>
      <div className={styles.flexAlignRight}>
        <ToDashboardButton
          type="brand"
        />
      </div>
    </div>
  </div>
);

export default SingleBrandTitle;
