import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Handle, Position } from 'react-flow-renderer';
import styles from './ConditionNode.module.scss';
import { ReactComponent as Condition } from '../../../../../assets/icons/flows/conditions_icon.svg';
import { handle, handleInput } from '../../../../../utils/flows';
import { ReactComponent as Delete } from '../../../../../assets/icons/flows/delete.svg';
import {
  clearActiveSettings,
  deleteSettings, selectConfigsList, selectErrors,
  selectFlowStatus,
  setActiveSettings,
} from '../../../../../store/reducers/flows';
import { ReactComponent as Alert } from '../../../../../assets/icons/flows/alert.svg';
import Tooltip from '../../../../../components/ui/Tooltip';
import { ReactComponent as Edit } from '../../../../../assets/icons/flows/edit_setting.svg';
import { clearState } from '../../../../../store/reducers/segment';

const ConditionNode = ({ ...props }) => {
  const [data, setData] = useState();
  const [onNodeHover, setOnNodeHover] = useState(false);
  const [completed, setCompleted] = useState(false);
  const dispatch = useDispatch();

  const errors = useSelector(selectErrors);
  const configList = useSelector(selectConfigsList);
  const status = useSelector(selectFlowStatus);

  const filteredErrors = () => errors.length && errors.filter((elem) => elem.node_id === props.id);

  useEffect(() => {
    if (configList.length) {
      const index = configList.map((object) => object.node_id).indexOf(props.id);
      if (index >= 0) {
        setData(configList[index]);
      }
    }
  }, [configList, props.id]);

  useEffect(() => {
    if (
      data
      && data?.segment?.segment?.categories?.length > 0
      && data.name
    ) {
      setCompleted(true);
    } else {
      setCompleted(false);
    }
  }, [data]);

  return (
    <div className="position-relative">
      {completed && (
        <div className={styles.title}>
          {data.segment.segment.categories[0].name}
        </div>
      )}
      <div
        className={`
        ${styles.wrapper}
        ${status === 'running' || status === 'scheduled' || status === 'stopped' ? 'cursor-pointer' : ''}
        d-flex justify-content-center align-items-center`}
        onMouseEnter={() => setOnNodeHover(true)}
        onMouseLeave={() => setOnNodeHover(false)}
        role="presentation"
        onClick={() => {
          if (status === 'running' || status === 'scheduled' || status === 'stopped') {
            dispatch(setActiveSettings({ type: 'conditionNode', node_id: props.id }));
          }
        }}
      >
        <Condition />
        {!completed || filteredErrors().length
          ? (
            <>
              <div
                className={styles.alert}
                data-for={`${props.id}_error`}
                data-tip
              >
                <Alert />
              </div>
              {filteredErrors().length
                ? (
                  <div
                    style={{ transform: 'rotate(-45deg)' }}
                  >
                    <Tooltip
                      id={`${props.id}_error`}
                      info={`${filteredErrors()[0].detail}`}
                    />
                  </div>
                )
                : null}
            </>
          )
          : null}
        <div className={onNodeHover ? styles.delete : styles.delete_hide}>
          {status !== 'running' && status !== 'stopped' && status !== 'scheduled'
            ? (
              <Delete onClick={() => {
                props.data.onRemove(props.id);
                dispatch(deleteSettings(props.id));
                dispatch(clearActiveSettings());
              }}
              />
            )
            : null}
        </div>
        <div className={onNodeHover ? styles.edit : styles.edit_hide}>
          <Edit onClick={() => {
            if (!data) {
              dispatch(clearState());
            }
            dispatch(setActiveSettings({ type: 'conditionNode', node_id: props.id }));
          }}
          />
        </div>
      </div>
      <Handle
        type="target"
        position={Position.Left}
        style={{ ...handleInput, left: '-21px' }}
        id="conditionNode_input_a"
      />
      <Handle
        type="source"
        position={Position.Right}
        style={{ ...handle, right: '-27px' }}
        id="conditionNode_output_b"
      />
      <Handle
        type="source"
        position={Position.Bottom}
        style={{ ...handle, bottom: '-27px' }}
        id="conditionNode_output_c"
      />
    </div>
  );
};

export default ConditionNode;
