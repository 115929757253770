import React, {
  useEffect,
  useState,
  useMemo,
  useRef,
} from 'react';
import Spinner from '../../../components/base/Spinner';
import './Asset.scss';
import styles from './Asset.module.scss';
import { formatAssetData } from '../../../utils/assetsData/formatAssetData';
import { convertNumber, convertToReadableFormat } from '../../../tools/NumberConverterTool';
import { ethFormat } from '../../../utils/singleAssetPage/parseData';
import { handlePlatforms } from '../../../utils/search/preventSearch';
import { selectAssetName } from '../../../utils/search/selectName';
import { tooltipChainIconPicker } from '../../../tools/ChainIconPicker';
import uniqueId from '../../../utils/uniqueId';
import Verification from '../../../components/ui/Verification';
import DefaultIcon from '../../../components/ui/DefaultIcon';
import ItemPreview from '../../../components/ui/ItemPreview';
import { showErrorMessage } from '../../../components/base/Notifications';

const Asset = ({
  type,
  item,
  addedAssets,
  flag,
  loading,
  onAddOrDeleteAsset,
  platforms,
}) => {
  const [activeClass, setActiveClass] = useState('');
  const [buttonText, setButtonText] = useState('');
  const [cardLogoError, setCardLogoError] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const isMounted = useRef(true);

  const inDifferentColumn = useMemo(() => {
    if (
      addedAssets.map((asset) => (asset.asset_id || asset.address || asset.coin_id))
        .includes(item.id || item.contract_address)
    ) {
      if (!addedAssets.map((asset) => (asset.item_type)).includes(flag)) {
        return true;
      }
    }
    return false;
  }, [addedAssets, flag, item]);

  useEffect(() => () => {
    isMounted.current = false;
  }, []);

  useEffect(() => {
    if (loading) {
      setDisableButton(true);
    }
  }, [loading, disableButton]);

  const addOrDeleteHandler = async () => {
    if (loading || disableButton) return;
    if (!isMounted.current) return;
    setDisableButton(true);
    const handleDisable = () => {
      setTimeout(() => setDisableButton(false), 0);
    };
    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    try {
      await delay(1000);
      if (buttonText === '+ Add') {
        const whereToAdd = inDifferentColumn ? flag === 'asset' ? 'asset' : 'watchlist' : flag;
        await onAddOrDeleteAsset(
          formatAssetData('add', whereToAdd, type, item.id || item.contract_address, item.blockchain),
        );
      } else {
        let placeType = null;
        if (item.type !== 'nft') {
          addedAssets.forEach((elem) => {
            if (elem.asset_id === item.id || elem.coin_id === item.id) {
              placeType = elem.item_type || elem.place_type;
            }
          });
        } else {
          addedAssets.forEach((elem) => {
            if (elem.address === item.contract_address
            || elem.asset_id === item.contract_address) {
              placeType = elem.item_type || elem.place_type;
            }
          });
        }
        await onAddOrDeleteAsset(
          formatAssetData('delete', placeType, type, item.id || item.contract_address, item.blockchain),
        );
      }
    } catch (error) {
      showErrorMessage('Something went wrong. Please refresh the page.');
    } finally {
      if (isMounted.current) {
        handleDisable();
      }
    }
  };

  const formatPillText = {
    nft: 'NFT Collection',
    coin: 'Token',
    brand: 'Brand',
  };

  const firstFieldTitle = (field) => {
    switch (field) {
      case 'coin': return 'Current price';
      case 'nft': return 'Holders';
      default: return 'Assets owned';
    }
  };

  const firstFieldData = (field) => {
    switch (field) {
      case 'coin': return `$${item.current_price < 1000
        ? ethFormat(item.current_price)
        : convertToReadableFormat(item.current_price)}`;
      case 'nft': return convertNumber(item.holder_count);
      default: return item.owned_assets_quantity;
    }
  };

  const secondFieldTitle = (field) => {
    switch (field) {
      case 'coin': return 'Market Cap';
      case 'nft': return 'Floor price';
      default: return 'Assets published';
    }
  };

  const secondFieldData = (field) => {
    switch (field) {
      case 'coin': return `$${convertToReadableFormat(item.market_cap)}`;
      case 'nft': return item.floor_price === null ? '-' : `${ethFormat(item.floor_price)} ETH`;
      default: return convertNumber(item.published_assets_quantity);
    }
  };

  useEffect(() => {
    const index = addedAssets.findIndex((object) => {
      if (item.type === 'nft'
        && (item.contract_address === object.address || item.contract_address === object.nft_collection?.address)
        && (item.blockchain === object.network || item.blockchain === object.nft_collection?.network)) {
        return true;
      }
      return item.type === 'coin' && (item.id === object.coin_id || item.id === object.asset_id);
    });
    if (index !== -1) {
      setActiveClass('active');
      setButtonText('Added');
    } else {
      setActiveClass('');
      setButtonText('+ Add');
    }
  }, [addedAssets, item.contract_address, item, flag]);

  useEffect(() => {
    if (buttonText === 'Added') {
      setActiveClass('active');
    } else {
      setActiveClass('');
    }
  }, [buttonText]);

  return (
    <div className="asset-wrapper">
      <div className="asset-container d-flex flex-column justify-content-between">
        <div className="asset-container-info gap-1 d-flex justify-content-between align-items-center">
          <div className="d-flex justify-content-between align-items-center gap-2 text-truncate">
            <div
              data-for={item.type === 'nft' && `preview_${item.contract_address}_${item.blockchain}`}
              data-tip="show"
            >
              {
                !cardLogoError && (item.image_url || item.logo || item.image)
                  ? (
                    <img
                      onError={() => setCardLogoError(true)}
                      src={item.image_url || item.logo || item.image}
                      alt="logo"
                      width="40"
                      height="40"
                      className={styles.icon}
                    />
                  )
                  : <DefaultIcon type={item.type} />
              }
            </div>
            <div className="d-flex text-truncate">
              <div
                className="text-truncate px-1"
                data-for={item.type === 'nft' && `preview_${item.contract_address}_${item.blockchain}`}
                data-tip="show"
              >
                {selectAssetName(type, item)}
              </div>
              {item.type === 'nft'
                ? (
                  <ItemPreview
                    id={`preview_${item.contract_address}_${item.blockchain}`}
                    data={{
                      address: item.contract_address,
                      type: item.type,
                      blockchain: item.blockchain,
                      img: item.image_url || item.logo || item.image,
                      name: item.name,
                    }}
                  />
                )
                : null}
              {item.is_verified_on_opensea
                ? <Verification className="ms-1" />
                : null}
            </div>
          </div>
          <button
            type="button"
            className={`add d-flex ${activeClass} align-items-center`}
            onClick={() => addOrDeleteHandler()}
            disabled={disableButton}
          >
            {loading ? <Spinner /> : buttonText}
          </button>
        </div>
        <div className="asset-container-data d-flex justify-content-between align-items-end mt-3 gap-4 flex-wrap">
          <div className="d-flex gap-2">
            <div className="d-flex flex-column justify-content-around info">
              <span>{firstFieldTitle(type)}</span>
              <span>{secondFieldTitle(type)}</span>
            </div>
            <div className="d-flex flex-column justify-content-around price">
              <span>{firstFieldData(type)}</span>
              <div>{secondFieldData(type)}</div>
            </div>
          </div>
          <div className="d-flex gap-2 align-items-center">
            <div className={`${styles[`pill_${type}`]} ${styles.pills}`}>
              {formatPillText[type]}
            </div>
            {handlePlatforms(platforms) ? (
              <>
                <div className={styles.separator_icon} />
                <div className="d-flex">
                  {handlePlatforms(platforms).ethereum && tooltipChainIconPicker('ethereum', uniqueId('chain'))}
                  {handlePlatforms(platforms).polygon && tooltipChainIconPicker('polygon', uniqueId('chain'))}
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Asset;
