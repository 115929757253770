import React from 'react';
import { ReactComponent as WebSite } from '../../../../../assets/icons/website.svg';
import { ReactComponent as Twitter } from '../../../../../assets/icons/twitter.svg';
import { ReactComponent as Telegram } from '../../../../../assets/icons/telegram.svg';
import { ReactComponent as Facebook } from '../../../../../assets/icons/facebook.svg';
import { ReactComponent as Discord } from '../../../../../assets/icons/discord.svg';
import { ReactComponent as Instagram } from '../../../../../assets/icons/instagram.svg';
import { ReactComponent as Opensea } from '../../../../../assets/icons/opensea.svg';
import uniqueId from '../../../../../utils/uniqueId';

const TitleSocials = ({ links }) => (
  <>
    {((links.homepage && links.homepage[0]) || links.website)
        && (
          <a
            href={(links.homepage && links.homepage[0]) || links.website}
            target="_blank"
            rel="noreferrer"
          >
            <WebSite />
          </a>
        )}
    {
      (links.twitter_screen_name || links.twitter)
        && (
          <a
            href={links.twitter || `https://twitter.com/${links.twitter_screen_name}`}
            target="_blank"
            rel="noreferrer"
          >
            <Twitter />
          </a>
        )
    }
    {
      links.telegram_channel_identifier
        && (
          <a
            href={
              links.telegram_channel_identifier.startsWith('https://t.me')
                ? links.telegram_channel_identifier
                : `https://t.me/${links.telegram_channel_identifier}`
            }
            target="_blank"
            rel="noreferrer"
          >
            <Telegram />
          </a>
        )
    }
    {
      (links.facebook_username || links.facebook)
        && (
          <a
            href={links.facebook || `https://www.facebook.com/${links.facebook_username}`}
            target="_blank"
            rel="noreferrer"
          >
            <Facebook />
          </a>
        )
    }
    {
      links.instagram
        && (
          <a
            href={
              links.instagram.startsWith('https://www.instagram.com')
                ? links.instagram
                : `https://www.instagram.com/${links.instagram}`
            }
            target="_blank"
            rel="noreferrer"
          >
            <Instagram />
          </a>
        )
    }
    {
      links.openSea
        && (
          <a
            href={`https://opensea.io/collection/${links.openSea}`}
            target="_blank"
            rel="noreferrer"
          >
            <Opensea />
          </a>
        )
    }
    {!links.chat_url?.includes(null) && (
      links.chat_url?.map((elem) => {
        if (elem.startsWith('https://discord')) {
          return (
            <a
              href={elem}
              target="_blank"
              rel="noreferrer"
              key={uniqueId('discord')}
            >
              <Discord />
            </a>
          );
        }
        return null;
      })
    )}
  </>
);

export default TitleSocials;
