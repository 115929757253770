import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SingleBrandTitle from './Titles/SingleBrandTitle';
import styles from './TitleSection.module.scss';
import { userApi } from '../../../api/user';
import {
  addAssetToMyAssets,
  addAssetToWatchlist,
  getAssetsFromDashboard,
  getNftAssetsFromDashboard,
  getNftWatchlistFromDashboard,
  getWatchlistFromDashboard,
  removeAssetsFromDashboard,
  removeWatchlistFromDashboard,
} from '../../../store/reducers/coin';
import { handleActionAsset } from '../../../utils/analiticsPage/assetActions';
import { showErrorMessage, showSuccessMessage } from '../../base/Notifications';
import SingleWalletTitle from './Titles/SingleWalletTitle';
import LoadingList from '../modals/SearchModal/LoadingList';

const TitleSection = ({
  type, assetData, isTeamAssetsLoading, isLoading,
}) => {
  const [logoError, setLogoError] = useState(false);

  const myAssets = useSelector(getAssetsFromDashboard);
  const watchList = useSelector(getWatchlistFromDashboard);
  const allAssets = useMemo(() => ([...myAssets, ...watchList]), [myAssets, watchList]);
  const assetNft = useSelector(getNftAssetsFromDashboard);
  const watchlistNft = useSelector(getNftWatchlistFromDashboard);
  const allNft = useMemo(() => ([...assetNft, ...watchlistNft]), [assetNft, watchlistNft]);

  const handleType = useCallback(() => {
    if (type === 'nft') {
      return allNft.includes(assetData.id);
    }
    return allAssets.includes(assetData.id);
  }, [allAssets, allNft, assetData.id, type]);

  const handleAssets = useCallback(() => {
    if (type === 'nft') {
      return assetNft.includes(assetData.id);
    }
    return myAssets.includes(assetData.id);
  }, [assetData.id, assetNft, myAssets, type]);

  const [activeClass, setActiveClass] = useState(
    handleType()
      ? `btn-success ${styles.buttonSecondaryColor}`
      : `btn-dark ${styles.buttonPrimaryColor}`,
  );
  const [buttonText, setButtonText] = useState(
    handleType() ? `Remove from ${handleAssets()
      ? 'My Assets' : 'WatchList'}`
      : 'Add to Dashboard',
  );
  const [actionOnAsset, result] = userApi.useActionOnAssetMutation();
  const dispatch = useDispatch();

  // useEffect(() => {
  //   if (teamAssets) {
  //     const { assets, watchlist } = teamAssets;
  //     dispatch(assetsFromDashboard(assets[pathname.split('/')[1]]));
  //     dispatch(watchlistFromDashboard(watchlist[pathname.split('/')[1]]));
  //   }
  //   return () => {
  //     dispatch(removeAssetsFromDashboard());
  //     dispatch(removeWatchlistFromDashboard());
  //   };
  // }, [dispatch, pathname, teamAssets]);

  useEffect(() => {
    setActiveClass(
      handleType()
        ? `btn-success ${styles.buttonSecondaryColor}`
        : `btn-dark ${styles.buttonPrimaryColor}`,
    );
    setButtonText(
      handleType() ? `Remove from ${handleAssets()
        ? 'My Assets' : 'WatchList'}`
        : 'Add to Dashboard',
    );
  }, [allAssets, assetData.id, handleAssets, handleType, myAssets]);

  useEffect(() => {
    if (result.isSuccess) {
      if (result.originalArgs.action === 'add') {
        if (result.originalArgs.place_type === 'asset') {
          setButtonText('Remove from My Assets');
          dispatch(addAssetToMyAssets(type === 'nft' ? { address: assetData.id } : { asset_id: assetData.id }));
        }
        if (result.originalArgs.place_type === 'watchlist') {
          setButtonText('Remove from Watchlist');
          dispatch(addAssetToWatchlist(type === 'nft' ? { address: assetData.id } : { asset_id: assetData.id }));
        }
        showSuccessMessage(result.data.detail || result.data.message);
        setActiveClass(`btn-dark ${styles.buttonPrimaryColor}`);
        result.reset();
      }
      if (result.originalArgs.action === 'delete') {
        if (handleAssets()) {
          dispatch(removeAssetsFromDashboard(assetData.id));
        } else {
          dispatch(removeWatchlistFromDashboard(assetData.id));
        }
        showSuccessMessage(result.data.detail || result.data.message);
        setButtonText('Add to Dashboard');
        setActiveClass(`btn-dark ${styles.buttonPrimaryColor}`);
        result.reset();
      }
    }
    if (result.isError) {
      showErrorMessage(result.error.data.detail || 'Something went wrong');
      result.reset();
    }
  }, [assetData.id, dispatch, handleAssets, result, type]);

  const deleteHandler = handleType()
    ? async () => {
      await actionOnAsset(handleActionAsset(type, assetData, buttonText));
    }
    : () => {};

  const menuData = [
    {
      name: 'My Assets',
      action: async (name) => {
        await actionOnAsset(handleActionAsset(type, assetData, buttonText, name));
      },
    },
    {
      name: 'Watchlist',
      action: async (name) => {
        await actionOnAsset(handleActionAsset(type, assetData, buttonText, name));
      },
    },
  ];

  return useMemo(() => {
    if (isLoading) {
      return <LoadingList rows={1} />;
    }
    if (type === 'brand') {
      return (
        <SingleBrandTitle
          singleBrandData={assetData}
          buttonText={buttonText}
          addOrDeleteHandler={deleteHandler}
          activeClass={activeClass}
          menuData={menuData}
          result={result}
          isTeamAssetsLoading={isTeamAssetsLoading}
        />
      );
    }
    return (
      <>
      </>
    );
  }, [activeClass, assetData, buttonText, deleteHandler, isLoading, isTeamAssetsLoading, menuData, result, type]);
};

export default TitleSection;
