import React, { useState, useEffect, useMemo } from 'react';
// import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Title from '../../../../components/base/Title';
import Widget from '../../../../components/ui/Market/Widget';
import uniqueId from '../../../../utils/uniqueId';
import { convertNumber } from '../../../../tools/NumberConverterTool';
// import { setVisitors } from '../../../../store/reducers/webSDK';
import styles from './FormActivityGraph.module.scss';
import DailyChart from './DailyChart';
import TopSources from '../../../../assets/icons/forms/top_sources.svg';
import ConversionRate from '../../../../assets/icons/forms/conversion_rate.svg';
import Responses from '../../../../assets/icons/forms/responses.svg';
import Views from '../../../../assets/icons/forms/views.svg';

const FormActivityGraph = () => {
  // const { id } = useParams();
  const dispatch = useDispatch();

  const [widgetData, setWidgetData] = useState([]);

  //   const {
  //     data: formDataOverview,
  //     isLoading: isFormDataOverviewLoading,
  //     isFetching: isFormDataOverviewFetching,
  //   } = webSDKApi.useGetWebSDKStatsQuery(id);

  const formDataOverview = useMemo(() => ({
    views: 100,
    responses: 100,
    conversion_rate: '100%',
    top_source: 'Twiiter',
  }), []);
  const isFormDataOverviewLoading = false;
  const isFormDataOverviewFetching = false;

  useEffect(() => {
    if (formDataOverview) {
      //   dispatch(setVisitors({ total: formDataOverview?.visitors,
      // withWallet: formDataOverview?.visitors_with_wallets }));
      setWidgetData([
        {
          id: 9,
          name: 'Views',
          number: formDataOverview?.views !== null
            ? `${convertNumber(formDataOverview?.views)}`
            : '?',
          icon: Views,
          info: 'Number of views of your form',
        },
        {
          id: 10,
          name: 'Responses',
          number: formDataOverview?.responses !== null
            ? `${convertNumber(formDataOverview?.responses)}`
            : '?',
          icon: Responses,
          info: 'Number of responses to your form',
        },
        {
          id: 11,
          name: 'Conversion rate',
          number: formDataOverview?.conversion_rate !== null
            ? `${convertNumber(formDataOverview?.conversion_rate)}`
            : '?',
          icon: ConversionRate,
          info: 'Percentage of visitors who responded to your form',
        },
        {
          id: 12,
          name: 'Top sources',
          number: formDataOverview?.top_source !== null
            ? `${convertNumber(formDataOverview?.top_source)}`
            : '?',
          icon: TopSources,
          info: 'Number 1 source of traffic to your form',
        },
      ]);
    }
  }, [dispatch, formDataOverview]);

  return (
    <div className="asset-container">
      <div className="title-gap d-flex align-items-center gap-2">
        <Title>Overview</Title>
      </div>
      <div className="dropdown-divider w-100" />
      <div className="info-wrapper-top d-flex justify-content-between gap-4">
        {(widgetData.length ? widgetData : [{}, {}, {}, {}, {}]).map((item) => (
          <Widget
            item={item}
            isLoading={isFormDataOverviewLoading
            || isFormDataOverviewFetching}
            key={uniqueId('form-widget')}
          />
        ))}
      </div>
      <div className={styles.blockWrapper}>
        <DailyChart />
      </div>
    </div>
  );
};

export default FormActivityGraph;
