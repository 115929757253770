import React from 'react';
import styles from './BuyingPower.module.scss';
import TitleComponent from '../../base/TitleComponent';
import Loader from '../../base/Loader';
import { convertToReadableFormat } from '../../../tools/NumberConverterTool';
import NoData from '../../base/NoData';

const BuyingPower = ({
  buyingPower, isLoading, isFetching, info,
}) => (
  <div className={styles.wrapper}>
    <TitleComponent info={info}>Buying Power</TitleComponent>
    {!isLoading || !isFetching ? (
      buyingPower && (buyingPower.total || buyingPower.native || buyingPower.stable) ? (
        <>
          <div className={`${styles.first_container} d-flex justify-content-between`}>
            <span>Total Investing power</span>
            <span>
              $
              {convertToReadableFormat(buyingPower.total < 0 ? 0 : buyingPower.total)}
            </span>
          </div>
          <div className="d-flex justify-content-between">
            <span className={styles.text}>Cumulative stable coin value:</span>
            <span className={styles.value}>
              $
              {convertToReadableFormat(buyingPower.stable < 0 ? 0 : buyingPower.stable)}
            </span>
          </div>
          <div className="d-flex justify-content-between">
            <span className={styles.text}>Cumulative native token value:</span>
            <span className={styles.value}>
              $
              {convertToReadableFormat(buyingPower.native < 0 ? 0 : buyingPower.native)}
            </span>
          </div>
        </>
      ) : (
        <div>
          <NoData />
        </div>
      )) : (
      <div className="d-flex justify-content-center align-items-center p-5 h-75">
          <Loader />
        </div>
    )}
  </div>
);

export default React.memo(BuyingPower);
