import React, { useState, useEffect, useMemo } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigationType, useParams } from 'react-router';
import { Helmet } from 'react-helmet';
import { coinApi } from '../../api/coin';
import Price from '../../assets/images/brand/values.svg';
import MarketCap from '../../assets/images/asset/MarketCap.svg';
import Box from '../../assets/images/asset/boxes.svg';
import Members from '../../assets/images/asset/relHolders.svg';
import {
  bitcoinPrice,
  dailyVolume,
  getHistoryVolume,
  historyPrices,
} from '../../store/reducers/price';
import { ethFormat, formattedPlatforms } from '../../utils/singleAssetPage/parseData';
import InvestorProfile from './InvestorProfile';
import InvestPortfolio from './InvestPortfolio';
import { convertNumber } from '../../tools/NumberConverterTool';
import Holders from './Holders';
import SingleCoinTitle from '../../components/ui/TitleSection/Titles/SingleCoinTitle';
import { assetData } from './dummy-data';
import Market from '../../components/ui/Market';
import { resetSelectedAsset } from '../../store/reducers/app';
import './Asset.scss';
import { selectTabPages, setTabPages } from '../../store/reducers/coin';
import Exchanges from './Exchanges';
import DappUsage from './DappUsage';

const Asset = () => {
  const dispatch = useDispatch();
  const { name } = useParams();
  const { pathname, search } = useLocation();
  const navType = useNavigationType();
  const [searchParams, setSearchParams] = useSearchParams();
  const [days, setDays] = useState('30');
  const [isSupportBlockchain, setIsSupportBlockchain] = useState(null);
  const [dailyVolumeData, setDailyVolumeData] = useState(undefined);
  const [dailyVolumeDataChart, setDailyVolumeDataChart] = useState(undefined);
  const [itemPage, setItemPage] = useState(searchParams.get('tab')?.replace('+', ' ') || 'Investor Profile');
  const [titleSectionData, setTitleSectionData] = useState(null);
  const [marketData, setMarketData] = useState({});
  const [pages, setPages] = useState([]);

  const historyVolume = useSelector(getHistoryVolume);
  const tabPages = useSelector(selectTabPages);

  const {
    currentData: asset,
    isLoading,
    isFetching,
  } = coinApi.useGetCoinByIdQuery(name);

  const {
    currentData: history,
    isLoading: historyIsLoading,
    isFetching: historyIsFetching,
    isSuccess,
  } = coinApi.useGetCoinHistoryFromMarketDataQuery({
    id: name,
    days,
  });

  const {
    data: volume,
    isLoading: isLoadingVolume,
  } = coinApi.useGetCoinFromMarketDataQuery({
    id: name,
    days: '30',
  });

  const { data: bitcoin } = coinApi.useGetCoinFromMarketDataQuery({
    id: 'bitcoin',
    days,
  });

  const {
    data: holders,
    isLoading: isLoadingHolders,
    isFetching: isFetchingHolders,
  } = coinApi.useGetCoinHoldersQuery(name);

  const {
    data: reach,
    isLoading: isReachLoading,
    isFetching: isReachFetching,
  } = coinApi.useReachTokenQuery(name);

  useEffect(() => {
    if (volume && !volume.error) {
      setDailyVolumeData(volume?.total_volumes[0][1]);
      setDailyVolumeDataChart(volume?.total_volumes);
    }
  }, [volume]);

  useEffect(() => {
    if (historyIsLoading) {
      dispatch(historyPrices(undefined));
    }
    if (history) {
      dispatch(historyPrices(history));
    }
    if (!history && isSuccess) {
      dispatch(historyPrices(null));
    }
  }, [dispatch, history, historyIsFetching, historyIsLoading, isSuccess]);

  useEffect(() => {
    if (days === '30' && historyVolume) {
      dispatch(dailyVolume(historyVolume));
    }
  }, [days, dispatch, historyVolume]);

  useEffect(() => {
    if (bitcoin) {
      dispatch(bitcoinPrice(bitcoin));
    }
  }, [bitcoin, dispatch]);

  useEffect(() => {
    setItemPage(searchParams.get('tab')?.replace('+', ' ') || 'Investor Profile');
  }, [searchParams]);

  useEffect(() => {
    if (isLoading || isFetching) {
      setTitleSectionData(null);
      setIsSupportBlockchain(null);
    }
    if (asset) {
      const differenceFunc = (totalVolume, dailyVol) => (totalVolume !== null && dailyVol !== null
        ? totalVolume - dailyVol
        : '');

      const percentageFunc = (vol, dailyVolumes) => (vol !== null && dailyVolumes !== null
        ? ethFormat(Math.abs(((vol - dailyVolumes) / dailyVolumes) * 100))
        : '');

      setTitleSectionData({
        id: asset.id,
        logo: asset.image.large,
        name: asset.name,
        symbol: asset.symbol.toUpperCase(),
        tags: asset.categories.filter((category) => category !== null).slice(0, 5),
        icons: assetData.icons,
        links: asset.links,
        platforms: formattedPlatforms(asset.platforms, asset.name),
        blockchain: asset.platforms && Object.keys(asset.platforms).length ? asset.platforms : null,
      });

      const platformsSupporting = formattedPlatforms(asset.platforms, asset.name).includes('Polygon Pos')
        || formattedPlatforms(asset.platforms, asset.name).includes('Ethereum');

      setIsSupportBlockchain(platformsSupporting);

      setMarketData({
        id: asset.id,
        widgetData: [
          {
            id: 6,
            name: 'Price',
            number: asset?.market_data?.current_price?.usd
              ? `$${ethFormat(asset?.market_data?.current_price?.usd)}`
              : '?',
            icon: Price,
          },
          {
            id: 1,
            name: 'Market Cap',
            number: asset.market_data.market_cap.usd,
            icon: MarketCap,
          },
          {
            id: 2,
            name: 'Circulating supply',
            number: asset.market_data.circulating_supply,
            icon: Box,
          },
          {
            id: 3,
            name: 'Relevant holders',
            number: holders?.relevant_holder_count,
            icon: Members,
            info: 'Holders owning at least 5$ worth of this token',
            isLoading: isLoadingHolders,
          },
        ],
        tokenMarketData: {
          ath: asset?.market_data?.ath?.usd,
          atl: asset?.market_data?.atl?.usd,
          current_price: asset?.market_data?.current_price?.usd,
        },
        priceData: {
          day: asset.market_data.price_change_percentage_24h,
          week: asset.market_data.price_change_percentage_7d,
          month: asset.market_data.price_change_percentage_30d,
          twoMonth: asset.market_data.price_change_percentage_60d,
          year: asset.market_data.price_change_percentage_1y,
        },
        infoData: [
          {
            id: 1,
            header: 'Daily Volume',
            count: Object.keys(asset.market_data.total_volume).length && asset.market_data.total_volume.usd !== null
              ? ethFormat(asset.market_data.total_volume.usd)
              : '?',
            percentage:
              Object.keys(asset.market_data.total_volume).length
              && asset.market_data.total_volume.usd !== null && dailyVolumeData
                ? percentageFunc(
                  asset.market_data.total_volume.usd,
                  dailyVolumeData,
                )
                : '?',
            active:
              Object.keys(asset.market_data.total_volume).length
              && asset.market_data.total_volume.usd !== null && dailyVolumeData !== null
                ? differenceFunc(
                  asset.market_data.total_volume.usd,
                  dailyVolumeData,
                )
                : 0,
            chart: dailyVolumeDataChart,
            isLoading: isLoadingVolume,
          },
          {
            id: 2,
            header: 'Holders',
            count: holders && holders.holder_count !== null ? convertNumber(holders.holder_count) : '?',
            isLoading: isLoadingHolders,
          },
          {
            id: 3,
            header: 'Monthly Active Addresses',
            count: holders && holders.monthly_active_addresses !== null
              ? convertNumber(holders.monthly_active_addresses)
              : '?',
            isLoading: isLoadingHolders,
          },
          {
            id: 4,
            header: 'Social Score',
            count: `${Math.round(asset.community_score)}%`,
            isLoading,
          },
        ],
      });

      setPages([
        {
          label: 'Investor Profile',
          component: (
            <InvestorProfile
              holders={holders}
              symbol={asset.symbol}
              isSupportedBlockchain={platformsSupporting}
            />),
          id: 1,
        },
        {
          label: 'Investor Portfolio',
          component: (
            <InvestPortfolio
              holders={holders?.relevant_holder_count}
              isSupportedBlockchain={platformsSupporting}
            />),
          id: 2,
        },
        {
          label: 'Holders',
          component: (
            <Holders
              isLoading={isLoadingHolders}
              isFetching={isFetchingHolders}
              holders={holders}
              isSupportedBlockchain={platformsSupporting}
            />),
          id: 3,
        },
        { label: 'Dapp Usage', component: <DappUsage holders={holders?.relevant_holder_count} />, id: 4 },
        {
          label: 'Exchanges',
          component: (
            <Exchanges holders={holders?.holder_count} />
          ),
          id: 5,
        },
        // { label: 'Markets', component: <ComingSoon />, id: 4 },
        // { label: 'News', component: <ComingSoon />, id: 5 },
        // { label: 'Social', component: <ComingSoon />, id: 6 },
        // { label: 'Contract', component: <ComingSoon />, id: 7 },
        // { label: 'Competitors', component: <ComingSoon />, id: 8 },
      ]);
    }
  }, [
    asset,
    dailyVolumeData,
    dailyVolumeDataChart,
    holders,
    isFetchingHolders,
    isLoading,
    isFetching,
    isLoadingHolders,
    isLoadingVolume,
  ]);

  useEffect(() => {
    if (pages?.length && navType !== 'POP') {
      dispatch(setTabPages(pages));
    }
  }, [dispatch, navType, pages]);

  useEffect(() => {
    if (searchParams.get('scroll') && navType === 'POP') {
      setTimeout(
        () => window.scrollTo({ top: +searchParams.get('scroll'), left: 0, behavior: 'smooth' }),
        100,
      );
    }
  }, [searchParams.get('scroll')]);

  useEffect(() => () => {
    dispatch(resetSelectedAsset());
    dispatch(setTabPages([]));
    setIsSupportBlockchain(null);
  }, [dispatch]);

  const tabContent = useMemo(() => {
    if (typeof isSupportBlockchain === 'boolean') {
      return (
        <div className="row asset-section m-o mt-3">
          <div className="title-gap asset-navigation">
            <ul className="nav nav-pills gap-2">
              {(navType === 'POP' && tabPages?.length ? tabPages : pages).map((elem) => (
                <li
                  role="presentation"
                  className="nav-item cursor-pointer"
                  key={elem.id}
                  onClick={() => {
                    setItemPage(elem.label);
                    setSearchParams({
                      tab: elem.label,
                      scroll: '',
                    });
                  }}
                >
                  <Link
                    className={`nav-link ${
                      elem.label === itemPage ? 'active' : ''
                    }`}
                    to={`${pathname}${search}`}
                  >
                    {elem.label}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="dropdown-divider w-100" />
          {(navType === 'POP' && tabPages?.length ? tabPages : pages).map((item) => (item.label === itemPage ? (
            <div key={item.id}>{item.component}</div>
          ) : null))}
        </div>
      );
    }
    return null;
  }, [isSupportBlockchain, itemPage, navType, pages, pathname, search, searchParams, setSearchParams, tabPages]);

  return (
    <>
      {!(isLoading || isFetching) && (
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            {asset.name}
            {' '}
            Insights
            {' '}
            - Absolute Labs Platform
          </title>
        </Helmet>
      )}
      <SingleCoinTitle
        isAssetLoading={isLoading || isFetching}
        singleCoinData={titleSectionData}
      />
      <div className="asset-section mt-3">
        <Market
          isAssetLoading={isLoading || isFetching}
          isHoldersLoading={isLoadingHolders || isFetchingHolders}
          marketData={marketData.tokenMarketData}
          id={marketData.id}
          widgetData={marketData.widgetData || [{}, {}, {}, {}]}
          infoData={marketData.infoData}
          priceData={marketData.priceData}
          setDays={setDays}
          days={days}
          isLoading={historyIsLoading}
          isFetching={historyIsFetching}
          isReachLoading={isReachLoading || isReachFetching || isLoadingHolders || isFetchingHolders}
          holders={holders?.holder_count}
          reach={reach}
          type="coin"
          symbol={asset?.symbol}
        />
      </div>
      {tabContent}
    </>
  );
};

export default Asset;
