import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { localeStorageCache } from '../../utils/localeStorage';

const BASE_API = 'api/coins';

export const coinApi = createApi({
  reducerPath: 'coinAPI',
  baseQuery: fetchBaseQuery({
    prepareHeaders: (headers) => {
      const authKey = localeStorageCache.getAuth0Key();
      const authData = localeStorageCache.get(authKey);

      if (authData && authData.body) {
        const { id_token: idToken } = authData.body;
        if (idToken) {
          headers.set('Authorization', `Bearer ${idToken}`);
        }
      }
      return headers;
    },
    baseUrl: import.meta.env.VITE_APP_SERVER_DOMAIN,
  }),
  tagTypes: ['Coin'],
  endpoints: (build) => ({
    getCoinById: build.query({
      query: (id) => ({
        url: `${BASE_API}/${id}/detail/`,
        method: 'GET',
      }),
      providesTags: () => ['Coin'],
    }),
    getCoinFromMarketData: build.query({
      query: (arg) => ({
        url: `${BASE_API}/${arg.id}/market-data/?days=${arg.days}&interval=${
          arg.days !== '1' ? 'daily' : 'hourly'
        }&vs_currency=usd`,
        method: 'GET',
      }),
    }),
    getCoinHistoryFromMarketData: build.query({
      query: (arg) => ({
        url: `${BASE_API}/${arg.id}/market-data/?days=${arg.days}&interval=${
          arg.days !== '1' ? 'daily' : 'hourly'
        }&vs_currency=usd`,
        method: 'GET',
      }),
    }),
    getCoinHolders: build.query({
      query: (id) => ({
        url: `${BASE_API}/${id}/holders/`,
        method: 'GET',
      }),
      providesTags: () => ['Coin'],
    }),
    getCoinWallets: build.query({
      query: (id) => ({
        url: `${BASE_API}/${id}/wallets/`,
        method: 'GET',
      }),
      providesTags: () => ['Coin'],
    }),
    addCoin: build.mutation({
      query: (payload) => ({
        url: `${BASE_API}/add-coin/`,
        method: 'POST',
        body: payload,
      }),
    }),
    getCoinList: build.query({
      query: () => ({
        url: `${BASE_API}/list/`,
        method: 'GET',
      }),
      providesTags: () => ['Coin'],
    }),
    getCoinMarketCap: build.query({
      query: (query) => ({
        url: `${BASE_API}/markets/${query}`,
        method: 'GET',
      }),
      providesTags: () => ['Coin'],
    }),
    searchCoin: build.query({
      query: (coinName) => ({
        url: `${BASE_API}/search/?query=${coinName}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 5,
    }),
    searchERC20Token: build.query({
      query: (body) => ({
        url: `${BASE_API}/erc20/search/?query=${body.query}${body.limit
          ? `&limit=${body.limit}` : ''}${body.blockchain ? `&blockchain=${body.blockchain}` : ''}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 5,
    }),
    searchCoinWithChain: build.query({
      query: (data) => ({
        url: `${BASE_API}/search/?query=${data.query}&chain=${data.chain}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 5,
    }),
    getCoinFromSearch: build.query({
      query: (data) => ({
        url: `${BASE_API}/search/?query=${data.query}&chain=${data.chain}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 3600,
    }),
    getUserCoins: build.query({
      query: () => ({
        url: `${BASE_API}/user-coins/`,
        method: 'GET',
      }),
      providesTags: () => ['Coin'],
    }),
    deleteUserCoin: build.mutation({
      query: (id) => ({
        url: `${BASE_API}/${id}/delete-user-coin/`,
        method: 'DELETE',
      }),
    }),
    getActiveAddressesCountCoins: build.query({
      query: (id) => ({
        url: `${BASE_API}/${id}/active-addresses-count/`,
        method: 'GET',
      }),
    }),
    getNftActiveAddresses: build.query({
      query: (arg) => ({
        url: `${BASE_API}/nft/${arg.address}/active-addresses-count/?blockchain=${arg.network}`,
        method: 'GET',
      }),
    }),
    getCoinTraders: build.query({
      query: (arg) => ({
        url: `${BASE_API}/${arg.id}/traders/?duration=${arg.days}`,
        method: 'GET',
      }),
    }),
    getUserWatchlist: build.query({
      query: () => ({
        url: `${BASE_API}/watchlist/`,
        method: 'GET',
      }),
    }),
    getNFTExchangeData: build.query({
      query: (arg) => ({
        url: `${BASE_API}/cex/usage/nft/${arg.address}/${arg.chain}?order=${arg.order}${arg.limit
          ? `&limit=${arg.limit}` : ''}${arg.offset
          ? `&offset=${arg.offset}` : ''}${arg.orderBy
          ? `&order_by=${arg.orderBy}` : ''}`,
      }),
    }),
    getTokenExchangeData: build.query({
      query: (arg) => ({
        url: `${BASE_API}/cex/usage/token/${arg.address}?order=${arg.order}${arg.limit
          ? `&limit=${arg.limit}` : ''}${arg.offset
          ? `&offset=${arg.offset}` : ''}${arg.orderBy
          ? `&order_by=${arg.orderBy}` : ''}`,
      }),
    }),
    addToUserWatchlist: build.mutation({
      query: (payload) => ({
        url: `${BASE_API}/watchlist/`,
        method: 'POST',
        body: payload,
      }),
    }),
    deleteFromUserWatchlist: build.mutation({
      query: (id) => ({
        url: `${BASE_API}/watchlist/${id}`,
        method: 'DELETE',
      }),
    }),
    searchNft: build.query({
      query: (nftName) => ({
        url: `${BASE_API}/nft/search/?query=${nftName}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 5,
    }),
    getSearchNft: build.query({
      query: (nftName) => ({
        url: `${BASE_API}/nft/search/?query=${nftName}`,
        method: 'GET',
      }),
    }),
    getNftMetadata: build.query({
      query: (addresses) => ({
        url: `${BASE_API}/nft-metadata/${addresses}`,
        method: 'GET',
      }),
    }),
    getNftBasicInfo: build.query({
      query: ({ address, network }) => ({
        url: `${BASE_API}/nft/${address}/${network}/`,
        method: 'GET',
      }),
    }),
    getNftOwnerInfo: build.query({
      query: (data) => ({
        url: `${BASE_API}/nft/${data.address}/${data.chain}/owners/?limit=${data.limit}&offset=${data.offset}`,
        method: 'GET',
      }),
    }),
    getNftTopHolders: build.query({
      query: (data) => ({
        url: `${BASE_API}/nft/${data.address}/${data.chain}/top-holders/`,
        method: 'GET',
      }),
    }),
    getNftTradersInfo: build.query({
      query: (data) => ({
        url: `${BASE_API}/nft/${data.address}/${data.chain}/traders/?duration=${data.duration}`,
        method: 'GET',
      }),
    }),
    getNftStatsTrading: build.query({
      query: (arg) => ({
        url: `${BASE_API}/nft/${arg.address}/${arg.network}/${arg.contractType}/trading-stats/`,
        method: 'GET',
      }),
      keepUnusedDataFor: 1800,
    }),
    getNftNotableInvestments: build.query({
      query: (arg) => ({
        url: `${BASE_API}/nft/${arg.address}/notable-investments/${
          arg.limit ? `?limit=${arg.limit}` : ''
        }${arg.network ? `?platform=${arg.network}` : ''}`,
        method: 'GET',
      }),
    }),
    getTokenNotableInvestments: build.query({
      query: (arg) => ({
        url: `${BASE_API}/${arg.id}/notable-investments/${
          arg.limit ? `?limit=${arg.limit}` : ''
        }${arg.chain ? `?platform=${arg.chain}` : ''}`,
        method: 'GET',
      }),
    }),
    getTokenBuyingPower: build.query({
      query: (id) => ({
        url: `${BASE_API}/${id}/buying-power/`,
        method: 'GET',
      }),
    }),
    getNftBuyingPower: build.query({
      query: (args) => ({
        url: `${BASE_API}/nft/${args.address}/buying-power/?blockchain=${args.blockchain || 'ethereum'}`,
        method: 'GET',
      }),
    }),
    getGroupInterest: build.query({
      query: (address) => ({
        url: `${BASE_API}/${address}/group-interest/`,
        method: 'GET',
      }),
    }),
    getNftGroupInterest: build.query({
      query: (args) => ({
        url: `${BASE_API}/nft/${args.address}/group-interest/?blockchain=${args.blockchain || 'ethereum'}`,
        method: 'GET',
      }),
    }),
    getTokenInvestments: build.query({
      query: (arg) => ({
        url: `${BASE_API}/${arg.id}/investor-portfolio/?desc=${arg.des}${arg.limit
          ? `&limit=${arg.limit}` : ''}${arg.asset_type
          ? `&asset_type=${arg.asset_type}` : ''}${arg.page
          ? `&page=${arg.page}` : ''}${arg.chain
          ? `&platform=${arg.chain}` : ''}${arg.order
          ? `&order=${arg.order}` : ''}`,
      }),
    }),
    getNftInvestments: build.query({
      query: (arg) => ({
        url: `${BASE_API}/nft/${arg.address}/investor-portfolio/?desc=${arg.des}${arg.limit
          ? `&limit=${arg.limit}` : ''}${arg.asset_type
          ? `&asset_type=${arg.asset_type}` : ''}${arg.page
          ? `&page=${arg.page}` : ''}${arg.chain
          ? `&blockchain=${arg.chain}` : ''}${arg.order
          ? `&order=${arg.order}` : ''}`,
      }),
    }),
    getTypicalPortfolio: build.query({
      query: (id) => ({
        url: `${BASE_API}/${id}/typical-portfolio/`,
        method: 'GET',
      }),
    }),
    getNftSharePortfolio: build.query({
      query: (arg) => ({
        url: `${BASE_API}/nft/share-portfolio?address=${arg.address}&blockchain=${arg.network}`,
        method: 'GET',
      }),
    }),
    getNftTypicalPortfolio: build.query({
      query: (arg) => ({
        url: `${BASE_API}/nft/${arg.address}/typical-portfolio/?blockchain=${arg.network}`,
        method: 'GET',
      }),
    }),
    getSharePortfolio: build.query({
      query: (id) => ({
        url: `${BASE_API}/${id}/share-portfolio/`,
        method: 'GET',
      }),
    }),
    getNftMint: build.query({
      query: (arg) => ({
        url: `${BASE_API}/nft/${arg.address}/mint-info/?blockchain=${arg.network}`,
        method: 'GET',
      }),
    }),
    getNftTopTraders: build.query({
      query: (data) => ({
        url: `${BASE_API}/nft/${data.address}/${data.chain}/top-traders/`,
        method: 'GET',
      }),
    }),
    getChangesOfNFTsHolders: build.query({
      query: (arg) => ({
        url: `${BASE_API}/changes-of-nft-holders/${arg.address}/?blockchain=${arg.blockchain
          ? `${arg.blockchain}` : 'ethereum'}`,
      }),
    }),
    getTokenHoldersList: build.query({
      query: (arg) => ({
        url: `${BASE_API}/table-token-holders/${arg.id}/?desc=${arg.des}${arg.limit
          ? `&limit=${arg.limit}` : ''}${arg.asset_type
          ? `&asset_type=${arg.asset_type}` : ''}${arg.page
          ? `&page=${arg.page}` : ''}${arg.order
          ? `&order=${arg.order}` : ''}`,
      }),
    }),
    getNFTsHoldersList: build.query({
      query: (arg) => ({
        url: `${BASE_API}/table-nft-holders/${arg.address}/?desc=${arg.des}${arg.limit
          ? `&limit=${arg.limit}` : ''}${arg.asset_type
          ? `&asset_type=${arg.asset_type}` : ''}${arg.page
          ? `&page=${arg.page}` : ''}${arg.blockchain
          ? `&blockchain=${arg.blockchain}` : 'ethereum'}${arg.order
          ? `&order=${arg.order}` : ''}`,
      }),
    }),
    getChangesOfTokensHolders: build.query({
      query: (arg) => ({
        url: `${BASE_API}/changes-of-tokens-holders/${arg.id}/`,
      }),
    }),
    getTokenHolderListCSV: build.mutation({
      query: (arg) => ({
        url: `${BASE_API}/token-holders-csv/${arg.id}/?limit=${arg.limit || 50000}${arg.sort
          ? `&sort=${arg.sort}`
          : ''}${arg.sortBy
          ? `&sort_by=${arg.sortBy}`
          : ''}&wait=${arg.wait}`,
        method: 'GET',
      }),
    }),
    getNftHolderListCSV: build.mutation({
      query: (arg) => ({
        url: `${BASE_API}/nft-holders-csv/${arg.id}/?limit=${arg.limit || 50000}${arg.sort
          ? `&sort=${arg.sort}`
          : ''}${arg.sortBy
          ? `&sort_by=${arg.sortBy}`
          : ''}${arg.blockchain
          ? `&blockchain=${arg.blockchain}` : 'ethereum'}&wait=${arg.wait}`,
        method: 'GET',
      }),
    }),
    postSnapshotOfCoinHolderList: build.mutation({
      query: (body) => ({
        url: `${BASE_API}/save-as-custom-list/${body.id}/`,
        method: 'POST',
        body: { name: body.name },
      }),
    }),
    postSnapshotOfNftHolderList: build.mutation({
      query: (body) => ({
        url: `${BASE_API}/nft-save-as-custom-list/${body.id}/`,
        method: 'POST',
        body: {
          name: body.name,
          blockchain: body.network,
        },
      }),
    }),
    getAddressesCollection: build.query({
      query: (slug) => ({
        url: `${BASE_API}/nft/${slug}/addresses/`,
        method: 'GET',
      }),
    }),
    reachNFT: build.query({
      query: (arg) => ({
        url: `${BASE_API}/nft/${arg.address}/${arg.network}/nft-reach/`,
        method: 'GET',
      }),
    }),
    reachToken: build.query({
      query: (name) => ({
        url: `${BASE_API}/${name}/token-reach/`,
        method: 'GET',
      }),
    }),
    getNFTLookup: build.query({
      query: (arg) => ({
        url: `${BASE_API}/nft-lookup/${arg.address}/?blockchain=${arg.blockchain}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 180,
    }),
    getNftDappUsageData: build.query({
      query: (arg) => ({
        url: `${BASE_API}/dapps/usage/nft/${arg.name}/${arg.network}?limit=${arg.limit}${arg.offset
          ? `&offset=${arg.offset}` : ''}${arg.order
          ? `&order=${arg.order}` : ''}${arg.orderBy
          ? `&order_by=${arg.orderBy}` : ''}`,
      }),
    }),
    getTokenDappUsageData: build.query({
      query: (arg) => ({
        url: `${BASE_API}/dapps/usage/token/${arg.id}?limit=${arg.limit}${arg.offset
          ? `&offset=${arg.offset}` : ''}${arg.order
          ? `&order=${arg.order}` : ''}${arg.orderBy
          ? `&order_by=${arg.orderBy}` : ''}`,
      }),
    }),
    getAssetDeployer: build.query({
      query: (arg) => ({
        url: `${BASE_API}/${arg.assetType}/${arg.assetId}/deployer/`,
      }),
    }),
  }),
});
