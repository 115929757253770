import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearActiveSettings,
  selectCurrPage,
  selectFieldSettings,
  setFieldSettings,
} from '../../../../../../store/reducers/forms';
import styles from './ButtonSettings.module.scss';
import formStyles from '../../FormSettings.module.scss';
import { ReactComponent as Close } from '../../../../../../assets/icons/close.svg';
import { ReactComponent as AlignButtonLeft } from '../../../../../../assets/icons/forms/align_button_left.svg';
import { ReactComponent as AlignButtonCenter } from '../../../../../../assets/icons/forms/align_button_center.svg';
import { ReactComponent as AlignButtonRight } from '../../../../../../assets/icons/forms/align_button_right.svg';
import { ReactComponent as AlignButtonFull } from '../../../../../../assets/icons/forms/align_button_full.svg';

const buttonAlignOptions = ['left', 'center', 'full', 'right'];
const buttonStyles = ['filled', 'outlined'];
const buttonAlignementIcons = {
  left: <AlignButtonLeft />,
  center: <AlignButtonCenter />,
  full: <AlignButtonFull />,
  right: <AlignButtonRight />,
};

const ButtonSettings = ({ activeSettings }) => {
  const dispatch = useDispatch();
  const currPage = useSelector(selectCurrPage);
  const fieldSettings = useSelector(selectFieldSettings);
  const [text, setText] = useState('');
  const [link, setLink] = useState('');
  const [style, setStyle] = useState(buttonStyles[0]);
  const [align, setAlign] = useState(buttonAlignOptions[1]);
  const [fullWidth, setFullWidth] = useState(false);
  const [change, setChange] = useState(false);

  const handleChange = useCallback(() => {
    setChange(false);
    const obj = {
      name: 'Button',
      type: 'button',
      text,
      link,
      style,
      align: align === 'full' ? 'center' : align,
      fullWidth: align === 'full',
    };
    dispatch(setFieldSettings({ ...obj, field_id: activeSettings.field_id }));
  }, [text, link, style, align, dispatch, activeSettings]);

  const setOptions = (config) => {
    setText(config.text);
    setLink(config.link);
    setStyle(config.style);
    setAlign(config.fullWidth ? 'full' : config.align);
  };

  useEffect(() => {
    const currPageSettings = fieldSettings.find((o) => o.pageName === currPage);
    if (currPageSettings) {
      const config = currPageSettings.fields?.find((x) => x.field_id === activeSettings.field_id);
      if (config) {
        setOptions(config);
      } else {
        handleChange();
      }
    }
  }, [activeSettings, currPage, fieldSettings]);

  useEffect(() => {
    if (change) {
      handleChange();
    }
  }, [text, link, style, align, fullWidth, handleChange]);

  return (
    <div className={`${styles.wrapper} h-100`}>
      <div className="d-flex flex-column justify-content-between h-100">
        <div>
          <div className={`${formStyles.title} d-flex align-items-center gap-2`}>
            Button Properties
          </div>
          <div className={`${styles.close} d-flex justify-content-end position-absolute`}>
            <Close
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => dispatch(clearActiveSettings())}
            />
          </div>
        </div>

        <div className="d-flex flex-row w-100 gap-3 mb-3">
          <div className="d-flex flex-column w-50 gap-1">
            <div className={formStyles.title_select}>
              Style
            </div>
            <div className={styles.buttonGroup}>
              {
                buttonStyles.map((option) => (
                  <button
                    type="button"
                    className={`${style === option
                      ? `regular-button ${styles.small_padding}`
                      : `outline-button ${styles.small_padding}`} ${styles.singleButton}`}
                    onClick={() => {
                      setStyle(option);
                      setChange(true);
                    }}
                  >
                    {option}
                  </button>
                ))
              }
            </div>
          </div>
          <div className="d-flex flex-column w-50 gap-1">
            <div className={formStyles.title_select}>
              Align
            </div>
            <div className={styles.buttonGroup}>
              {
                buttonAlignOptions.map((option) => (
                  <button
                    type="button"
                    className={`${align === option
                      ? `regular-button ${styles.small_padding_selected}`
                      : `outline-button ${styles.small_padding}`} ${styles.singleButton}`}
                    onClick={() => {
                      setAlign(option);
                      setChange(true);
                    }}
                  >
                    {buttonAlignementIcons[option]}
                  </button>
                ))
              }
            </div>
          </div>
        </div>

        <div className="d-flex flex-column w-100 gap-1 mb-3">
          <div className={formStyles.title_select}>
            Text
          </div>
          <div className={`${formStyles.text_input}`}>
            <input
              placeholder="Type here"
              type="text"
              className="w-100"
              value={text}
              onChange={(e) => {
                setText(e.currentTarget.value);
                setChange(true);
              }}
            />
          </div>
          <div className={formStyles.title_select}>
            Link
          </div>
          <div className={`${formStyles.text_input}`}>
            <input
              placeholder="Type in URL"
              type="text"
              className="w-100"
              value={link}
              onChange={(e) => setLink(e.currentTarget.value)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ButtonSettings;
