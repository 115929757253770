import React, { useEffect, useMemo, useState } from 'react';
import styles from '../../../components/ui/modals/ConfirmModal/ConfirmModal.module.scss';
import customLIstStyles from './SaveCustomListModal.module.scss';
import CloseModalButton from '../../../components/ui/modals/components/CloseModalButton';
import useKeydown from '../../../components/hooks/app/useKeydown';
import { ReactComponent as Arrow } from '../../../assets/icons/select_arrow.svg';
import { ReactComponent as Search } from '../../../assets/icons/icon_search.svg';
import { audiencesApi } from '../../../api/audiences';
import Loader from '../../../components/base/Loader';
import uniqueId from '../../../utils/uniqueId';
import { showErrorMessage, showSuccessMessage } from '../../../components/base/Notifications';
import RoundSpinner from '../../../components/base/RoundSpinner';

const SaveCustomListModal = ({
  onCancel, onSubmit, setCustomListName, customListName, setCustomListId,
}) => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [suggestedList, setSuggestedList] = useState([]);
  const {
    data: customListsData,
    isLoading: isCustomListsDataLoading,
  } = audiencesApi.useGetTeamSegmentsQuery({
    limit: 100000, itemType: 'custom list',
  }, {
    skip: !showAddModal,
  });
  const [createCustomListAddresses, createCustomListAddressesResult] = audiencesApi.useSubmitCustomListMutation();

  useKeydown('Escape', onCancel);

  useEffect(() => {
    if (customListsData?.results) {
      const dataName = customListsData?.results.map((elem) => elem.name);
      const filterName = dataName
        .filter((elem) => elem.toLowerCase().includes(customListName.toLowerCase()));
      setSuggestedList(filterName);
    } else {
      setSuggestedList([]);
    }
  }, [customListsData?.results, customListName]);

  useEffect(() => {
    if (createCustomListAddressesResult.isSuccess) {
      showSuccessMessage('Custom list was successfully created');
      setCustomListName(createCustomListAddressesResult?.originalArgs?.name);
      setCustomListId(createCustomListAddressesResult?.data?.id);
      createCustomListAddressesResult.reset();
      setShowAddModal(false);
    }
    if (createCustomListAddressesResult.isError) {
      if (createCustomListAddressesResult.error.status === 400) {
        showErrorMessage(Object.keys(createCustomListAddressesResult.error.data).length !== 0
          && 'Custom list with the same name already exists');
      } else {
        showErrorMessage(Object.keys(createCustomListAddressesResult.error.data).length
          !== 0 && 'Something went wrong');
      }
      createCustomListAddressesResult.reset();
    }
  }, [createCustomListAddressesResult]);

  const modalContent = useMemo(() => {
    if (isCustomListsDataLoading) {
      return (
        <div className="my-4">
          <Loader />
        </div>
      );
    }
    if (suggestedList.length) {
      return (
        <div className="d-flex flex-column gap-2">
          <span className={customLIstStyles.title_group}>Select an existing list or create new one</span>
          <div className={customLIstStyles.wrapper_list}>
            {suggestedList.map((elem) => (
              <div
                key={uniqueId('name_list')}
                className={customLIstStyles.hover}
              >
                <button
                  className={`${customLIstStyles.name_list_wrapper} text-truncate my-2 w-100`}
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    setCustomListName(elem);
                    setCustomListId(customListsData?.results.filter((item) => item.name === elem)[0].id);
                    setCustomListName(elem);
                    setShowAddModal(false);
                  }}
                >
                  {elem}
                </button>
                <div className={customLIstStyles.divider_list} />
              </div>
            ))}
          </div>
        </div>
      );
    }
    return null;
  }, [isCustomListsDataLoading, suggestedList]);

  return (
    <div
      className={`modal modal-dialog-centered ${styles.modal_wrapper}`}
      id="confirmModal"
      tabIndex="-1"
      aria-labelledby="confirmModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-md modal-phone w-100 position-relative">
        <div className={`${styles.body} modal-content position-relative border-0`}>
          <CloseModalButton onCancel={onCancel} />
          <div>
            <div className={`${styles.title} d-flex justify-content-start`}>
              Save a custom list for this import
            </div>
          </div>
          <div className={`${styles.description}`}>
            <span>
              To save a custom list for this import,
              please provide a name for the custom list to be created or select existing
            </span>
            <div
              role="presentation"
              className={`${customLIstStyles.link_area} mt-2`}
              onClick={() => {
                setShowAddModal(true);
                setCustomListName('');
              }}
            >
              <div width="28px" height="28px" className="cursor-pointer">
                <Search />
              </div>
              <input
                placeholder="Search for the list or create a new one"
                type="text"
                className="w-100 p-1"
                maxLength={50}
                value={customListName}
                onChange={(e) => setCustomListName(e.target.value)}
              />
              <div className="d-flex align-items-center me-2">
                <div className={`${customLIstStyles.divider} me-2`} />
                <Arrow className="cursor-pointer" />
              </div>
              {showAddModal && (
                <div
                  role="presentation"
                  className={`${customLIstStyles.modal_wrapper} position-absolute d-flex flex-column gap-2`}
                  onClick={(e) => e.stopPropagation()}
                >
                  <div className="d-flex flex-column gap-2">
                    <span className={customLIstStyles.title_group}>Create new list</span>
                    <button
                      className={`${customLIstStyles.name_wrapper} text-truncate`}
                      type="button"
                      disabled={!customListName}
                      onClick={() => createCustomListAddresses({
                        name: customListName,
                        addresses: [],
                      })}
                    >
                      {customListName}
                    </button>
                  </div>
                  {modalContent}
                </div>
              )}
            </div>
            <div className={`mt-3 ${customLIstStyles.no_cl}`}>
              You can upload file with no custom list creation.
            </div>
          </div>
          <div className={`${styles.footer} d-flex justify-content-end`}>
            {onCancel && (
              <button
                type="button"
                className="outline-button border border-1"
                data-bs-dismiss="modal"
                onClick={() => onCancel()}
              >
                Cancel
              </button>
            )}
            <button
              type="button"
              className="regular-button"
              data-bs-dismiss="modal"
              onClick={() => onSubmit()}
            >
              {!customListName ? 'Import without saving a list' : 'Import'}
            </button>
          </div>
        </div>
      </div>
      {createCustomListAddressesResult.isLoading || createCustomListAddresses.isFetching
        ? <RoundSpinner />
        : null}
    </div>
  );
};

export default SaveCustomListModal;
