import React, { useEffect, useMemo, useState } from 'react';
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { countriesOptions, loggedInOptions, walletOptions } from '../../../utils/webSDK';
import InputOption from '../components/InputOption';
import { setWebSDKFilterContents, setWebSDKFilterParam, setWebSDKVisitors } from '../../../store/reducers/webSDK';
import Spinner from '../../../components/base/Spinner';
import styles from './WebSDKFilters.module.scss';
import { webSDKApi } from '../../../api/webSDK';

const WebSDKFilters = () => {
  const [day, setDay] = useState('30d');
  const [walletFilter, setWalletFilter] = useState(null);
  const [countries, setCountries] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const dispatch = useDispatch();
  const { id } = useParams();

  const {
    data: filteredStats,
    isLoading: isLoadingFilteredStats,
    isFetching: isFetchingFilteredStats,
  } = webSDKApi.useGetWebSDKStatsFilteredQuery(
    {
      id,
      day,
      walletFilter,
      countries,
      isLoggedIn,
    },
  );

  useEffect(() => {
    if (filteredStats?.visitors !== null) {
      dispatch(setWebSDKVisitors(filteredStats?.visitors));
    }
  }, [dispatch, filteredStats?.visitors]);

  useEffect(() => {
    dispatch(setWebSDKFilterParam({
      day,
      walletFilter,
      countries,
      isLoggedIn,
    }));
  }, [dispatch, day, walletFilter, countries, isLoggedIn]);

  useEffect(() => {
    if (isLoadingFilteredStats || isFetchingFilteredStats) {
      dispatch(setWebSDKFilterContents({
        filteredStats: null,
        isLoadingFilteredStats,
        isFetchingFilteredStats,
      }));
    } else {
      dispatch(setWebSDKFilterContents({
        filteredStats,
        isLoadingFilteredStats,
        isFetchingFilteredStats,
      }));
    }
  }, [dispatch, isLoadingFilteredStats, isFetchingFilteredStats, filteredStats]);

  const matchingVisitorsContent = useMemo(() => {
    if (isLoadingFilteredStats || isFetchingFilteredStats) {
      return (
        <Spinner />
      );
    }
    return (
      `${filteredStats?.visitors.toLocaleString() || 0} matching visitor${filteredStats?.visitors !== 1 ? 's' : ''}`
    );
  }, [isLoadingFilteredStats, isFetchingFilteredStats, filteredStats?.visitors]);

  return (
    <div className={`${styles.wrapper} mt-4 mb-3 d-flex gap-4 align-items-center`}>
      <div
        className="btn-group me-4 group"
        role="group"
        aria-label="Second group"
      >
        <button
          type="button"
          className={`btn btn-outline-secondary shadow-none ${
            day === '7d' ? 'active' : ''
          }`}
          onClick={() => setDay('7d')}
        >
          7d
        </button>
        <button
          type="button"
          className={`btn btn-outline-secondary shadow-none ${
            day === '30d' ? 'active' : ''
          }`}
          onClick={() => setDay('30d')}
        >
          30d
        </button>
        <button
          type="button"
          className={`btn btn-outline-secondary shadow-none ${
            day === '180d' ? 'active' : ''
          }`}
          onClick={() => setDay('180d')}
        >
          180d
        </button>
      </div>
      <Select
        className={styles.selects}
        defaultValue={walletOptions[0]}
        hideSelectedOptions={false}
        maxMenuHeight={194}
        onChange={(option) => {
          setWalletFilter(option.value);
        }}
        options={walletOptions}
      />
      <Select
        className={styles.selects}
        placeholder="All countries"
        styles={{
          placeholder: (base) => ({
            ...base,
            color: 'black',
          }),
        }}
        isMulti
        maxMenuHeight={194}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        components={{
          Option: InputOption,
        }}
        onChange={(options) => {
          if (Array.isArray(options)) {
            setCountries(options.map((opt) => opt.value));
          }
        }}
        options={countriesOptions}
      />
      <Select
        className={styles.selects}
        defaultValue={loggedInOptions[0]}
        hideSelectedOptions={false}
        maxMenuHeight={194}
        onChange={(option) => {
          setIsLoggedIn(option.value);
        }}
        options={loggedInOptions}
      />

      <div className={styles.visitors}>
        {matchingVisitorsContent}
      </div>
    </div>
  );
};

export default WebSDKFilters;
