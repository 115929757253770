import React from 'react';

import './Loader.scss';

const Loader = ({ size = 'large' }) => (
  <div className="dot-pulse">
    <div className={`single-dot-1 ${size}`} />
    <div className={`single-dot-2 ${size}`} />
    <div className={`single-dot-3 ${size}`} />
  </div>
);

export default Loader;
