import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Settings from '../../../assets/images/dashboard/settings.png';
import './Card.scss';
import styles from './Card.module.scss';
import { LoadingAvatar, LoadingLines } from '../modals/SearchModal/LoadingList';
import { tooltipChainIconPicker } from '../../../tools/ChainIconPicker';
import uniqueId from '../../../utils/uniqueId';
import DefaultIcon from '../DefaultIcon';
import ItemPreview from '../ItemPreview';
import { ReactComponent as Trash } from '../../../assets/icons/table/trash.svg';
import { contractsApi } from '../../../api/contracts';
import ConfirmModal from '../modals/ConfirmModal';
import { showSuccessMessage } from '../../base/Notifications';
import { deleteNFTCreationAsset } from '../../../store/reducers/contracts';

const Card = ({
  cardData, isLoading,
}) => {
  const dispatch = useDispatch();
  const [cardLogoError, setCardLogoError] = useState(false);
  const [showDeleteConfirmModel, setShowDeleteConfirmModel] = useState(false);
  const [removeNFT, removeResult] = contractsApi.useDeleteNFTMutation();

  useEffect(() => {
    if (removeResult.isSuccess) {
      dispatch(deleteNFTCreationAsset(cardData.id));
      showSuccessMessage('The object has been successfully removed');
    }
  }, [removeResult]);

  const getNetworkIcon = (data) => {
    let isEth = false;
    let isPoly = false;
    if (data.blockchain === 'goerli' || data.blockchain === 'ethereum') {
      isEth = true;
    }
    if (data.blockchain === 'mumbai' || data.blockchain === 'polygon') {
      isPoly = true;
    }

    if (!isEth && !isPoly) {
      return null;
    }

    return (
      <div className="d-flex align-items-center">
        <div className={`mx-2 ${styles.vertical_separator}`} />
        {isEth && tooltipChainIconPicker('ethereum', uniqueId('chain'))}
        {isPoly && tooltipChainIconPicker('polygon', uniqueId('chain'))}
      </div>
    );
  };

  const renderData = () => {
    // render the title and logo
    const titleCardRender = () => (
      <div className={`${styles.card_row} d-flex align-items-center gap-2`}>
        <div
          className="d-flex"
          data-for={cardData.asset_type === 'nft' && `preview_${cardData.contract_address}_${cardData.blockchain}`}
          data-tip="show"
        >
          {
            !cardLogoError && cardData.media
              ? (
                <img
                  src={cardData.media}
                  onError={() => setCardLogoError(true)}
                  height="40"
                  width="40"
                  alt="card"
                  className="rounded-circle"
                />
              )
              : <DefaultIcon type={cardData.asset_type} />
          }
        </div>
        <div
          className="text-truncate"
          data-for={cardData.asset_type === 'nft' && `preview_${cardData.contract_address}_${cardData.blockchain}`}
          data-tip="show"
        >
          <h3 className={`m-0 p-0 d-block ${styles.card_title}`}>
            {cardData?.collection_name || '?'}
            {' '}
          </h3>
        </div>
        <ItemPreview
          id={`preview_${cardData.address}_${cardData.network}`}
          data={{
            address: cardData.contract_address,
            type: 'nft',
            blockchain: cardData.blockchain,
            img: cardData.media,
            name: cardData?.collection_name,
          }}
        />
        <div
          role="presentation"
          onClick={(e) => e.preventDefault()}
          className={`ms-auto ${styles.popover__wrapper} text-center position-relative cursor-pointer`}
        >
          <img
            src={Settings}
            className="cursor-pointer"
            height="25"
            width="25"
            alt="settings"
          />
          <div className={`${styles.invisible_dropdown} position-absolute top-0 right-0`}>
            <div className={`${styles.popover__content} position-absolute`}>
              <div className="dropdown-menu-wrapper">
                <div
                  role="presentation"
                  className="dropdown-menu-item"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowDeleteConfirmModel(true);
                  }}
                >
                  <Trash />
                  Remove
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Confirm model to delete nft */}
        { showDeleteConfirmModel && (
          <ConfirmModal
            title="Confirm delete"
            description={`Are you sure you want to delete “${cardData?.collection_name?.length > 30
              ? `${cardData?.collection_name.slice(0, 30)}...` : cardData?.collection_name}”?`}
            buttonName="Remove"
            onSubmit={() => {
              removeNFT(cardData.id);
              setShowDeleteConfirmModel(false);
            }}
            onCancel={() => setShowDeleteConfirmModel(false)}
            loading={removeResult.isLoading || removeResult.isFetching}
          />
        )}

      </div>
    );

    return (
      <>
        <div>
          {titleCardRender()}
          <div className={styles.data_content}>
            <div className="d-flex align-items-center gap-2">
              <p className={`${styles.card_info} m-0 p-0`}>Holders</p>
              <p className={`${styles.card_info_bold} m-0 p-0`}>
                {cardData?.holder_number || 0}
              </p>
            </div>
            <div className="d-flex align-items-center gap-2">
              <p className={`${styles.card_info} m-0 p-0`}>Created date</p>
              <p className={`${styles.card_info_bold} m-0 p-0`}>
                {cardData.created_at !== null
                  ? `${new Date(cardData.created_at).toLocaleDateString('en-US')}`
                  : '?'}
              </p>
            </div>
            <div className="d-flex align-items-center gap-2">
              <p className={`${styles.card_info} m-0 p-0`}>Created by</p>
              <p className={`${styles.card_info_bold} m-0 p-0`}>
                {cardData.creator !== null
                  ? `${cardData.creator}`
                  : '?'}
              </p>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-start gap-2">
          <div
            className={`${styles.asset}
            px-3 d-flex justify-content-center align-items-center`}
          >
            NFT Collection
          </div>
          <div
            className={`${styles.transferable} 
            px-3 d-flex justify-content-center align-items-center`}
          >
            {cardData?.transferable ? 'Transferable' : 'Non-transferable'}
          </div>
          {getNetworkIcon(cardData)}
        </div>
      </>
    );
  };

  if (isLoading) {
    return (
      <div className={`${styles.card_wrapper} d-flex`}>
        <div className="d-flex">
          <div className="w-25">
            <LoadingAvatar size={60} />
          </div>
          <LoadingLines rows={1} />
        </div>
        <LoadingLines rows={1} />
      </div>
    );
  }

  return (
    <div className={`${styles.card_wrapper} d-flex justify-content-between`}>
      {renderData()}
    </div>
  );
};

export default Card;
