import React, { useState } from 'react';

import { convertToReadableFormat } from '../../../../../tools/NumberConverterTool';
import { handlePlatforms } from '../../../../../utils/search/preventSearch';
import { ReactComponent as Ethereum } from '../../../../../assets/icons/eth.svg';
import { ReactComponent as Polygon } from '../../../../../assets/icons/polygon logo svg.svg';
import styles from './McapLabel.module.scss';
import Verification from '../../../../../components/ui/Verification';
import DefaultIcon from '../../../../../components/ui/DefaultIcon';
import ItemPreview from '../../../../../components/ui/ItemPreview';

const McapLabel = ({ val, type }) => {
  const [imgError, setImgError] = useState(false);

  return (
    <div className={`${styles.wrapper} d-flex cursor-pointer justify-content-between`}>
      <div className="d-flex">
        <div
          className="pe-2"
          data-for={type.toLowerCase() === 'nft' && `preview_${val.contract_address || val.value}_${val.blockchain}`}
          data-tip="show"
        >
          {val.blockchain
            ? (val.image_url || val.image) && !imgError ? (
              <img
                width="20px"
                height="20px"
                src={val.image_url || val.image}
                alt=""
                onError={() => setImgError(true)}
              />
            ) : (
              <DefaultIcon size="20px" type={type} />
            )
            : null}
        </div>
        <div className={`d-flex ${styles.name}`}>
          <div
            className="text-truncate"
            data-for={type.toLowerCase() === 'nft' && `preview_${val.contract_address || val.value}_${val.blockchain}`}
            data-tip="show"
          >
            {val.label}
          </div>
          {type.toLowerCase() === 'nft'
            ? (
              <ItemPreview
                id={`preview_${val.contract_address || val.value}_${val.blockchain}`}
                data={{
                  address: val.contract_address || val.value,
                  type: 'nft',
                  blockchain: val.blockchain,
                  img: val.image_url || val.logo || val.image,
                  name: val.name || val.label,
                }}
              />
            )
            : null}
        </div>
        {val.is_verified_on_opensea
          ? <Verification className="ms-1" width="16px" height="16px" />
          : null}
      </div>
      <div className="d-flex">
        {!val.head && val.blockchain
          ? (
            <div className={`${styles.mcap} text-nowrap`}>
              {`${convertToReadableFormat(val.usd_market_cap || val.market_cap)} mcap`}
            </div>
          )
          : null}
        <div className="px-2">
          {val.blockchain ? (
            <div className="d-flex gap-1 align-items-center">
              <div className={styles.separator_icon} />
              <div className={`${styles.svg} d-flex align-items-center`}>
                {handlePlatforms(val.blockchain).ethereum && <Ethereum />}
                {handlePlatforms(val.blockchain).polygon && <Polygon />}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default McapLabel;
