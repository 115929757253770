import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import Chart from 'react-apexcharts';
import {
  getBitcoinPrices,
  getHistoryPrices,
} from '../../../../store/reducers/price';
import TitleComponent from '../../../base/TitleComponent';
import { converterChart, nftChart } from '../../../../tools/OptionsChart';
import ArrowUp from '../../../../assets/images/arrow-up.png';
import ArrowDown from '../../../../assets/images/arrow-down.png';
import './Price.scss';
import NoData from '../../../base/NoData';
import RoundSpinner from '../../../base/RoundSpinner';
import { ethFormat } from '../../../../utils/singleAssetPage/parseData';
import styles from './Price.module.scss';

export const defaultOptions = {
  series: [{
    data: [],
    name: '',
  }],
  options: {
    chart: {
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      animations: {
        enabled: true,
        easing: 'easein',
      },
    },
    dataLabels: {
      enabled: false,
    },
    subtitle: {
      align: 'left',
      offsetY: 15,
      style: {
        color: '#90A0B7',
        fontWeight: 500,
        fontSize: '14px',
      },
    },
    title: {
      text: '',
      align: 'left',
      style: {
        color: '#90A0B7',
        fontWeight: 500,
        fontSize: '14px',
      },
    },
    stroke: {
      curve: 'smooth',
    },
    colors: ['#109CF1', '#C2CFE0'],
    yaxis: [{
      title: {
        style: {
          color: '#90A0B7',
        },
      },
      axisBorder: {
        show: true,
      },
      labels: {
        formatter: (y) => ethFormat(y),
      },
    },
    {
      opposite: true,
      title: {
        text: '',
        style: {
          color: '#90A0B7',
        },
      },
      axisBorder: {
        show: true,
      },
      labels: {
        show: true,
        formatter: (y) => ethFormat(y),
      },
    },
    ],
    legend: {
      show: false,
    },
  },
};

const Price = ({
  id, type, setDays, days, chartData, exchange, priceData, isLoading, status, isFetching, symbol,
}) => {
  const [dataChart, setDataChart] = useState(undefined);
  const [checkbox, setCheckbox] = useState(id !== 'bitcoin');
  const [currency, setCurrency] = useState('USD');
  const [day, setDay] = useState('30');
  const historyPrice = useSelector(getHistoryPrices);
  const BTCPrices = useSelector(getBitcoinPrices);

  useEffect(() => {
    if (type === 'coin') {
      if (isLoading || isFetching || typeof historyPrice === 'undefined') {
        setDataChart(undefined);
      }
      if (historyPrice && BTCPrices) {
        setDataChart(
          converterChart(historyPrice, days, id, checkbox, BTCPrices, symbol),
        );
      }
      if (!isLoading && historyPrice === null) {
        setDataChart(null);
      }
    }
    if (type === 'nft') {
      if (isLoading) {
        setDataChart(undefined);
      }
      if (chartData) {
        setDataChart(nftChart(currency, chartData, day, exchange));
      }
      if ((status === 'fulfilled' || status === 'rejected') && !chartData) {
        setDataChart(null);
      }
    }
  }, [
    BTCPrices,
    chartData,
    checkbox,
    currency,
    day, days, exchange, historyPrice, id, isFetching, isLoading, status, type, symbol,
  ]);

  const chartContent = useMemo(() => {
    if (dataChart === null) {
      return <NoData isOnChart />;
    }
    return (
      <>
        <div id="chart" className="position-relative">
          {isLoading || isFetching || typeof dataChart === 'undefined'
            ? (
              <div className={`${styles.load_wrap} position-absolute`}>
                <RoundSpinner position="position-absolute" theme="light" />
              </div>
            )
            : null}
          <Chart
            options={dataChart
            && Object.keys(dataChart).includes('options') && Object.keys(dataChart).includes('series')
              ? dataChart.options : defaultOptions.options}
            series={dataChart
            && Object.keys(dataChart).includes('options') && Object.keys(dataChart).includes('series')
              ? dataChart.series : defaultOptions.series}
            type="line"
            height={400}
          />
        </div>
        <div className="d-flex justify-content-between mx-4 mb-4" style={{ marginTop: '-20px' }}>
          <div className="d-flex gap-2">
            <div className="d-flex align-items-center gap-2 asset-wrap">
              <div className="dot" />
              <span>{type !== 'nft' ? symbol ? `$${symbol.toUpperCase()}` : id?.toUpperCase() : 'Average price'}</span>
            </div>
            {id !== 'bitcoin' && id !== 'Transactional price' && (
              <div className="d-flex align-items-center gap-2 asset-wrap">
                <div className="dot-1" />
                <span>{type === 'nft' ? 'Volume' : 'BTC'}</span>
              </div>
            )}
          </div>
          {id !== 'bitcoin' && id !== 'Transactional price' && type !== 'nft' && (
            <div className={`form-check ${styles.btc}`}>
              <label className="form-check-label" htmlFor="flexCheckChecked">
                <input
                  className="form-check-input shadow-none cursor-pointer"
                  type="checkbox"
                  value=""
                  id="flexCheckChecked"
                  checked={checkbox}
                  onChange={() => setCheckbox(!checkbox)}
                />
                BTC
              </label>
            </div>
          )}
        </div>
      </>
    );
  }, [dataChart, isLoading, isFetching, type, symbol, id, checkbox]);

  const priceCHangingContent = useMemo(() => {
    if (type === 'coin' && priceData) {
      return (
        <div className="d-flex gap-4 flex-wrap mx-4">
          <div className="d-flex gap-1 align-items-center">
            <p className="data p-0 m-0">24h</p>
            {priceData.day !== null ? (
              <>
                <img
                  src={priceData.day > 0 ? ArrowUp : ArrowDown}
                  alt="up"
                  width="9"
                  height="5"
                />
                <span
                  className={
                    priceData.day > 0 ? 'data-percent' : 'data-percent-down'
                  }
                >
                  {+Math.abs(priceData.day)
                    .toFixed(2)}
                  %
                </span>
              </>
            ) : <div className="dash">-</div>}
          </div>
          <div className="d-flex gap-1 align-items-center">
            <p className="data p-0 m-0">7d</p>
            {priceData.week !== null ? (
              <>
                <img
                  src={priceData.week > 0 ? ArrowUp : ArrowDown}
                  alt="up"
                  width="9"
                  height="5"
                />
                <span
                  className={
                    priceData.week > 0 ? 'data-percent' : 'data-percent-down'
                  }
                >
                  {+Math.abs(priceData.week).toFixed(2)}
                  %
                </span>
              </>
            ) : <div className="dash">-</div>}

          </div>
          <div className="d-flex gap-1 align-items-center">
            <p className="data p-0 m-0">30d</p>
            {priceData.month !== null
              ? (
                <>
                  <img
                    src={priceData.month > 0 ? ArrowUp : ArrowDown}
                    alt="up"
                    width="9"
                    height="5"
                  />
                  <span
                    className={
                      priceData.month > 0 ? 'data-percent' : 'data-percent-down'
                    }
                  >
                    {+Math.abs(priceData.month).toFixed(2)}
                    %
                  </span>
                </>
              ) : <div className="dash">-</div>}

          </div>
          <div className="d-flex p-2 gap-1 align-items-center">
            <p className="data p-0 m-0">3m</p>
            {priceData.twoMonth !== null
              ? (
                <>
                  <img
                    src={priceData.twoMonth > 0 ? ArrowUp : ArrowDown}
                    alt="up"
                    width="9"
                    height="5"
                  />
                  <span
                    className={
                      priceData.twoMonth > 0 ? 'data-percent' : 'data-percent-down'
                    }
                  >
                    {+Math.abs(priceData.twoMonth).toFixed(2)}
                    %
                  </span>
                </>
              ) : <div className="dash">-</div>}
          </div>
          <div className="d-flex p-2 gap-1 align-items-center">
            <p className="data p-0 m-0">12m</p>
            {priceData.year !== null
              ? (
                <>
                  <img
                    src={priceData.year > 0 ? ArrowUp : ArrowDown}
                    alt="up"
                    width="9"
                    height="5"
                  />
                  <span
                    className={
                      priceData.year > 0 ? 'data-percent' : 'data-percent-down'
                    }
                  >
                    {+Math.abs(priceData.year).toFixed(2)}
                    %
                  </span>
                </>
              ) : <div className="dash">-</div>}
          </div>
        </div>
      );
    }
    if (type === 'nft' && dataChart && Object.keys(dataChart).includes('sevenDay')) {
      return (
        <div className="d-flex gap-4 flex-wrap mx-4">
          <div className="d-flex gap-1 align-items-center">
            <p className="data p-0 m-0">7d</p>
            {dataChart.sevenDay === Infinity
              ? <span>?</span>
              : (
                <>
                  <img
                    src={dataChart.sevenDay > 0 ? ArrowUp : ArrowDown}
                    alt="up"
                    width="9"
                    height="5"
                  />
                  <span
                    className={
                      dataChart.sevenDay > 0 ? 'data-percent' : 'data-percent-down'
                    }
                  >
                    {+Math.abs(dataChart.sevenDay).toFixed(3)}
                    %
                  </span>
                </>
              )}
          </div>
          <div className="d-flex gap-1 align-items-center">
            <p className="data p-0 m-0">30d</p>
            {dataChart.month === Infinity
              ? <span>?</span>
              : (
                <>
                  <img
                    src={dataChart.month > 0 ? ArrowUp : ArrowDown}
                    alt="up"
                    width="9"
                    height="5"
                  />
                  <span
                    className={
                      dataChart.month > 0 ? 'data-percent' : 'data-percent-down'
                    }
                  >
                    {+Math.abs(dataChart.month).toFixed(3)}
                    %
                  </span>
                </>
              )}
          </div>
          <div className="d-flex gap-1 align-items-center">
            <p className="data p-0 m-0">180d</p>
            {dataChart.sixMonth === Infinity
              ? <span>?</span>
              : (
                <>
                  <img
                    src={dataChart.sixMonth > 0 ? ArrowUp : ArrowDown}
                    alt="up"
                    width="9"
                    height="5"
                  />
                  <span
                    className={
                      dataChart.sixMonth > 0 ? 'data-percent' : 'data-percent-down'
                    }
                  >
                    {+Math.abs(dataChart.sixMonth).toFixed(3)}
                    %
                  </span>
                </>
              )}
          </div>
        </div>
      );
    }
    return null;
  }, [dataChart, priceData, type]);

  return (
    <div className="price-wrapper d-flex flex-column">
      <div className="d-flex justify-content-between align-items-center data-chart">
        <div className="price-title">
          <TitleComponent>{ type === 'nft' ? 'Activity' : 'Price'}</TitleComponent>
        </div>
        <div
          className="btn-toolbar mb-3"
          role="toolbar"
          aria-label="Toolbar with button groups"
        >
          {type === 'nft' && (
            <div
              className="btn-group me-4 group"
              role="group"
              aria-label="First group"
            >
              <button
                type="button"
                className={`btn btn-outline-secondary shadow-none ${
                  currency === 'USD' ? 'active' : ''
                }`}
                onClick={() => setCurrency('USD')}
              >
                USD
              </button>
              <button
                type="button"
                className={`btn btn-outline-secondary shadow-none ${
                  currency === 'ETH' ? 'active' : ''
                }`}
                onClick={() => setCurrency('ETH')}
              >
                ETH
              </button>
            </div>
          )}
          {type !== 'nft' ? (
            <div
              className="btn-group me-4 group"
              role="group"
              aria-label="Second group"
            >
              <button
                type="button"
                className={`btn btn-outline-secondary shadow-none ${
                  days === '1' ? 'active' : ''
                }`}
                onClick={() => {
                  setDays('1');
                }}
              >
                24h
              </button>
              <button
                type="button"
                className={`btn btn-outline-secondary shadow-none ${
                  days === '7' ? 'active' : ''
                }`}
                onClick={() => setDays('7')}
              >
                7d
              </button>
              <button
                type="button"
                className={`btn btn-outline-secondary shadow-none ${
                  days === '30' ? 'active' : ''
                }`}
                onClick={() => setDays('30')}
              >
                30d
              </button>
              <button
                type="button"
                className={`btn btn-outline-secondary shadow-none ${
                  days === '365' ? 'active' : ''
                }`}
                onClick={() => setDays('365')}
              >
                365d
              </button>
            </div>
          ) : (
            <div
              className="btn-group me-4 group"
              role="group"
              aria-label="Second group"
            >
              <button
                type="button"
                className={`btn btn-outline-secondary shadow-none ${
                  day === '7' ? 'active' : ''
                }`}
                onClick={() => setDay('7')}
              >
                7d
              </button>
              <button
                type="button"
                className={`btn btn-outline-secondary shadow-none ${
                  day === '30' ? 'active' : ''
                }`}
                onClick={() => setDay('30')}
              >
                30d
              </button>
              <button
                type="button"
                className={`btn btn-outline-secondary shadow-none ${
                  day === '180' ? 'active' : ''
                }`}
                onClick={() => setDay('180')}
              >
                180d
              </button>
            </div>
          )}
        </div>
      </div>
      {chartContent}
      {priceCHangingContent}
    </div>
  );
};

export default Price;
