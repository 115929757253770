import React, {
  useState, useRef, useEffect, useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import List from './List';
import NoResults from './NoResults';
// import { brandsApi } from '../../../../api/brands';
import { coinApi } from '../../../../api/coin';
import { userApi } from '../../../../api/user';
import { useDebounce } from '../../../hooks/app';
import { convertNumber, convertToReadableFormat } from '../../../../tools/NumberConverterTool';
import Search from '../../../../assets/icons/icon_search.svg';
import Delete from '../../../../assets/images/delete.png';
import './SearchModal.scss';
import { ethFormat } from '../../../../utils/singleAssetPage/parseData';
import { preventPaste, preventSearch } from '../../../../utils/search/preventSearch';
import { isValidContractAddress } from '../../../../tools/EtherTool';
import { ReactComponent as Wallet } from '../../../../assets/icons/wallet_logo.svg';
import ModalCloseButton from '../../../base/ModalCloseButton';
import { profilesApi } from '../../../../api/profiles';
import { setSelectedAsset } from '../../../../store/reducers/app';
import LoadingList from './LoadingList';
import SearchList from './SearchList';
import uniqueId from '../../../../utils/uniqueId';
import ItemPreview from '../../ItemPreview';
import { getIsSearchModalOpen, selectContractDeployer } from '../../../../store/reducers/search';
import styles from './SearchModal.module.scss';

const SearchModal = ({ setShowModal }) => {
  const dispatch = useDispatch();
  const [debouncedText, setDebouncedText] = useDebounce('');
  const [displayList, setDisplayList] = useState([false, false, false, false]);
  const [walletAddress, setWalletAddress] = useState('');
  const inputRef = useRef(null);
  const navigate = useNavigate();

  const contractDeployer = useSelector(selectContractDeployer);

  const {
    currentData: coins,
  } = coinApi.useSearchCoinQuery(debouncedText, {
    skip: (!(!displayList.some((checkbox) => checkbox) || displayList[2]) && contractDeployer) || !debouncedText,
  });

  const {
    currentData: wallets,
  } = profilesApi.useSearchWalletsQuery(debouncedText, {
    skip: (!(!displayList.some((checkbox) => checkbox) || displayList[3]) && contractDeployer) || !debouncedText,
  });

  const {
    currentData: nfts,
  } = coinApi.useSearchNftQuery(debouncedText, {
    skip: (!(!displayList.some((checkbox) => checkbox) || displayList[1]) && contractDeployer) || !debouncedText,
  });

  const {
    currentData: nftDeployers,
  } = coinApi.useSearchNftQuery(contractDeployer, {
    skip: !contractDeployer,
  });

  const {
    currentData: coinDeployers,
  } = coinApi.useSearchERC20TokenQuery({ query: contractDeployer }, {
    skip: !contractDeployer,
  });

  const {
    currentData: nftInAsset,
  } = userApi.useGetNFTsInAssetsQuery(nfts && nfts.length ? nfts.map((elem) => ({
    address: elem.contract_address,
    network: elem.blockchain,
  })) : undefined, {
    skip: !(nfts && nfts.length),
  });

  const {
    currentData: tokensInAsset,
  } = userApi.useGetCoinInAssetsQuery(coins && coins.length ? coins.map((elem) => ({
    coin: elem.id,
  })) : undefined, {
    skip: !(coins && coins.length),
  });

  useEffect(() => {
    if (contractDeployer) {
      inputRef.current.value = contractDeployer;
      setWalletAddress(contractDeployer);
    }
    return () => setWalletAddress('');
  }, [contractDeployer]);

  // const {
  //   data: brands,
  //   isLoading: isBrandsLoading,
  //   isFetching: isBrandsFetching,
  // } = brandsApi.useSearchBrandByNameQuery(debouncedText, {
  //   skip: !debouncedText,
  // });

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const handleTextModal = (event) => {
    setDebouncedText(event.target.value);
  };

  const handleCheckbox = (event, numberOf) => {
    const newDisplayList = [...displayList];
    newDisplayList[numberOf] = event.target.checked;
    setDisplayList(newDisplayList);
  };

  const modalStyle = {
    opacity: 1,
    backgroundColor: 'rgba(0,0,0,0.5)',
  };

  const nftsData = useMemo(() => ({
    id: 1,
    check: displayList[1],
    name: 'NFT Collections',
    data: nfts ? nfts.map((nft) => (
      {
        img: nft.image_url,
        name: nft.opensea_slug_contract_count > 1 ? nft.contract_name || nft.name : nft.name,
        transcript: nft.opensea_slug_contract_count > 1 ? nft.name : '',
        symbol: nft.symbol,
        holders: 'Holders',
        boldHolders: nft.holder_count !== null ? convertNumber(nft.holder_count) : '?',
        nameId: { id: nft.contract_address },
        value: 'Floor price',
        floor: 'Market cap',
        boldFloor: nft.market_cap !== null ? `${convertToReadableFormat(nft.market_cap)} ETH` : '-',
        boldValue: nft.floor_price !== null
          ? `${ethFormat(nft.floor_price)} ETH ${nft.floor_price_usd !== null
            ? `($${ethFormat(nft.floor_price_usd)})`
            : ''}`
          : '-',
        category: 'NFT Collection',
        type: 'nft',
        platforms: [nft.blockchain],
        verified: nft.is_verified_on_opensea,
        onDashboard: nftInAsset && nftInAsset.length
          ? nftInAsset.filter((elem) => elem.address === nft.contract_address && elem.network === nft.blockchain)
          : null,
      }
    )) : null,
  }), [displayList, nfts, nftInAsset]);

  const tokenData = useMemo(() => ({
    id: 2,
    check: displayList[2],
    name: 'Tokens',
    data: coins ? coins.map((coin) => (
      {
        id: coin.id,
        img: coin.image,
        nameId: { id: coin.id },
        name: coin.name,
        symbol: coin.symbol,
        value: 'Current Price',
        floor: 'Market cap',
        boldValue: coin.current_price ? `$${ethFormat(coin.current_price)}` : '?',
        boldFloor: coin.market_cap ? `$${convertToReadableFormat(coin.market_cap)}` : '?',
        category: 'Token',
        type: 'token',
        platforms: coin?.platforms ? Object.keys(coin.platforms) : [],
        onDashboard: tokensInAsset && tokensInAsset.length
          ? tokensInAsset.filter((elem) => elem.coin === coin.id)
          : null,
      }
    )) : null,
  }), [coins, displayList, tokensInAsset]);

  const addressesData = useMemo(() => ({
    id: 3,
    check: displayList[3],
    name: 'Addresses',
    data: wallets ? wallets.map((wallet) => (
      {
        id: wallet.address,
        nameId: { id: wallet.ALID || wallet.address },
        name: wallet.ens_name,
        category: 'Wallet',
        type: 'wallet',
      }
    )) : [],
  }), [displayList, wallets]);

  const contractsDeployedResultNFT = useMemo(() => ({
    id: 5,
    check: displayList[1],
    name: 'NFT Collections',
    data: nftDeployers && nftDeployers?.length ? nftDeployers.map((nft) => (
      {
        img: nft.image_url,
        name: nft.opensea_slug_contract_count > 1 ? nft.contract_name || nft.name : nft.name,
        transcript: nft.opensea_slug_contract_count > 1 ? nft.name : '',
        symbol: nft.symbol,
        holders: 'Holders',
        boldHolders: nft.holder_count !== null ? convertNumber(nft.holder_count) : '?',
        nameId: { id: nft.contract_address },
        value: 'Floor price',
        floor: 'Market cap',
        boldFloor: nft.market_cap !== null ? `${convertToReadableFormat(nft.market_cap)} ETH` : '-',
        boldValue: nft.floor_price !== null
          ? `${ethFormat(nft.floor_price)} ETH ${nft.floor_price_usd !== null
            ? `($${ethFormat(nft.floor_price_usd)})`
            : ''}`
          : '-',
        category: 'NFT Collection',
        type: 'nft',
        platforms: [nft.blockchain],
        verified: nft.is_verified_on_opensea,
      }
    )) : null,
  }), [displayList, nftDeployers]);

  const contractsDeployedResultToken = useMemo(() => ({
    id: 6,
    check: displayList[2],
    name: 'Tokens',
    data: coinDeployers && coinDeployers?.length ? coinDeployers.map((coin) => (
      {
        id: coin?.coin_id,
        img: coin.image_url,
        nameId: { id: coin?.coin_id },
        name: coin.name,
        symbol: coin.symbol,
        value: 'Current Price',
        floor: 'Market cap',
        boldValue: coin.current_price ? `$${ethFormat(coin.current_price)}` : '?',
        boldFloor: coin.usd_market_cap ? `$${convertToReadableFormat(coin.usd_market_cap)}` : '?',
        category: 'Token',
        type: 'token',
        platforms: [coin?.blockchain],
      }
    )) : null,
  }), [coinDeployers, displayList]);

  const fetchContractsDeployedRender = useMemo(() => {
    if ((nftDeployers && !nftDeployers?.length && coinDeployers && !coinDeployers?.length)) {
      return <NoResults />;
    }
    if ((nftDeployers && nftDeployers?.length) || (coinDeployers && coinDeployers?.length)) {
      const contractDeployedResult = [contractsDeployedResultNFT, contractsDeployedResultToken];
      const contractDeployedCheckingData = contractDeployedResult
        .filter((contractDeployedData) => !!contractDeployedData.check)
        .map((elem) => elem.data).flat();
      if (!displayList.some((checkbox) => checkbox)) {
        return (
          contractDeployedResult.map((contractDeployedData) => (
            <div className={styles.search_list_wrapper} key={uniqueId('deployedDataFromSearch')}>
              <List
                searchItems={contractDeployedData}
                setShowModal={setShowModal}
                setDisplayList={setDisplayList}
                displayList={displayList}
              />
            </div>
          ))
        );
      }
      return (
        <SearchList
          filterDataList={contractDeployedCheckingData.filter((item) => !!item)}
          setShowModal={setShowModal}
        />
      );
    }
    return <LoadingList />;
  }, [
    coinDeployers,
    contractsDeployedResultNFT,
    contractsDeployedResultToken,
    displayList,
    nftDeployers,
    setShowModal,
  ]);

  const fetchRender = useMemo(() => {
    const resultFromSearch = [nftsData, tokenData, addressesData];
    if (coins && nfts && wallets && !coins.length && !nfts.length && !wallets.length && !walletAddress) {
      return <NoResults />;
    }
    if (coins || nfts || wallets) {
      if (displayList.some((checkbox) => checkbox)) {
        const checkingData = resultFromSearch.filter((assetsFromSearch) => assetsFromSearch.check)
          .map((elem) => elem.data).flat();
        if (checkingData.includes(null) && !checkingData.filter((item) => !!item).length) {
          return <LoadingList />;
        }
        if (checkingData.filter((item) => !!item).length) {
          return (
            <SearchList
              filterDataList={checkingData.filter((item) => !!item)}
              setShowModal={setShowModal}
            />
          );
        }
        if (!checkingData.includes(null) && !checkingData.length) {
          return <NoResults />;
        }
      }
      if ((coins?.length || nfts?.length || wallets?.length) && !displayList.some((checkbox) => checkbox)) {
        return (
          resultFromSearch.map((assetData) => (
            <div className={styles.search_list_wrapper} key={uniqueId('assetDataFromSearch')}>
              <List
                searchItems={assetData}
                setShowModal={setShowModal}
                setDisplayList={setDisplayList}
                displayList={displayList}
              />
            </div>
          ))
        );
      }
    }
    return <LoadingList />;
  }, [addressesData, coins, tokenData, displayList, nfts, nftsData, setShowModal, walletAddress, wallets]);

  return (
    <div
      className="modal modal-dialog-centered modal-phones search-modal"
      id="exampleModal"
      style={modalStyle}
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      onMouseDown={() => {
        setShowModal(false);
        dispatch(getIsSearchModalOpen(false));
      }}
    >
      <div
        role="presentation"
        onMouseDown={(e) => {
          e.stopPropagation();
        }}
        className="modal-dialog modal-xl modal-phone w-100"
      >
        <div className="modal-content">
          <div className="modal-header d-flex">
            <div className="search-wrapper-modal d-flex justify-content-around align-items-center">
              <img src={Search} alt="search" height="20" width="20" />
              <input
                onChange={(e) => {
                  handleTextModal(e);
                  // TODO: uncomment if need
                  // if (isValidContractAddress(e.target.value)) {
                  //   setWalletAddress(e.target.value);
                  //   setDebouncedText('');
                  // } else {
                  //   setWalletAddress('');
                  //   handleTextModal(e);
                  // }
                }}
                onPaste={(e) => { preventPaste(e); }}
                onKeyPress={(e) => { preventSearch(e); }}
                ref={inputRef}
                placeholder="Brands, NFT collections, Tokens, contract address"
                className="search-input-modal text-black"
              />
              {(debouncedText || walletAddress) && (
                <img
                  role="presentation"
                  src={Delete}
                  alt="search"
                  height="20"
                  width="20"
                  className="cursor-pointer"
                  onClick={() => {
                    setDebouncedText('');
                    setWalletAddress('');
                    inputRef.current.value = '';
                    inputRef.current.focus();
                  }}
                />
              )}
            </div>
            <ModalCloseButton setShowModal={setShowModal} />
          </div>
          <div className="modal-body modal-body-wrapper">
            <p className="body-text">I am looking for...</p>
            <div className="form-check checkbox-container align-items-center">
              {/* <div>
                <label
                  className="form-check-label checkbox-text"
                  htmlFor="brandsCheckbox"
                >
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={displayList[0]}
                    id="brandsCheckbox"
                    onChange={(e) => handleCheckbox(e, 0)}
                  />
                  Brands
                </label>
              </div> */}
              <div>
                <label
                  className="form-check-label checkbox-text"
                  htmlFor="NFTCheckbox"
                >
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={displayList[1]}
                    id="NFTCheckbox"
                    onChange={(e) => handleCheckbox(e, 1)}
                  />
                  NFT Collections
                </label>
              </div>
              <div>
                <label
                  className="form-check-label checkbox-text"
                  htmlFor="tokensCheckbox"
                >
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={displayList[2]}
                    id="tokensCheckbox"
                    onChange={(e) => handleCheckbox(e, 2)}
                  />
                  Tokens
                </label>
              </div>
              <div>
                <label
                  className="form-check-label checkbox-text"
                  htmlFor="walletsCheckbox"
                >
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={displayList[3]}
                    id="walletsCheckbox"
                    onChange={(e) => handleCheckbox(e, 3)}
                  />
                  Addresses
                </label>
              </div>
              <div className="ms-auto grid-button">
                <button
                  type="button"
                  className="apply-button"
                  onClick={() => {
                    if (displayList.some((checkbox) => checkbox)) {
                      setDisplayList([false, false, false, false]);
                    } else {
                      setDisplayList([false, true, true, true]);
                    }
                  }}
                >
                  {displayList.some((checkbox) => checkbox)
                    ? 'Remove all'
                    : 'Apply all'}
                </button>
              </div>
            </div>
          </div>
          {walletAddress
            ? (
              <div className={styles.wallet_address}>
                <div className={styles.head}>
                  Address
                </div>
                <div
                  role="presentation"
                  className={`${styles.body} d-flex gap-2 align-items-center cursor-pointer`}
                  onClick={() => {
                    dispatch(setSelectedAsset({}));
                    navigate(`profile/${walletAddress}`);
                    setShowModal(false);
                    dispatch(getIsSearchModalOpen(false));
                  }}
                >
                  <Wallet />
                  <div
                    data-for={`preview_${walletAddress}`}
                    data-tip="show"
                  >
                    {walletAddress}
                  </div>
                </div>
                {walletAddress
                  ? (
                    <ItemPreview
                      id={`preview_${walletAddress}`}
                      data={{
                        id: walletAddress,
                        name: walletAddress,
                        type: 'wallet',
                      }}
                    />
                  )
                  : null}
              </div>
            )
            : null}
          {Boolean(debouncedText) && !walletAddress
            && (
              <div className={`modal-footer modal-footer-wrapper ${styles.scroll}`}>
                {fetchRender}
              </div>
            )}
          {!debouncedText && !!contractDeployer && (
            <div className={`modal-footer modal-footer-wrapper ${styles.scroll}`}>
              {fetchContractsDeployedRender}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchModal;
