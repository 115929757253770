import React, { useState, useEffect, useMemo } from 'react';
// import { useParams } from 'react-router-dom';
import Table from '../../../../../components/base/Table';
import { counter } from '../../../../../tools/Counter';
import styles from './Responses.module.scss';

const ResponsesTable = () => {
//   const { id } = useParams();
  const [page, setPage] = useState(1);
  const [desc, setDesc] = useState(true);
  const [orderBy, setOrder] = useState('number_of_users_last_30d');
  const [limit, setLimit] = useState(25);

  const header = [
    {
      field: 'pageNumber',
      title: '#',
      width: '50px',
    },
    {
      field: 'email',
      title: 'Email',
      width: '250px',
    },
    {
      field: 'wallet_address',
      title: 'Wallet address',
    },
    {
      field: 'twitter_id',
      title: 'Twitter ID',
    },
    {
      field: 'discord_id',
      title: 'Discord ID',
    },
    {
      field: 'help',
      title: 'How can I help?',
    },
    {
      field: 'posted_at',
      title: 'Posted on',
    },
  ];

  //   const {
  //     data: dappData,
  //     isLoading: isDappDataLoading,
  //     isFetching: isDappDataFetching,
  //   } = audiencesApi.useGetSegmentDappUsageDataQuery({
  //     id,
  //     limit,
  //     offset: (page - 1) * limit,
  //     order: desc ? 'desc' : 'asc',
  //     orderBy,
  //   });

  const [tableData, setTableData] = useState([]);

  // test resposne data
  const responseData = useMemo(() => ({
    count: 5, // Let's assume there are 5 items in total
    result: [
      {
        email: 'john.doe@example.com',
        wallet_address: '0x12345abcde...',
        twitter_id: '@john_doe',
        discord_id: 'JohnDoe#1234',
        help: 'I can help with code reviews.',
        posted_at: '2023-09-15 14:45:00',
      },
      {
        email: 'jane.smith@example.com',
        wallet_address: '0x67890fghij...',
        twitter_id: '@jane_smith',
        discord_id: 'JaneSmith#5678',
        help: 'I can help with documentation.',
        posted_at: '2023-09-16 09:30:00',
      },
      {
        email: 'alice.bob@example.com',
        wallet_address: '0xabcdef1234...',
        twitter_id: '@alice_bob',
        discord_id: 'AliceBob#9012',
        help: 'I can help with testing.',
        posted_at: '2023-09-16 12:00:00',
      },
      {
        email: 'charlie.david@example.com',
        wallet_address: '0x56789lmnop...',
        twitter_id: '@charlie_david',
        discord_id: 'CharlieDavid#3456',
        help: 'I can help with design.',
        posted_at: '2023-09-17 15:45:00',
      },
      {
        email: 'erin.frank@example.com',
        wallet_address: '0xqrstuvwxyz...',
        twitter_id: '@erin_frank',
        discord_id: 'ErinFrank#7890',
        help: 'I can help with translations.',
        posted_at: '2023-09-18 10:15:00',
      },
    ],
  }), []);

  //   const responseData = null;
  const isLoading = false;
  // const isFetching = false;

  useEffect(() => {
    if (responseData?.result?.length) {
      const temp = responseData.result.map((elem, index) => ({
        pageNumber: (
          <span className={styles.number}>{(page - 1) * limit + index + 1}</span>
        ),
        email: (
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex gap-2 align-items-center">
              <span className={`${styles.content_name} text-truncate`}>
                {elem.email}
              </span>
            </div>
          </div>
        ),
        wallet_address: (
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex gap-2 align-items-center">
              <span className={`${styles.content_name} text-truncate`}>
                {elem.wallet_address}
              </span>
            </div>
          </div>
        ),
        twitter_id: (
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex gap-2 align-items-center">
              <span className={`${styles.content_name} text-truncate`}>
                {elem.twitter_id}
              </span>
            </div>
          </div>
        ),
        discord_id: (
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex gap-2 align-items-center">
              <span className={`${styles.content_name} text-truncate`}>
                {elem.discord_id}
              </span>
            </div>
          </div>
        ),
        help: (
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex gap-2 align-items-center">
              <span className={`${styles.content_name} text-truncate`}>
                {elem.help}
              </span>
            </div>
          </div>
        ),
        posted_at: (
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex gap-2 align-items-center">
              <span className={`${styles.content_name} text-truncate`}>
                {elem.posted_at}
              </span>
            </div>
          </div>
        ),
      }));
      setTableData(temp);
    }
  }, [responseData, limit, page]);

  return (
    <div className={`${styles.wrapper} d-flex w-100 flex-column align-items-center`}>
      <div className="d-flex flex-column align-self-start">
        <div className={styles.title}>Response List</div>
        <div className="d-flex w-100 justify-content-between align-items-center mb-4">
          <div className={styles.content_info}>
            {counter(responseData?.count, page, limit)}
          </div>
        </div>
      </div>
      <div className="w-100">
        <Table
          total={responseData?.count || 0}
          data={tableData}
          desc={desc}
          setDesc={setDesc}
          ordering={orderBy}
          setOrdering={setOrder}
          page={page}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
          headers={header}
          isLoading={isLoading}
          min={25}
        />
      </div>
    </div>
  );
};

export default ResponsesTable;
