import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDroppable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';
import styles from './FormsBuilder.module.scss';
import {
  setActiveSettings,
  selectActiveSettings,
  clearActiveSettings,
} from '../../../../../store/reducers/forms';
import { ReactComponent as Delete } from '../../../../../assets/icons/forms/trash.svg';
import { ReactComponent as Edit } from '../../../../../assets/icons/forms/edit.svg';
import { ReactComponent as Drag } from '../../../../../assets/icons/forms/drag.svg';

import { renderers } from '../../Fields';

function getRenderer(type) {
  if (type === 'spacer') {
    return () => <div className="spacer">{' '}</div>;
  }

  return renderers[type] || (() => (
    <div>
      No renderer found for
      {' '}
      {type}
    </div>
  ));
}

export const FormBuilderOverlayField = (props) => {
  const { field, overlay, ...rest } = props;
  const { type } = field;

  const Component = getRenderer(type);

  let className = 'canvas-field';
  if (overlay) {
    className += ' overlay';
  }

  return (
    <div className={className}>
      <Component field={field} {...rest} />
    </div>
  );
};

const SortableField = (props) => {
  const dispatch = useDispatch();
  const [isHovered, setIsHovered] = useState(false);
  const activeSettings = useSelector(selectActiveSettings);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const {
    id, index, field, fieldData, onRemove,
  } = props;

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
    removable,
  } = useSortable({
    id,
    data: {
      index,
      id,
      field,
    },
    removable: true,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    isDragging,
    removable,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      className="d-flex"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className={`${styles.iconContainer} ${isHovered ? styles.visible : styles.hidden}`}>
        <div className={`${styles.delete} mr-2`}>
          <Delete onClick={() => {
            onRemove(id);
            if (activeSettings?.field_id === id) {
              dispatch(clearActiveSettings());
            }
          }}
          />
        </div>

        <div className={`${styles.edit} mr-2`}>
          <Edit onClick={() => { dispatch(setActiveSettings({ type: field.type, field_id: id })); }} />
        </div>

        <div className={`${styles.drag} mr-2`} {...listeners} {...attributes}>
          <Drag />
        </div>
      </div>

      <div
        role="presentation"
        className={`${styles.parent} flex-grow-1`}
        {...listeners}
        {...attributes}
        onClick={() => {
          dispatch(clearActiveSettings());
          dispatch(setActiveSettings({ type: field.type, field_id: id }));
        }}
      >
        <FormBuilderOverlayField field={field} fieldData={fieldData} />
      </div>
    </div>
  );
};

const FormBuilder = (props) => {
  const { fields, onRemove } = props;

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useDroppable({
    id: 'canvas_droppable',
    data: {
      parent: null,
      isContainer: true,
    },
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      className={`${fields.length === 0 ? 'canvas-empty' : 'canvas'}`}
      style={style}
      {...attributes}
      {...listeners}
    >
      {fields.length === 0 ? (
        <div className="empty-message">
          Drop your form elements by dragging from the left bar
        </div>
      ) : (
        <div className="canvas-fields">
          {fields.map((field, index) => (
            <SortableField
              key={field.id}
              id={field.id}
              index={index}
              field={field}
              fieldData={field.data}
              onRemove={onRemove}
            />
          ))}
        </div>
      )}
    </div>
  );
};
export default FormBuilder;
