export const legendData = [
  {
    color: 'blue',
  },
  {
    color: 'orange',
  },
  {
    color: 'green',
  },
  {
    color: 'purple',
  },
  {
    color: 'red',
  },
  {
    color: 'grey',
    title: 'Others',
  },
];

export const walletOptions = [
  { value: 'any_wallet_status', label: 'Any wallet status' },
  { value: 'no_wallet_detected', label: 'No wallet detected' },
  { value: 'wallet_detected', label: 'Wallet detected' },
  { value: 'wallet_connected', label: 'Wallet connected' },
];
const regionNamesInEnglish = new Intl.DisplayNames(['en'], { type: 'region' });
const countryList = ['AE', 'AM', 'AR', 'AT', 'AU', 'BA', 'BD', 'BE', 'BG', 'BR', 'BY', 'CA', 'CH', 'CI', 'CM',
  'CN', 'CO', 'CR', 'CY', 'CZ', 'DE', 'DK', 'EG', 'ES', 'ET', 'FI', 'FR', 'GA', 'GB', 'GE', 'GR', 'HK', 'HR', 'ID',
  'IE', 'IL', 'IN', 'IS', 'IT', 'JP', 'KR', 'KZ', 'LB', 'LK', 'LT', 'LU', 'MA', 'MC', 'MD', 'MK', 'MO', 'MT', 'MX',
  'MY', 'NG', 'NI', 'NL', 'NO', 'NP', 'PA', 'PE', 'PH', 'PK', 'PL', 'PR', 'PS', 'PT', 'PY', 'RO', 'RS', 'RU', 'SA',
  'SE', 'SG', 'SI', 'TH', 'TR', 'TW', 'UA', 'US', 'UZ', 'VN', 'ZA'];
export const countriesOptions = countryList.map((elem) => ({
  value: elem,
  label: regionNamesInEnglish.of(elem),
})).sort((a, b) => (a.label > b.label
  ? 1 : ((b.label > a.label) ? -1 : 0)));

export const loggedInOptions = [
  { value: '', label: 'Any state' },
  { value: 'logged_in', label: 'Logged in' },
  { value: 'not_logged_in', label: 'Not logged in' },
];
