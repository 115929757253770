export const selectAssetName = (type, item) => {
  if (type === 'brand') {
    return item.title;
  }
  if (type === 'nft') {
    if (item.opensea_slug_contract_count > 1) {
      return item.contract_name || item.name;
    }
    return item.name;
  }
  return item.name;
};
