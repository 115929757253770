import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import Chart from 'react-apexcharts';
import { selectSales } from '../../../../store/reducers/coin';
import TitleComponent from '../../../../components/base/TitleComponent';
import { nftChart } from '../../../../tools/OptionsChart';
import NoData from '../../../../components/base/NoData';
import Loader from '../../../../components/base/Loader';
import styles from './Sales.module.scss';

const Sales = () => {
  const [day, setDay] = useState('30');

  const getSalesData = useSelector(selectSales);

  const salesContent = useMemo(() => {
    if (!getSalesData) {
      return (
        <div className="p-5">
          <Loader />
        </div>
      );
    }
    if (getSalesData) {
      const data = getSalesData?.length ? nftChart('ETH', getSalesData, day, 1, true) : null;
      return (
        getSalesData?.length
          ? (
            <>
              <div className="d-flex justify-content-end align-items-center data-chart">
                <div
                  className="btn-toolbar mb-3"
                  role="toolbar"
                  aria-label="Toolbar with button groups"
                >
                  <div
                    className="btn-group me-4 group"
                    role="group"
                    aria-label="Second group"
                  >
                    <button
                      type="button"
                      className={`btn btn-outline-secondary shadow-none ${
                        day === '7' ? 'active' : ''
                      }`}
                      onClick={() => setDay('7')}
                    >
                      7d
                    </button>
                    <button
                      type="button"
                      className={`btn btn-outline-secondary shadow-none ${
                        day === '30' ? 'active' : ''
                      }`}
                      onClick={() => setDay('30')}
                    >
                      30d
                    </button>
                    <button
                      type="button"
                      className={`btn btn-outline-secondary shadow-none ${
                        day === '180' ? 'active' : ''
                      }`}
                      onClick={() => setDay('180')}
                    >
                      180d
                    </button>
                  </div>
                </div>
              </div>
              <div id="chart">
                <Chart
                  options={data.options}
                  series={data.series}
                  type="line"
                  height={400}
                />
              </div>
              <div className="d-flex gap-2">
                <div className="d-flex align-items-center gap-2 asset-wrap">
                  <div className="dot" />
                  <span>Sales</span>
                </div>
                <div className="d-flex align-items-center gap-2 asset-wrap">
                  <div className="dot-1" />
                  <span>Volume</span>
                </div>
              </div>
            </>
          )
          : (
            <div className="d-flex justify-content-center align-items-center h-75">
              <NoData />
            </div>
          )
      );
    }
  }, [getSalesData, day]);

  return (
    <div className={`${styles.sales_wrapper} d-flex flex-column`}>
      <div className="w-50">
        <TitleComponent>Sales</TitleComponent>
      </div>
      {salesContent}
    </div>
  );
};

export default Sales;
