import React from 'react';

import HeadlineText from './Headline';
import Paragraph from './Paragraph';
import Image from './Image';
import ButtonField from './Button';
import ConnectWallet from './ConnectWallet';
import CountryField from './Country';
import EmailField from './Email';
// import ImageField from './Image';
import LanguageField from './Language';
import NameField from './Name';
import PhoneField from './Phone';
import DiscordField from './Discord';
import TwitterField from './Twitter';
import TelegramField from './Telegram';
import InstagramField from './Instagram';

// Each Rendered can be a component or a function that returns a component
export const renderers = {
  headline: (props) => <HeadlineText {...props} />,
  paragraph: (props) => <Paragraph {...props} />,
  image: (props) => <Image {...props} />,
  button: (props) => <ButtonField {...props} />,
  connectWallet: (props) => <ConnectWallet {...props} />,
  country: (props) => <CountryField {...props} />,
  email: (props) => <EmailField {...props} />,
  // image: (props) => <ImageField {...props} />,
  language: (props) => <LanguageField {...props} />,
  name: (props) => <NameField {...props} />,
  phone: (props) => <PhoneField {...props} />,
  discord: (props) => <DiscordField {...props} />,
  twitter: (props) => <TwitterField {...props} />,
  telegram: (props) => <TelegramField {...props} />,
  instagram: (props) => <InstagramField {...props} />,
};
