import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import uniqueId from '../../../utils/uniqueId';
import { ReactComponent as Arrow } from '../../../assets/icons/long_arrow.svg';
import styles from './CSVTable.module.scss';
import navStyles from '../CSVUpload.module.scss';
import AttributesSearch from '../AttributesSearch';
import Mapping from '../Mapping';
import ConfirmModal from '../../../components/ui/modals/ConfirmModal';
import UniqueModal from '../UniqueModal/inedx';
import { showErrorMessage } from '../../../components/base/Notifications';
import SaveCustomListModal from '../SaveCustomListModal';
import { clearCSVUpload, selectSelectedCustomList } from '../../../store/reducers/csvUpload';
import { audiencesApi } from '../../../api/audiences';
import RoundSpinner from '../../../components/base/RoundSpinner';
import { useCallbackPrompt } from '../../../components/hooks/app/useCallbackPrompt';
import { parseDataToFormData } from '../../../utils/csvUpload';

const columns = [
  {
    title: 'Columns in file',
    width: '400px',
  },
  {
    title: 'Absolute Labs Attributes',
    width: '400px',
  },
  {
    title: 'Data type',
    width: '200px',
  },
  {
    title: 'mapping',
    width: '200px',
  }];
const dataTypes = [
  { value: 'Integer', label: 'Integer' },
  { value: 'Float', label: 'Float' },
  { value: 'Boolean', label: 'Boolean' },
  { value: 'String', label: 'String' },
  { value: 'Date', label: 'Date' },
  { value: 'Timestamp', label: 'Timestamp' },
];

const removeDashAndUnderScore = (string) => string.split('-').join(' ').split('_').join(' ');

const altTwitterNames = ['twitter', 'twitter id', 'twitter name', 'twitter handle', 'twitter username'];
const altDiscordNames = ['discord', 'discord name', 'discord handle', 'discord username', 'discord id'];
const altEmailNames = ['e mail', 'e mail address', 'e mail handle'];
const altPhoneNames = ['phone', 'phone number'];
const altAddressNames = ['wallet address', 'wallet', 'address'];
const altTelegramNames = ['telegram', 'telegram id', 'telegram handle'];
const altIDNames = ['third party user id', 'id', 'user id', 'third party id'];
const altFirstNamesNames = ['first name'];
const altLastNamesNames = ['last name', 'surname'];
const altCountryNames = ['country'];
const altLanguageNames = ['language'];
const altEmailOptOutNames = ['email opt out'];
const altTwitterOptOutNames = ['twitter opt out'];
const altSMSOptOutNames = ['sms opt out'];
const altTelegramOptOutNames = ['telegram opt out'];
const altDiscordOptOutNames = ['discord opt out'];
const altWalletOptOutNames = ['wallet opt out'];
const altAppsNames = ['appsflyer id'];

const handleAttribute = (field) => {
  const string = removeDashAndUnderScore(field.toLowerCase());
  if (altDiscordNames.includes(string)) {
    return 'Discord handle';
  }
  if (altTwitterNames.includes(string)) {
    return 'Twitter handle';
  }
  if (altEmailNames.includes(string)) {
    return 'Email';
  }
  if (altPhoneNames.includes(string)) {
    return 'Phone';
  }
  if (altAddressNames.includes(string)) {
    return 'Wallet address';
  }
  if (altTelegramNames.includes(string)) {
    return 'Telegram ID';
  }
  if (altIDNames.includes(string)) {
    return 'Third party User ID';
  }
  if (altFirstNamesNames.includes(string)) {
    return 'First Name';
  }
  if (altLastNamesNames.includes(string)) {
    return 'Last Name';
  }
  if (altCountryNames.includes(string)) {
    return 'Country';
  }
  if (altLanguageNames.includes(string)) {
    return 'Language';
  }
  if (altEmailOptOutNames.includes(string)) {
    return 'Email opt-out';
  }
  if (altTwitterOptOutNames.includes(string)) {
    return 'Twitter opt-out';
  }
  if (altSMSOptOutNames.includes(string)) {
    return 'SMS opt-out';
  }
  if (altTelegramOptOutNames.includes(string)) {
    return 'Telegram opt-out';
  }
  if (altDiscordOptOutNames.includes(string)) {
    return 'Discord opt-out';
  }
  if (altWalletOptOutNames.includes(string)) {
    return 'Wallet opt-out';
  }
  if (altAppsNames.includes(string)) {
    return 'AppsFlyer ID';
  }
  return '';
};

const pivotList = ['Twitter handle', 'Discord handle', 'Email', 'Telegram ID', 'Third party User ID',
  'First Name', 'Last Name', 'Wallet address', 'AppsFlyer ID', 'Phone'];

const CSVTable = ({
  fields, attributesFields, isLoading, file,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [showConfirmMappingModal, setShowConfirmMappingModal] = useState(false);
  const [showUniqueModal, setShowUniqueModal] = useState(false);
  const [showSaveCustomListModal, setShowSaveCustomListModal] = useState(false);
  const [showSummaryModal, setShowSummaryModal] = useState(false);
  const [uniqueField, setUniqueField] = useState('');
  const [customListName, setCustomListName] = useState('');
  const [customListId, setCustomListId] = useState('');
  const [showDialog, setShowDialog] = useState(true);
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(showDialog);
  const [dataTypesByAttribute, setDataTypesByAttribute] = useState([]);
  const [mappingErrors, setMappingErrors] = useState([]);

  const selectedCustomList = useSelector(selectSelectedCustomList);

  const [postCSV, postCSVResult] = audiencesApi.usePostCSVDataMutation();

  const handleUniqueFields = () => data
    .filter((elem) => elem.attribute && elem.type && elem.import && elem.mapping)
    .map((elem) => elem.attribute.toLowerCase())
    .filter((item) => pivotList
      .map((elem) => elem.toLowerCase()).includes(item));

  const onConfirmAll = () => {
    const mappingErrorsArray = data.filter((elem) => !elem.attribute || !elem.type);
    if (mappingErrorsArray.length) {
      setMappingErrors(data.filter((elem) => !elem.attribute || !elem.type));
      showErrorMessage('Some fields have errors and could not be confirmed');
    }
    setData(data.map((elem) => (elem.attribute && elem.type ? ({
      ...elem,
      mapping: true,
    })
      : elem)));
  };

  const duplicatedAttributes = (array) => array.map((obj) => {
    let count = 0;
    array.forEach((elem) => {
      if (elem.attribute === obj.attribute) {
        count += 1;
      }
    });
    return count;
  });

  const sendData = () => {
    const attributesArray = [...Object.values(attributesFields)[0], ...Object.values(attributesFields)[1]];
    const selectAttribute = (attribute) => {
      const index = attributesArray.map((obj) => obj[Object.keys(obj)[0]]).indexOf(attribute);
      if (index > -1) {
        return Object.keys(attributesArray[index])[0];
      }
      return attribute.toLowerCase().split(' ').join('_');
    };
    const reservedAttributes = Object.keys(dataTypesByAttribute);
    const formattedData = data
      .filter((elem) => elem.attribute && elem.type && elem.import && elem.mapping)
      .map((elem) => {
        if (reservedAttributes.includes(elem.attribute)) {
          delete elem.type;
        }
        return ({
          ...elem,
          attribute: selectAttribute(elem.attribute),
        });
      });
    const pivotColumn = attributesArray.filter((obj) => Object.values(obj)[0].toLowerCase() === uniqueField);
    postCSV(parseDataToFormData(file, formattedData, customListId, Object.keys(pivotColumn[0])[0]));
  };

  const onSubmit = () => {
    if (!handleUniqueFields().length) {
      showErrorMessage('You cannot import data because at least one contact field must be selected from the list');
      return;
    }
    if (handleUniqueFields().length === 1) {
      setUniqueField(handleUniqueFields()[0]);
      setShowSaveCustomListModal(true);
      return;
    }
    setShowUniqueModal(true);
  };

  const onError = () => {
    const canImportAndMapping = data.filter((elem) => elem.import && elem.mapping);
    return !canImportAndMapping.length;
  };

  const countRowsToImport = () => {
    const result = data.filter((elem) => elem.mapping);
    const rowsWithErrors = duplicatedAttributes(result);
    const removedErrorsFromResult = [];
    result.forEach((obj, index) => {
      if (rowsWithErrors[index] === 1) {
        removedErrorsFromResult.push(obj);
      }
    });
    return removedErrorsFromResult.length;
  };

  const summaryTitle = () => {
    const difference = data.length - countRowsToImport();
    if (countRowsToImport() === 0) {
      return `Nothing from your file will be imported due to errors or lack
      of mapping confirmation. Please confirm mappings and try again`;
    }
    const ignoredColumnsText = difference !== 0
      ? `${difference} columns are going to be ignored either
      due to errors or because you have not confirmed their mapping.`
      : '';
    return `You are about to import ${countRowsToImport()} columns. ${ignoredColumnsText} Do you wish to proceed?`;
  };

  useEffect(() => {
    if (attributesFields) {
      const obj = {};
      [...Object.values(attributesFields)[0], ...Object.values(attributesFields)[1]]
        .forEach((elem) => {
          const attribute = Object.values(elem)[0];
          obj[attribute] = elem.field_type.charAt(0) + elem.field_type.slice(1).toLowerCase();
        });
      setDataTypesByAttribute(obj);
    }
  }, [attributesFields]);

  useEffect(() => {
    if (Object.keys(selectedCustomList).length) {
      setCustomListName(selectedCustomList.name);
      setCustomListId(selectedCustomList.id);
    }
  }, [selectedCustomList]);

  useEffect(() => {
    if (fields.length && Object.keys(dataTypesByAttribute).length) {
      const arr = fields.map((field) => ({
        column: field,
        attribute: handleAttribute(field),
        type: dataTypesByAttribute[handleAttribute(field)],
        import: true,
        mapping: false,
      }));
      setData(arr);
    }
  }, [fields, dataTypesByAttribute]);

  useEffect(() => {
    if (postCSVResult.isSuccess) {
      setShowDialog(false);
      dispatch(clearCSVUpload());
    }
    if (postCSVResult.isError) {
      showErrorMessage('Something went wrong');
      postCSVResult.reset();
    }
  }, [postCSVResult]);

  useEffect(() => {
    if (postCSVResult.isSuccess && !showDialog) {
      postCSVResult.reset();
      navigate('/audiences');
    }
  }, [postCSVResult, showDialog]);

  if (isLoading) {
    return <RoundSpinner />;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        Import from CSV
      </div>
      {showConfirmMappingModal
        && (
          <ConfirmModal
            title="Confirm all"
            description="Are you sure you want to confirm the settings of all rows?"
            buttonName="Confirm all"
            onSubmit={() => {
              onConfirmAll();
              setShowConfirmMappingModal(false);
            }}
            onCancel={() => setShowConfirmMappingModal(false)}
          />
        )}
      {showSummaryModal
        && (
          <ConfirmModal
            title="Summary"
            description={summaryTitle()}
            buttonName={countRowsToImport() === 0 ? 'Ok' : 'Proceed'}
            onSubmit={() => {
              if (countRowsToImport() === 0) {
                setShowSummaryModal(false);
              } else {
                onSubmit();
                setShowSummaryModal(false);
              }
            }}
            onCancel={countRowsToImport() === 0 ? '' : () => setShowSummaryModal(false)}
          />
        )}
      {showSaveCustomListModal
        && (
          <SaveCustomListModal
            customListName={customListName}
            setCustomListName={setCustomListName}
            setCustomListId={setCustomListId}
            customListId={customListId}
            onSubmit={() => {
              sendData();
              setShowSaveCustomListModal(false);
            }}
            onCancel={() => setShowSaveCustomListModal(false)}
          />
        )}
      {showUniqueModal
        && (
          <UniqueModal
            uniqueField={uniqueField}
            setUniqueField={setUniqueField}
            uniqueFields={handleUniqueFields()}
            onSubmit={() => {
              setShowUniqueModal(false);
              setShowSaveCustomListModal(true);
            }}
            onCancel={() => setShowUniqueModal(false)}
          />
        )}
      {showPrompt && (
        <ConfirmModal
          onSubmit={() => {
            confirmNavigation();
            dispatch(clearCSVUpload());
          }}
          onCancel={cancelNavigation}
          buttonName="Proceed"
          title="Unsaved values"
          description="All unsaved values will not be saved! Are you sure?"
        />
      )}
      <table className={`${styles.table} w-100 position-relative`}>
        <thead className={`${styles.header} position-sticky top-0`}>
          <tr className="border-bottom-0">
            {columns.map((column) => (
              <th
                className={styles.t_head}
                key={column.title}
                style={{ width: column.width }}
              >
                {column.title === 'mapping'
                  ? (
                    <div
                      className="d-flex justify-content-between align-items-center"
                      style={{ width: '220px' }}
                    >
                      <span>{column.title}</span>
                      <div
                        className={styles.mapping}
                        role="presentation"
                        onClick={() => setShowConfirmMappingModal(true)}
                      >
                        Confirm and import all
                      </div>
                    </div>
                  )
                  : <span>{column.title}</span>}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="border-top-0">
          {data.map((field, index) => (
            <tr
              key={uniqueId('bodyRow')}
              className={`${styles.row} ${!data[index].import ? styles.disabled : ''}`}
            >
              <td>
                <div className="d-flex align-items-center gap-5 w-100">
                  <div className={`${styles.column} text-truncate`}>
                    {field.column}
                  </div>
                  <Arrow />
                </div>
              </td>
              <td
                className="position-relative"
              >
                <AttributesSearch
                  data={data}
                  setData={setData}
                  index={index}
                  isLoading={isLoading}
                  attributesFields={attributesFields}
                  disabled={!data[index].import}
                  dataTypesByAttribute={dataTypesByAttribute}
                  mappingErrors={mappingErrors}
                  setMappingErrors={setMappingErrors}
                />
              </td>
              <td className="position-relative">
                <Select
                  classNames={{
                    control: (props) => (props.isDisabled
                      ? styles.disabled_select
                      : mappingErrors.filter((elem) => elem.column === data[index].column && !elem.type)
                        .length === 1
                        ? styles.error
                        : styles.select),
                    menuList: () => styles.scroll,
                    option: () => styles.menu_option,
                    placeholder: () => styles.select_placeholder,
                  }}
                  placeholder="Choose type"
                  maxMenuHeight={194}
                  options={dataTypes}
                  onChange={(val) => {
                    const tempArr = [...data];
                    tempArr[index].type = val.value;
                    tempArr[index].mapping = false;
                    setData(tempArr);
                    if (mappingErrors.filter((row) => row.column === data[index].column).length === 1) {
                      setMappingErrors(mappingErrors.filter((row) => row.column !== data[index].column));
                    }
                  }}
                  isDisabled={!data[index].import || data[index].attribute in dataTypesByAttribute}
                  value={{ value: data[index].type, label: data[index].type }}
                />
                {mappingErrors.filter((elem) => elem.column === data[index].column && !elem.type).length === 1
                  ? <span className={styles.multiple}>Mapping is missing data type</span>
                  : null}
              </td>
              <td>
                <Mapping data={data} setData={setData} index={index} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div
        className={`
          ${navStyles.nav}
          justify-content-end
          d-flex
          align-items-center`}
      >
        <div className="d-flex align-items-center gap-3">
          <button
            type="button"
            className="outline-button"
            onClick={() => {
              dispatch(clearCSVUpload());
              navigate('/audiences');
            }}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="regular-button"
            onClick={() => setShowSummaryModal(true)}
            disabled={onError()}
          >
            Import
          </button>
        </div>
      </div>
      {postCSVResult.isLoading || postCSVResult.isFetching
        ? <RoundSpinner />
        : null}
    </div>
  );
};

export default CSVTable;
