import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { convertToReadableFormat } from '../../../../tools/NumberConverterTool';
import { getWalletAge } from '../../../../utils/segments';
import { counter } from '../../../../tools/Counter';
import Loader from '../../../../components/base/Loader';
import DownloadTooltip from '../../../../components/ui/DownloadTooltip';
import DownloadModal from '../../../../components/ui/modals/DownloadModal';
import uniqueId from '../../../../utils/uniqueId';
import styles from './List.module.scss';
import tableStyles from '../../../../components/base/Table/Table.module.scss';
import Table from '../../../../components/base/Table';
import { setSelectedAsset } from '../../../../store/reducers/app';
import ExportCSV from '../../../../components/svgIcons/ExportCSV';
import ItemPreview from '../../../../components/ui/ItemPreview';
import ConfirmModal from '../../../../components/ui/modals/ConfirmModal';
import { webSDKApi } from '../../../../api/webSDK';
import { selectWebSDKFilterParam } from '../../../../store/reducers/webSDK';

const List = () => {
  const { id } = useParams();
  const filterParams = useSelector(selectWebSDKFilterParam);
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(Number(searchParams.get('page')) || 1);
  const [isOpen, setIsOpen] = useState(false);
  const [orderBy, setOrderBy] = useState(searchParams.get('orderBy') || 'last_seen');
  const [order, setOrder] = useState(searchParams.get('order') !== 'false');
  const [isDownloaded, setIsDownloaded] = useState(false);
  const [limit, setLimit] = useState(searchParams.get('perPage') || 25);
  const [tableData, setTableData] = useState([]);
  const toolTipId = uniqueId('segment-download');
  const warningsList = [
    'No more than 50,000 profiles will be downloaded',
  ];
  const {
    data: profilesData,
    isLoading: isProfilesDataLoading,
    isFetching: isWProfilesDataFetching,
  } = webSDKApi.useGetWebSDKIdentifiedProfilesListQuery(
    {
      id,
      limit: +limit,
      offset: (page - 1) * (+limit),
      orderBy,
      order: order ? 'desc' : 'asc',
      ...filterParams,
    },
  );
  const [getWebSDKIdentifiedProfilesCSV, result] = webSDKApi.useGetWebSDKIdentifiedProfilesCSVMutation();

  const header = [
    {
      field: 'user_id',
      title: 'User ID',
    },
    {
      field: 'address',
      title: 'Wallet',
    },
    {
      field: 'total_portfolio_value',
      title: 'Portfolio Value',
    },
    {
      field: 'wallet_type',
      title: 'Wallet Type',
    },
    {
      field: 'sessions',
      title: 'Sessions',
    },
    {
      field: 'last_seen',
      title: 'Last Seen',
    },

  ];

  useEffect(() => {
    const profileLink = (elem, field) => (
      <Link className="disable-link-styles" to={`/profile/${elem.address}`}>
        <div
          role="presentation"
          onClick={() => {
            setSearchParams({
              ...Object.fromEntries([...searchParams]),
              scroll: window.scrollY,
            });
            dispatch(setSelectedAsset({}));
          }}
          className={`${styles.name} text-truncate`}
        >
          {elem[field]}
        </div>
      </Link>
    );
    const profilePreview = (elem, fieldId) => (
      <ItemPreview
        id={fieldId}
        data={{
          id: elem.address,
          name: elem.address_name,
          type: 'wallet',
        }}
      />
    );
    if (profilesData?.length) {
      const temp = profilesData.map((elem) => ({
        user_id: (
          <>
            <span
              className={elem.address ? `d-block ${tableStyles.underline_on_hover}` : null}
              data-for={`Name_${elem.user_id}`}
              data-tip
            >
              {elem.address ? profileLink(elem, 'user_id') : elem.user_id }
            </span>
            {elem.address ? profilePreview(elem, `Name_${elem.user_id}`) : null}
          </>
        ),
        address: (
          <>
            <span
              className={`d-block ${tableStyles.underline_on_hover}`}
              data-for={`Name_${elem.address_name}`}
              data-tip
            >
              {elem.address ? profileLink(elem, 'address_name') : null}
            </span>
            {elem.address ? profilePreview(elem, `Name_${elem.address_name}`) : null}
          </>
        ),
        total_portfolio_value: `${elem.total_portfolio_value < 0 || !elem.address ? ''
          : (elem.total_portfolio_value !== null
            ? `${convertToReadableFormat(elem.total_portfolio_value)} USD` : '0 USD')}`,
        wallet_type: elem.wallet_type,
        sessions: elem.sessions,
        last_seen: (new Date() - Date.parse(elem.last_seen) <= 600000 ? (
          <div
            className={`${styles.online}`}
          >
            Online
          </div>
        ) : (
          <div className={`${styles.walletAge}`}>
            {getWalletAge(elem.last_seen)}
          </div>
        )
        ),
      }));
      setTableData(temp);
    }
  }, [profilesData, dispatch, searchParams, setSearchParams]);

  useEffect(() => {
    if (result.isLoading) {
      setIsOpen(true);
    }
    if (result.isSuccess && result.data) {
      const handleOpen = () => {
        setTimeout(() => {
          window.location.replace(result.data[0].link);
        }, 1000);
      };
      handleOpen();
    }
    if (result.error?.status === 403) {
      setIsOpen(false);
    }
    return () => clearTimeout();
  }, [result]);

  return (
    <div className="row p-4">
      <div className={`title-h4-bold ${styles.adaptive}`}>
        Profile List
      </div>
      {(isProfilesDataLoading || isWProfilesDataFetching) && !profilesData
        ? (
          <div className="p-5">
            <Loader />
          </div>
        )
        : (
          <>
            <div className={`${styles.showing} d-flex justify-content-between align-items-center mb-3`}>
              {counter(
                profilesData ? profilesData.length !== 0 ? profilesData[0].total_count : 0 : 0,
                page,
                limit,
              )}
              <button
                type="button"
                className="btn d-flex align-items-center justify-content-center shadow-none m-0 p-0"
                data-for={toolTipId}
                data-tip
                onMouseEnter={() => setIsDownloaded(true)}
                onMouseLeave={() => setIsDownloaded(false)}
                onClick={() => getWebSDKIdentifiedProfilesCSV({
                  id,
                  limit: 50000,
                  orderBy,
                  order: order ? 'desc' : 'asc',
                  ...filterParams,
                })}
              >
                <ExportCSV hovered={isDownloaded} />
              </button>
              <DownloadTooltip
                id={toolTipId}
                header="Export profile list into CSV"
                warningsList={warningsList}
              />
            </div>
            <div className="w-100">
              <Table
                total={profilesData ? profilesData.length !== 0 ? profilesData[0].total_count : 0 : 0}
                data={tableData}
                desc={order}
                setDesc={setOrder}
                ordering={orderBy}
                setOrdering={setOrderBy}
                page={page}
                setPage={setPage}
                limit={limit}
                setLimit={setLimit}
                headers={header}
                isLoading={isProfilesDataLoading || isWProfilesDataFetching}
                min={25}
              />
            </div>
            {isOpen && result?.error?.status !== 403 ? <DownloadModal result={result} setIsOpen={setIsOpen} /> : null}
            {result?.error?.status === 403 ? (
              <ConfirmModal
                title="We are sorry..."
                description="The feature you’re trying to use has restricted access.
                 Please reach out to your Customer Success Manager."
                buttonName="Okay"
                onSubmit={() => result.reset()}
              />
            ) : null}
          </>
        )}
    </div>
  );
};

export default List;
