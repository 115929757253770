import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styles from './FormSettings.module.scss';
import { selectActiveSettings, clearActiveSettings } from '../../../../store/reducers/forms';
import OverallSettings from './OverallSettings';

import ConnectWalletButtonSettings from './FieldSettings/ConnectWalletButton';
import HeadlineSettings from './FieldSettings/Headline';
import ButtonSettings from './FieldSettings/Button';
import CountrySettings from './FieldSettings/Country';
import ParagraphSettings from './FieldSettings/Paragraph';
import EmailSettings from './FieldSettings/Email';
import ImageSettings from './FieldSettings/Image';
import LanguageSettings from './FieldSettings/Language';
import NameSettings from './FieldSettings/Name';
import PhoneSettings from './FieldSettings/Phone';
import DiscordSettings from './FieldSettings/Discord';
import TwitterSettings from './FieldSettings/Twitter';
import TelegramSettings from './FieldSettings/Telegram';
import InstagramSettings from './FieldSettings/Instagram';

const FormSettings = () => {
  const activeSettings = useSelector(selectActiveSettings);
  const dispatch = useDispatch();
  const settingsList = ({
    overall: <OverallSettings activeSettings={activeSettings} />,
    connectWallet: <ConnectWalletButtonSettings activeSettings={activeSettings} />,
    headline: <HeadlineSettings activeSettings={activeSettings} />,
    paragraph: <ParagraphSettings activeSettings={activeSettings} />,
    button: <ButtonSettings activeSettings={activeSettings} />,
    country: <CountrySettings activeSettings={activeSettings} />,
    email: <EmailSettings activeSettings={activeSettings} />,
    image: <ImageSettings activeSettings={activeSettings} />,
    language: <LanguageSettings activeSettings={activeSettings} />,
    name: <NameSettings activeSettings={activeSettings} />,
    phone: <PhoneSettings activeSettings={activeSettings} />,
    discord: <DiscordSettings activeSettings={activeSettings} />,
    twitter: <TwitterSettings activeSettings={activeSettings} />,
    telegram: <TelegramSettings activeSettings={activeSettings} />,
    instagram: <InstagramSettings activeSettings={activeSettings} />,
  });

  useEffect(() => {
    const handleClickOutside = (event) => {
      const wrapperEl = document.querySelector(`.${styles.wrapper}`);
      if (wrapperEl && !wrapperEl.contains(event.target)) {
        dispatch(clearActiveSettings());
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dispatch]);

  return (
    <div
      className={`
      ${styles.wrapper}
      ${activeSettings ? '' : styles.closed}`}
    >
      {activeSettings
        ? settingsList[activeSettings.type]
        : null}
    </div>
  );
};

export default FormSettings;
