export const submitTokenAirdrop = (
  obj,
  submitData,
  selectedItem,
  assetType,
  selectedContract,
  selectedBlockchain,
  team,
) => {
  const newObj = { ...obj, ...submitData, asset_id: selectedItem?.id };
  if (assetType) {
    newObj.assetType = assetType;
  }
  if (selectedItem.image_url) {
    newObj.assetImg = selectedItem.image_url;
  }
  if (selectedItem.name) {
    newObj.assetName = selectedItem.name;
  }
  if (assetType) {
    newObj.asset_type = assetType;
  }
  if (selectedContract) {
    newObj.token_address = selectedContract;
  }
  if (selectedBlockchain) {
    newObj.blockchain = selectedBlockchain;
  }
  newObj.token_amount = +newObj.token_amount;
  newObj.token_decimals = newObj.token_decimals || 18;
  newObj.team_name = team;
  return newObj;
};

export const submitNftAirdrop = (
  obj,
  submitData,
  assetType,
  selectedItem,
  team,
) => {
  const newObj = { ...obj, ...submitData, asset_id: selectedItem?.id };
  if (selectedItem.image_url) {
    newObj.assetImg = selectedItem.image_url;
  }
  if (selectedItem.name) {
    newObj.assetName = selectedItem.name;
  }
  if (assetType) {
    newObj.asset_type = assetType;
  }
  newObj.team_name = team;
  return newObj;
};

export const submitCustomAirdrop = (obj, submitData, customAirdropPayload, team) => {
  const newObj = { ...obj, ...submitData };
  if (customAirdropPayload.selectedBlockchain) {
    newObj.blockchain = customAirdropPayload.selectedBlockchain;
  }

  if (customAirdropPayload.assetType) {
    newObj.asset_type = customAirdropPayload.assetType;
  }

  newObj.data = {};

  if (customAirdropPayload.contractAddress) {
    newObj.data.contract_address = customAirdropPayload.contractAddress;
  }

  if (customAirdropPayload.selectedFunction) {
    newObj.data.selected_function = customAirdropPayload.selectedFunction;
  }

  if (customAirdropPayload.etherscanStatus) {
    newObj.data.etherscan_status = customAirdropPayload.etherscanStatus;
  }

  if (customAirdropPayload.abi) {
    newObj.data.abi = customAirdropPayload.abi;
  }

  if (customAirdropPayload.contractFunctions) {
    newObj.data.contract_functions = customAirdropPayload.contractFunctions;
  }

  if (customAirdropPayload.selectedParameters) {
    newObj.data.selected_parameters = customAirdropPayload.selectedParameters;
  }

  newObj.team_name = team;

  return newObj;
};
