import { useParams } from 'react-router-dom';
import React from 'react';
import { useSelector } from 'react-redux';
import { webSDKApi } from '../../../api/webSDK';
import Reach from '../../../components/ui/Reach';
import styles from '../UsersTab/UsersTab.module.scss';
import { selectWebSDKFilterParam } from '../../../store/reducers/webSDK';
import List from './List';

const IdentifiedProfilesTab = () => {
  const { id } = useParams();
  const filterParams = useSelector(selectWebSDKFilterParam);
  const {
    data: reachData,
    isLoading: isReachDataLoading,
    isFetching: isReachDataFetching,
  } = webSDKApi.useGetWebSDKIdentifiedProfilesReachQuery({
    id,
    ...filterParams,
  });
  return (
    <div className={styles.wrapper}>
      { isReachDataLoading || isReachDataFetching || reachData?.total_count !== 0
        ? (
          <Reach
            data={reachData}
            isLoading={isReachDataFetching || isReachDataLoading}
            totalCount={reachData?.total_count}
          />
        ) : null}
      <List />
    </div>

  );
};

export default IdentifiedProfilesTab;
