import React from 'react';

import styles from '../inputs/Input.module.scss';

const DappLabel = ({ val }) => (
  <div className="d-flex cursor-pointer justify-content-between align-items-center">
    <span className={`${styles.label} me-2`}>{val?.label}</span>
    <div
      className={`${styles.pills}
      ${val?.category.includes('DeFi') ? styles.blue : styles.yellow}
      px-3 d-flex justify-content-end text-truncate align-items-center mx-1`}
    >
      {val?.category}
    </div>
  </div>
);

export default DappLabel;
