import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Tooltip from '../../../../components/ui/Tooltip';
import {
  createdCustomList,
  createdSegment,
  getCreatedCustomList,
  getCreatedSegmentId,
} from '../../../../store/reducers/segment';
import tableStyles from '../../../../components/base/Table/Table.module.scss';
import { useWindowSize } from '../../../../components/hooks/app';
import { setSelectedAsset } from '../../../../store/reducers/app';
import styles from '../SegmentsTable.module.scss';

const LinkElem = ({
  elem, search, createLink, filteredIndexes, i,
}) => {
  const dispatch = useDispatch();
  const [canRedirect, setCanRedirect] = useState(true);

  const { width } = useWindowSize();

  const newSegmentId = useSelector(getCreatedSegmentId);
  const newCustomListId = useSelector(getCreatedCustomList);

  useEffect(() => {
    if ((elem.item_type === 'segment' && newSegmentId === elem?.id)
      || (newCustomListId === elem?.id && elem.item_type === 'custom list')) {
      setCanRedirect(false);
      setTimeout(() => {
        if (elem.item_type === 'segment') {
          dispatch(createdSegment(null));
        } else {
          dispatch(createdCustomList(null));
        }
      }, 10000);
    } else {
      setCanRedirect(true);
    }
    if (!elem.ready) {
      setCanRedirect(false);
    }
    return () => clearTimeout();
  }, [dispatch, newCustomListId, newSegmentId]);

  return (
    <>
      <Link
        onClick={() => dispatch(setSelectedAsset({ name: elem.name }))}
        to={canRedirect ? createLink(elem) : '#'}
        data-for={`Name_${elem.id}`}
        data-tip
        className={`d-block highlight ${styles.link} ${tableStyles.underline_on_hover}`}
      >
        <div
          role="presentation"
          className={`${typeof elem?.ready === 'undefined'
          || elem?.ready ? styles.name : styles.name_unready} text-truncate ${canRedirect ? '' : styles.cursor_wait}`}
        >
          {
            search.length === 0
              ? (
                elem.name
              ) : (
                elem.name.split('').map((e, j) => (
                  j < filteredIndexes[i] + search.length && j >= filteredIndexes[i]
                    ? <span className={`${styles.highlightText}`}>{e}</span>
                    : <span className={`${styles.lightText}`}>{e}</span>
                ))
              )
          }
        </div>
      </Link>
      {(elem.name.length > width / 50 || elem.is_restricted) && (
        <Tooltip
          id={`Name_${elem.id}`}
          info={
            elem.is_restricted
              ? 'This segment cannot be viewed because the dataset, that it used, was deleted or edited'
              : (!elem?.ready
                ? 'This segment is still in processing'
                : elem.name
              )
          }
        />
      )}
    </>
  );
};

export default LinkElem;
