import React, { useState } from 'react';
import styles from '../List/List.module.scss';
import walletImg from '../../../../../assets/icons/wallet_logo.svg';
import DefaultIcon from '../../../DefaultIcon';

const LogoDisplay = ({
  logo, asset, type, size, position = 'position-absolute',
}) => {
  const [imgError, setImgError] = useState(false);

  if ((!logo || imgError) && (asset.toLowerCase() === 'addresses' || asset.toLowerCase() === 'wallet')) {
    return <img src={walletImg} alt="" width={size || '28px'} height={size || '28px'} />;
  }
  return logo && !imgError
    ? (
      <img
        className={`${position} ${styles.icon}`}
        width={size || ''}
        height={size || ''}
        src={logo}
        alt="logo"
        onError={() => setImgError(true)}
      />
    )
    : (asset === 'Brands'
      ? <i style={{ fontSize: size || '24px', color: 'black' }} className="bi bi-coin" />
      : <DefaultIcon size={size || '24px'} type={type} />
    );
};

export default LogoDisplay;
