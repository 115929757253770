import React, { useEffect } from 'react';
import { brandsApi } from '../../../api/brands';
import TradersAndInvestComponent from '../../../components/ui/TradersAndInvestComponent';
import styles from './InvestorProfile.module.scss';

const InvestorProfile = ({ id, slug, holders }) => {
  const {
    data,
    refetch,
    isLoading,
    isFetching,
  } = brandsApi.useGetBrandTradersQuery({ slug, days: '1' });

  const {
    currentData: brandActiveAddresses,
    refetch: brandActiveAddressesRefetch,
    isLoading: isBrandActiveAddressesLoading,
    isFetching: isBrandActiveAddressesFetching,
  } = brandsApi.useGetBrandActiveAddressesQuery(id);
  useEffect(() => {
    brandActiveAddressesRefetch();
    refetch();
  }, [refetch, brandActiveAddressesRefetch]);

  return (
    <div className={`${styles.container} d-grid gap-4`}>
      <TradersAndInvestComponent
        holders={holders}
        data={data}
        isLoading={isLoading}
        isFetching={isFetching}
        title="Avg Risk Profile"
        info="Risk profile of investors in assets of this brand, measured by the type of investments made."
      />
      {/* // TODO: uncomment after BE fixes */}
      {/* <TradersAndInvestComponent */}
      {/*   holders={holders} */}
      {/*   data={brandActiveAddresses} */}
      {/*   isLoading={isBrandActiveAddressesLoading} */}
      {/*   isFetching={isBrandActiveAddressesFetching} */}
      {/*   title={`Invest. in $${slug.toUpperCase()}`} */}
      {/*   info="Typical duration of investment in assets of this brand" */}
      {/* /> */}
    </div>
  );
};

export default InvestorProfile;
