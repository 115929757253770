import React, { useState } from 'react';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { coinApi } from '../../../api/coin';
import styles from './Holders.module.scss';
import HoldersList from '../../../components/ui/HoldersList';

const Holders = ({ address }) => {
  const { network } = useParams();
  const [searchParams] = useSearchParams();
  const [page, setPage] = useState(Number(searchParams.get('page')) || 1);
  const [desc, setDesc] = useState(searchParams.get('order') !== 'false');
  const [order, setOrder] = useState(searchParams.get('orderBy') || 'token_qty');
  const [limit, setLimit] = useState(searchParams.get('perPage') || 25);

  const [getNFTHolderListCSV, result] = coinApi.useGetNftHolderListCSVMutation();

  const {
    data,
    isLoading: isHoldersListLoading,
    isFetching: isHoldersListFetching,
  } = coinApi.useGetNFTsHoldersListQuery({
    address,
    des: desc ? '1' : '0',
    limit: +limit,
    order,
    page,
    blockchain: network || 'ethereum',
  });

  return (
    <div className={styles.wrapper}>
      <HoldersList
        data={data}
        isLoading={isHoldersListLoading || isHoldersListFetching}
        limit={limit}
        setLimit={setLimit}
        setDesc={setDesc}
        setPage={setPage}
        setOrder={setOrder}
        page={page}
        desc={desc}
        order={order}
        result={result}
        downloadCSV={getNFTHolderListCSV}
        nft
      />
    </div>
  );
};

export default Holders;
