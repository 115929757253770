import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { preventPaste, preventSearch } from '../../../../utils/search/preventSearch';

import styles from './MultipleValuesInput.module.scss';

function useOutsideAlerter(ref, func, func2) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        func(false);
        func2();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, func, func2]);
}

const MultipleValuesInput = ({
  option,
  pushSelectedOptionToArray,
  deleteConditionFromTree,
  id,
  conditionName,
  edit,
}) => {
  const dispatch = useDispatch();
  const {
    value, name, label, type,
  } = option;
  const wrapperRef = useRef(null);
  const [t, setT] = useState(false);
  const [input, setInput] = useState('');
  const [arr, setArr] = useState([]);

  const pushValueToArray = () => {
    if (input.length > 0) {
      const newTest = [...arr];
      newTest.push({ value: input, label: input });
      setArr(newTest);
      setInput('');
      pushSelectedOptionToArray(newTest, name, type);
    }
  };

  useEffect(() => {
    if (value && Array.isArray(value)) {
      setArr(value);
    }
  }, [value]);

  useOutsideAlerter(wrapperRef, setT, pushValueToArray);

  return (
    <div
      key={label}
      className={`w-100 ${styles.container} ${t && styles.active}`}
      onSelectCapture={() => setT(true)}
      ref={wrapperRef}
    >
      <span id="react-select-9-live-region" />
      <span
        aria-live="polite"
        aria-atomic="false"
        aria-relevant="additions text"
      />
      <div className={`${styles.control} ${t && styles.active}`}>
        <div className={styles.wrapper}>
          {arr.map((val, index) => (
            <div className={styles.multiValue}>
              <div>
                <div className={`${styles.multiValueTextWrapper} d-flex align-items-center`}>
                  <span className="text-truncate ms-1">{val.value}</span>
                </div>
              </div>
              <div
                role="presentation"
                onClick={() => {
                  const newTest = [...arr];
                  newTest.splice(index, 1);
                  setArr(newTest);
                  if (newTest.length > 0) {
                    pushSelectedOptionToArray(newTest, name, type);
                  } else {
                    dispatch(deleteConditionFromTree({ id, name: conditionName }));
                  }
                }}
                aria-label={`Remove ${val.value}`}
              >
                <div
                  className={`
                      ${styles.multiValueIconWrapper} d-flex ms-auto
                       flex-column align-items-center justify-content-center h-100
                       `}
                >
                  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M11.8346 1.34102L10.6596 0.166016L6.0013 4.82435L1.34297
                          0.166016L0.167969 1.34102L4.8263 5.99935L0.167969 10.6577L1.34297
                           11.8327L6.0013 7.17435L10.6596 11.8327L11.8346 10.6577L7.1763
                            5.99935L11.8346 1.34102Z"
                      fill="#90A0B7"
                    />
                  </svg>
                </div>
              </div>
            </div>
          ))}
          <div className={styles.inputWrapper} data-value="">
            <input
              className={styles.input}
              autoCapitalize="none"
              autoComplete="off"
              autoCorrect="off"
              placeholder={!arr.length && 'Input your values...'}
              id="react-select-9-input"
              spellCheck="false"
              tabIndex="0"
              type="text"
              disabled={!edit}
              aria-autocomplete="list"
              aria-expanded="false"
              aria-haspopup="true"
              onPaste={(e) => { preventPaste(e); }}
              onKeyPress={(e) => { preventSearch(e); }}
              onChange={(e) => {
                setInput(e.target.value);
              }}
              onKeyUp={(e) => {
                if (e.key === 'Enter' && input) {
                  pushValueToArray();
                }
              }}
              value={input}
              // eslint-disable-next-line jsx-a11y/role-has-required-aria-props
              role="combobox"
            />
          </div>
        </div>
        {!!arr.length && (
          <div className={styles.indicatorWrapper}>
            <span className={styles.separator} />
            <div
              className={styles.indicatorContainer}
              aria-hidden="true"
              onClick={() => {
                setArr([]);
                dispatch(deleteConditionFromTree({ id, name: conditionName }));
              }}
            >
              <svg
                height="20"
                width="20"
                viewBox="0 0 20 20"
                aria-hidden="true"
                focusable="false"
                className={styles.svg}
              >
                <path
                  d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651
                        3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229
                        0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469
                        1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229
                        0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"
                />
              </svg>
            </div>
          </div>
        )}

      </div>
    </div>
  );
};

export default MultipleValuesInput;
