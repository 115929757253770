import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { localeStorageCache } from '../../utils/localeStorage';

const BASE_API = 'api/web-sdk';

export const webSDKApi = createApi({
  reducerPath: 'webSDKApi',
  baseQuery: fetchBaseQuery({
    prepareHeaders: (headers) => {
      const authKey = localeStorageCache.getAuth0Key();
      const authData = localeStorageCache.get(authKey);

      if (authData && authData.body) {
        const { id_token: idToken } = authData.body;
        if (idToken) {
          headers.set('Authorization', `Bearer ${idToken}`);
        }
      }
      return headers;
    },
    baseUrl: import.meta.env.VITE_APP_SERVER_DOMAIN,
  }),
  tagTypes: ['WebSDK'],
  endpoints: (build) => ({
    getWebSDK: build.query({
      query: () => ({
        url: `${BASE_API}/`,
        method: 'GET',
      }),
      keepUnusedDataFor: 10,
      providesTags: () => ['WebSDK'],
    }),
    getWebSDKDataForTopBlocks: build.query({
      query: (id) => ({
        url: `${BASE_API}/${id}/top-block/`,
        method: 'GET',
      }),
      keepUnusedDataFor: 1,
      providesTags: () => ['WebSDK'],
    }),
    getWebSDKStats: build.query({
      query: (id) => ({
        url: `${BASE_API}/${id}/stats/`,
        method: 'GET',
      }),
      keepUnusedDataFor: 1,
      providesTags: () => ['WebSDK'],
    }),
    getWebSDKStatsFiltered: build.query({
      query: (arg) => ({
        url: `${BASE_API}/${arg.id}/stats-filtered/?${arg.day ? `period=${arg.day}` : ''}${arg?.isLoggedIn
          ? `&logged_in=${arg?.isLoggedIn}` : ''}${arg?.walletFilter
          ? `&wallet_filter=${arg.walletFilter}` : ''}${arg.countries.length !== 0
          ? `&selected_countries=${arg.countries}` : ''}`,
        method: 'get',
      }),
      keepUnusedDataFor: 1,
      providesTags: () => ['WebSDK'],
    }),
    getWebSDKForUsersTab: build.query({
      query: (arg) => ({
        url: `${BASE_API}/${arg.id}/visitor-tab/?${arg.day ? `period=${arg.day}` : ''}${arg?.isLoggedIn
          ? `&logged_in=${arg?.isLoggedIn}` : ''}${arg?.walletFilter
          ? `&wallet_filter=${arg.walletFilter}` : ''}${arg.countries.length !== 0
          ? `&selected_countries=${arg.countries}` : ''}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 1,
      providesTags: () => ['WebSDK'],
    }),
    getWebSDKDataForChart: build.query({
      query: (id) => ({
        url: `${BASE_API}/${id}/daily-graph/`,
        method: 'GET',
      }),
      keepUnusedDataFor: 1,
      providesTags: () => ['WebSDK'],
    }),
    getWebSDKById: build.query({
      query: (id) => ({
        url: `${BASE_API}/${id}/`,
        method: 'GET',
      }),
      providesTags: () => ['WebSDK'],
    }),
    createWebSDK: build.mutation({
      query: (formData) => ({
        url: `${BASE_API}/`,
        method: 'POST',
        body: formData,
      }),
      providesTags: () => ['WebSDK'],
    }),
    deleteWebSDK: build.mutation({
      query: (id) => ({
        url: `${BASE_API}/${id}/`,
        method: 'DELETE',
      }),
      providesTags: () => ['WebSDK'],
    }),
    getWebSDKForWalletTab: build.query({
      query: (arg) => ({
        url: `${BASE_API}/${arg.id}/wallet-tab/?${arg.day ? `period=${arg.day}` : ''}${arg?.isLoggedIn
          ? `&logged_in=${arg?.isLoggedIn}` : ''}${arg?.walletFilter
          ? `&wallet_filter=${arg.walletFilter}` : ''}${arg.countries.length !== 0
          ? `&selected_countries=${arg.countries}` : ''}`,
        method: 'GET',
      }),
      providesTags: () => ['WebSDK'],
    }),
    getWebSDKWalletTabInvestmentPortfolio: build.query({
      query: (arg) => ({
        url: `${BASE_API}/${arg.id}/wallet-tab-investment-portfolio/?desc=${arg.desc}${arg.day
          ? `&period=${arg.day}` : ''}${arg?.isLoggedIn
          ? `&logged_in=${arg?.isLoggedIn}` : ''}${arg?.walletFilter
          ? `&wallet_filter=${arg.walletFilter}` : ''}${arg.countries.length !== 0
          ? `&selected_countries=${arg.countries}` : ''}${arg.limit
          ? `&limit=${arg.limit}` : ''}${arg.asset_type
          ? `&asset_type=${arg.asset_type}` : ''}${arg.page
          ? `&page=${arg.page}` : ''}${arg.chain
          ? `&blockchain=${arg.chain}` : ''}${arg.order
          ? `&order=${arg.order}` : ''}`,
        method: 'GET',
      }),
      providesTags: () => ['WebSDK'],
    }),
    getWebSDKIdentifiedProfilesReach: build.query({
      query: (arg) => ({
        url: `${BASE_API}/${arg.id}/identified-profiles-reach/?${arg.day ? `period=${arg.day}` : ''}${arg?.isLoggedIn
          ? `&logged_in=${arg?.isLoggedIn}` : ''}${arg?.walletFilter
          ? `&wallet_filter=${arg.walletFilter}` : ''}${arg.countries.length !== 0
          ? `&selected_countries=${arg.countries}` : ''}`,
        method: 'GET',
      }),
      providesTags: () => ['WebSDK'],
    }),

    getWebSDKIdentifiedProfilesList: build.query({
      query: (arg) => ({
        url: `${BASE_API}/${arg.id}/identified-profiles-list/?limit=${arg.limit}${arg.orderBy
          ? `&order_by=${arg.orderBy}` : ''}${arg.order
          ? `&order=${arg.order}` : ''}${arg.offset
          ? `&offset=${arg.offset}` : ''}${arg.day
          ? `&period=${arg.day}` : ''}${arg?.isLoggedIn
          ? `&logged_in=${arg?.isLoggedIn}` : ''}${arg?.walletFilter
          ? `&wallet_filter=${arg.walletFilter}` : ''}${arg.countries.length !== 0
          ? `&selected_countries=${arg.countries}` : ''}`,
        method: 'GET',
      }),
      providesTags: () => ['WebSDK'],
    }),

    getWebSDKIdentifiedProfilesCSV: build.mutation({
      query: (arg) => ({
        url: `${BASE_API}/${arg.id}/identified-profiles-csv/?limit=${arg.limit}${arg.orderBy
          ? `&order_by=${arg.orderBy}` : ''}${arg.order
          ? `&order=${arg.order}` : ''}${arg.offset
          ? `&offset=${arg.offset}` : ''}${arg.day
          ? `&period=${arg.day}` : ''}${arg?.isLoggedIn
          ? `&logged_in=${arg?.isLoggedIn}` : ''}${arg?.walletFilter
          ? `&wallet_filter=${arg.walletFilter}` : ''}${arg.countries.length !== 0
          ? `&selected_countries=${arg.countries}` : ''}`,
        method: 'GET',
      }),
    }),
  }),
});
