import React from 'react';
import { useSelector } from 'react-redux';
import styles from './NodeSettings.module.scss';
import { selectActiveSettings } from '../../../../store/reducers/flows';
import SegmentsNodeSettings from './SegmentsNodeSettings';
import WebHookNodeSettings from './WebHookNodeSettings';
import AirDropSettings from './AirDropSettings';
import WaitUntilNodeSettings from './WaitUntilNodeSettings';
import WaitForNodeSettings from './WaitForNodeSettings';
import WebHookESNodeSettings from './WebHookESNodeSettings';
import SMSNodeSettings from './SMSNodeSettings';
import TransactionNodeSettings from './TransactionNodeSettings';
import ListNodeSettings from './ListNodeSettings';
import EmailNodeSettings from './EmailNodeSettngs';
import AddToListSettings from './AddToListSettings';
import ConditionNodeSettings from './ConditionNodeSettings';
import XMTPNodeSettings from './XMTPNodeSettings';

const NodeSettings = () => {
  const activeSettings = useSelector(selectActiveSettings);
  const settingsList = ({
    segmentsNode: <SegmentsNodeSettings activeSettings={activeSettings} />,
    webhookNode: <WebHookNodeSettings activeSettings={activeSettings} />,
    webhookESNode: <WebHookESNodeSettings activeSettings={activeSettings} />,
    airdropNode: <AirDropSettings activeSettings={activeSettings} />,
    waitUntilNode: <WaitUntilNodeSettings activeSettings={activeSettings} />,
    waitForNode: <WaitForNodeSettings activeSettings={activeSettings} />,
    smsNode: <SMSNodeSettings activeSettings={activeSettings} />,
    transactionNode: <TransactionNodeSettings activeSettings={activeSettings} />,
    listNode: <ListNodeSettings activeSettings={activeSettings} />,
    emailNode: <EmailNodeSettings activeSettings={activeSettings} />,
    addToListNode: <AddToListSettings activeSettings={activeSettings} />,
    conditionNode: <ConditionNodeSettings activeSettings={activeSettings} />,
    XMTPNode: <XMTPNodeSettings activeSettings={activeSettings} />,
  });
  return (
    <div
      className={`
      ${styles.wrapper}
      ${activeSettings ? '' : styles.closed}`}
    >
      {activeSettings
        ? settingsList[activeSettings.type]
        : null}
    </div>
  );
};

export default NodeSettings;
