import React, { useMemo, useState } from 'react';
import { flowsApi } from '../../api/flows';
import NoFlow from './NoFlow';
import Loader from '../../components/base/Loader';
import FlowsList from './FlowsList';

const Flows = () => {
  const [ordering, setOrdering] = useState('created_at');
  const [page, setPage] = useState(1);
  const [desc, setDesc] = useState(true);
  const [limit, setLimit] = useState(10);

  const {
    data: flows,
    isLoading,
    isFetching,
    refetch,
  } = flowsApi.useGetFlowsQuery(
    {
      limit, offset: (page - 1) * limit, ordering: `${desc ? '-' : ''}${ordering}`,
    },
  );

  return useMemo(() => {
    if ((isLoading || isFetching) && !flows) {
      return <Loader />;
    }

    if (!flows?.results?.length) {
      return <NoFlow />;
    }

    if (flows?.results?.length) {
      return (
        <FlowsList
          data={flows.results}
          count={flows.count}
          limit={limit}
          setLimit={setLimit}
          isLoading={isLoading || isFetching}
          refetch={refetch}
          setDesc={setDesc}
          desc={desc}
          ordering={ordering}
          setOrdering={setOrdering}
          page={page}
          setPage={setPage}
        />
      );
    }

    return null;
  }, [isLoading, isFetching, flows, limit, refetch, desc, ordering, page]);
};

export default Flows;
