import React, { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import BuyingPower from '../../../components/ui/BuyingPower';
import { coinApi } from '../../../api/coin';
import Investments from '../../../components/ui/Investments';
import TypicalPortfolio from '../../../components/ui/TypicalPortfolio';
import NotSupportedBlockchain from '../../../components/NotSupportedBlockchain';

const InvestPortfolio = ({ holders, assetsRefetch, isSupportedBlockchain }) => {
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const initialSearchParams = {
    ...Object.fromEntries([...searchParams]),
  };

  const [queryParams, setQueryParams] = useState({
    page: Number(initialSearchParams.page) || 1,
    desc: initialSearchParams.order !== 'false',
    type: initialSearchParams.sort,
    order: initialSearchParams.orderBy || 'number_holders',
    limit: initialSearchParams.perPage || 25,
  });

  const [checkedToken, setCheckedToken] = useState(queryParams.type !== 'nft');
  const [checkedNft, setCheckedNft] = useState(queryParams.type !== 'token');

  const {
    page, desc, type, order, limit,
  } = queryParams;

  const {
    data: buyingPower,
    isLoading: isBuyingPowerLoading,
    isFetching: isBuyingPowerFetching,
  } = coinApi.useGetTokenBuyingPowerQuery(pathname.split('/')[2]);

  const {
    data: investments,
    isLoading: isInvestmentsLoading,
    isFetching: isInvestmentsFetching,
  } = coinApi.useGetTokenInvestmentsQuery({
    id: pathname.split('/')[2],
    des: desc ? '1' : '0',
    asset_type: type || '',
    chain: 'ethereum',
    limit: +limit,
    order,
    page,
  });

  const {
    currentData: portfolioData,
    isLoading: isPortfolioLoading,
    isFetching: isPortfolioFetching,
  } = coinApi.useGetTypicalPortfolioQuery(pathname.split('/')[2]);

  useEffect(() => {
    let newType = '';

    if (checkedNft && checkedToken) {
      newType = '';
    } else if (checkedNft && !checkedToken) {
      newType = 'nft';
    } else if (!checkedNft && checkedToken) {
      newType = 'token';
    }

    setQueryParams((prevState) => ({
      ...prevState,
      type: newType,
    }));

    const newSearchParams = new URLSearchParams(searchParams.toString());
    newSearchParams.set('sort', newType);
    newSearchParams.set('page', page.toString());
    newSearchParams.set('order', desc ? 'true' : 'false');
    setSearchParams(newSearchParams);
  }, [checkedNft, checkedToken, page, desc]);

  return (
    isSupportedBlockchain
      ? (
        <div className="row m-3">
          <div className="col-12 col-lg-4 mb-3 mb-lg-0">
            <TypicalPortfolio
              data={portfolioData}
              isLoading={isPortfolioLoading}
              isFetching={isPortfolioFetching}
              info="Typical portfolio breakdown for relevant holders of this token"
            />
          </div>
          <div className="col-12 col-lg-4">
            <BuyingPower
              buyingPower={buyingPower}
              isLoading={isBuyingPowerLoading}
              isFetching={isBuyingPowerFetching}
              info="Total amount available to invest by the relevant holders of this token"
            />
          </div>
          <Investments
            investments={investments}
            isLoading={isInvestmentsLoading || isInvestmentsFetching}
            limit={limit}
            setLimit={(newLimit) => setQueryParams({ ...queryParams, limit: newLimit })}
            holders={holders}
            page={page}
            desc={desc}
            setPage={(newPage) => setQueryParams({ ...queryParams, page: newPage })}
            setDesc={(newDesc) => setQueryParams({ ...queryParams, desc: newDesc })}
            setCheckedToken={setCheckedToken}
            setCheckedNft={setCheckedNft}
            checkedToken={checkedToken}
            checkedNft={checkedNft}
            order={order}
            setOrder={(newOrder) => setQueryParams({ ...queryParams, order: newOrder })}
            assetsRefetch={assetsRefetch}
          />
        </div>
      )
      : <NotSupportedBlockchain />
  );
};

export default InvestPortfolio;
