import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { coinApi } from '../../../api/coin';
import ExchangesTable from '../../../components/ui/ExchangesTable';

const Exchanges = ({
  holders,
}) => {
  const { name, network } = useParams();
  const [page, setPage] = useState(1);
  const [desc, setDesc] = useState(true);
  const [orderBy, setOrder] = useState('number_of_users_last_30d');
  const [limit, setLimit] = useState(25);

  const {
    data: exchanges,
    isLoading: isExchnagesLoading,
    isFetching: isExchnagesFetching,
  } = coinApi.useGetNFTExchangeDataQuery({
    address: name,
    chain: network,
    limit,
    order: desc ? 'desc' : 'asc',
    orderBy,
    offset: (page - 1) * limit,
  });

  const header = [
    {
      field: '',
      title: '#',
      width: '32px',
    },
    {
      field: 'exchange_name',
      title: 'Name',
      width: '250px',
    },
    {
      field: 'total_number_of_users',
      title: 'Total users',
    },
    {
      field: 'number_of_users_last_7d',
      title: 'Users last 7d',
    },
    {
      field: 'number_of_users_last_30d',
      title: 'Users last 30d',
    },
    {
      field: 'number_of_users_last_90d',
      title: 'Users last 90d',
    },
  ];

  return (
    <div className="p-4">
      <ExchangesTable
        header={header}
        data={exchanges}
        totalUsers={holders}
        isLoading={isExchnagesLoading || isExchnagesFetching}
        setPage={setPage}
        setDesc={setDesc}
        setOrder={setOrder}
        setLimit={setLimit}
        page={page}
        limit={limit}
        desc={desc}
        orderBy={orderBy}
      />
    </div>
  );
};

export default Exchanges;
