import React, { useState } from 'react';
import { convertNumber, convertToReadableFormat } from '../../../../tools/NumberConverterTool';
import styles from './Asset.module.scss';
import { chainIconPicker } from '../../../../tools/ChainIconPicker';

const Asset = ({ item }) => {
  const [cardLogoError, setCardLogoError] = useState(false);

  return (
    <div className="asset-wrapper">
      <div
        className="asset-container d-flex flex-column justify-content-between additional-class"
      >
        <div className="asset-container-info gap-1 d-flex justify-content-between align-items-center">
          <div className="d-flex justify-content-between align-items-center gap-3">
            <div className="circle">
              {cardLogoError || (!item.logo && !item.metadata?.logo)
                ? <i style={{ fontSize: '30px', color: 'black' }} className="bi bi-images" />
                : (
                  <img
                    src={item.logo || item.metadata?.logo}
                    alt="logo"
                    width="60"
                    height="60"
                    onError={() => setCardLogoError(true)}
                  />
                )}
            </div>
            <span className={styles.title}>{item.name || item.metadata?.name}</span>
          </div>
        </div>
        <div className="asset-container-data d-flex justify-content-between align-items-center mt-3 gap-4">
          <div className="d-flex gap-2">
            <div className="d-flex flex-column justify-content-around info">
              <span className={styles.section}>Holders</span>
              <span className={styles.section}>{item.address ? 'Floor price' : 'Market cap'}</span>
            </div>
            <div className="d-flex flex-column justify-content-around price">
              <span className={styles.section_data}>
                {(convertNumber(item.holder_count) || '?')}
              </span>
              <div className={styles.section_data}>
                {item.address
                  ? (item.metadata?.floor_price_usd
                    ? `$${convertToReadableFormat(item.metadata?.floor_price_usd)}`
                    : '?')
                  : convertToReadableFormat(+item.market_cap)}
              </div>
            </div>
          </div>
          <div className="class-additional">
            <div className="d-flex">
              <div
                className={`${styles.nft} ${!item.address ? styles.red : ''}
            px-3 d-flex justify-content-center align-items-center`}
              >
                {item.address ? 'NFT Collection' : 'Token'}
              </div>
              {item.metadata?.network
                ? (
                  <>
                    <div className={`${styles.divider} mx-2`} />
                    <img src={chainIconPicker(item.metadata?.network)} alt="" />
                  </>
                )
                : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Asset;
