import React from 'react';
import Chart from 'react-apexcharts';
import TitleComponent from '../../../../components/base/TitleComponent';
import Loader from '../../../../components/base/Loader';
import NoData from '../../../../components/base/NoData';
import styles from './HoldersCountHistory.module.scss';
import { formatDate } from '../../../../utils/flows';
import { convertNumber, convertToReadableFormat } from '../../../../tools/NumberConverterTool';

const HoldersCountHistory = ({ data, isLoading, isFetching }) => {
  const chartOptions = () => ({
    chart: {
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      stacked: true,
    },
    dataLabels: {
      enabled: false,
    },
    colors: ['#FFB946', '#F7685B'],
    fill: {
      type: 'solid',
      colors: ['#FFB946', '#F7685B'],
    },
    grid: {
      strokeDashArray: 8,
      borderColor: '#c4c6cb',
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    stroke: {
      curve: 'straight',
      width: 1,
    },
    xaxis: {
      type: 'datetime',
      categories: data.map((elem) => elem.inserted_date),
      labels: {
        format: 'dd. MMM',
        style: {
          colors: ['#6A707E'],
          fontSize: '11px',
          fontFamily: 'Poppins',
          fontWeight: 400,
        },
      },
      tooltip: {
        enabled: false,
      },
      axisBorder: {
        show: true,
        color: '#6A707E',
      },
      axisTicks: {
        show: true,
        borderType: 'solid',
        color: '#6A707E',
        width: 4,
      },
    },
    yaxis: {
      labels: {
        formatter: (value) => convertToReadableFormat(value),
      },
      axisBorder: {
        show: true,
        color: '#6A707E',
      },
      axisTicks: {
        show: true,
        borderType: 'solid',
        color: '#6A707E',
        width: 4,
      },
    },
    tooltip: {
      followCursor: true,
      custom: ({ dataPointIndex }) => {
        const date = data.map((elem) => elem.inserted_date);
        const holderCount = data.map((elem) => elem.holder_count);
        const relevantHolders = data.map((elem) => elem.relevant_holders);
        const other = data.map((elem) => elem.other);
        return (
          '<div style="background: white; padding: 6px 4px; font-size: 10px; color: #192a3e;">'
            + `<div style="margin-bottom: 8px; color: #90A0B7;">${formatDate(date[dataPointIndex], 'MMMM dd, p')}</div>`
            + `<div>Total ${convertNumber(holderCount[dataPointIndex])}</div>`
            + '<div>'
            + '<span style="color: #FFB946;">Relevant holders </span>'
            + `<span>${convertNumber(relevantHolders[dataPointIndex])}</span>`
            + '</div>'
            + '<div>'
            + '<span style="color: #F7685B;">Other </span>'
            + `<span>${convertNumber(other[dataPointIndex])}</span>`
            + '</div>'
          + '</div>'
        );
      },
      x: {
        format: 'dd MMM HH:mm',
      },
    },
    legend: {
      fontSize: '12px',
      fontFamily: 'Poppins, Arial',
      fontWeight: 400,
      labels: {
        colors: '#6A707E',
      },
      markers: {
        width: 8,
        height: 8,
      },
      horizontalAlign: 'left',
      itemMargin: {
        horizontal: 5,
        vertical: 10,
      },
    },
  });

  return (
    <div className={`${styles.wrapper} d-flex flex-column h-100`}>
      <div className="d-flex justify-content-between align-items-center data-chart">
        <TitleComponent>Holder Count History</TitleComponent>
      </div>
      {isLoading || isFetching
        ? (
          <div className="d-flex justify-content-center align-items-center h-75">
            <Loader />
          </div>
        )
        : data ? (
          <div id="chart">
            <Chart
              options={chartOptions()}
              series={[
                { name: 'Relevant Holders', data: data.map((elem) => elem.relevant_holders) },
                { name: 'Other Holders', data: data.map((elem) => elem.other) },
              ]}
              type="area"
              height={280}
            />
          </div>
        ) : (
          <NoData />
        )}
    </div>
  );
};

export default HoldersCountHistory;
