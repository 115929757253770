import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearActiveSettings,
  selectCurrPage,
  selectFieldSettings,
  setFieldSettings,
} from '../../../../../../store/reducers/forms';
import styles from './HeadlineSettings.module.scss';
import formStyles from '../../FormSettings.module.scss';
import { ReactComponent as Close } from '../../../../../../assets/icons/close.svg';
import { ReactComponent as AlignRight } from '../../../../../../assets/icons/forms/align_right.svg';
import { ReactComponent as AlignCenter } from '../../../../../../assets/icons/forms/align_center.svg';
import { ReactComponent as AlignLeft } from '../../../../../../assets/icons/forms/align_left.svg';

const styleTagOptions = ['h1', 'h2', 'h3', 'h4'];
const textAlignmentOptions = ['left', 'center', 'right'];
const textAlignmentIcons = {
  left: <AlignLeft />,
  center: <AlignCenter />,
  right: <AlignRight />,
};

const HeadlineSettings = ({ activeSettings }) => {
  const dispatch = useDispatch();
  const initialized = useRef(false);
  const currPage = useSelector(selectCurrPage);
  const fieldSettings = useSelector(selectFieldSettings);
  const [label, setLabel] = useState('Headline');
  const [styleTag, setStyleTag] = useState(styleTagOptions[0]);
  const [textAlignment, setTextAlignment] = useState(textAlignmentOptions[0]);
  const [change, setChange] = useState(false);

  const handleChange = useCallback(async () => {
    setChange(false);
    const obj = {
      name: 'headline',
      label,
      type: 'headline',
      styleTag,
      textAlignment,
    };
    dispatch(setFieldSettings({ ...obj, field_id: activeSettings.field_id }));
  }, [
    label,
    styleTag,
    textAlignment,
    dispatch,
    activeSettings,
  ]);

  const setOptions = (config) => {
    setLabel(config.label);
    setStyleTag(config.styleTag);
    setTextAlignment(config.textAlignment);
  };

  useEffect(() => {
    if (initialized.current) return;
    const currPageSettings = fieldSettings.find((o) => o.pageName === currPage);
    if (currPageSettings) {
      const config = currPageSettings.fields?.find((x) => x.field_id === activeSettings.field_id);
      if (config) {
        setOptions(config);
      } else {
        handleChange();
      }
    }
    initialized.current = true;
  }, [activeSettings, currPage, fieldSettings]);

  useEffect(() => {
    if (change) {
      handleChange();
    }
  }, [label, styleTag, textAlignment]);

  return (
    <div className={`${styles.wrapper} h-100`}>
      <div className="d-flex flex-column justify-content-between h-100">
        <div>
          <div className={`${formStyles.title} d-flex align-items-center gap-2`}>
            Heading Properties
          </div>
          <div className={`${styles.close} d-flex justify-content-end position-absolute`}>
            <Close
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => dispatch(clearActiveSettings())}
            />
          </div>
        </div>

        <div className="d-flex flex-row w-100 gap-4 mb-3">
          <div className="d-flex flex-column w-45 gap-1">
            <div className={`${formStyles.title_select}`}>
              Style
            </div>
            <div className={styles.buttonGroup}>
              {
                styleTagOptions.map((option) => (
                  <button
                    type="button"
                    className={`${styleTag === option
                      ? `regular-button ${styles.small_padding_selected}`
                      : `outline-button ${styles.small_padding}`} ${styles.singleButton}`}
                    onClick={() => {
                      setStyleTag(option);
                      setChange(true);
                    }}
                  >
                    {option}
                  </button>
                ))
              }
            </div>
          </div>
          <div className="d-flex flex-column w-30 gap-1">
            <div className={`${formStyles.title_input}`}>
              <br />
            </div>
            <div className={styles.buttonGroup}>
              {
                textAlignmentOptions.map((option) => (
                  <button
                    type="button"
                    className={`${textAlignment === option
                      ? `regular-button ${styles.small_padding_selected}`
                      : `outline-button ${styles.small_padding}`} ${styles.singleButton}`}
                    onClick={() => {
                      setTextAlignment(option);
                      setChange(true);
                    }}
                  >
                    {textAlignmentIcons[option]}
                  </button>
                ))
              }
            </div>
          </div>
        </div>

        <div className="d-flex flex-column w-100 gap-1 mb-3">
          <div className={`${formStyles.title_input}`}>
            Heading
          </div>
          <div className={`${formStyles.text_input}`}>
            <input
              placeholder="Type here"
              type="text"
              className="w-100"
              value={label}
              onChange={(e) => {
                setLabel(e.currentTarget.value);
                setChange(true);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeadlineSettings;
