import React, {
  useEffect, useState, useCallback, useRef,
} from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Bootstrap from 'bootstrap';
import { formatDistanceStrict } from 'date-fns';
import { ReactComponent as Trash } from '../../../assets/icons/table/trash.svg';
import { ReactComponent as Edit } from '../../../assets/icons/table/edit.svg';
import { ReactComponent as DisabledTrash } from '../../../assets/icons/disabled_trash.svg';
import Cancel from '../../../assets/icons/cancelFilter.svg';
import { ReactComponent as Copy } from '../../../assets/icons/table/copy.svg';
import { ReactComponent as TypeFilter } from '../../../assets/icons/table/type.svg';
import { audiencesApi } from '../../../api/audiences';
import { showErrorMessage, showSuccessMessage } from '../../../components/base/Notifications';
import ErrorModal from '../../../components/ui/modals/ErrorModal';
import Tooltip from '../../../components/ui/Tooltip';
import ConfirmModal from '../../../components/ui/modals/ConfirmModal';
import DropDownFilter from './DropDownFilter';
import DropDownSearchFilter from './DropDownSearchFilter';
import { counter } from '../../../tools/Counter';
import SnapshotElem from './SnapshotElem';
import Table from '../../../components/base/Table';
import tableStyles from '../../../components/base/Table/Table.module.scss';
import Settings from '../../../assets/images/dashboard/settings.png';
import { convertNumber } from '../../../tools/NumberConverterTool';
import searchIcon from '../../../assets/icons/icon_search.svg';
import filterIcon from '../../../assets/icons/filter.svg';
import filledFilterIcon from '../../../assets/icons/filledFilter.svg';
import { debounce } from '../../../utils/debounce';
import useOutsideClick from '../../../components/hooks/app/useOutsideClick';
import { createdCustomList, createdSegment } from '../../../store/reducers/segment';
import LinkElem from './LinkElem';
import styles from './SegmentsTable.module.scss';

const SegmentsTable = ({
  data, count, refetch, field, setField, page, setPage, setItemType, desc, setDesc,
  limit, setLimit, isLoading, search, setSearch,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const parentRef = useRef();

  const [deletedItem, setDeletedItem] = useState();
  const [copyItem, setCopyItem] = useState(null);
  const [snapshotItem, setSnapshotItem] = useState(null);
  const [checkedSegment, setCheckedSegment] = useState(true);
  const [checkedList, setCheckedList] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [deleteTeamSegment, result] = audiencesApi.useDeleteSegmentMutation();
  const [deleteCustomList, deleteCustomListResult] = audiencesApi.useDeleteCustomListMutation();
  const [copySegments, copyResult] = audiencesApi.useCopySegmentsMutation();
  const [snapshotSegments, snapshotResult] = audiencesApi.useSnapshotSegmentsMutation();
  const [filteredData, setFilteredData] = useState([]);
  const [filteredIndexes, setFilteredIndexes] = useState([]);
  const [showSearchFilter, setSearchShowFilter] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [showPlaceholder, setShowPlaceholder] = useState(true);
  const [hoverFilter, setHoverFilter] = useState(false);

  const handleFilter = (event, searching) => {
    const showMenu = searching ? showSearchFilter : showFilter;
    if (showMenu) {
      if ((event.target.innerText === 'Segment' && checkedSegment && !checkedList)
        || (event.target.innerText === 'Custom List' && checkedList && !checkedSegment)) {
        if (searching) setSearchShowFilter(false);
        else setShowFilter(false);
      } else if (event.target.innerText === 'Segment\n'
        + 'Custom List' || event.target.innerText === 'Type\n'
        + 'Segment\n'
        + 'Custom List' || event.target.parentNode.nodeName === 'TH'
        || event.target.parentNode.nodeName === 'svg'
        || event.target.parentNode.nodeName === 'TR') {
        if (searching) setSearchShowFilter(false);
        else setShowFilter(false);
      } else {
        setTimeout(() => {
          if (searching) setSearchShowFilter(false);
          else setShowFilter(false);
        }, 500);
      }
    } else if (searching) setSearchShowFilter(true);
    else {
      event.stopPropagation();
      setShowFilter(true);
    }
  };

  const handleReset = () => {
    setCheckedList(false);
    setCheckedSegment(false);
  };

  useEffect(() => {
    if (!checkedList && !checkedSegment) {
      setCheckedList(true);
      setCheckedSegment(true);
    }
  }, [checkedList, checkedSegment]);

  const handleOutsideClick = (e) => {
    if (showFilter && !e.target.closest('.dropdown') && !e.target.closest('.search-dropdown')) {
      setShowFilter(false);
    }
    if (showSearchFilter && !e.target.closest('.search-dropdown') && !e.target.closest('.dropdown')) {
      setSearchShowFilter(false);
    }
  };

  const handleSearchFilter = (searchText) => {
    const newData = data.filter((item) => item.name.toLowerCase().includes(searchText.toLowerCase()));
    const indexes = newData.map((item) => item.name.toLowerCase().indexOf(searchText.toLowerCase()));
    setFilteredData(newData);
    setFilteredIndexes(indexes);
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [showFilter, showSearchFilter]);

  useEffect(() => {
    handleSearchFilter(search);
  }, [data, search]);

  const debounceValue = useCallback(debounce((val) => setSearch(val), 300), []);

  const handleSearch = (val) => {
    setSearchValue(val);
    debounceValue(val);
    setPage(1);
  };

  const handleNameLength = (name) => (name.length > 30 ? `${name.slice(0, 30)}...` : name);

  const header = [
    {
      id: 1,
      field: 'name',
      title: 'Name',
    },
    {
      id: 7,
      title: (
        <div
          role="presentation"
          className="cursor-pointer"
          onClick={(e) => handleFilter(e, false)}
        >
          Type
          <TypeFilter className="mx-1" />
          {showFilter && (
            <div
              className={`${styles.invisible_dropdown} position-absolute`}
            >
              <DropDownFilter
                setCheckedSegment={setCheckedSegment}
                checkedSegment={checkedSegment}
                setCheckedList={setCheckedList}
                checkedList={checkedList}
                setPage={setPage}
                setShowFilter={setShowFilter}
              />
            </div>
          )}
        </div>
      ),
    },
    {
      id: 2,
      field: 'population',
      title: 'Audience size',
    },
    {
      id: 6,
      field: 'updated_at',
      title: 'Last audience update',
    },
    {
      id: 3,
      field: 'created_at',
      title: 'Date of creation',
    },
    {
      id: 4,
      field: 'creator',
      title: 'Creator',
    },
    {
      id: 5,
      title: (
        <div className="d-flex justify-content-center">
          Action
        </div>
      ),
    },
  ];

  const createLink = useCallback((elem) => {
    if (elem.is_restricted) {
      return '#';
    }
    if (elem.item_type === 'segment') {
      return `/segment/${elem.id}`;
    }
    return `/custom-list/${elem.id}`;
  }, []);

  useEffect(() => {
    if (data || filteredData) {
      const tempData = filteredData.length > 0 ? filteredData : data;
      const temp = tempData.map((elem, i) => ({
        name: <LinkElem
          elem={elem}
          i={i}
          search={search}
          filteredIndexes={filteredIndexes}
          createLink={createLink}
        />,
        item_type: (
          <span style={{ textTransform: 'capitalize' }}>{elem.item_type}</span>
        ),
        population: elem.population !== null ? convertNumber(elem.population) : '?',
        updated_at: elem.updated_at ? `${formatDistanceStrict(new Date(), new Date(elem.updated_at))} ago` : '?',
        created_at: elem.created_at.split('T')[0],
        creator: elem.creator,
        ready: elem.ready,
        button: (
          <div className={`ms-auto ${tableStyles.popover_wrapper} text-center position-relative cursor-pointer`}>
            <img
              src={Settings}
              className="cursor-pointer"
              height="25"
              width="25"
              alt="settings"
            />
            <div className={`${tableStyles.invisible_dropdown} position-absolute top-0 right-0`}>
              <div className={`${tableStyles.popover_content} position-absolute`}>
                {elem.is_restricted ? (
                  <>
                    <div
                      className={`
                    d-flex
                    justify-content-start
                    align-items-center
                    gap-1
                    ${tableStyles.modal_row}`}
                      role="presentation"
                      data-for={`edit_info_${elem.id}`}
                      data-tip
                    >
                      <Edit />
                      Edit
                    </div>
                    <Tooltip
                      fixWidth
                      id={`edit_info_${elem.id}`}
                      info="This segment cannot be edited because the dataset, that it used, was deleted or edited."
                    />
                  </>
                ) : (
                  elem?.ready ? (
                    <div
                      className={`
                      d-flex
                      justify-content-start
                      align-items-center
                      gap-1
                      ${tableStyles.modal_row}`}
                      onClick={() => (elem.item_type === 'segment'
                        ? navigate(`/add-segment/${elem.id}?edit=true`)
                        : navigate(`/add-custom-list/${elem.id}`))}
                      role="presentation"
                    >
                      <Edit />
                      Edit
                    </div>
                  ) : (
                    <>
                      <div
                        className={`
                      d-flex
                      justify-content-start
                      align-items-center
                      gap-1
                      ${tableStyles.modal_row}`}
                        role="presentation"
                        data-for={`edit_info_${elem.id}`}
                        data-tip
                      >
                        <Edit />
                        Edit
                      </div>
                      <Tooltip
                        fixWidth
                        id={`edit_info_${elem.id}`}
                        info="This segment cannot be edited because it is in processing."
                      />
                    </>
                  )
                )}
                {elem.item_type === 'segment'
                  ? elem.is_restricted ? (
                    <>
                      <div
                        className={`
                          d-flex
                          justify-content-start
                          align-items-center
                          gap-1
                          ${tableStyles.modal_row}`}
                        data-for={`copy_info_${elem.id}`}
                        data-tip
                      >
                        <Copy />
                        Copy
                      </div>
                      <div
                        data-for={`copy_info_${elem.id}`}
                        data-tip
                      >
                        <SnapshotElem
                          item={elem}
                          setSnapshotItem={setSnapshotItem}
                          copyResult={copyResult}
                          disabled
                        />
                      </div>
                      <Tooltip
                        fixWidth
                        id={`copy_info_${elem.id}`}
                        info="This segment cannot be copied because the dataset, that it used, was deleted or edited."
                      />
                    </>
                  ) : (
                    <>
                      <div
                        className={`
                          d-flex
                          justify-content-start
                          align-items-center
                          gap-1
                          ${tableStyles.modal_row}`}
                        onClick={() => setCopyItem({ name: elem.name, id: elem.id })}
                        role="presentation"
                      >
                        <Copy />
                        Copy
                      </div>
                      {elem?.ready ? (
                        <SnapshotElem
                          item={elem}
                          setSnapshotItem={setSnapshotItem}
                          copyResult={copyResult}
                        />
                      ) : (
                        <>
                          <div
                            data-for={`copy_info_${elem.id}`}
                            data-tip
                          >
                            <SnapshotElem
                              item={elem}
                              setSnapshotItem={setSnapshotItem}
                              copyResult={copyResult}
                              disabled
                            />
                          </div>
                          <Tooltip
                            fixWidth
                            id={`copy_info_${elem.id}`}
                            info="This segment cannot be snapshoted because it is in processing."
                          />
                        </>
                      )}
                    </>
                  ) : null}
                {elem.IsInRunning
                  ? (
                    <>
                      <Tooltip
                        fixWidth
                        id={`delete_info_${elem.id}`}
                        info={
                          `${elem.item_type !== 'custom list' ? 'Segment' : 'Custom list'}
                           that is used in the running or stopped flow can not be deleted.`
                        }
                      />
                      <div
                        className={`
                          d-flex
                          justify-content-start
                          align-items-center
                          gap-1
                          ${tableStyles.modal_row}`}
                        data-for={`delete_info_${elem.id}`}
                        data-tip
                      >
                        <DisabledTrash />
                        Delete
                      </div>
                    </>
                  ) : (
                    <div
                      className={`
                        d-flex
                        justify-content-start
                        align-items-center
                        gap-1
                        ${tableStyles.modal_row}`}
                      onClick={elem.item_type === 'segment'
                        ? () => setDeletedItem({ name: elem.name, id: elem.id, isSegment: true })
                        : () => setDeletedItem({ name: elem.name, id: elem.id, isSegment: false })}
                      role="presentation"
                    >
                      <Trash />
                      Delete
                    </div>
                  )}
              </div>
            </div>
          </div>
        ),
      }));
      setTableData(temp);
    }
  }, [data, filteredData, copyResult]);

  useEffect(() => {
    if (checkedList && checkedSegment) {
      setItemType('');
    }
    if (!checkedList && checkedSegment) {
      setItemType('segment');
    }
    if (checkedList && !checkedSegment) {
      setItemType('custom list');
    }
  }, [checkedList, checkedSegment, setItemType]);

  useEffect(() => {
    if (copyResult.isSuccess) {
      showSuccessMessage('Segment was successfully copied');
      dispatch(createdSegment(copyResult?.data?.id));
      if (copyResult.isSuccess) {
        setTimeout(() => {
          dispatch(createdSegment(null));
        }, 10000);
      }
      setCopyItem(null);
      refetch();
      copyResult.reset();
    }
    if (copyResult.isError) {
      showErrorMessage(Object.keys(copyResult.error.data).length !== 0 && copyResult.error.data.detail);
      copyResult.reset();
    }
    return () => clearTimeout();
  }, [copyResult, dispatch, refetch]);

  useEffect(() => {
    if (result.isSuccess) {
      showSuccessMessage('Segment was successfully deleted');
      setDeletedItem(null);
      if (tableData.length === 1 && page > 1) {
        setPage(page - 1);
      } else {
        refetch();
      }
      result.reset();
    }
    if (result.isError) {
      if (result.error.status === 400) {
        const myModal = new Bootstrap.Modal(document.getElementById('errorModal'));
        myModal.toggle();
        setDeletedItem({ ...deletedItem, isError: true });
      } else {
        showErrorMessage(Object.keys(result.error.data).length !== 0 && result.error.data.message);
      }
      result.reset();
    }
  }, [page, refetch, result, setPage, tableData.length]);

  useEffect(() => {
    if (snapshotResult.isSuccess) {
      showSuccessMessage('Segment was successfully saved as Custom List');
      dispatch(createdCustomList(snapshotResult?.data?.id));
      setTimeout(() => {
        dispatch(createdCustomList(null));
      }, 10000);
      setSnapshotItem(null);
      snapshotResult.reset();
      refetch();
    }
    if (snapshotResult.isError) {
      showErrorMessage(snapshotResult?.error?.data?.detail || 'Something went wrong');
      setSnapshotItem(null);
      snapshotResult.reset();
    }
  }, [dispatch, refetch, snapshotResult]);

  useEffect(() => {
    if (deleteCustomListResult.isSuccess) {
      showSuccessMessage('Custom list was successfully deleted');
      setDeletedItem(null);
      if (tableData.length === 1 && page > 1) {
        setPage(page - 1);
      } else {
        refetch();
      }
      deleteCustomListResult.reset();
    }
    if (deleteCustomListResult.isError) {
      if (deleteCustomListResult.error.status === 400) {
        const myModal = new Bootstrap.Modal(document.getElementById('errorModal'));
        myModal.toggle();
        setDeletedItem({ ...deletedItem, isError: true });
      } else {
        showErrorMessage(Object.keys(deleteCustomListResult.error.data).length !== 0
          && deleteCustomListResult.error.data.message);
      }
      deleteCustomListResult.reset();
    }
  }, [deleteCustomListResult, page, refetch, setPage, tableData.length]);

  useEffect(() => () => clearTimeout());

  useOutsideClick(parentRef, () => setHoverFilter(false));

  return (
    <div className={styles.wrapper}>
      {deletedItem && !deletedItem?.isError && (
        <ConfirmModal
          title="Confirm delete"
          description={`Are you sure you want to delete “${handleNameLength(deletedItem?.name)}”?`}
          buttonName="Delete"
          onSubmit={() => (deletedItem?.isSegment
            ? (deleteTeamSegment(deletedItem?.id))
            : deleteCustomList(deletedItem?.id)
          )}
          onCancel={() => setDeletedItem(null)}
          loading={result.isLoading || result.isFetching
            || deleteCustomListResult.isLoading || deleteCustomListResult.isFetching}
        />
      )}
      {copyItem && (
        <ConfirmModal
          title="Confirm copy"
          description={`Are you sure you want to copy the
           “${handleNameLength(copyItem.name)}” segment with its configurations?`}
          buttonName="Copy"
          onSubmit={() => copySegments(copyItem.id)}
          onCancel={() => setCopyItem(null)}
          loading={copyResult.isLoading || copyResult.isFetching}
        />
      )}
      {snapshotItem && (
        <ConfirmModal
          title="Confirm action"
          description={`Are you sure you want to take a snapshot of
           “${handleNameLength(snapshotItem.name)}” segment and save it as Custom List?`}
          buttonName="Take a snapshot"
          onSubmit={() => snapshotSegments({ id: snapshotItem.id })}
          onCancel={() => setSnapshotItem(null)}
          loading={snapshotResult.isLoading || snapshotResult.isFetching}
        />
      )}
      {deletedItem
        ? (
          <ErrorModal
            message={`
            The deletion can’t be completed because the ${deletedItem?.isSegment
            ? 'segment' : 'custom list'} "${deletedItem.name}"
            is in use in running or stopped flow
            `}
            title={`Cannot delete ${deletedItem?.isSegment ? 'segment' : 'custom list'}`}
          />
        )
        : null}
      <div
        className={`d-flex justify-content-between align-items-center ${styles.responsive}`}
      >
        <div className="title-h4-bold mb-3">
          Audiences
          <div className={styles.showing}>
            {counter(count, page, limit)}
          </div>
        </div>
        <div className="d-flex flex-lg-row flex-column gap-3 flex-wrap">
          <div className="d-flex rounded mb-0 border mb-lg-3">
            <img style={{ color: '#90A0B7' }} className={styles.searchIcon} width={16} src={searchIcon} alt="search" />
            <input
              type="text"
              className={`${styles.inputArea} rounded border-0`}
              placeholder={showPlaceholder ? 'Search audience names' : ''}
              aria-label="Search"
              aria-describedby="search-addon"
              value={searchValue}
              onChange={(e) => handleSearch(e.target.value)}
              onClick={() => setShowPlaceholder(false)}
              onBlur={() => {
                if (searchValue === '') {
                  setShowPlaceholder(true);
                }
              }}
            />
            <div className="d-flex align-items-center dropdown show search-dropdown">
              <div className="d-flex">
                {!checkedList && (
                  <span
                    role="presentation"
                    onClick={() => setCheckedSegment(false)}
                    className={styles.selectedOption}
                  >
                    Segment
                  </span>
                )}
                {!checkedSegment
                && (
                  <span
                    role="presentation"
                    onClick={() => setCheckedList(false)}
                    className={styles.selectedOption}
                  >
                    Custom List
                  </span>
                )}
                {(!(checkedSegment && checkedList)) && (
                  <img role="presentation" onClick={handleReset} className={styles.cancelIcon} src={Cancel} alt="" />
                )}
              </div>
              <div className={styles.separator_icon} />
              <div
                role="presentation"
                className={`${styles.filterIcon} cursor-pointer`}
                onClick={(e) => handleFilter(e, true)}
              >
                <img
                  className={styles.filterIcon}
                  src={`${(!checkedSegment || !checkedList) ? filledFilterIcon : filterIcon}`}
                  alt="filter"
                  onMouseEnter={() => setHoverFilter(true)}
                  role="presentation"
                  onClick={() => setHoverFilter(false)}
                />
                {hoverFilter && (
                  <div
                    ref={parentRef}
                    className={`${styles.invisible_dropdownFilter} position-relative`}
                    onMouseLeave={() => setHoverFilter(false)}
                  >
                    <DropDownSearchFilter
                      setCheckedSegment={setCheckedSegment}
                      checkedSegment={checkedSegment}
                      setCheckedList={setCheckedList}
                      checkedList={checkedList}
                      setPage={setPage}
                      setShowFilter={setShowFilter}
                      onMouseLeave={() => setHoverFilter(false)}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <button
            className="outline-blue-button mb-0 mb-lg-3"
            type="button"
            onClick={() => {
              navigate('/csv-upload');
            }}
          >
            Import Profiles
          </button>
          <button
            className="regular-button mb-3 mb-lg-3"
            type="button"
            onClick={() => {
              navigate('/add-segment');
            }}
          >
            Create a Segment
          </button>
        </div>
      </div>
      <Table
        total={count}
        data={tableData}
        desc={desc}
        setDesc={setDesc}
        ordering={field}
        setOrdering={setField}
        page={page}
        setPage={setPage}
        limit={limit}
        setLimit={setLimit}
        headers={header}
        isLoading={isLoading}
        isAudienceSearch
      />
    </div>
  );
};

export default SegmentsTable;
