import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Title from '../../../../components/base/Title';
import Widget from '../../../../components/ui/Market/Widget';
import uniqueId from '../../../../utils/uniqueId';
import { webSDKApi } from '../../../../api/webSDK';
import { convertNumber } from '../../../../tools/NumberConverterTool';
import Sessions from '../../../../assets/icons/trackedWebsite/Sessions.svg';
import Users from '../../../../assets/icons/trackedWebsite/Users.svg';
import WalletDetected from '../../../../assets/icons/trackedWebsite/walletDetected.svg';
import WalletConnected from '../../../../assets/icons/trackedWebsite/WalletConnected.svg';
import Web3 from '../../../../assets/icons/trackedWebsite/Web3.svg';
import { ethFormat } from '../../../../utils/singleAssetPage/parseData';
import TopBlocks from '../TopBlocks';
import DailyChart from '../DailyChart';
import styles from './MonthlyActivity.module.scss';

const MonthlyActivity = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [widgetData, setWidgetData] = useState([]);

  const {
    data: topBlock,
    isLoading,
    isFetching,
  } = webSDKApi.useGetWebSDKDataForTopBlocksQuery(id);

  const {
    data: stats,
    isLoading: isStatsLoading,
    isFetching: isStatsFetching,
  } = webSDKApi.useGetWebSDKStatsQuery(id);

  useEffect(() => {
    if (stats) {
      setWidgetData([
        {
          id: 9,
          name: 'Sessions',
          number: stats?.sessions !== null
            ? `${convertNumber(stats?.sessions)}`
            : '?',
          icon: Sessions,
        },
        {
          id: 10,
          name: 'Visitors',
          number: stats?.visitors !== null
            ? `${convertNumber(stats?.visitors)}`
            : '?',
          icon: Users,
          info: 'Monthly active users',
        },
        {
          id: 11,
          name: 'Wallets detected',
          number: stats?.visitors_with_wallets !== null
            ? `${convertNumber(stats?.visitors_with_wallets)}`
            : '?',
          icon: WalletDetected,
          info: 'Visitors with a wallet in their browser',
        },
        {
          id: 12,
          name: 'Wallets connected',
          number: stats?.visitor_wallets_connected !== null
            ? `${convertNumber(stats?.visitor_wallets_connected)}`
            : '?',
          icon: WalletConnected,
          info: 'Visitors with a wallet connected to the website',
        },
        {
          id: 13,
          name: 'Web3 Adoption',
          number: stats.visitors && stats.can_have_wallet !== null && stats.can_have_wallet > 0
            ? `${ethFormat((stats.visitors_with_wallets / stats.can_have_wallet) * 100)}%`
            : '-',
          icon: Web3,
          info: 'Score indicating the weighted Web3 adoption among visitors',
        },
      ]);
    }
  }, [dispatch, stats]);

  return (
    <div className="asset-container">
      <div className="title-gap d-flex align-items-center gap-2">
        <Title>Monthly activity</Title>
      </div>
      <div className="dropdown-divider w-100" />
      <div className="info-wrapper-top d-flex justify-content-between gap-4">
        {(widgetData.length ? widgetData : [{}, {}, {}, {}, {}]).map((item) => (
          <Widget item={item} isLoading={isStatsLoading || isStatsFetching} key={uniqueId('website-widget')} />
        ))}
      </div>
      <div className={styles.graphWrapper}>
        <DailyChart />
      </div>
      <div className={`${styles.blockWrapper} d-flex gap-3 justify-content-between`}>
        <TopBlocks title="Top Wallets" data={topBlock?.wallets} isLoading={isLoading || isFetching} />
        <TopBlocks title="Top Countries" data={topBlock?.countries} isLoading={isLoading || isFetching} />
        <TopBlocks title="Top Languages" data={topBlock?.languages} isLoading={isLoading || isFetching} />
        <TopBlocks title="Top Sources" data={topBlock?.sources} isLoading={isLoading || isFetching} />
      </div>
    </div>
  );
};

export default MonthlyActivity;
