import React, { useEffect } from 'react';
import Select from 'react-select';

const DefaultSelect = ({
  option, pushSelectedOptionToArray, edit, filterOptions,
}) => {
  const {
    value, name, options, type,
  } = option;

  useEffect(() => {
    if (['chain', 'filter-type', 'any-of-or-all-of', 'select-option', 'transaction'].includes(name) && !value) {
      pushSelectedOptionToArray(options[0]?.value, name, type);
    }
  }, [name, options, pushSelectedOptionToArray, value, type]);

  const selectStyles = {
    menu: (provided) => ({
      ...provided,
      zIndex: 1000,
    }),
  };

  const filteredOptions = filterOptions ? filterOptions(options) : options;

  useEffect(() => {
    if (Array.isArray(value) && !filteredOptions.some((opt) => opt.value === value[0]?.value)) {
      pushSelectedOptionToArray(filteredOptions[0]?.value, name, type);
    }
  }, [filteredOptions, value, name, type, pushSelectedOptionToArray]);

  return (
    <div className="w-100" key={option.name}>
      <Select
        maxMenuHeight={194}
        defaultValue={
          ['chain', 'filter-type', 'any-of-or-all-of'].includes(name)
        && Array.isArray(value)
        && !value[0]?.value ? options[0]?.label : undefined
        }
        options={filteredOptions}
        value={Array.isArray(value) ? filteredOptions.filter((opt) => opt.value === value[0]?.value)[0] : ''}
        onChange={(val) => {
          pushSelectedOptionToArray(val.value, name, type);
        }}
        isDisabled={!edit}
        styles={selectStyles}
      />
    </div>
  );
};

export default DefaultSelect;
