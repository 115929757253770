import React, { useEffect, useState } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { audiencesApi } from '../../../api/audiences';
import { convertToReadableFormat } from '../../../tools/NumberConverterTool';
import Loader from '../../../components/base/Loader';
import { getWalletAge } from '../../../utils/segments';
import uniqueId from '../../../utils/uniqueId';
import DownloadModal from '../../../components/ui/modals/DownloadModal';
import DownloadTooltip from '../../../components/ui/DownloadTooltip';
import styles from './List.module.scss';
import { counter } from '../../../tools/Counter';
import { tooltipChainIconPicker } from '../../../tools/ChainIconPicker';
import Table from '../../../components/base/Table';
import tableStyles from '../../../components/base/Table/Table.module.scss';
import { setSelectedAsset } from '../../../store/reducers/app';
import ExportCSV from '../../../components/svgIcons/ExportCSV';
import ItemPreview from '../../../components/ui/ItemPreview';
import ConfirmModal from '../../../components/ui/modals/ConfirmModal';
import { aliasData } from '../../../utils/wallets';

const List = ({ walletCounts }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(Number(searchParams.get('page')) || 1);
  const [order, setOrder] = useState(searchParams.get('orderBy') || 'total_portfolio_value');
  const [desc, setDesc] = useState(searchParams.get('order') !== 'false');
  const [isOpen, setIsOpen] = useState(false);
  const [isDownloaded, setIsDownloaded] = useState(false);
  const [limit, setLimit] = useState(searchParams.get('perPage') || 25);
  const [tableData, setTableData] = useState([]);

  const toolTipId = uniqueId('segment-download');
  const warningsList = [
    'No more than 50,000 profiles will be downloaded',
  ];

  const {
    data: walletsData,
    isLoading: isWalletsDataLoading,
    isFetching: isWalletsDataFetching,
  } = audiencesApi.useGetCustomListAddressesListQuery({
    id,
    limit: +limit,
    offset: (page - 1) * (+limit),
    orderBy: desc ? 'desc' : 'asc',
    order,
  });

  const [getSegmentWalletCSV, result] = audiencesApi.useGetCustomListWalletsCSVMutation();

  const header = [
    {
      field: 'address_name',
      title: 'Address Name',
    },
    {
      field: '',
      title: 'Alias',
    },
    {
      field: 'total_portfolio_value',
      title: 'Portfolio Value',
    },
    {
      field: 'first_txn_timestamp',
      title: 'Wallet age',
    },
    {
      title: (
        <div className="d-flex justify-content-center">
          Active on
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (result.isLoading) {
      setIsOpen(true);
    }
    if (result.isSuccess && result.data) {
      const handleOpen = () => {
        setTimeout(() => {
          window.location.replace(result.data[0].link);
        }, 1000);
      };
      handleOpen();
    }
    if (result.error?.status === 403) {
      setIsOpen(false);
    }
    return () => clearTimeout();
  }, [result]);

  useEffect(() => {
    if (walletsData?.length) {
      const temp = walletsData.map((elem) => ({
        address_name: (
          <>
            <span
              className={`d-block ${tableStyles.underline_on_hover}`}
              data-for={`Name_${elem.ALID || elem.address || elem.address}`}
              data-tip
            >
              <Link className="disable-link-styles" to={`/profile/${elem.ALID || elem.address}`}>
                <div
                  role="presentation"
                  onClick={() => {
                    setSearchParams({
                      ...Object.fromEntries([...searchParams]),
                      scroll: window.scrollY,
                    });
                    dispatch(setSelectedAsset({}));
                  }}
                  className={`${styles.name} text-truncate`}
                >
                  {elem.address_name || elem.address}
                </div>
              </Link>
            </span>
            <ItemPreview
              id={`Name_${elem.ALID || elem.address || elem.address}`}
              data={{
                id: elem.address,
                name: elem.address,
                type: 'wallet',
                alid: elem.ALID || '',
              }}
            />
          </>
        ),
        alias: (
          <div className="d-flex gap-2 align-items-baseline">
            {elem?.alias
              ? (
                <>
                  <span>{aliasData[elem.alias_type]}</span>
                  <span>{elem.alias}</span>
                </>
              )
              : '-'}
          </div>
        ),
        total_portfolio_value: `${elem.total_portfolio_value < 0 ? '?'
          : (elem.total_portfolio_value !== null
            ? `${convertToReadableFormat(elem.total_portfolio_value)} USD` : '0 USD')}`,
        first_txn_timestamp: getWalletAge(elem.first_txn_timestamp),
        network: (
          <div className="d-flex justify-content-center">
            {elem.first_txn_timestamp_eth
              && tooltipChainIconPicker('ethereum', uniqueId('chain'))}
            {elem.first_txn_timestamp_polygon
              && tooltipChainIconPicker('polygon', uniqueId('chain'))}
          </div>
        ),
      }));
      setTableData(temp);
    }
  }, [walletsData]);

  return (
    <div className="row p-4">
      <div className={`title-h4-bold ${styles.adaptive}`}>
        Profile list
      </div>
      {(isWalletsDataLoading || isWalletsDataFetching) && !walletsData
        ? (
          <div className="p-5">
            <Loader />
          </div>
        )
        : (
          <>
            <div className={`${styles.showing} d-flex d-flex justify-content-between align-items-center my-2`}>
              {counter(walletCounts, page, limit)}
              <button
                type="button"
                className="btn d-flex align-items-center justify-content-center shadow-none m-o p-0"
                data-for={toolTipId}
                data-tip
                onMouseEnter={() => setIsDownloaded(true)}
                onMouseLeave={() => setIsDownloaded(false)}
                onClick={() => getSegmentWalletCSV({
                  id,
                  wait: true,
                })}
              >
                <ExportCSV hovered={isDownloaded} />
              </button>
              <DownloadTooltip
                id={toolTipId}
                header="Export profile list into CSV"
                warningsList={warningsList}
              />
            </div>
            <div className="w-100">
              <Table
                total={walletCounts}
                data={tableData}
                desc={desc}
                setDesc={setDesc}
                ordering={order}
                setOrdering={setOrder}
                page={page}
                setPage={setPage}
                limit={limit}
                setLimit={setLimit}
                headers={header}
                isLoading={isWalletsDataLoading || isWalletsDataFetching}
                min={25}
              />
            </div>
            {isOpen && result?.error?.status !== 403
              ? <DownloadModal result={result} setIsOpen={setIsOpen} />
              : null}
            {result?.error?.status === 403 ? (
              <ConfirmModal
                title="We are sorry..."
                description="The feature you’re trying to use has restricted access.
                 Please reach out to your Customer Success Manager."
                buttonName="Okay"
                onSubmit={() => result.reset()}
              />
            ) : null}
          </>
        )}
    </div>
  );
};

export default List;
