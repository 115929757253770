import React, { useMemo, useState } from 'react';
import NFTCollectionCreationModal from '../modals/NFTCollectionCreationModal';
import styles from '../../../pages/Dashboard/Dashboard.module.scss';

const NFTCollectionCreation = () => {
  const [showNFTModal, setShowNFTModal] = useState(false);

  return useMemo(() => (
    <>
      <button
        type="button"
        className={`regular-button ${styles.nft_button}`}
        onClick={() => setShowNFTModal(true)}
      >
        Create NFT Collection
      </button>
      {showNFTModal && (
        <NFTCollectionCreationModal
          onSubmit={() => setShowNFTModal(false)}
          onCancel={() => setShowNFTModal(false)}
        />
      )}
    </>
  ), [showNFTModal]);
};

export default NFTCollectionCreation;
