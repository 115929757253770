import { showErrorMessage } from '../../components/base/Notifications';

export const preventSearch = (e) => {
  const str = "!@#$%^*()+=[]\\';,/{}|\"<>?";
  if (str.split('').includes(e.key)) {
    e.preventDefault();
  }
};

export const preventPaste = (e) => {
  const paste = e.clipboardData.getData('text/plain');
  const notPermittedSymbols = "!@#$%^*()+=[]\\';,/{}|\"<>?";

  const check = paste.split('').some((sym) => notPermittedSymbols.includes(sym));
  if (check) {
    showErrorMessage('The pasted values contain symbols that are not permitted.');
    e.preventDefault();
  }
};

export const handlePlatforms = (info) => {
  if (!info) return null;
  const data = {
    ethereum: false,
    polygon: false,
  };
  if (info.length) {
    if (info.includes('ethereum')) {
      data.ethereum = true;
    }
    if (info.includes('polygon-pos') || info.includes('polygon')) {
      data.polygon = true;
    }
    return data;
  }
  return null;
};
