import React, { useState, useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import TypicalPortfolio from '../../../components/ui/TypicalPortfolio';
import BuyingPower from '../../../components/ui/BuyingPower';
import Investments from '../../../components/ui/Investments';
import { audiencesApi } from '../../../api/audiences';

const InvestorPortfolio = ({ holders, assetsRefetch }) => {
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const initialSearchParams = {
    ...Object.fromEntries([...searchParams]),
  };

  const [queryParams, setQueryParams] = useState({
    page: Number(initialSearchParams.page) || 1,
    desc: initialSearchParams.order !== 'false',
    type: initialSearchParams.sort,
    order: initialSearchParams.orderBy || 'number_holders',
    limit: initialSearchParams.perPage || 25,
  });

  const [checkedToken, setCheckedToken] = useState(queryParams.type !== 'nft');
  const [checkedNft, setCheckedNft] = useState(queryParams.type !== 'token');

  const {
    page, desc, type, order, limit,
  } = queryParams;

  const {
    data: portfolioData,
    isLoading: isPortfolioLoading,
    isFetching: isPortfolioFetching,
  } = audiencesApi.useGetCustomListTypicalPortfolioQuery(
    {
      id: pathname.split('/')[2],
    },
    {
      skip: !pathname.split('/')[2],
    },
  );

  const {
    data: buyingPower,
    isLoading: isBuyingPowerLoading,
    isFetching: isBuyingPowerFetching,
  } = audiencesApi.useGetCustomListBuyingPowerQuery(
    {
      id: pathname.split('/')[2],
    },
    {
      skip: !pathname.split('/')[2],
    },
  );

  const {
    data: investments,
    isLoading: isInvestmentsLoading,
    isFetching: isInvestmentsFetching,
  } = audiencesApi.useGetCustomListInvestorPortfolioQuery({
    id: pathname.split('/')[2],
    des: desc ? '1' : '0',
    asset_type: type || '',
    limit: +limit,
    order,
    page,
  });

  useEffect(() => {
    let newType = '';

    if (checkedNft && checkedToken) {
      newType = '';
    } else if (checkedNft && !checkedToken) {
      newType = 'nft';
    } else if (!checkedNft && checkedToken) {
      newType = 'token';
    }

    setQueryParams((prevState) => ({
      ...prevState,
      type: newType,
    }));

    const newSearchParams = new URLSearchParams(searchParams.toString());
    newSearchParams.set('sort', newType);
    newSearchParams.set('page', page.toString());
    newSearchParams.set('order', desc ? 'true' : 'false');
    setSearchParams(newSearchParams);
  }, [checkedNft, checkedToken, page, desc]);

  return (
    <>
      <div className="row mt-4 mx-2">
        <div className="col-4">
          <TypicalPortfolio
            data={portfolioData}
            isLoading={isPortfolioLoading}
            isFetching={isPortfolioFetching}
            info="Typical audience portfolio breakdown"
          />
        </div>
        <div className="col-4">
          <BuyingPower
            buyingPower={buyingPower}
            isLoading={isBuyingPowerLoading}
            isFetching={isBuyingPowerFetching}
            info="Total amount available to invest by the audience"
          />
        </div>
      </div>
      <Investments
        investments={investments}
        isLoading={isInvestmentsLoading || isInvestmentsFetching}
        limit={limit}
        setLimit={(newLimit) => setQueryParams({ ...queryParams, limit: newLimit })}
        holders={holders}
        page={page}
        desc={desc}
        setPage={(newPage) => setQueryParams({ ...queryParams, page: newPage })}
        setDesc={(newDesc) => setQueryParams({ ...queryParams, desc: newDesc })}
        setCheckedToken={setCheckedToken}
        setCheckedNft={setCheckedNft}
        checkedToken={checkedToken}
        checkedNft={checkedNft}
        order={order}
        setOrder={(newOrder) => setQueryParams({ ...queryParams, order: newOrder })}
        assetsRefetch={assetsRefetch}
      />
    </>
  );
};

export default InvestorPortfolio;
