import React from 'react';
import ethereumIcon from '../assets/icons/eth.svg';
import polygonIcon from '../assets/icons/polygon logo svg.svg';
import Tooltip from '../components/ui/Tooltip';

export const chainIconPicker = (chain) => {
  switch (chain) {
    case 'ethereum':
      return ethereumIcon;
    case 'polygon':
      return polygonIcon;
    default: return null;
  }
};

export const tooltipChainIconPicker = (chain, id) => {
  switch (chain) {
    case 'ethereum':
      return (
        <div>
          <div
            className="d-flex"
            data-for={id}
            data-tip
          >
            <img src={ethereumIcon} alt="" width="24px" height="24px" />
          </div>
          <Tooltip
            id={id}
            info="Ethereum"
          />
        </div>
      );
    case 'polygon':
      return (
        <div>
          <div
            className="d-flex"
            data-for={id}
            data-tip
          >
            <img src={polygonIcon} alt="" width="24px" height="24px" />
          </div>
          <Tooltip
            id={id}
            info="Polygon"
          />
        </div>
      );
    case 'goerli':
      return (
        <div>
          <div
            className="d-flex"
            data-for={id}
            data-tip
          >
            <img src={ethereumIcon} alt="" width="24px" height="24px" />
          </div>
          <Tooltip
            id={id}
            info="Ethereum [Goerli testnet]"
          />
        </div>
      );
    case 'mumbai':
      return (
        <div>
          <div
            className="d-flex"
            data-for={id}
            data-tip
          >
            <img src={polygonIcon} alt="" width="24px" height="24px" />
          </div>
          <Tooltip
            id={id}
            info="Polygon [Mumbai testnet]"
          />
        </div>
      );
    default: return null;
  }
};
