import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Paragraph } from '@absolutelabs/react-component-library';
import {
  selectCurrPage,
  selectFieldSettings,
} from '../../../../../store/reducers/forms';

const ParagraphField = ({ ...props }) => {
  const currPage = useSelector(selectCurrPage);
  const fieldSettings = useSelector(selectFieldSettings);
  const [fieldConfig, setFieldConfig] = useState({});

  const defaultContent = `Lorem Ipsum is simply dummy text of the printing 
    and typesetting industry. Lorem Ipsum has been the industry's standard 
    dummy text ever since the 1500s, when an unknown printer took a galley 
    of type and scrambled it to make a type specimen book. It has survived 
    not only five centuries, but also the leap into electronic typesetting, 
    remaining essentially unchanged.`;

  useEffect(() => {
    const currPageSettings = fieldSettings.find((o) => o.pageName === currPage);
    const config = currPageSettings?.fields?.find((x) => x.field_id === props?.field?.id);
    if (config) {
      setFieldConfig(config);
    }
  }, [fieldSettings, currPage, props?.field?.id]);

  return (
    <Paragraph
      content={fieldConfig.content || defaultContent}
      align={fieldConfig.align || 'left'}
    />
  );
};

export default ParagraphField;
