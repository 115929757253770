import React from 'react';
import { formatInTimeZone } from 'date-fns-tz';
import intervalToDuration from 'date-fns/intervalToDuration';
import TitleComponent from '../../../../components/base/TitleComponent';
import styles from './Mint.module.scss';
import NoData from '../../../../components/base/NoData';
import { ethFormat } from '../../../../utils/singleAssetPage/parseData';
import { convertNumber } from '../../../../tools/NumberConverterTool';
import { convertWeiToEth } from '../../../../utils/segments';

const Mint = ({ data }) => {
  const handleData = () => !(
    data.avg_mint_price_eth === null
    && data.avg_mint_price_usd === null
    && data.first_mint_txn_date === null
    && data.free_minters_count === null
    && data.free_mints_count === null
    && data.last_mint_txn_date === null
    && data.max_mint_per_wallet === null
    && data.minters_count === null
  );

  const handleDuration = (first, last) => {
    const result = intervalToDuration({
      start: new Date(first),
      end: new Date(last),
    });
    if (!result.years && !result.months && !result.days && !result.hours) {
      return `${result.minutes ? `${result.minutes} minute${result.minutes !== 1 ? 's' : ''}` : ''} 
    ${result.seconds ? `${result.seconds} second${result.seconds !== 1 ? 's' : ''}` : ''}`;
    }
    if (!result.years && !result.months && !result.days) {
      return `${result.hours ? `${result.hours} hour${result.hours !== 1 ? 's' : ''}` : ''} 
    ${result.minutes ? `${result.minutes} minute${result.minutes !== 1 ? 's' : ''}` : ''}
    ${result.seconds ? `${result.seconds} second${result.seconds !== 1 ? 's' : ''}` : ''}`;
    }
    if (!result.years && !result.months) {
      return `${result.days ? `${result.days} day${result.days !== 1 ? 's' : ''}` : ''} 
    ${result.hours ? `${result.hours} hour${result.hours !== 1 ? 's' : ''}` : ''}
    ${result.minutes ? `${result.minutes} minute${result.minutes !== 1 ? 's' : ''}` : ''}`;
    }
    if (!result.years) {
      return `${result.months ? `${result.months} month${result.months !== 1 ? 's' : ''}` : ''}
    ${result.days ? `${result.days} day${result.days !== 1 ? 's' : ''}` : ''}
    ${result.hours ? `${result.hours} hour${result.hours !== 1 ? 's' : ''}` : ''}`;
    }
    return `${result.years ? `${result.years} year${result.years !== 1 ? 's' : ''}` : ''}
    ${result.months ? `${result.months} month${result.months !== 1 ? 's' : ''}` : ''}
    ${result.days ? `${result.days} day${result.days !== 1 ? 's' : ''}` : ''}`;
  };
  return (
    <div className={`${styles.mint_wrapper} d-flex flex-column`}>
      <TitleComponent>Mint</TitleComponent>
      <div className="d-flex flex-column">
        {handleData() ? (
          <>
            <div className="d-flex w-100 justify-content-between align-items-center gap-1 mt-3">
              <span className={styles.mint_title}>Mint start date</span>
              <span className={styles.mint_info}>
                {`${formatInTimeZone(
                  new Date(data.first_mint_txn_date),
                  'America/New_York',
                  "MMM dd, yyyy 'at' haaa",
                )}
                       EST`}
              </span>
            </div>
            <div className="d-flex w-100 justify-content-between align-items-center gap-1">
              <span className={styles.mint_title}>Mint duration</span>
              <span className={styles.mint_info}>
                {data.first_mint_txn_date && data.last_mint_txn_date
                  ? handleDuration(data.first_mint_txn_date, data.last_mint_txn_date)
                  : '?'}
              </span>
            </div>
            <div className="d-flex w-100 justify-content-between align-items-center gap-1">
              <span className={styles.mint_title}># minters</span>
              <span className={styles.mint_info}>
                {data.minters_count !== null
                  ? `${convertNumber(data.minters_count)} wallet${data.minters_count !== 1 ? 's' : ''}`
                  : '?'}
              </span>
            </div>
            <div className="d-flex w-100 justify-content-between align-items-center mt-3 gap-1">
              <span className={styles.mint_title}>Avg mint price</span>
              <span className={styles.mint_info}>
                {data.avg_mint_price_eth !== null
                  ? `${ethFormat(+convertWeiToEth(data.avg_mint_price_eth))}
                   ETH ($${ethFormat(data.avg_mint_price_usd)})`
                  : '?'}
              </span>
            </div>
            <div className="d-flex w-100 justify-content-between align-items-center gap-1">
              <span className={styles.mint_title}>Max # / Wallet</span>
              <span className={styles.mint_info}>
                {data.max_mint_per_wallet !== null
                  ? `${convertNumber(data.max_mint_per_wallet)} item${data.max_mint_per_wallet !== 1 ? 's' : ''}`
                  : '?'}
              </span>
            </div>
            <div className="d-flex w-100 justify-content-between align-items-center mt-3 gap-1">
              <span className={styles.mint_title}>Free mints</span>
              <span className={styles.mint_info}>
                {data.free_mints_count !== null
                  ? `${convertNumber(data.free_mints_count)} item${data.free_mints_count !== 1 ? 's' : ''}`
                  : '?'}
              </span>
            </div>
            <div className="d-flex w-100 justify-content-between align-items-center gap-1">
              <span className={styles.mint_title}>Free minters</span>
              <span className={styles.mint_info}>
                {data.free_minters_count !== null
                  ? `${convertNumber(data.free_minters_count)} wallet${data.free_minters_count !== 1 ? 's' : ''}`
                  : '?'}
              </span>
            </div>
          </>
        ) : (
          <div className="d-flex justify-content-center align-items-center">
            <NoData />
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(Mint);
