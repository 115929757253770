import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  brands: {},
  isLoading: false,
  error: '',
};

export const brandsSlice = createSlice({
  name: 'brands',
  initialState,
  reducers: {
    setBrands: (state, action) => {
      state.brands = action.payload;
    },
  },
});

export const { setBrands } = brandsSlice.actions;
export const getBrands = (state) => state.brandsReducer.brands;
export default brandsSlice.reducer;
