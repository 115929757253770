import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFieldArray, useForm } from 'react-hook-form';
import {
  clearActiveSettings,
  onSettingsChange,
  removeErrors,
  selectConfigsList,
  selectFlowStatus,
} from '../../../../../store/reducers/flows';
import { ReactComponent as Info } from '../../../../../assets/icons/info_small.svg';
import { ReactComponent as Plus } from '../../../../../assets/icons/flows/plus-circle.svg';
import { ReactComponent as PlusDisabled } from '../../../../../assets/icons/flows/plus-circle-disabled.svg';
import { ReactComponent as Copy } from '../../../../../assets/icons/copy.svg';
import styles from './WebHookNodeSettings.module.scss';
import CustomField from './CustomField';
import { flowsApi } from '../../../../../api/flows';
import Tooltip from '../../../../../components/ui/Tooltip';
import { showSuccessMessage } from '../../../../../components/base/Notifications';
import EditableTitle from '../../../../../components/base/EditableTitle';

const contactInfoOptions = ({
  email: 'john@gmail.com',
  phone: '14677585137',
  discord_id: '99john#0344',
  twitter_id: '99john',
  telegram_id: '991john',
});

const contactInfoOptionsAd = ({
  email: 'anna@gmail.com',
  phone: '13265652177',
  discord_id: '11anna#0101',
  twitter_id: '01anna',
  telegram_id: '02anna',
});

const customParameterOptions = ({
  BOOL: true,
  STRING: 'value',
  FLOAT64: 1.1,
  DATE: '2022-05-21',
  TIMESTAMP: '2022-02-13 12:00:00 UTC',
  INT64: 1,
});

const customParameterOptionsAd = ({
  BOOL: false,
  STRING: 'lorem',
  FLOAT64: 2.2,
  DATE: '2022-06-20',
  TIMESTAMP: '2022-02-11 12:00:00 UTC',
  INT64: 2,
});

const emptyExample = '[\n  "0x00000001",\n  "0x00000002",\n  "0x00000003"\n]';
const WebHookNodeSettings = ({ activeSettings }) => {
  const [titleValue, setTitleValue] = useState('');

  const dispatch = useDispatch();

  const {
    data: customDatasetFields,
    isLoading,
    isFetching,
  } = flowsApi.useGetCustomDatasetFieldsQuery();

  const {
    register, handleSubmit, setValue, watch, reset, control, formState: { errors },
  } = useForm();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'additional_fields',
  });

  const customFields = watch();

  const configList = useSelector(selectConfigsList);
  const status = useSelector(selectFlowStatus);

  const disableChangeStatus = useMemo(() => status === 'running'
    || status === 'scheduled' || status === 'stopped', [status]);

  let walletIncludeInitial = configList[0]?.wallet_included;
  walletIncludeInitial ??= true;
  const [walletIncluded, setWalletIncluded] = useState(walletIncludeInitial);
  const onSubmit = (submitData) => {
    const tempArr = [];
    if (fields?.length) {
      customFields.additional_fields.forEach((elem) => {
        if (elem.type === 'contact_info' || elem.type === 'profile_attributes') {
          tempArr.push({
            type: elem.type,
            name: elem.name,
            value_type: elem.value_type,
          });
        }
        if (elem.type === 'static_value') {
          tempArr.push({
            type: 'static_value',
            name: elem.name,
            value_type: elem.value_type,
            value: elem.value,
          });
        }
      });
    }
    const hookInfo = { ...submitData, additional_fields: tempArr };
    if (titleValue) {
      hookInfo.name = titleValue;
    } else {
      hookInfo.name = 'Webhook';
    }
    dispatch(removeErrors(activeSettings.node_id));
    dispatch(onSettingsChange({ ...hookInfo, node_id: activeSettings.node_id, wallet_included: walletIncluded }));
    dispatch(clearActiveSettings());
  };

  const payloadExample = () => {
    const payload = {};
    const additionalPayload = {};
    if (fields?.length && customFields.additional_fields[0].type) {
      customFields.additional_fields.forEach((elem) => {
        if (!elem.type) {
          return;
        }
        if (walletIncluded) {
          payload.address = '0x00000001';
          additionalPayload.address = '0x00000002';
        }
        if (elem.type === 'contact_info') {
          if (elem.name && elem.value_type) {
            payload[elem.name] = contactInfoOptions[elem.value_type];
            additionalPayload[elem.name] = contactInfoOptionsAd[elem.value_type];
          }
        }
        if (elem.type === 'static_value') {
          if (elem.value_type && elem.name) {
            payload[elem.name] = elem.value;
            additionalPayload[elem.name] = elem.value;
          }
        }
        if (elem.type === 'profile_attributes') {
          if (elem.value_type && elem.name && customDatasetFields) {
            const dataIndex = customDatasetFields.findIndex((object) => object.column_name === elem.value_type);
            if (dataIndex !== -1) {
              payload[elem.name] = customParameterOptions[customDatasetFields[dataIndex].data_type];
              additionalPayload[elem.name] = customParameterOptionsAd[customDatasetFields[dataIndex].data_type];
            }
          }
        }
      });
      if (Object.keys(payload).length) {
        return JSON.stringify([{ ...payload }, { ...additionalPayload }], null, 2);
      }
    }
    return emptyExample;
  };

  useEffect(() => {
    if (configList.length) {
      const index = configList.map((object) => object.node_id).indexOf(activeSettings.node_id);
      if (index >= 0) {
        setTitleValue(configList[index].name);
        setValue('url', configList[index].url);
        setValue('additional_fields', configList[index].additional_fields);
      } else {
        reset({
          url: '',
          additional_fields: [],
        });
        setTitleValue('');
      }
    }
  }, [activeSettings.node_id, configList, reset, setValue]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={`d-flex flex-column justify-content-between h-100 ${styles.form} position-relative`}
    >
      <div>
        <div className={`${styles.title} d-flex align-items-center gap-2`}>
          <EditableTitle
            defaultTitle="Webhook"
            titleValue={titleValue}
            setTitleValue={setTitleValue}
            maxLength={25}
            size={16}
            edit={!disableChangeStatus}
          />
        </div>
        <div className="d-flex flex-column w-100 gap-1 mb-3">
          <div className={styles.title_input}>
            Add the link to the endpoint to call
          </div>
          <div className={disableChangeStatus ? styles.link_area_disabled : styles.link_area}>
            <input
              {...register('url')}
              onChange={(e) => setValue('url', e.target.value.trim())}
              placeholder="Link"
              type="text"
              className="w-100 p-2"
              disabled={disableChangeStatus}
            />
          </div>
        </div>
        <div className={`${styles.title_input} mb-2`}>
          Example payload, sent in the body of a POST call
          <Info
            className="ms-1"
            data-for="example_info"
            data-tip
          />
          <Tooltip
            id="example_info"
            info="Each call contains up to 1,000 ALIDs."
          />
        </div>
        <div className="position-relative mb-3">
          <textarea className={styles.example} value={payloadExample()} disabled />
          <Copy
            className={styles.copy}
            onClick={() => {
              navigator.clipboard.writeText(payloadExample());
              showSuccessMessage('The request body was copied to your clipboard');
            }}
          />
        </div>
        <div className={styles.fields}>
          <div className={`${styles.title_input} mb-1`}>
            Additional fields
          </div>
          <div>
            {fields.map((elem, index) => (
              <CustomField
                disableChangeStatus={disableChangeStatus}
                index={index}
                elem={{
                  name: customFields.additional_fields[index].name,
                  type: customFields.additional_fields[index].type,
                  value_type: customFields.additional_fields[index].value_type,
                  value: customFields.additional_fields[index].value,
                }}
                customDatasetFields={customDatasetFields || []}
                customFieldsLoading={isLoading || isFetching}
                register={register}
                onDelete={() => remove(index)}
                key={elem.id}
                errors={errors}
              />
            ))}
          </div>
          <div
            role="presentation"
            className={`
                    d-flex align-items-center gap-2 cursor-pointer
                    ${styles.add_field} ${disableChangeStatus ? styles.blocked : ''}
                    `}
            onClick={() => !disableChangeStatus && append({
              name: '',
              type: '',
              value_type: '',
              value: '',
            })}
          >
            {disableChangeStatus ? <PlusDisabled /> : <Plus />}
            Add field to payload
          </div>
          <div role="presentation">
            <label
              className={`d-flex align-items-cente gap-2 cursor-pointer
              ${styles.exclude_wallet} ${disableChangeStatus ? styles.blocked : ''}`}
              htmlFor="exclude_wallet"
            >
              <input
                type="checkbox"
                id="exclude_wallet"
                defaultChecked={!walletIncluded}
                disabled={disableChangeStatus}
                onChange={() => {
                  setWalletIncluded(!walletIncluded);
                }}
              />
              Exclude wallet(s)
            </label>
          </div>
        </div>
      </div>
      <div className="d-flex gap-4 mt-4">
        <button
          type="button"
          className={`${styles.btn_cancel}`}
          onClick={() => dispatch(clearActiveSettings())}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="regular-button w-100"
          disabled={!watch('url') || status === 'running' || status === 'scheduled'}
        >
          Save
        </button>
      </div>
    </form>
  );
};

export default WebHookNodeSettings;
