import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearActiveSettings,
  selectCurrPage,
  selectFieldSettings,
  setFieldSettings,
} from '../../../../../../store/reducers/forms';
import styles from './NameSettings.module.scss';
import formStyles from '../../FormSettings.module.scss';
import { ReactComponent as Close } from '../../../../../../assets/icons/close.svg';

const NameSettings = ({ activeSettings }) => {
  const dispatch = useDispatch();
  const initialized = useRef(false);
  const currPage = useSelector(selectCurrPage);
  const fieldSettings = useSelector(selectFieldSettings);
  const [label, setLabel] = useState('Name');
  const [nameType, setNameType] = useState('first');
  const [firstPlaceholder, setFirstPlaceholder] = useState('First Name');
  const [lastPlaceholder, setLastPlaceholder] = useState('Last Name');
  const [firstSubLabel, setFirstSubLabel] = useState('');
  const [lastSubLabel, setLastSubLabel] = useState('');
  const [required, setRequired] = useState(true);
  const [change, setChange] = useState(false);

  const handleChange = useCallback(() => {
    setChange(false);
    const obj = {
      name: 'name',
      type: 'name',
      label,
      nameType,
      firstPlaceholder,
      lastPlaceholder,
      firstSubLabel,
      lastSubLabel,
      required,
    };
    dispatch(setFieldSettings({ ...obj, field_id: activeSettings.field_id }));
  }, [
    label, nameType, firstPlaceholder, lastPlaceholder, firstSubLabel, lastSubLabel, required, dispatch, activeSettings,
  ]);

  const setOptions = (config) => {
    setLabel(config.label);
    setNameType(config.nameType);
    setFirstPlaceholder(config.firstPlaceholder);
    setLastPlaceholder(config.lastPlaceholder);
    setFirstSubLabel(config.subLabel);
    setLastSubLabel(config.lastSubLabel);
    setRequired(config.required);
  };

  useEffect(() => {
    if (initialized.current) return;
    const currPageSettings = fieldSettings.find((o) => o.pageName === currPage);
    if (currPageSettings) {
      const config = currPageSettings.fields?.find((x) => x.field_id === activeSettings.field_id);
      if (config) {
        setOptions(config);
      } else {
        handleChange();
      }
    }
    initialized.current = true;
  }, [activeSettings, currPage, fieldSettings]);

  useEffect(() => {
    if (change) {
      handleChange();
    }
  }, [label, nameType, firstPlaceholder, lastPlaceholder, firstSubLabel, lastSubLabel, required]);

  return (
    <div className={`${styles.wrapper} h-100`}>
      <div className="d-flex flex-column justify-content-between h-100">
        <div>
          <div className={`${formStyles.title} d-flex align-items-center gap-2`}>
            Name Properties
          </div>
          <div className={`${styles.close} d-flex justify-content-end position-absolute`}>
            <Close
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => dispatch(clearActiveSettings())}
            />
          </div>
        </div>

        <div className="d-flex flex-column w-100 gap-1 mb-3">
          <div className={`${formStyles.title_input}`}>
            Label
          </div>
          <div className={`${formStyles.text_input}`}>
            <input
              placeholder="Type a label"
              type="text"
              className="w-100"
              value={label}
              onChange={(e) => {
                setLabel(e.currentTarget.value);
                setChange(true);
              }}
            />
          </div>

          <div className={`${formStyles.title_input}`}>
            Type
          </div>
          <div className={`${formStyles.select}`}>
            <div className={`${formStyles.select_icon}`}>
              <select
                value={nameType}
                className="w-100"
                onChange={(e) => {
                  setNameType(e.currentTarget.value);
                  setChange(true);
                }}
              >
                <option value="first">First name only</option>
                <option value="firstAndLast">First name & Last name</option>
              </select>
            </div>
          </div>

          <div className={`${formStyles.title_input}`}>
            First Name Placeholder
          </div>
          <div className={`${formStyles.text_input}`}>
            <input
              placeholder="Type here"
              type="text"
              className="w-100"
              value={firstPlaceholder}
              onChange={(e) => {
                setFirstPlaceholder(e.currentTarget.value);
                setChange(true);
              }}
            />
          </div>
          {nameType === 'firstAndLast' && (
            <>
              <div className={`${formStyles.title_input}`}>
                Last Name Placeholder
              </div>
              <div className={`${formStyles.text_input}`}>
                <input
                  placeholder="Type here"
                  type="text"
                  className="w-100"
                  value={lastPlaceholder}
                  onChange={(e) => {
                    setLastPlaceholder(e.currentTarget.value);
                    setChange(true);
                  }}
                />
              </div>
            </>
          )}

          <div className={`d-flex align-items-center justify-content-between ${formStyles.title_select} w-100`}>
            <div>First Name Sublabel</div>
            { !required && <div className={`${formStyles.optionalText} `}>optional</div> }
          </div>
          <div className={`${formStyles.text_input}`}>
            <input
              placeholder="Type here"
              type="text"
              className="w-100"
              value={firstSubLabel}
              onChange={(e) => {
                setFirstSubLabel(e.currentTarget.value);
                setChange(true);
              }}
            />
          </div>

          <div className={`d-flex align-items-center justify-content-between ${formStyles.title_select} w-100`}>
            <div>Last Name Sublabel</div>
            { !required && <div className={`${formStyles.optionalText} `}>optional</div> }
          </div>
          <div className={`${formStyles.text_input}`}>
            <input
              placeholder="Type here"
              type="text"
              className="w-100"
              value={lastSubLabel}
              onChange={(e) => {
                setLastSubLabel(e.currentTarget.value);
                setChange(true);
              }}
            />
          </div>

          <div className={`${formStyles.checkbox_input}`}>
            <input
              className="form-check-input shadow-none cursor-pointer"
              type="checkbox"
              value=""
              id="flexCheckChecked"
              checked={required}
              onChange={(e) => {
                setRequired(e.currentTarget.checked);
                setChange(true);
              }}
            />
            <div className={`${formStyles.labelText}`}>Required Field</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NameSettings;
