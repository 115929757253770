import React from 'react';
import ReactTooltip from 'react-tooltip';
import styles from './Tooltip.module.scss';

const chooseOffset = ({
  left: {
    left: 100,
  },
  right: {
    left: -100,
  },
  none: {
    left: 0,
  },
});

const Tooltip = ({
  info, id, fixWidth, maxWidth, position = 'none', truncate = false, place = 'top', effect = 'float',
}) => (
  <ReactTooltip
    id={id}
    backgroundColor="white"
    place={place}
    multiline
    effect={effect}
    className={`
      ${styles.tooltip}
      ${position !== 'unset' ? styles[position] : ''}
      ${fixWidth ? info.includes('Running') ? styles.flow_fix : styles.fix : ''}
    `}
    textColor="#90A0B7"
    offset={chooseOffset[position]}
  >
    <div className={truncate ? 'text-truncate' : 'text-break'} style={{ maxWidth: `${maxWidth}px` }}>
      {info}
    </div>
  </ReactTooltip>
);

export default Tooltip;
