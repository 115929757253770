import React, {
  useEffect, useMemo, useState,
} from 'react';
import NoSegments from './NoSegments';
import { audiencesApi } from '../../api/audiences';
import Loader from '../../components/base/Loader';
import SegmentsTable from './SegmentsTable';

const Segments = () => {
  const [field, setField] = useState('created_at');
  const [type, setType] = useState(true);
  const [page, setPage] = useState(1);
  const [itemType, setItemType] = useState('');
  const [search, setSearch] = useState('');

  const [desc, setDesc] = useState(true);
  const [limit, setLimit] = useState(10);

  const {
    data: userSegments,
    isLoading: isUserSegmentsLoading,
    isFetching: isUserSegmentsFetching,
    refetch,
  } = audiencesApi.useGetTeamSegmentsQuery({
    limit, offset: (page - 1) * limit, type: !desc ? 'asc' : 'desc', field, itemType, search,
  });

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    refetch();
  }, [limit, page, type, field, refetch]);

  return useMemo(() => {
    if (isUserSegmentsLoading) {
      return <Loader />;
    }

    if (!userSegments?.total_count) {
      return <NoSegments />;
    }

    if (userSegments?.total_count) {
      return (
        <div className="row asset-section">
          <SegmentsTable
            setPage={setPage}
            page={page}
            data={userSegments.results}
            count={userSegments.count}
            refetch={refetch}
            field={field}
            setField={setField}
            type={type}
            setType={setType}
            setItemType={setItemType}
            desc={desc}
            setDesc={setDesc}
            limit={limit}
            setLimit={setLimit}
            search={search}
            setSearch={setSearch}
            isLoading={isUserSegmentsFetching || isUserSegmentsLoading}
          />
        </div>
      );
    }

    return null;
  }, [
    isUserSegmentsLoading,
    userSegments?.total_count,
    userSegments?.results,
    userSegments?.count,
    page,
    refetch,
    field,
    type,
    desc,
    limit,
  ]);
};

export default Segments;
