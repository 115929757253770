import React from 'react';
import Chart from 'react-apexcharts';
import styles from './SharePortfolio.module.scss';
import TitleComponent from '../../base/TitleComponent';
import { convertToReadableFormat } from '../../../tools/NumberConverterTool';
import Loader from '../../base/Loader';
import NoData from '../../base/NoData';

const SharePortfolio = ({ data, isFetching, isLoading }) => {
  const RadialBarData = {
    options: {
      chart: {
        type: 'radialBar',
      },
      stroke: {
        width: 2,
        lineCap: 'round',
      },
      states: {
        normal: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
        hover: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: 'none',
            value: 0,
          },
        },
      },
      tooltip: {
        enabled: true,
        custom: (y) => (
          // eslint-disable-next-line max-len
          '<div style="background: rgba(0, 143, 251, 0.85); padding: 6px 8px; font-size: 12px; color: #FFFFFF; font-family: Poppins, sans-serif; font-weight: 600;">'
            + `${y.series[0]}%`
            + '</div>'
        ),
        marker: {
          show: false,
        },
        x: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        radialBar: {
          offsetY: -20,
          hollow: {
            margin: 15,
            size: '60%',
          },
          startAngle: -135,
          endAngle: 135,
          track: {
            strokeWidth: '100%',
            background: '#C2CFE0',
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              show: false,
            },
          },
        },
      },
    },
  };

  return (
    <div className={`${styles.wrapper} d-flex flex-column h-100`}>
      <TitleComponent
        info="How big is this investment in the portfolio of its investors"
        place="left"
      >
        Share of Portfolio
      </TitleComponent>
      {isLoading || isFetching
        ? (
          <div className="p-5">
            <Loader />
          </div>
        )
        : data?.share_portfolio && data.share_portfolio <= 100 ? (
          <div className="d-flex flex-column justify-content-end">
            <div className={`${styles.chart} position-relative mt-1`}>
              <div>
                <Chart
                  options={RadialBarData.options}
                  series={[Math.round(data.share_portfolio)]}
                  type="radialBar"
                  height={220}
                />
                <div
                  className={`${styles.percentage} d-flex gap-5 justify-content-center mt-2 position-absolute w-100`}
                >
                  <span className={styles.info}>0%</span>
                  <span className={styles.info}>100%</span>
                </div>
              </div>
              <div
                className={`${styles.chart} ${styles.percent_info_circle} position-absolute ${styles.centre}`}
              />
              <div
                className={`${styles.hand} position-absolute`}
                style={{ transform: `rotate(${(-45) + (data.share_portfolio * 2.7)}deg)` }}
              />
            </div>
            <div className="d-flex gap-2 align-items-center mt-1 mb-3">
              <span className={styles.info}>Median portfolio value</span>
              <span className={styles.count}>
                {data.median_portfolio_value ? `$${convertToReadableFormat(data.median_portfolio_value)}` : '?'}
              </span>
            </div>
          </div>
        ) : (
          <NoData />
        )}
    </div>
  );
};

export default SharePortfolio;
