import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  clearActiveSettings,
  selectCurrPage,
  selectFieldSettings,
  setFieldSettings,
} from '../../../../../../store/reducers/forms';
import styles from './ConnectWalletButtonSettings.module.scss';
import formStyles from '../../FormSettings.module.scss';
import { ReactComponent as Close } from '../../../../../../assets/icons/close.svg';
import { ReactComponent as AlignButtonLeft } from '../../../../../../assets/icons/forms/align_button_left.svg';
import { ReactComponent as AlignButtonCenter } from '../../../../../../assets/icons/forms/align_button_center.svg';
import { ReactComponent as AlignButtonRight } from '../../../../../../assets/icons/forms/align_button_right.svg';
import { ReactComponent as AlignButtonFull } from '../../../../../../assets/icons/forms/align_button_full.svg';
import { ReactComponent as Info } from '../../../../../../assets/icons/info_small.svg';

const styleOptions = ['filled', 'outlined'];
const textAlignmentOptions = ['left', 'center', 'full', 'right'];
const buttonAlignementIcons = {
  left: <AlignButtonLeft />,
  center: <AlignButtonCenter />,
  full: <AlignButtonFull />,
  right: <AlignButtonRight />,
};

const ConnectWalletButtonSettings = ({ activeSettings }) => {
  const dispatch = useDispatch();
  const currPage = useSelector(selectCurrPage);
  const fieldSettings = useSelector(selectFieldSettings);
  const [label, setLabel] = useState('');
  const [subLabel, setSubLabel] = useState('');
  const [requiresSignature, setRequiresSignature] = useState(false);
  const [signatureMessage, setSignatureMessage] = useState('');
  const [requiredField, setRequiredField] = useState(false);
  const [buttonStyle, setButtonStyle] = useState(styleOptions[0]);
  const [textAlignment, setTextAlignment] = useState(textAlignmentOptions[1]);
  const [hovered, setHovered] = useState(false);

  const handleChange = useCallback(async () => {
    const obj = {
      name: 'connectWallet',
      label,
      subLabel,
      type: 'connectWallet',
      buttonStyle,
      textAlignment,
      requiredField,
      requiresSignature,
      signatureMessage,
    };
    dispatch(setFieldSettings({ ...obj, field_id: activeSettings.field_id }));
  }, [
    label,
    subLabel,
    buttonStyle,
    textAlignment,
    requiredField,
    requiresSignature,
    signatureMessage,
    dispatch,
    activeSettings,
  ]);

  useEffect(() => {
    const currPageSettings = fieldSettings.find((o) => o.pageName === currPage);
    if (currPageSettings?.fields?.length && label === '') {
      const config = currPageSettings.fields?.find((x) => x.field_id === activeSettings.field_id);
      if (config) {
        setLabel(config.label);
        setSubLabel(config.subLabel);
        setButtonStyle(config.buttonStyle);
        setTextAlignment(config.textAlignment);
        setRequiredField(config.requiredField);
        setRequiresSignature(config.requiresSignature);
        setSignatureMessage(config.signatureMessage);
      } else {
        setLabel('Connect Wallet');
      }
    }
  }, [activeSettings, currPage, fieldSettings, dispatch, label]);

  useEffect(() => {
    handleChange();
  }, [
    label,
    subLabel,
    buttonStyle,
    textAlignment,
    requiredField,
    requiresSignature,
    signatureMessage,
  ]);

  return (
    <div className={`${styles.wrapper} h-100`}>
      <div
        className="d-flex flex-column justify-content-between h-100"
      >
        <div>
          <div className={`${styles.title} d-flex align-items-center gap-2`}>
            Wallet Properties
          </div>
          <div className={`${styles.close} d-flex justify-content-end position-absolute`}>
            <Close
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => dispatch(clearActiveSettings())}
            />
          </div>
        </div>
        <div className={formStyles.title_select}>
          Style
        </div>
        <div className="d-flex flex-row w-100 gap-3 mb-3">
          <div className="d-flex flex-column w-50 gap-1">
            <div className={styles.buttonGroup}>
              {
                styleOptions.map((option) => (
                // eslint-disable-next-line jsx-a11y/control-has-associated-label
                  <button
                    type="button"
                    className={`${buttonStyle === option
                      ? `regular-button ${styles.small_padding}`
                      : `outline-button ${styles.small_padding}`} ${styles.singleButton}`}
                    onClick={() => {
                      setButtonStyle(option);
                    }}
                  >
                    {option}
                  </button>
                ))
              }
            </div>
          </div>
          <div className="d-flex flex-column w-50 gap-1">
            <div className={styles.buttonGroup}>
              {
                textAlignmentOptions.map((option) => (
                // eslint-disable-next-line jsx-a11y/control-has-associated-label
                  <button
                    type="button"
                    className={`${textAlignment === option
                      ? `regular-button ${styles.small_padding_selected}`
                      : `outline-button ${styles.small_padding}`} ${styles.singleButton}`}
                    onClick={() => {
                      setTextAlignment(option);
                    }}
                  >
                    {buttonAlignementIcons[option]}
                  </button>
                ))
              }
            </div>
          </div>
        </div>

        <div className="d-flex flex-column w-100 gap-1 mb-3">
          <div className={formStyles.title_select}>
            Label
          </div>
          <div className={`${formStyles.text_input}`}>
            <input
              placeholder="Type a value"
              type="text"
              className="w-100"
              value={label}
              onChange={(e) => {
                setLabel(e.currentTarget.value);
              }}
            />
          </div>
        </div>

        <div className="d-flex flex-column w-100 gap-1 mb-3">
          <div className={`d-flex align-items-center justify-content-between ${formStyles.title_select} w-100`}>
            <div>Sublabel</div>
            { !requiredField && <div className={`${formStyles.optionalText} `}>optional</div> }
          </div>
          <div className={formStyles.text_input}>
            <input
              placeholder="Type a value"
              type="text"
              className="w-100 p-2"
              value={subLabel}
              onChange={(e) => {
                setSubLabel(e.currentTarget.value);
              }}
            />
          </div>
        </div>

        <div className="d-flex flex-row w-100 gap-1 mb-3 align-items-center">
          <label className={`form-check-label ${formStyles.checkbox_input}`} htmlFor="flexCheckChecked">
            <input
              className="form-check-input shadow-none cursor-pointer"
              type="checkbox"
              value=""
              id="flexCheckChecked"
              checked={requiresSignature}
              onChange={() => setRequiresSignature(!requiresSignature)}
            />
          </label>
          <div className={`${formStyles.labelText}`}>Signature required</div>
          <div
            style={{
              position: 'relative',
              cursor: 'pointer',
            }}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
          >
            <Info />
            {hovered && (
              <div className={`${styles.info}`}>
                Require users to sign a message from their wallet to ensure they really own it
              </div>
            )}
          </div>
        </div>

        {
          requiresSignature && (
            <div className="d-flex flex-column w-100 gap-1 mb-3">
              <div className={styles.title_input}>
                Signature message
              </div>
              <div className={`${formStyles.text_input}`}>
                <textarea
                  className="w-100 p-2"
                  rows="5"
                  placeholder="Enter text"
                  value={signatureMessage}
                  onChange={(e) => {
                    setSignatureMessage(e.currentTarget.value);
                  }}
                />
              </div>
            </div>
          )
        }

        <div className="d-flex flex-row w-100 gap-1 mb-3 align-items-center">
          <label className={`form-check-label ${formStyles.checkbox_input}`} htmlFor="flexCheckChecked2">
            <input
              className="form-check-input shadow-none cursor-pointer"
              type="checkbox"
              value=""
              id="flexCheckChecked2"
              checked={requiredField}
              onChange={() => setRequiredField(!requiredField)}
            />
          </label>
          <div className={`${formStyles.labelText}`}>Required Field</div>
        </div>

      </div>
    </div>
  );
};

export default ConnectWalletButtonSettings;
