import React from 'react';
import { coinApi } from '../../../api/coin';
import TradersAndInvestComponent from '../../../components/ui/TradersAndInvestComponent';
import styles from './InvestorProfile.module.scss';
import NotableInvestments from '../../../components/ui/NotableInvestments';
import SharedInterests from '../../../components/ui/SharedInterests';

const InvestorProfile = ({
  address, holders, symbol, pctHeldOver30days, network,
}) => {
  const {
    currentData: dataFor1,
    isLoading: isLoading1,
    isFetching: isFetching7,
  } = coinApi.useGetNftTradersInfoQuery({ address, chain: network, duration: 1 });
  const {
    currentData: nftActiveAddresses,
    isLoading: isNftActiveAddressesLoading,
    isFetching: isNftActiveAddressesFetching,
  } = coinApi.useGetNftActiveAddressesQuery({ address, network });

  const {
    currentData: notableInvestments,
    isLoading: isNotableInvestmentsLoading,
    isFetching: isNotableInvestmentsFetching,
  } = coinApi.useGetNftNotableInvestmentsQuery({ address, network });

  const {
    currentData: sharedInterestsData,
    isLoading: sharedInterestsIsLoading,
    isFetching: sharedInterestsIsFetching,
  } = coinApi.useGetNftGroupInterestQuery({ address, blockchain: network || 'ethereum' });

  return (
    <div className={styles.wrapper}>
      <div className="row">
        <div className="col-12 col-lg-6 col-xl-4">
          <TradersAndInvestComponent
            holders={holders}
            data={dataFor1}
            isLoading={isLoading1}
            isFetching={isFetching7}
            title="Avg Risk Profile"
            info="Risk profile of investors in this collection, measured by the type of investments made"
          />
        </div>
        <div className="col-12 col-lg-6 col-xl-4 mt-4 mt-lg-0">
          <TradersAndInvestComponent
            pctHeldOver30days={pctHeldOver30days}
            holders={holders}
            data={nftActiveAddresses}
            isLoading={isNftActiveAddressesLoading}
            isFetching={isNftActiveAddressesFetching}
            title={symbol ? `Invest. in $${symbol}` : 'Invest. in'}
            info="Typical duration of investment in the collection"
          />
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12 col-lg-6 col-xl-4">
          <NotableInvestments
            text="tokens"
            symbol={symbol}
            isNotableInvestmentsLoading={isNotableInvestmentsLoading}
            isNotableInvestmentsFetching={isNotableInvestmentsFetching}
            data={notableInvestments?.tokens}
            holders={holders}
            info="Tokens most commonly held by holders of this collection"
          />
        </div>
        <div className="col-12 col-lg-6 col-xl-4 mt-4 mt-lg-0">
          <NotableInvestments
            text="NFTs"
            symbol={symbol}
            isNotableInvestmentsLoading={isNotableInvestmentsLoading}
            isNotableInvestmentsFetching={isNotableInvestmentsFetching}
            data={notableInvestments?.nfts}
            holders={holders}
            info="NFTs most commonly held by holders of this collection"
          />
        </div>
        <div className="col-12 col-lg-6 col-xl-4 mt-4 mt-xl-0">
          <SharedInterests
            symbol={symbol}
            data={sharedInterestsData}
            isLoading={sharedInterestsIsLoading}
            isFetching={sharedInterestsIsFetching}
            info="Topics of interest for holders of this collection"
          />
        </div>
      </div>
    </div>
  );
};

export default InvestorProfile;
