import React from 'react';
import styles from './DropDownSearchFilter.module.scss';

const DropDownSearchFilter = ({
  setPage, setCheckedSegment, checkedSegment, checkedList, setCheckedList,
}) => (
  <div className="d-flex flex-column p-1 align-items-start">
    <div
      role="presentation"
      className={styles.filterOption}
      onClick={() => {
        setCheckedList(!checkedList);
        setPage(1);
      }}
    >
      <div>Segment</div>
    </div>
    <div
      role="presentation"
      className={styles.filterOption}
      onClick={() => {
        setCheckedSegment(!checkedSegment);
        setPage(1);
      }}
    >
      <div>Custom List</div>
    </div>
  </div>
);

export default DropDownSearchFilter;
