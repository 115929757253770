import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearActiveSettings,
  selectCurrPage,
  selectFieldSettings,
  setFieldSettings,
  setImageFile,
} from '../../../../../../store/reducers/forms';
import styles from './ImageSettings.module.scss';
import formStyles from '../../FormSettings.module.scss';
import { ReactComponent as Close } from '../../../../../../assets/icons/close.svg';
import { ReactComponent as Upload } from '../../../../../../assets/icons/forms/upload_image.svg';
import { ReactComponent as Trash } from '../../../../../../assets/icons/forms/trash.svg';
import { ReactComponent as AlignLeft } from '../../../../../../assets/icons/forms/align_button_left.svg';
import { ReactComponent as AlignCenter } from '../../../../../../assets/icons/forms/align_button_center.svg';
import { ReactComponent as AlignRight } from '../../../../../../assets/icons/forms/align_button_right.svg';
import { formsApi } from '../../../../../../api/forms';
import RoundSpinner from '../../../../../../components/base/RoundSpinner';

const imageAlignmentOptions = ['left', 'center', 'right'];
const imageAlignmentIcons = {
  left: <AlignLeft />,
  center: <AlignCenter />,
  right: <AlignRight />,
};

const ImageFieldSettings = ({ activeSettings }) => {
  const dispatch = useDispatch();
  const initialized = useRef(false);
  const currPage = useSelector(selectCurrPage);
  const fieldSettings = useSelector(selectFieldSettings);
  const [url, setUrl] = useState('');
  const [imageAlign, setImageAlign] = useState(imageAlignmentOptions[1]);
  const [caption, setImageCaption] = React.useState('');
  const [imageFit, setImageFit] = React.useState('fit');
  const [fileName, setFileName] = useState('');
  const [change, setChange] = useState(false);
  const [height, setHeight] = useState(300);
  const [width, setWidth] = useState(100);

  const [uploadImage, uploadResult] = formsApi.useUploadImageMutation();

  // use Effect to see imageFit Value
  useEffect(() => {
  }, [imageFit]);

  const handleFileUpload = async (event) => {
    setChange(true);
    const file = event.target.files[0];

    if (file && (file.type === 'image/jpeg' || file.type === 'image/png')) {
      const localImageUrl = URL.createObjectURL(file);
      setUrl(localImageUrl);
      setFileName(file.name);
      dispatch(setImageFile({ fieldId: activeSettings.field_id, file }));

      // Prepare the formData to send to the server
      const formData = new FormData();
      formData.append('form_image', file);

      try {
        // Upload the image using the mutation hook
        await uploadImage(formData);
      } catch (error) {
        console.error('Failed to upload the image:', error);
      }
    }
  };

  useEffect(() => {
    if (uploadResult.isSuccess && uploadResult.data?.form_image) {
      setUrl(uploadResult.data.form_image);
    } else if (uploadResult.isError) {
      console.error('Error occurred during image upload:', uploadResult.error);
    }
  }, [uploadResult]);

  const shortenFileName = (name) => {
    if (name.length <= 4) return name;
    return `${name.substr(0, 3)}...${name.slice(-5, -4)}`;
  };

  const removeImage = () => {
    setUrl('');
    setFileName('');
  };

  const handleChange = useCallback(async () => {
    const obj = {
      name: 'image',
      url,
      type: 'image',
      imageFit,
      imageAlign,
      height,
      width,
      caption,
    };
    dispatch(setFieldSettings({ ...obj, field_id: activeSettings.field_id }));
  }, [
    url,
    imageFit,
    imageAlign,
    height,
    width,
    caption,
    dispatch,
    activeSettings,
  ]);

  const setOptions = (config) => {
    setUrl(config.url);
    setImageFit(config.imageFit);
    setImageAlign(config.imageAlign);
    setImageCaption(config.caption);
    setHeight(config.height);
    setWidth(config.width);
  };

  useEffect(() => {
    if (initialized.current) return;
    const currPageSettings = fieldSettings.find((o) => o.pageName === currPage);
    if (currPageSettings) {
      const config = currPageSettings.fields?.find((x) => x.field_id === activeSettings.field_id);
      if (config) {
        setOptions(config);
      } else {
        setUrl('https://picsum.photos/200/300');
        handleChange();
      }
    }
    initialized.current = true;
  }, [activeSettings, currPage, fieldSettings, dispatch]);

  useEffect(() => {
    if (change) {
      handleChange();
    }
  }, [url, imageFit, imageAlign, width, height, caption]);

  return (
    <div className={`${styles.wrapper} h-100`}>
      <div className="d-flex flex-column justify-content-between h-100">
        <div>
          <div className={`${formStyles.title} d-flex align-items-center gap-2`}>
            Image Properties
          </div>
          <div className={`${styles.close} d-flex justify-content-end position-absolute`}>
            <Close
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => dispatch(clearActiveSettings())}
            />
          </div>
        </div>

        <div className="d-flex flex-column w-100 gap-1 mb-3">
          <div className={`${formStyles.title_input}`}>
            Style
          </div>
          <div className="d-flex justify-content-between gap-2">

            <div className="w-50">
              <div className="d-flex align-items-center justify-content-center border rounded p-2">
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleFileUpload}
                  className="d-none"
                  id="formImageUpload"
                />
                {uploadResult.isLoading ? (
                  <RoundSpinner />
                ) : !url ? (
                  <label htmlFor="formImageUpload" className={styles.fullWidthButton}>
                    <span className="mr-3">Upload</span>
                    <div className="m-2" />
                    <Upload className="ml-auto" />
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleFileUpload}
                      className="d-none"
                      id="formImageUpload"
                    />
                  </label>
                ) : (
                  <div className="d-flex align-items-center gap-2">
                    <img src={url} alt="Preview" className={`${styles.imagePreview}`} />
                    <span className="mx-2">{shortenFileName(fileName)}</span>
                    <Trash onClick={removeImage} className="trash-icon ml-2" />
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <label htmlFor="formImageUpload" className="ml-">
                      <Upload />
                    </label>
                  </div>
                )}
              </div>
            </div>

            <div className="w-50">
              <div className={`${formStyles.select}`}>
                <div className={`${formStyles.select_icon}`}>
                  <select
                    className=""
                    value={imageFit}
                    onChange={(e) => {
                      setImageFit(e.target.value);
                      setChange(true);
                    }}
                    aria-label="Image Fit"
                  >
                    <option value="fill">Fill</option>
                    <option value="scale-down">Fit</option>
                    <option value="cover">Crop</option>
                    <option value="tile">Tile</option>
                  </select>
                </div>
              </div>
            </div>

          </div>

          <div className="d-flex justify-content-between w-100 gap-3">

            <div className="d-flex flex-column w-33 gap-1">
              <div className={formStyles.title_select}>
                Height
              </div>
              <div className={`${formStyles.text_input} w-33`}>
                <input
                  type="number"
                  value={height}
                  onChange={(e) => {
                    setHeight(Number(e.target.value));
                    setChange(true);
                  }}
                  placeholder="Height (in px)"
                />
              </div>
            </div>

            <div className="d-flex flex-column w-33 gap-1">
              <div className={formStyles.title_select}>
                Width
              </div>
              <div className={`${formStyles.text_input} w-33`}>
                <input
                  type="number"
                  min="1"
                  max="100"
                  value={width}
                  onChange={(e) => {
                    setWidth(Number(e.target.value));
                    setChange(true);
                  }}
                  placeholder="Width (in %)"
                />
                %
              </div>
            </div>

            <div className="d-flex flex-column w-50 gap-1">
              <div className={formStyles.title_select}>
                Align
              </div>
              <div className={`${styles.buttonGroup}`}>
                {
                  imageAlignmentOptions.map((option) => (
                    <button
                      type="button"
                      className={`${imageAlign === option
                        ? `regular-button ${styles.small_padding_selected}`
                        : `outline-button ${styles.small_padding}`} ${styles.singleButton}`}
                      onClick={() => {
                        setImageAlign(option);
                        setChange(true);
                      }}
                    >
                      {imageAlignmentIcons[option]}
                    </button>
                  ))
                }
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default ImageFieldSettings;
