import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { webSDKApi } from '../../../api/webSDK';
import UserTabContainer from '../components/UserTabContainer';
import { selectWebSDKFilterParam, selectWebSDKFilterContents } from '../../../store/reducers/webSDK';
import UserTabHeatMap from './UserTabHeatMap';
import uniqueId from '../../../utils/uniqueId';
import styles from './UsersTab.module.scss';

const UsersTab = () => {
  const { id } = useParams();
  const filterParams = useSelector(selectWebSDKFilterParam);
  const filterContents = useSelector(selectWebSDKFilterContents);

  const {
    data: userTabData,
    isLoading: isLoadingUserTab,
    isFetching: isFetchingUserTab,
  } = webSDKApi.useGetWebSDKForUsersTabQuery(
    {
      id,
      ...filterParams,
    },
  );

  const usersTabContent = useMemo(() => [{
    data: userTabData?.wallet_detection || [],
    title: 'Web3 Adoption',
    info: 'Score indicating the weighted Web3 adoption among visitors',
  }, {
    data: userTabData?.wallets || [],
    title: 'Wallet Types',
  }, {
    data: userTabData?.chains || [],
    title: 'Chains',
    info: "Blockchain that the visitor's wallet was connected to",
  }, {
    data: userTabData?.languages || [],
    title: 'Languages',
  }], [userTabData?.chains, userTabData?.languages, userTabData?.wallet_detection, userTabData?.wallets]);

  return (
    <div className={styles.wrapper}>
      <div className="d-flex w-100 justify-content-between mb-4">
        {usersTabContent.map((elem) => (
          <UserTabContainer
            key={uniqueId('users_constant')}
            title={elem.title}
            isLoading={isLoadingUserTab || isFetchingUserTab || !filterContents
              || filterContents?.isLoadingFilteredStats
              || filterContents?.isFetchingFilteredStats}
            data={elem.data}
            total={elem.title === 'Chains' || elem.title === 'Wallet Types'
              ? filterContents?.filteredStats?.visitors_with_wallets : elem.title === 'Web3 Adoption'
                ? filterContents?.filteredStats?.can_have_wallet : filterContents?.filteredStats?.visitors}
            info={elem.info}
          />
        ))}
      </div>
      <div className="d-flex w-100 justify-content-between">
        <UserTabHeatMap
          countriesData={userTabData?.countries || []}
          isLoading={isLoadingUserTab || isFetchingUserTab || !filterContents
            || filterContents?.isLoadingFilteredStats
            || filterContents?.isFetchingFilteredStats}
          maxValue={userTabData?.countries
            ? Math.max(...userTabData.countries.map((elem) => elem.visitor_count)) : null}
          total={filterContents?.filteredStats?.visitors}
        />
        <UserTabContainer
          title="Countries"
          isLoading={isLoadingUserTab || isFetchingUserTab || !filterContents
            || filterContents?.isLoadingFilteredStats
            || filterContents?.isFetchingFilteredStats}
          data={userTabData?.countries.slice(0, 8) || []}
          total={filterContents?.filteredStats?.visitors}
        />
      </div>
    </div>
  );
};

export default UsersTab;
