import React from 'react';
import { converterData, convertToReadableFormat } from '../../../../tools/NumberConverterTool';
import styles from '../../../../components/ui/Market/Widget/Widget.module.scss';
import { ReactComponent as InfoSmall } from '../../../../assets/icons/info_small.svg';
import Tooltip from '../../../../components/ui/Tooltip';

const Widget = ({ item }) => (
  <div className={`${styles.widget_wrapper} d-flex align-items-center position-relative`}>
    <div className={`${styles.widget_logo} d-flex align-items-center justify-content-center p-1`}>
      <img src={item.icon} alt="logo" width="32" height="32" />
    </div>
    {item.info
      ? (
        <>
          <div
            className={`position-absolute cursor-pointer ${styles.info_btn}`}
            data-for={`tooltipId${item.id}`}
            data-tip
          >
            <InfoSmall />
          </div>
          <Tooltip info={item.info} id={`tooltipId${item.id}`} position="left" />
        </>
      )
      : null}
    <div className="d-flex flex-column justify-content-between">
      <span className="card-info-widget">{item.name}</span>
      {item.id === 1 && (
        <span className={styles.card_info_bold_widget}>
          $
          {convertToReadableFormat(item.number)}
        </span>
      )}
      {item.id === 2 && (
        <span className={styles.card_info_bold_widget}>
          {convertToReadableFormat(item.number)}
        </span>
      )}
      {item.id === 3 && (
        <span className={styles.card_info_bold_widget}>
          {item.number ? converterData(item.number) : ''}
        </span>
      )}
      {item.id > 3 && (
        <span className={styles.card_info_bold_widget}>{item.number}</span>
      )}
    </div>
  </div>
);

export default Widget;
