import { ethers } from 'ethers';
import { erc20AirdropAbi } from './erc20AirdropAbi';
import { erc721AirdropAbi } from './erc721AirdropAbi';
import { erc1155AirdropAbi } from './erc1155AirdropAbi';
import { erc20Abi } from './erc20abi';

const GOERLI_ADDRESS = import.meta.env.VITE_APP_AIRDROP_ERC20_GOERLI_CONTRACT_ADDRESS;
const ETH_ADDRESS = import.meta.env.VITE_APP_AIRDROP_ERC20_ETHEREUM_CONTRACT_ADDRESS;
const POLYGON_ADDRESS = import.meta.env.VITE_APP_AIRDROP_ERC20_POLYGON_CONTRACT_ADDRESS;
const MUMBAI_ADDRESS = import.meta.env.VITE_APP_AIRDROP_ERC20_MUMBAI_CONTRACT_ADDRESS;

const ERC721_GOERLI_ADDRESS = import.meta.env.VITE_APP_AIRDROP_ERC721_GOERLI_CONTRACT_ADDRESS;
const ERC721_ETH_ADDRESS = import.meta.env.VITE_APP_AIRDROP_ERC721_ETHEREUM_CONTRACT_ADDRESS;
const ERC721_POLYGON_ADDRESS = import.meta.env.VITE_APP_AIRDROP_ERC721_POLYGON_CONTRACT_ADDRESS;
const ERC721_MUMBAI_ADDRESS = import.meta.env.VITE_APP_AIRDROP_ERC721_MUMBAI_CONTRACT_ADDRESS;

const ERC1155_GOERLI_ADDRESS = import.meta.env.VITE_APP_AIRDROP_ERC1155_GOERLI_CONTRACT_ADDRESS;
const ERC1155_ETH_ADDRESS = import.meta.env.VITE_APP_AIRDROP_ERC1155_ETHEREUM_CONTRACT_ADDRESS;
const ERC1155_POLYGON_ADDRESS = import.meta.env.VITE_APP_AIRDROP_ERC1155_POLYGON_CONTRACT_ADDRESS;
const ERC1155_MUMBAI_ADDRESS = import.meta.env.VITE_APP_AIRDROP_ERC1155_MUMBAI_CONTRACT_ADDRESS;

export const getAirdropContractAddress = (chain) => {
  let address;
  // Load Airdrop contract
  switch (chain) {
    case 'goerli': address = GOERLI_ADDRESS; break;
    case 'polygon': address = POLYGON_ADDRESS; break;
    case 'mumbai': address = MUMBAI_ADDRESS; break;
    default: address = ETH_ADDRESS;
  }

  return address;
};

export const getERC721AirdropContractAddress = (chain) => {
  let address;
  // Load Airdrop contract
  switch (chain) {
    case 'goerli': address = ERC721_GOERLI_ADDRESS; break;
    case 'polygon': address = ERC721_POLYGON_ADDRESS; break;
    case 'mumbai': address = ERC721_MUMBAI_ADDRESS; break;
    default: address = ERC721_ETH_ADDRESS;
  }

  return address;
};

export const loadERC20Contract = (signer, erc20Address, chainId) => {
  try {
    let contract;
    if (erc20Address) {
      // Load standard ERC20 token
      contract = new ethers.Contract(erc20Address, erc20Abi, signer);
    } else {
      // Load Airdrop contract
      switch (chainId) {
        case 5: contract = new ethers.Contract(GOERLI_ADDRESS, erc20AirdropAbi, signer); break;
        case 137: contract = new ethers.Contract(POLYGON_ADDRESS, erc20AirdropAbi, signer); break;
        case 80001: contract = new ethers.Contract(MUMBAI_ADDRESS, erc20AirdropAbi, signer); break;
        default: contract = new ethers.Contract(ETH_ADDRESS, erc20AirdropAbi, signer);
      }
    }
    return contract;
  } catch (err) {
    return Promise.reject(
      new Error('There was an error connecting to contract!'),
    );
  }
};

export const loadNFTAidropContract = (signer, isERC1155, chainId) => {
  try {
    let contract;
    if (!isERC1155) {
      // Load ERC721 Airdrop contract
      switch (chainId) {
        case 5: contract = new ethers.Contract(ERC721_GOERLI_ADDRESS, erc721AirdropAbi, signer); break;
        case 137: contract = new ethers.Contract(ERC721_POLYGON_ADDRESS, erc721AirdropAbi, signer); break;
        case 80001: contract = new ethers.Contract(ERC721_MUMBAI_ADDRESS, erc721AirdropAbi, signer); break;
        default: contract = new ethers.Contract(ERC721_ETH_ADDRESS, erc721AirdropAbi, signer);
      }
    } else {
      // Load ERC1155 Airdrop contract
      switch (chainId) {
        case 5: contract = new ethers.Contract(ERC1155_GOERLI_ADDRESS, erc1155AirdropAbi, signer); break;
        case 137: contract = new ethers.Contract(ERC1155_POLYGON_ADDRESS, erc1155AirdropAbi, signer); break;
        case 80001: contract = new ethers.Contract(ERC1155_MUMBAI_ADDRESS, erc1155AirdropAbi, signer); break;
        default: contract = new ethers.Contract(ERC1155_ETH_ADDRESS, erc1155AirdropAbi, signer);
      }
    }
    return contract;
  } catch (err) {
    return Promise.reject(
      new Error('There was an error connecting to contract!'),
    );
  }
};
