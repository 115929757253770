import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import styles from '../Header.module.scss';
import { getUser } from '../../../../store/reducers/user';

const getPages = (user) => {
  const pages = [
    { label: 'Insights', val: 'dashboard' },
    { label: 'Audiences' },
    { label: 'Flows' },
  ];
  if (user.forms) {
    pages.push({ label: 'Forms' });
  }
  return pages;
};
const audiencesRoutes = [
  'audiences',
  'segment',
  'add-segment',
  'custom-list',
  'add-custom-list',
  'wallet',
  'csv-upload',
];
const flowsRoutes = ['flows', 'add-flow'];
const formsRoutes = ['forms', 'add-form', 'form-analytics'];

const Navigation = () => {
  const { pathname } = useLocation();
  const user = useSelector(getUser);

  const pages = getPages(user);

  const handleActiveLink = () => {
    if (audiencesRoutes.includes(pathname.split('/')[1])) return 1;
    if (flowsRoutes.includes(pathname.split('/')[1])) return 2;
    if (formsRoutes.includes(pathname.split('/')[1]) && user.forms) {
      return 3;
    }
    return 0;
  };

  const handlePageTitle = () => {
    const urlEnd = pathname.split('/')[1];
    if (audiencesRoutes.includes(urlEnd)) return 'Audiences';
    if (flowsRoutes.includes(urlEnd)) return 'Flows';
    if (formsRoutes.includes(urlEnd) && user.forms) return 'Forms';
    if (['dashboard'].includes(urlEnd)) return 'Dashboard';
    return 'Insights';
  };

  // TODO: hidden navigation elements - tools and connect, uncomment when need
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {handlePageTitle()}
          {' '}
          - Absolute Labs Platform
        </title>
      </Helmet>
      <nav className="navbar navbar-expand-lg gap-3 pb-0">
        <ul className="nav nav-pills d-flex gap-2">
          {pages.map((elem, index) => (
            <li className="nav-item" key={[index]}>
              <Link to={elem.val || elem.label.toLowerCase()}>
                <button
                  className={`nav-link ${index === handleActiveLink() ? styles.active : ''}`}
                  aria-current="page"
                  type="button"
                >
                  {elem.label}
                </button>
              </Link>
            </li>
          ))}
        </ul>
        {/* {size.wight > 768 && ( */}
        {/*  <> */}
        {/*    <div className={styles.separator} /> */}
        {/*    <ul className="nav nav-pills d-flex gap-2"> */}
        {/*      {pagesNext.map((elem, index) => ( */}
        {/*        <li className="nav-item" key={[index]}> */}
        {/*          <Link className="nav-link" aria-current="page" to="/#"> */}
        {/*            {elem.label} */}
        {/*          </Link> */}
        {/*        </li> */}
        {/*      ))} */}
        {/*    </ul> */}
        {/*  </> */}
        {/* )} */}
      </nav>
    </>
  );
};

export default Navigation;
