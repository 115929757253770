import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Settings from '../../../assets/images/dashboard/settings.png';
import ArrowUp from '../../../assets/images/arrow-up.png';
import ArrowDown from '../../../assets/images/arrow-down.png';
import {
  convertNumber,
  convertToReadableFormat,
} from '../../../tools/NumberConverterTool';
import Trash from '../../../assets/images/trash.png';
import Plus from '../../../assets/images/plus.png';
import DropdownMenu from '../../base/DropdownMenu';
import './Card.scss';
import styles from './Card.module.scss';
import { formatAssetData } from '../../../utils/assetsData/formatAssetData';
import { ethFormat, formattedPlatforms } from '../../../utils/singleAssetPage/parseData';
import { setSelectedAsset } from '../../../store/reducers/app';
import { LoadingAvatar, LoadingLines } from '../modals/SearchModal/LoadingList';
import { tooltipChainIconPicker } from '../../../tools/ChainIconPicker';
import uniqueId from '../../../utils/uniqueId';
import DefaultIcon from '../DefaultIcon';
import ItemPreview from '../ItemPreview';
import Spinner from '../../base/Spinner';
import { webSDKApi } from '../../../api/webSDK';
import { showErrorMessage, showSuccessMessage } from '../../base/Notifications';
import { deleteTrackedWebsiteFromDashboard } from '../../../store/reducers/coin';

const Card = ({
  cardData, flag = 'asset', actionOnAsset, isLoading,
}) => {
  const dispatch = useDispatch();
  const [cardLogoError, setCardLogoError] = useState(false);

  const [deleteTrackedWebsite, deletedResult] = webSDKApi.useDeleteWebSDKMutation();

  const menuData = [
    {
      icon: Plus,
      name: flag === 'asset' ? 'Move to Watchlist' : 'Move to My Assets',
      action: () => {
        actionOnAsset(
          formatAssetData(
            'move',
            flag,
            cardData.asset_type,
            cardData.coin_id || cardData.address || cardData.id,
            cardData.network,
          ),
        );
      },
    },
    {
      icon: Trash,
      name: 'Delete',
      action: () => {
        actionOnAsset(
          formatAssetData(
            'delete',
            flag,
            cardData.asset_type,
            cardData.coin_id || cardData.address || cardData.id,
            cardData.network,
          ),
        );
      },
    },
  ];

  const websiteAction = [{
    icon: Trash,
    name: 'Delete',
    id: cardData.id,
    action: (id) => deleteTrackedWebsite(id),
  }];

  const addAssetIdToUrl = (type, data) => {
    switch (type) {
      case 'brand':
        return data.slug;
      case 'coin':
        return data.coin_id;
      default:
        return data.address;
    }
  };

  const getNetworkIcon = (data) => {
    let isEth = false;
    let isPoly = false;
    if (data.platforms) {
      if (Object.keys(data.platforms).includes('ethereum')) {
        isEth = true;
      }
      if (Object.keys(data.platforms).includes('polygon')) {
        isPoly = true;
      }
    } else {
      if (data.network === 'ethereum') {
        isEth = true;
      }
      if (data.network === 'polygon') {
        isPoly = true;
      }
    }

    if (!isEth && !isPoly) {
      return null;
    }

    return (
      <div className="d-flex align-items-center">
        <div className={`mx-2 ${styles.vertical_separator}`} />
        {isEth && tooltipChainIconPicker('ethereum', uniqueId('chain'))}
        {isPoly && tooltipChainIconPicker('polygon', uniqueId('chain'))}
      </div>
    );
  };

  useEffect(() => {
    if (deletedResult.isSuccess) {
      showSuccessMessage('Tracked website was successfully deleted');
      dispatch(deleteTrackedWebsiteFromDashboard(deletedResult.originalArgs));
      deletedResult.reset();
    }
    if (deletedResult.isError) {
      showErrorMessage('Something went wrong');
      deletedResult.reset();
    }
  }, [deletedResult, dispatch]);

  const renderData = () => {
    const titleCardRender = () => (
      <div className={`${styles.card_row} d-flex align-items-center gap-2`}>
        <div
          className="d-flex"
          data-for={cardData.asset_type === 'nft' && `preview_${cardData.address}_${cardData.network}`}
          data-tip="show"
        >
          {
            !cardLogoError && cardData.logo
              ? (
                <img
                  src={cardData.logo}
                  onError={() => setCardLogoError(true)}
                  height="40"
                  width="40"
                  alt="card"
                  className="rounded-circle"
                />
              )
              : <DefaultIcon type={cardData.asset_type} />
          }
        </div>
        <div
          className="text-truncate"
          data-for={cardData.asset_type === 'nft' && `preview_${cardData.address}_${cardData.network}`}
          data-tip="show"
        >
          <h3 className={`m-0 p-0 d-block ${styles.card_title}`}>
            {cardData?.opensea_slug_contract_count && cardData.opensea_slug_contract_count > 1
              ? cardData.contract_name || cardData.name || cardData.upper_name || '?'
              : cardData.name || cardData.title || cardData.website_name || '?'}
            {' '}
          </h3>
          {cardData?.opensea_slug_contract_count && cardData.opensea_slug_contract_count > 1
            ? <span className={`${styles.parent_title} text-truncate d-block`}>{cardData.name || '?'}</span>
            : null}
        </div>
        {cardData.asset_type === 'nft'
          ? (
            <ItemPreview
              id={`preview_${cardData.address}_${cardData.network}`}
              data={{
                address: cardData.address,
                type: cardData.asset_type,
                blockchain: cardData.network,
                img: cardData.logo,
                name: cardData?.opensea_slug_contract_count && cardData.opensea_slug_contract_count > 1
                  ? cardData.contract_name || cardData.name : cardData.name,
              }}
            />
          )
          : null}
        {deletedResult.isLoading || deletedResult.isFetching
          ? (
            <div className={`ms-auto ${styles.popover__wrapper} text-center position-relative cursor-pointer`}>
              <Spinner />
            </div>
          )
          : (
            <div
              role="presentation"
              onClick={(e) => e.preventDefault()}
              className={`ms-auto ${styles.popover__wrapper} text-center position-relative cursor-pointer`}
            >
              <img
                src={Settings}
                className="cursor-pointer"
                height="25"
                width="25"
                alt="settings"
              />
              <div className={`${styles.invisible_dropdown} position-absolute top-0 right-0`}>
                <div className={`${styles.popover__content} position-absolute`}>
                  <DropdownMenu
                    menuData={cardData.asset_type ? menuData : websiteAction}
                    breakWord={false}
                  />
                </div>
              </div>
            </div>
          )}
      </div>
    );

    switch (cardData.asset_type) {
      case 'coin':
        return (
          <>
            <div>
              {titleCardRender()}
              <div className={styles.data_content}>
                <div className="d-flex align-items-center gap-2">
                  <p className={`${styles.card_info} m-0 p-0`}>Price</p>
                  <p className={`${styles.card_info_bold} m-0 p-0`}>
                    $
                    {cardData.current_price !== null
                      ? ethFormat(cardData.current_price) : '?'}
                  </p>
                  {cardData.price_change_24h !== null ? (
                    <div className="d-flex align-items-center gap-1">
                      {String(cardData.price_change_24h)
                        .includes('-') ? (
                          <img src={ArrowDown} height="8" width="10" alt="arrow down" />
                        ) : (
                          <img src={ArrowUp} height="8" width="10" alt="arrow up" />
                        )}
                      <p
                        className={`${styles.card_info} m-0 p-0 ${
                          String(cardData.price_change_24h)
                            .includes('-')
                            ? styles.card_info_error
                            : styles.card_info_success
                        }`}
                      >
                        {cardData.price_change_24h !== null && cardData.current_price
                          ? +((Math.abs(cardData.price_change_24h)
                          / (cardData.current_price - cardData.price_change_24h)) * 100)
                            .toFixed(2) : '?'}
                        %
                      </p>
                    </div>
                  ) : null}
                </div>
                <div className="d-flex align-items-center gap-2">
                  <p className={`${styles.card_info} m-0 p-0`}>Market cap</p>
                  <p className={`${styles.card_info_bold} m-0 p-0`}>
                    {cardData.market_cap !== null
                      ? `$${convertToReadableFormat(cardData.market_cap)}`
                      : '?'}
                  </p>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-start align-items-center">
              <div
                className={`${styles.asset} ${styles.red}
            px-3 d-flex justify-content-center align-items-center`}
              >
                Token
              </div>
              {getNetworkIcon(cardData)}
            </div>
          </>
        );
      case 'nft':
        return (
          <>
            <div>
              {titleCardRender()}
              <div className={styles.data_content}>
                <div className="d-flex align-items-center gap-2">
                  <p className={`${styles.card_info} m-0 p-0`}>Floor price</p>
                  <p className={`${styles.card_info_bold} m-0 p-0`}>
                    {cardData.floor_price_eth === null && cardData.floor_eth_7d === null
                      ? '-'
                      : `${convertToReadableFormat(cardData.floor_price_eth || cardData.floor_eth_7d)} ETH (${
                        cardData.floor_price_usd === null && cardData.floor_eth_7d_usd === null
                          ? '-'
                          : `$${convertToReadableFormat(cardData.floor_price_usd || cardData.floor_eth_7d_usd)}`})`}
                  </p>
                </div>
                <div className="d-flex align-items-center gap-2">
                  <p className={`${styles.card_info} m-0 p-0`}>Holders</p>
                  <p className={`${styles.card_info_bold} m-0 p-0`}>
                    {cardData.holder_count !== null ? `${convertNumber(cardData.holder_count)}` : '?'}
                  </p>
                </div>
                <div className="d-flex align-items-center gap-2">
                  <p className={`${styles.card_info} m-0 p-0`}>Market cap</p>
                  <p className={`${styles.card_info_bold} m-0 p-0`}>
                    {cardData.market_cap !== null ? `${convertToReadableFormat(cardData.market_cap)} ETH` : '-'}
                  </p>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-start">
              <div
                className={`${styles.asset}
            px-3 d-flex justify-content-center align-items-center`}
              >
                NFT Collection
              </div>
              {getNetworkIcon(cardData)}
            </div>
          </>
        );
      case 'brand':
        return (
          <>
            <div>
              {titleCardRender()}
              <div className={styles.data_content}>
                <div className="d-flex align-items-center gap-2">
                  <p className={`${styles.card_info} m-0 p-0`}>Assets published</p>
                  <p className={`${styles.card_info_bold} m-0 p-0`}>
                    {cardData.published_assets_quantity}
                  </p>
                </div>
                <div className="d-flex card-info-wrapper">
                  <p className={`${styles.card_info} m-0 p-0`}>Assets owned</p>
                  <p className={`${styles.card_info_bold} m-0 p-0`}>
                    {cardData.owned_assets_quantity}
                  </p>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-start">
              <div
                className={`${styles.asset}
            px-3 d-flex justify-content-center align-items-center`}
              >
                Brand
              </div>
            </div>
          </>
        );
      default:
        return (
          <>
            <div>
              {titleCardRender()}
              <div className={styles.data_content}>
                <div className="d-flex align-items-center gap-2">
                  <p className={`${styles.card_info} m-0 p-0`}>7-Day Visitors</p>
                  <p className={`${styles.card_info_bold} m-0 p-0`}>
                    {cardData.visitors !== null
                      ? `${convertNumber(cardData.visitors)}`
                      : '?'}
                  </p>
                </div>
                <div className="d-flex align-items-center gap-2">
                  <p className={`${styles.card_info} m-0 p-0`}>Web3 Adoption</p>
                  <p className={`${styles.card_info_bold} m-0 p-0`}>
                    {cardData.web3_adoption !== null
                      ? `${ethFormat(cardData.web3_adoption * 100)}%`
                      : '?'}
                  </p>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-start align-items-center">
              <div
                className={`${styles.asset} ${styles.orange}
            px-3 d-flex justify-content-center align-items-center`}
              >
                Website
              </div>
              {getNetworkIcon(cardData)}
            </div>
          </>
        );
    }
  };

  const handleRedirect = (cardInfo) => {
    if (cardInfo?.asset_type) {
      return `/${cardInfo.asset_type}s/${cardInfo.asset_type === 'nft'
        ? `${cardInfo.network}/`
        : ''}${addAssetIdToUrl(cardData.asset_type, cardData)}`;
    }
    return `/tracked-website/${cardData.id}`;
  };

  if (isLoading) {
    return (
      <div className={`${styles.card_wrapper} d-flex`}>
        <div className="d-flex">
          <div className="w-25">
            <LoadingAvatar size={60} />
          </div>
          <LoadingLines rows={1} />
        </div>
        <LoadingLines rows={1} />
      </div>
    );
  }

  return (
    <Link
      onClick={() => {
        dispatch(setSelectedAsset({
          type: cardData.asset_type,
          name: cardData.asset_type === 'nft' && cardData.opensea_slug_contract_count > 1
            ? cardData.contract_name || cardData.name
            : cardData.name,
          place: cardData.item_type,
          logo: cardData.logo,
          symbol: cardData.symbol,
          network: cardData.network,
          platforms: cardData.asset_type && cardData.asset_type !== 'nft'
            ? formattedPlatforms(cardData.platforms, cardData.name)
            : null,
        }));
      }}
      to={handleRedirect(cardData)}
      className="cursor-pointer"
    >
      <div className={`${styles.card_wrapper} d-flex justify-content-between`}>
        {renderData()}
      </div>
    </Link>
  );
};

export default Card;
