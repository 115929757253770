import React from 'react';
import Chart from 'react-apexcharts';
import styles from './TypicalPortfolio.module.scss';
import TitleComponent from '../../base/TitleComponent';
import Loader from '../../base/Loader';
import NoData from '../../base/NoData';
import { convertNumber, convertToReadableFormat } from '../../../tools/NumberConverterTool';
import uniqueId from '../../../utils/uniqueId';

const colors = {
  0: '',
  1: 'green',
  2: 'purple',
  3: 'red',
};

const TypicalPortfolio = ({
  data, isLoading, isFetching, info,
}) => {
  const seriesDataCount = () => {
    if (data) {
      const defaultValueNFT = Math.max(0, data.nft50);
      const defaultValueStable = Math.max(0, data.stable50);
      const defaultValueToken = Math.max(0, data.token50);
      const defaultValueNative = Math.max(0, data.total50 - data.nft50 - data.stable50 - data.token50);
      const total = defaultValueNFT + defaultValueStable + defaultValueToken + defaultValueNative;
      return [
        {
          title: 'NFTs',
          value: Math.max(0, Math.min(((defaultValueNFT / total) * 100).toFixed(2), 100)),
          defaultValue: defaultValueNFT,
        },
        {
          title: 'Stable coins',
          value: Math.max(0, Math.min(((defaultValueStable / total) * 100).toFixed(2), 100)),
          defaultValue: defaultValueStable,
        },
        {
          title: 'Token',
          value: Math.max(0, Math.min(((defaultValueToken / total) * 100).toFixed(2), 100)),
          defaultValue: defaultValueToken,
        },
        {
          title: 'Native tokens',
          value: Math.max(0, Math.min(((defaultValueNative / total) * 100).toFixed(2), 100)),
          defaultValue: defaultValueNative,
        },
      ].sort((a, b) => b.value - a.value);
    }
    return [];
  };

  const seriesFormatter = (y, seriesIndex) => (
    `$${convertToReadableFormat(seriesDataCount()[seriesIndex].defaultValue)} (${y}%)`);

  const DONUTTypicalPortfolio = {
    options: {
      chart: {
        type: 'donut',
      },
      plotOptions: {
        pie: {
          expandOnClick: false,
        },
      },
      states: {
        normal: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
        hover: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: 'none',
            value: 0,
          },
        },
      },
      stroke: {
        width: 5,
        colors: ['#f1f4f8'],
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      tooltip: {
        enabled: true,
        style: {
          fontSize: '12px',
          background: ['rgba(0, 143, 251, 0.85)', 'rgba(194, 207, 224, 0.85)'],
        },
        marker: {
          show: false,
        },
        x: {
          show: false,
        },
        y: {
          formatter: (y, { ...chartData }) => seriesFormatter(y, chartData.seriesIndex),
          title: {
            formatter: () => '',
          },
        },
      },
      fixed: {
        enabled: false,
      },
      colors: ['#1C76FD', '#2ED47A', '#885AF8', '#F7685B'],
      responsive: [
        {
          breakpoint: 2000,
          options: {
            chart: {
              width: 150,
            },
          },
        },
      ],
    },
  };

  return (
    <div className={styles.wrapper}>
      <TitleComponent
        info={info}
      >
        Typical Portfolio
      </TitleComponent>
      { isLoading || isFetching
        ? (
          <div className="p-5">
            <Loader />
          </div>
        )
        : data && data.total50 ? (
          <>
            <div className={`${styles.chart} d-flex mb-2 flex-wrap justify-content-center`}>
              <div className="distribution-donut">
                {data
                  ? (
                    <Chart
                      options={DONUTTypicalPortfolio.options}
                      series={seriesDataCount().map((elem) => elem.value)}
                      type="donut"
                      height={180}
                    />
                  )
                  : null}
              </div>
              <div className="d-flex gap-1 flex-column mt-3">
                {seriesDataCount().map((elem, index) => (
                  <div className="d-flex align-items-center gap-2 asset-wrap text-nowrap" key={uniqueId('asset')}>
                    <div className={`dot ${colors[index]}`} />
                    <span>
                      {`$${convertToReadableFormat(elem.defaultValue)} 
                      in ${elem.title} (${convertToReadableFormat(elem.value)}%)`}
                    </span>
                  </div>
                ))}
              </div>
            </div>
            <div className="mt-4 d-flex gap-2 flex-column">
              <span className="info-distribution text-nowrap">
                Wallet age
                &nbsp;
                {convertNumber(((Date.now() - Date.parse(data.first_txn50)) / 86400000).toFixed(0))}
                &nbsp;
                days
              </span>
              <span className="info-distribution text-nowrap">
                Transactions
                &nbsp;
                {convertNumber(data.qty_txn50)}
              </span>
            </div>
          </>
        ) : (
          <NoData />
        )}
    </div>
  );
};

export default React.memo(TypicalPortfolio);
