import React from 'react';
import Chart from 'react-apexcharts';
import { convertNumber } from '../../../../tools/NumberConverterTool';
import TitleComponent from '../../../../components/base/TitleComponent';
import Loader from '../../../../components/base/Loader';
import NoData from '../../../../components/base/NoData';
import styles from './RelevantHolders.module.scss';

const RelevantHolders = ({
  isFetching, isLoading, title, holders, relevantHolders,
}) => {
  const RadialBarData = {
    options: {
      chart: {
        type: 'donut',
      },
      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            size: '83%',
          },
        },
      },
      states: {
        normal: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
        hover: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: 'none',
            value: 0,
          },
        },
      },
      stroke: {
        width: 0,
        colors: ['#f1f4f8'],
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      fixed: {
        enabled: false,
      },
      tooltip: {
        enabled: true,
        style: {
          fontSize: '12px',
          background: ['rgba(0, 143, 251, 0.85)', 'rgba(194, 207, 224, 0.85)'],
        },
        marker: {
          show: false,
        },
        x: {
          show: false,
        },
        y: {
          formatter: (y) => `${y}%`,
          title: {
            formatter: () => '',
          },
        },
      },
      colors: ['rgba(0, 143, 251, 0.85)', 'rgba(194, 207, 224, 0.85)'],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
          },
        },
      ],
    },
  };

  const content = () => {
    if (holders) {
      return (
        <>
          <div className="d-flex align-items-center justify-content-center my-2 gap-1">
            <div className={styles.chart}>
              <Chart
                options={RadialBarData.options}
                series={
                  [Math.round((relevantHolders / holders) * 100),
                    100 - Math.round((relevantHolders / holders) * 100),
                  ]
                }
                type="donut"
                height={220}
              />
            </div>
          </div>
          <div className={`${styles.holders} mt-4`}>
            {`${convertNumber(holders)} Holders`}
          </div>
          <div className="d-flex gap-2 align-items-center mt-2">
            <div className={`${styles.circle} ${styles.blue}`} />
            <span className={styles.content}>
              Relevant holders
            </span>
          </div>
          <div className="d-flex gap-2 align-items-center">
            <div className={`${styles.circle}`} />
            <span className={styles.content}>
              Other
            </span>
          </div>
        </>
      );
    }
    return (
      <NoData />
    );
  };

  return (
    <div className={styles.wrapper}>
      <TitleComponent>{title}</TitleComponent>
      {isLoading || isFetching
        ? (
          <div className="p-5">
            <Loader />
          </div>
        )
        : (content())}
    </div>
  );
};

export default React.memo(RelevantHolders);
