import React from 'react';
import { useNavigate } from 'react-router-dom';

const NoForm = () => {
  const navigate = useNavigate();

  return (
    <div className="asset-section vh-75 d-flex justify-content-center align-items-center">
      <div className="d-flex flex-column align-items-center">
        <p className="p-1 title-h3-bold text-center">
          No forms to show yet. Let’s create your first Form.
        </p>
        <button
          type="button"
          className="p-1 regular-button"
          onClick={() => {
            navigate('/add-form');
          }}
        >
          Create a Form
        </button>
      </div>
    </div>
  );
};

export default NoForm;
