import React, {
  useState, useMemo, useCallback, useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { coinApi } from '../../../api/coin';
import { audiencesApi } from '../../../api/audiences';
import { useDebounce } from '../../../components/hooks/app';
import {
  checkTxCountValues,
  formatTxCountValues,
  shouldSkipWalletFetch,
} from '../../../utils/segments';
import {
  addFilterNameAndOptionsToCondition,
  deleteConditionById,
  addValueToOptions,
  pushConditionIntoTree,
  deleteConditionFromTree,
  getFilterByNameAndId,
  addTxCountValueToOption,
  addTxCountValueToOption2,
  setFormIsReady,
  getUserSegment,
} from '../../../store/reducers/segment';
import { debounce } from '../../../utils/debounce';
import {
  CoinLabel,
  NftLabel,
  CustomDataLabel,
  CexLabel,
  CustomListDataLabel,
  DappLabel,
} from '../components/labels';
import { processStructure } from '../../../utils/segments/checkForCompletion';
import { proccessDeletion } from '../../../utils/segments/checkForDeletion';
import { getDashboardData } from '../../../utils/segments/getCoinNftData';
import { convertValues } from '../../../utils/segments/convertCompletedValues';
import { shouldDeleteInEffect } from '../../../utils/segments/shouldDeleteInEffect';
import { getUrl, parseData } from '../../../utils/segments/getRequestData';
import ControlFormItem from '../components/control/ControlFormItem';
import TxCountControl from '../components/control/TxCountControl';
import { getNextOption, parseValues } from '../../../utils/segments/transactionCountFunctions';
import ConditionForm from '../ConditionForm';
import Segments from '../../../components/Layout/Footer/Segments';

const Condition = ({
  filters,
  conditionName,
  edit,
  id,
  data,
  teamData,
  teamDataIsLoading = false,
  teamDataIsFetching = false,
  page = 'add-segment',
}) => {
  const [firstSelectOptions] = useState(filters?.map((filter) => ({
    label: filter.label,
    value: filter.name,
  })));
  const [showHeader, setShowHeader] = useState(true);
  const [txCountData, setTxCountData] = useState();
  const [txCountFirstValue, setTxCountFirstValue] = useState(null);
  const [txNextOption, setTxNextOption] = useState(null);
  const [txChainOption, setTxChainOption] = useState([{ value: 'across-all-chains' }]);
  const [txTrailingPeriod, setTxTrailingPeriod] = useState(null);
  const [txStartDate, setTxStartDate] = useState(null);
  const [txIsMoreLess, setTxIsMoreLess] = useState(null);
  const [txInputValue, setTxInputValue] = useState(null);
  const [inputValue, setInputValue] = useState(null);
  const [showAddresses, setShowAddresses] = useState(false);
  const [coinOptions, setCoinOptions] = useState([]);
  const [nftOptions, setNFTOptions] = useState([]);
  const [isCustomDataLoading, setCustomDataLoading] = useState(true);
  const userSegment = useSelector(getUserSegment);

  const dispatch = useDispatch();

  const [debouncedValue, setDebouncedValue] = useDebounce({ value: '', index: null });
  const [debounceCoinValue, setDebounceCoinValue] = useDebounce('');
  const [debounceNFTValue, setDebounceNFTValue] = useDebounce('');

  const walletCountBody = useSelector(getFilterByNameAndId(conditionName, id));

  const {
    data: totalWalletCount,
    isLoading: isTotalWalletCountLoading,
    error,
    isFetching: isTotalWalletCountFetching,
  } = audiencesApi.useGetSegmentWalletsCountQuery(
    walletCountBody,
    { skip: shouldSkipWalletFetch(walletCountBody) },
  );

  const {
    data: searchCoin,
    isLoading: isSearchCoinLoading,
    isFetching: isSearchCoinFetching,
  } = coinApi.useSearchERC20TokenQuery(
    {
      query: debounceCoinValue,
    },
    {
      skip: !(data?.label && data?.label.includes('token')),
    },
  );

  const {
    data: searchNFT,
    isLoading: isSearchNFTLoading,
    isFetching: isSearchNFTFetching,
  } = coinApi.useGetSearchNftQuery(
    debounceNFTValue,
    {
      skip: !(data?.label && data?.label.includes('NFT')),
    },
  );

  const deleteCondition = () => {
    const valuesIsIncludedInTree = proccessDeletion(data);
    dispatch(deleteConditionById(id));
    if (valuesIsIncludedInTree) {
      dispatch(deleteConditionFromTree({ id, name: conditionName }));
    }
    if (data?.name === 'transaction-count') {
      dispatch(deleteConditionFromTree({ id, name: conditionName }));
    }
  };

  const clearAllTxValues = () => {
    setTxCountFirstValue(null);
    setTxNextOption(null);
    setTxTrailingPeriod(null);
    setTxStartDate(null);
    setTxIsMoreLess(null);
    setTxInputValue(null);
    setTxCountData(null);
  };

  const defineFirstSelectOptions = async (value) => {
    clearAllTxValues();
    setInputValue(null);
    setCustomDataLoading(true);

    const valuesIsIncludedInTree = proccessDeletion(data);

    if (valuesIsIncludedInTree) {
      dispatch(deleteConditionFromTree({ id, name: conditionName }));
    }
    if (data?.name === 'transaction-count') {
      dispatch(deleteConditionFromTree({ id, name: conditionName }));
    }

    const filterData = filters.filter((filter) => filter.name === value);
    const filteredOptions = filterData[0].options.filter((option) => option.name);
    dispatch(addFilterNameAndOptionsToCondition({
      id, name: value, label: filterData[0].label, options: filteredOptions, type: filterData[0].type,
    }));
  };

  const pushSelectedOptionToArray = useCallback(async (value, name, type) => {
    if (value === '') return;
    setInputValue(null);
    setDebouncedValue({ value: '', index: null });
    dispatch(addValueToOptions({
      value, id, name, type,
    }));
  }, [dispatch, id]);

  const pushTxCountValueOptionToSelectedOptions = useCallback(async (value, name) => {
    if (value === '') return;
    if (['since', 'period'].includes(name)) {
      dispatch(addTxCountValueToOption2({ value, id, name }));
    } else {
      dispatch(addTxCountValueToOption({ value, id, name }));
    }
  }, [dispatch, id]);

  useEffect(() => {
    const shouldProccessData = processStructure(data);

    if (shouldProccessData?.status && data?.name !== 'transaction-count') {
      const convertedValues = convertValues(data, shouldProccessData.type, shouldProccessData);
      dispatch(deleteConditionFromTree({ id, name: conditionName }));
      if (convertedValues.status === true) {
        dispatch(pushConditionIntoTree({
          ...data,
          options: convertedValues.options,
        }));
        setShowAddresses(true);
      }
      return;
    }

    const shouldDelete = shouldDeleteInEffect(data, shouldProccessData);
    dispatch(setFormIsReady(null));
    if (shouldDelete) {
      dispatch(deleteConditionFromTree({ id, name: conditionName }));
    }

    if (txCountData?.name === 'transaction-count') {
      const isComplete = checkTxCountValues(txCountData.options[0]);
      if (isComplete) {
        const newOptions = formatTxCountValues(txCountData.options[0]);
        const newData = { ...txCountData, options: newOptions };
        dispatch(pushConditionIntoTree(newData));
        setShowAddresses(true);
      } else {
        dispatch(deleteConditionFromTree({ id, name: conditionName }));
        setShowAddresses(false);
      }
    }
  }, [data, dispatch, txCountData, conditionName, id]);

  useEffect(() => {
    if (debouncedValue.value === null) {
      dispatch(deleteConditionFromTree({ id, name: conditionName }));
      setDebouncedValue({ value: '', name: null });
    } else {
      pushSelectedOptionToArray(debouncedValue.value, debouncedValue.name);
    }
  }, [debouncedValue, pushSelectedOptionToArray]);

  const getRequestData = useCallback(async (val, type) => {
    const handler = getUrl(val, type, data);
    const apiData = await dispatch(
      handler.url.initiate(
        typeof handler.value === 'string' ? handler.value : { ...handler.value },
        { forceRefetch: 10 },
      ),
    );
    if (type === 'customList') setCustomDataLoading(false);
    return parseData(apiData, type);
  }, [dispatch, data]);

  const loadInterestOptions = useCallback(
    debounce((val) => getRequestData(val, 'interests'), 1000),
    [getRequestData],
  );
  const loadCustomData = useCallback(debounce((val) => getRequestData(val, 'custom'), 1000), [getRequestData]);
  const loadCustomListData = useCallback(debounce((val) => getRequestData(val, 'customList'), 1000), [getRequestData]);
  const loadCEXOptions = useCallback(debounce((val) => getRequestData(val, 'cexOptions'), 1000), [getRequestData]);
  const loadSegmentOptions = useCallback(debounce((val) => getRequestData(val, 'segments'), 1000), [getRequestData]);
  const loadDappOptions = useCallback(debounce((val) => getRequestData(val, 'dapps'), 1000), [getRequestData]);

  const getNftLabel = useCallback((val) => <NftLabel val={val} />, []);
  const getCoinLabel = useCallback((val) => <CoinLabel val={val} />, []);
  const getCustomDataLabel = useCallback((val) => <CustomDataLabel val={val} />, []);
  const getCEXLabel = useCallback((val) => <CexLabel val={val} />, []);
  const getCustomListDataLabel = useCallback((val) => <CustomListDataLabel val={val} />, []);
  const getSegmentLabel = useCallback((val) => <CustomListDataLabel val={val} />, []);
  const getDappLabel = useCallback((val) => <DappLabel val={val} />, []);
  useEffect(() => {
    if (teamData) {
      const res = getDashboardData(teamData, searchCoin, searchNFT, data);
      if (res?.coin) {
        setCoinOptions(debounceCoinValue || !res.coin[0].length ? res.coin[1] : res.coin[0]);
        setShowHeader(!!res.coin[0].length);
      }
      if (res?.nft) {
        setNFTOptions(debounceNFTValue || !res.nft[0].length ? res.nft[1] : res.nft[0]);
        setShowHeader(!!res.nft[0].length);
      }
    }
  }, [teamData, debounceCoinValue, searchCoin, data, debounceNFTValue, searchNFT]);

  useEffect(() => {
    if (data?.name === 'transaction-count') {
      const formattedData = parseValues(data);
      setTxCountData(formattedData.data);
      setTxCountFirstValue(formattedData.firstValue);
      if (formattedData.startDate) {
        setTxStartDate(formattedData.startDate);
      }
      if (formattedData.trailingPeriod) {
        setTxTrailingPeriod(formattedData.trailingPeriod);
      }
      if (formattedData.isMoreLess) {
        setTxIsMoreLess(formattedData.isMoreLess);
      }
      if (formattedData.inputValue) {
        setTxInputValue(formattedData.inputValue);
      }
      if (formattedData.chainOption) {
        setTxChainOption(formattedData.chainOption);
      }
    }
  }, [data]);

  useEffect(() => {
    const nextOption = getNextOption(txCountFirstValue, data);
    setTxNextOption(nextOption);
  }, [txCountFirstValue, data]);

  const content = useMemo(
    () => {
      if (isTotalWalletCountLoading || isTotalWalletCountFetching || totalWalletCount) {
        dispatch(setFormIsReady(true));
      }
      if (data && data.options && data?.label !== 'Transaction count') {
        return (
          <ControlFormItem
            conditionName={conditionName}
            edit={edit}
            id={id}
            data={data}
            pushSelectedOptionToArray={pushSelectedOptionToArray}
            setDebouncedValue={setDebouncedValue}
            setInputValue={setInputValue}
            inputValue={inputValue}
            loadCEXOptions={loadCEXOptions}
            getCEXLabel={getCEXLabel}
            showHeader={showHeader}
            getNftLabel={getNftLabel}
            isCustomDataLoading={isCustomDataLoading}
            nftOptions={nftOptions}
            setDebounceNFTValue={setDebounceNFTValue}
            getCoinLabel={getCoinLabel}
            coinOptions={coinOptions}
            setDebounceCoinValue={setDebounceCoinValue}
            loadInterestOptions={loadInterestOptions}
            loadSegmentOptions={loadSegmentOptions}
            getSegmentLabel={getSegmentLabel}
            loadCustomData={loadCustomData}
            loadCustomListData={loadCustomListData}
            loadDappOptions={loadDappOptions}
            getDappLabel={getDappLabel}
            getCustomDataLabel={getCustomDataLabel}
            getCustomListDataLabel={getCustomListDataLabel}
            isTeamDataLoading={teamDataIsLoading || teamDataIsFetching}
            isNftLoading={isSearchNFTLoading || isSearchNFTFetching}
            isCoinLoading={isSearchCoinLoading || isSearchCoinFetching}
          />
        );
      }

      if (
        txCountData && txCountData.options
      && txCountData.label === 'Transaction count'
      && txCountData.options.length === 1
      ) {
        return (
          <TxCountControl
            txCountData={txCountData}
            txCountFirstValue={txCountFirstValue}
            setTxCountFirstValue={setTxCountFirstValue}
            pushTxCountValueOptionToSelectedOptions={pushTxCountValueOptionToSelectedOptions}
            txNextOption={txNextOption}
            txTrailingPeriod={txTrailingPeriod}
            setTxTrailingPeriod={setTxTrailingPeriod}
            txStartDate={txStartDate}
            setTxStartDate={setTxStartDate}
            txIsMoreLess={txIsMoreLess}
            setTxIsMoreLess={setTxIsMoreLess}
            txInputValue={txInputValue}
            setTxInputValue={setTxInputValue}
            txChainOption={txChainOption}
            setTxChainOption={setTxChainOption}
            data={data}
            edit={edit}
          />
        );
      }

      return null;
    },
    [
      data,
      txCountData,
      pushSelectedOptionToArray,
      edit,
      setDebouncedValue,
      inputValue,
      loadCEXOptions,
      teamDataIsLoading,
      teamDataIsFetching,
      isSearchNFTLoading,
      isSearchNFTFetching,
      nftOptions,
      setDebounceNFTValue,
      isSearchCoinLoading,
      isSearchCoinFetching,
      coinOptions,
      setDebounceCoinValue,
      loadInterestOptions,
      loadCustomData,
      txCountFirstValue,
      txNextOption,
      txTrailingPeriod,
      txStartDate,
      pushTxCountValueOptionToSelectedOptions,
      txIsMoreLess,
      txInputValue,
      conditionName,
      id,
      loadCustomListData,
      isCustomDataLoading,
      txChainOption,
      loadSegmentOptions,
      showHeader,
      dispatch,
      isTotalWalletCountLoading,
      isTotalWalletCountFetching,
      totalWalletCount,
      loadDappOptions,
      getDappLabel,
    ],
  );

  return (
    <>
      <ConditionForm
        conditionName={conditionName}
        edit={edit}
        data={data}
        content={content}
        deleteCondition={deleteCondition}
        firstSelectOptions={firstSelectOptions}
        defineFirstSelectOptions={defineFirstSelectOptions}
        isTotalWalletCountLoading={isTotalWalletCountLoading}
        isTotalWalletCountFetching={isTotalWalletCountFetching}
        showAddresses={showAddresses}
        error={error}
        totalWalletCount={totalWalletCount}
        walletCountBody={walletCountBody}
        page={page}
      />
      {userSegment.segment?.categories?.some((category) => category.filters.length > 0)
         && !userSegment.isInRunning && window.location.pathname.includes('add-segment')
        ? (
          <Segments />
        )
        : null}

    </>
  );
};

export default Condition;
