import React, { useEffect, useState } from 'react';
import { useLocation, useNavigationType, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import './NFTDetails.scss';
import Insights from './Insights';
import { coinApi } from '../../api/coin';
import {
  isRevenueLoading, salesData, selectTabPages, setTabPages,
} from '../../store/reducers/coin';
import { resetSelectedAsset } from '../../store/reducers/app';
import Total from '../../assets/images/asset/TotalCollection.svg';
import Price from '../../assets/images/asset/FloorPrice.svg';
import Volume from '../../assets/images/asset/volume.svg';
import Holder from '../../assets/images/asset/holder.svg';
import Item from '../../assets/images/asset/Items.svg';
import { convertNumber, dividerCommaFormat } from '../../tools/NumberConverterTool';
import Frame1 from '../../assets/images/asset/Frame 1.png';
import { ethFormat } from '../../utils/singleAssetPage/parseData';
import InvestorProfile from './InvestorProfile';
import InvestPortfolio from './InvestorPortfolio';
import Holders from './Holders';
import SingleNFTTitle from '../../components/ui/TitleSection/Titles/SingleNFTTitle';
import Market from '../../components/ui/Market';
import Exchanges from './Exchanges';
import DappUsage from './DappUsage';

const NFTDetails = () => {
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();
  const navType = useNavigationType();
  const [searchParams, setSearchParams] = useSearchParams();
  const [itemPage, setItemPage] = useState(searchParams.get('tab')?.replace('+', ' ') || 'Insights');
  const { network, name } = useParams();
  const [pages, setPages] = useState([]);
  const [titleSectionData, setTitleSectionData] = useState(null);
  const [marketData, setMarketData] = useState({});

  const tabPages = useSelector(selectTabPages);

  const {
    data: nft,
    isLoading,
    isFetching,
    isSuccess,
  } = coinApi.useGetNftBasicInfoQuery({ address: name, network });

  const { data: ethereum } = coinApi.useGetCoinByIdQuery('ethereum');

  const {
    currentData: statsTrading,
    isLoading: isStatsTradingLoading,
    isFetching: isStatsTradingFetching,
    status,
  } = coinApi.useGetNftStatsTradingQuery({ address: name, network, contractType: nft?.contract_type }, {
    skip: !(isSuccess && nft?.contract_type),
  });

  const {
    data: reach,
    isLoading: isReachLoading,
    isFetching: isReachFetching,
  } = coinApi.useReachNFTQuery({ address: name, network });

  useEffect(() => {
    dispatch(salesData(null));
    setItemPage(searchParams.get('tab')?.replace('+', ' ') || 'Insights');
  }, [dispatch, name]);

  useEffect(() => {
    if (status === 'fulfilled' && statsTrading) {
      dispatch(salesData(statsTrading));
    }
    if (status === 'fulfilled' && statsTrading === null) {
      dispatch(salesData([]));
    }
  }, [dispatch, statsTrading, status]);

  useEffect(() => {
    if (isLoading || isFetching) {
      setTitleSectionData(null);
      dispatch(isRevenueLoading(true));
    }
    if (nft) {
      dispatch(isRevenueLoading(false));
      const floorPrice = (nftData) => {
        if (nftData.floor_price_eth) {
          return `${ethFormat(nftData.floor_price_eth)} ETH ($${ethFormat(nftData.floor_price_usd)})`;
        }
        if (nftData.floor_eth_7d) {
          return `${ethFormat(nftData.floor_eth_7d)}
          ETH ($${ethFormat(nftData.floor_price_usd)})`;
        }
        return '-';
      };
      const getMaxValue = (type) => {
        let averagePrice;
        let sevenDayAveragePrice;
        let thirtyDayAveragePrice;
        let oneDayAveragePrice;
        if (type === 'eth') {
          averagePrice = nft.average_price !== null ? nft.average_price : 0;
          sevenDayAveragePrice = nft.seven_day_average_price !== null ? nft.seven_day_average_price : 0;
          thirtyDayAveragePrice = nft.thirty_day_average_price !== null ? nft.thirty_day_average_price : 0;
          oneDayAveragePrice = nft.one_day_average_price !== null ? nft.one_day_average_price : 0;
        } else {
          averagePrice = nft.average_price_usd !== null ? nft.average_price_usd : 0;
          sevenDayAveragePrice = nft.seven_day_average_price_usd !== null ? nft.seven_day_average_price_usd : 0;
          thirtyDayAveragePrice = nft.thirty_day_average_price_usd !== null ? nft.thirty_day_average_price_usd : 0;
          oneDayAveragePrice = nft.one_day_average_price_usd !== null ? nft.one_day_average_price_usd : 0;
        }

        return Math.max(averagePrice, sevenDayAveragePrice, thirtyDayAveragePrice, oneDayAveragePrice);
      };

      setTitleSectionData({
        id: nft.contract_address,
        logo: nft.image_url || 'logo',
        name: nft.opensea_slug_contract_count > 1
          ? nft.contract_name || nft.name || nft.upper_name || '?'
          : nft.name || nft.upper_name || '?',
        collectionsName: nft.name || '?',
        symbol: nft.symbol,
        slug: nft.opensea_slug,
        contractCount: nft?.opensea_slug_contract_count,
        description: nft.description ? nft.description : 'Description coming soon',
        author: '',
        network: nft.blockchain,
        isVerified: nft.is_verified_on_opensea,
        links: {
          homepage: [nft?.external_url],
          telegram_channel_identifier: nft?.telegram_url,
          twitter_screen_name: nft?.twitter_username,
          chat_url: [nft?.discord_url],
          instagram: nft?.instagram_username,
          facebook_username: nft?.facebook_username,
          openSea: nft.opensea_slug,
        },
      });

      setMarketData({
        name: nft.name,
        NFTMarketData: {
          highest: `${getMaxValue('eth') ? ethFormat(getMaxValue('eth')) : 0} ETH / ${(getMaxValue('usd'))
            ? `$${ethFormat(getMaxValue('usd'))}` : '-'}`,
          seven: nft.seven_day_average_price !== null && nft.seven_day_average_price !== 0
            ? `${ethFormat(nft.seven_day_average_price)} ETH / ${nft.seven_day_average_price_usd
              ? `$${ethFormat(nft.seven_day_average_price_usd)}`
              : '-'}`
            : '-',
          floor: nft.floor_eth_7d !== null && nft.floor_eth_7d !== 0
            ? `${ethFormat(nft.floor_eth_7d)} ETH / ${nft?.floor_eth_7d_usd
              ? `$${ethFormat(nft.floor_eth_7d_usd)}`
              : '-'}`
            : '-',
          ceiling: nft.ceiling_eth_7d !== null && nft.ceiling_eth_7d !== 0
            ? `${ethFormat(nft.ceiling_eth_7d)} ETH / ${nft?.ceiling_eth_7d_usd
              ? `$${ethFormat(nft.ceiling_eth_7d_usd)}`
              : '-'}`
            : '-',
        },
        InfoData: [
          {
            id: 6,
            header: 'Holders',
            count: nft.holder_count !== null ? dividerCommaFormat(nft.holder_count) : '-',
            icon: Frame1,
          },
          {
            id: 2,
            header: '24h # Sales',
            count: nft.one_day_sales !== null ? nft.one_day_sales : '-',
          },
          {
            id: 3,
            header: '7d / total volume',
            count: (`${nft.volume_eth_7d === null ? '0 ETH' : `${ethFormat(nft.volume_eth_7d)} ETH`}
          / ${nft.total_volume === null ? '0 ETH' : `${ethFormat(nft.total_volume)} ETH`}`),
          },
          {
            id: 4,
            header: '24h Volume',
            count: nft.volume_eth_24h !== null ? `${ethFormat(nft.volume_eth_24h)} ETH` : '0 ETH',
          },
          {
            id: 5,
            header: '24h average',
            count: nft.one_day_average_price === null || nft.one_day_average_price === 0
              ? '-'
              : `${ethFormat(nft.one_day_average_price)} ETH`,
          },
          {
            id: 7,
            header: 'Monthly active addresses',
            count: nft.monthly_active_addresses !== null ? `${convertNumber(nft.monthly_active_addresses)}` : '0',
          },
          {
            id: 8,
            header: 'Total Sales',
            count: nft.total_sales !== null ? convertNumber(nft.total_sales) : '-',
          },
        ],
        WidgetData: [
          {
            id: 9,
            name: 'Total collection value',
            number: nft.market_cap !== null
              ? `${nft.market_cap > 999
                ? ethFormat(nft.market_cap)
                : ethFormat(nft.market_cap)} ETH ${nft.market_cap_usd ? `($${ethFormat(nft.market_cap_usd)})` : ''}`
              : '-',
            icon: Total,
          },
          {
            id: 5,
            name: '# Items',
            number: {
              items: nft.count !== null ? dividerCommaFormat(nft.count) : '?',
              itemsPerHolder:
                nft.count !== null && nft.holder_count
                  ? nft.count / nft.holder_count
                  : '?',
            },
            icon: Item,
          },
          {
            id: 6,
            name: 'Floor Price',
            number: floorPrice(nft),
            icon: Price,
          },
          {
            id: 7,
            name: 'Stickiness 7d / 30d',
            number: `${nft.pct_held_over_7_days || '?'}% / ${nft.pct_held_over_30_days || '?'}%`,
            icon: Volume,
            info: 'Percentage of holders owning at least an NFT for at least 7/30 days',
          },
          {
            id: 8,
            name: 'Avg. hold time ',
            number: nft.average_hold_time_days ? `${nft.average_hold_time_days} days` : '?',
            icon: Holder,
          },
        ],
      });

      const sentimentData = {
        oneDay: nft.one_day_average_price !== null ? nft.one_day_average_price : 0,
        sevenDay: nft.seven_day_average_price !== null ? nft.seven_day_average_price : 0,
        thirtyDay: nft.thirty_day_average_price !== null ? nft.thirty_day_average_price : 0,
      };

      const revenueData = {
        total: nft.total_royalties_eth,
        totalUsd: nft.total_royalties_usd,
        mint: nft.mint_revenue_eth,
        mintUsd: nft.mint_revenue_usd,
        week: nft.first_7d_royalties_eth,
        weekUsd: nft.first_7d_royalties_usd,
        royaltiesAfter: nft.after_first_week_royalties_eth,
        royaltiesAfterUsd: nft.after_first_week_royalties_usd,
        weekRevenue: nft.last_7d_royalties_eth,
        weekRevenueUsd: nft.last_7d_royalties_usd,
        monthRevenue: nft.last_30d_royalties_eth,
        monthRevenueUsd: nft.last_30d_royalties_usd,
        royalties: nft.royalties_pct,
      };

      const mintData = {
        avg_mint_price_eth: nft.avg_mint_price_eth,
        avg_mint_price_usd: nft.avg_mint_price_usd,
        first_mint_txn_date: nft.first_mint_txn_date,
        free_minters_count: nft.free_minters_count,
        free_mints_count: nft.free_mints_count,
        last_mint_txn_date: nft.last_mint_txn_date,
        max_mint_per_wallet: nft.max_mint_per_wallet,
        minters_count: nft.minters_count,
      };

      setPages([
        {
          label: 'Insights',
          component: (
            <Insights
              sentimentData={sentimentData}
              address={nft.contract_address}
              network={nft.blockchain}
              contractType={nft.contract_type}
              total={nft.count}
              revenueData={revenueData}
              mintData={mintData}
              isLoading={isLoading || isFetching}
            />),
          id: 1,
        },
        {
          label: 'Investor Profile',
          component: (
            <InvestorProfile
              address={nft.contract_address}
              holders={nft.holder_count}
              network={nft.blockchain}
              symbol={nft.symbol}
              pctHeldOver30days={nft.pct_held_over_30_days}
            />),
          id: 2,
        },
        {
          label: 'Investor Portfolio',
          component: (
            <InvestPortfolio
              address={nft.contract_address}
              holders={nft.holder_count}
            />),
          id: 3,
        },
        {
          label: 'Holders',
          component: <Holders address={nft.contract_address} />,
          id: 4,
        },
        {
          label: 'Dapp Usage',
          component: <DappUsage holders={nft.holder_count} />,
          id: 5,
        },
        {
          label: 'Exchanges',
          component: <Exchanges holders={nft?.holder_count} />,
          id: 6,
        },
        // {
        //   label: 'Items',
        //   component: <ComingSoon />,
        //   id: 5,
        // },
        // { label: 'News', component: <ComingSoon />, id: 6 },
        // { label: 'Social', component: <ComingSoon />, id: 7 },
        // { label: 'Contract', component: <ComingSoon />, id: 8 },
        // { label: 'Competitors', component: <ComingSoon />, id: 9 },
      ]);
    }
  }, [dispatch, isFetching, isLoading, nft, statsTrading]);

  useEffect(() => {
    if (searchParams.get('scroll') && navType === 'POP') {
      setTimeout(
        () => window.scrollTo({ top: +searchParams.get('scroll'), left: 0, behavior: 'smooth' }),
        100,
      );
    }
  }, [searchParams.get('scroll')]);

  useEffect(() => () => {
    dispatch(setTabPages([]));
    dispatch(resetSelectedAsset());
  }, [dispatch]);

  return (
    <>
      {!(isLoading || isFetching) && (
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            {nft?.name || 'NFT'}
            {' '}
            Insights
            {' '}
            - Absolute Labs Platform
          </title>
        </Helmet>
      )}
      <SingleNFTTitle
        isAssetLoading={isLoading || isFetching}
        singleNftData={titleSectionData}
      />
      <div className="asset-section mt-3">
        <Market
          contractCount={titleSectionData?.contractCount}
          isAssetLoading={isLoading || isFetching}
          marketData={marketData.NFTMarketData}
          id={marketData.name}
          widgetData={marketData.WidgetData || [{}, {}, {}, {}, {}]}
          infoData={marketData.InfoData}
          chartData={statsTrading}
          isLoading={isLoading || isFetching || isStatsTradingLoading || isStatsTradingFetching}
          isReachLoading={isReachLoading || isReachFetching || isLoading || isFetching}
          holders={nft?.holder_count}
          reach={reach}
          exchange={ethereum?.market_data.current_price.usd}
          status={status}
          type="nft"
        />
      </div>
      <div className="row asset-section m-o mt-3">
        <div className="title-gap asset-navigation">
          <ul className="nav nav-pills gap-2">
            {(navType === 'POP' && tabPages?.length ? tabPages : pages).map((item) => (
              <li
                role="presentation"
                className="nav-item cursor-pointer"
                key={item.id}
                onClick={() => {
                  setItemPage(item.label);
                  setSearchParams({
                    ...Object.fromEntries([...searchParams]),
                    tab: item.label,
                    scroll: '',
                    // page: '1',
                    // orderBy: '',
                  });
                }}
              >
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link
                  className={`nav-link ${item.label === itemPage ? 'active' : ''}`}
                  aria-current="page"
                  to={`${pathname}${search}`}
                >
                  {item.label}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="dropdown-divider w-100" />
        {(navType === 'POP' && tabPages?.length ? tabPages : pages).map((item) => (item.label === itemPage ? (
          <div key={item.id}>{item.component}</div>
        ) : null))}
      </div>
    </>
  );
};

export default NFTDetails;
