import React from 'react';
// import TopFiveTransaction from './TopFiveTransaction';
// import Sentiment from './Sentiment';
import Mint from './Mint';
import Revenue from './Revenue';
import Distribution from './Distribution';
import Sales from './Sales';
import styles from './Insights.module.scss';

const Insights = ({
  address, total, revenueData, network, mintData, contractType,
}) => (
  <div className={`${styles.wrapper} d-flex flex-column gap-4`}>
    <div className={`${styles.insights_container} d-grid gap-4`}>
      <Distribution address={address} network={network} total={total} />
      <Revenue
        revenueData={revenueData}
        info="Revenue generated by the initial sale and subsequent royalties on NFT transfers"
      />
      {contractType === 'erc1155'
        ? null
        : <Mint data={mintData} />}
      {/* <Sentiment sentimentData={sentimentData} /> */}
      {/* <TopFiveTransaction address={address} ethPrice={ethPrice} /> */}
    </div>
    <Sales />
  </div>
);

export default Insights;
