import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import 'bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import * as FullStory from '@fullstory/browser';
import mixpanel from 'mixpanel-browser';

import './scss/globals/_globals.scss';
import App from './App';

if (import.meta.env.VITE_APP_FULLSTORY_ORG && process.env.NODE_ENV === 'production') {
  FullStory.init({
    orgId: import.meta.env.VITE_APP_FULLSTORY_ORG,
    host: 'fs.absolutelabs.app',
    script: 'fs.absolutelabs.app/s/fs.js',
  });
}

if (import.meta.env.VITE_APP_MIXPANEL_TOKEN) {
  mixpanel.init(
    import.meta.env.VITE_APP_MIXPANEL_TOKEN,
    {
      track_pageview: false,
      api_host: import.meta.env.VITE_APP_MIXPANEL_PROXY
        ? 'https://tracking-proxy.absolutelabs.app' : 'https://api.mixpanel.com',
    },
  );
}

Sentry.init({
  enabled: process.env.NODE_ENV === 'production',
  dsn: import.meta.env.VITE_SENTY_DSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 0.5,
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
