import React from 'react';
import { useLocation } from 'react-router-dom';
import NotableInvestments from '../../../components/ui/NotableInvestments';
import SharedInterests from '../../../components/ui/SharedInterests';
import { audiencesApi } from '../../../api/audiences';

const InvestorProfile = ({ holders, assetsRefetch }) => {
  const { pathname } = useLocation();

  const {
    currentData: notableInvestments,
    isLoading: isNotableInvestmentsLoading,
    isFetching: isNotableInvestmentsFetching,
  } = audiencesApi.useGetSegmentNotableInvestmentsQuery(
    {
      id: pathname.split('/')[2],
    },
    {
      skip: !pathname.split('/')[2],
    },
  );

  const {
    currentData: sharedInterestsData,
    isLoading: sharedInterestsIsLoading,
    isFetching: sharedInterestsIsFetching,
  } = audiencesApi.useGetSegmentShareInterestQuery(
    {
      id: pathname.split('/')[2],
    },
    {
      skip: !pathname.split('/')[2],
    },
  );

  return (
    <div className="row my-4">
      <div className="col-12 col-lg-6 col-xl-4">
        <NotableInvestments
          text="tokens"
          symbol="audience"
          isNotableInvestmentsLoading={isNotableInvestmentsLoading}
          isNotableInvestmentsFetching={isNotableInvestmentsFetching}
          data={notableInvestments?.tokens}
          holders={holders}
          info="Tokens most commonly held by holders of this audience"
          assetsRefetch={assetsRefetch}
        />
      </div>
      <div className="col-12 col-lg-6 col-xl-4 mt-4 mt-lg-0">
        <NotableInvestments
          text="NFTs"
          symbol="audience"
          isNotableInvestmentsLoading={isNotableInvestmentsLoading}
          isNotableInvestmentsFetching={isNotableInvestmentsFetching}
          data={notableInvestments?.nfts}
          holders={holders}
          info="NFTs most commonly held by holders of this audience"
          assetsRefetch={assetsRefetch}
        />
      </div>
      <div className="col-12 col-lg-6 col-xl-4 mt-4 mt-xl-0">
        <SharedInterests
          symbol="AUDIENCE"
          data={sharedInterestsData}
          isLoading={sharedInterestsIsLoading}
          isFetching={sharedInterestsIsFetching}
          info="Topics of interest for audience"
        />
      </div>
    </div>
  );
};

export default InvestorProfile;
