import React, { useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import RelevantHolders from './RelevantHolders';
import { coinApi } from '../../../api/coin';
import HoldersCountHistory from './HoldersCountHistory';
import HoldersList from '../../../components/ui/HoldersList';
import styles from './Holders.module.scss';
import NotSupportedBlockchain from '../../../components/NotSupportedBlockchain';

const Holders = ({
  holders, isLoading, isFetching, isSupportedBlockchain,
}) => {
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const [page, setPage] = useState(Number(searchParams.get('page')) || 1);
  const [desc, setDesc] = useState(searchParams.get('order') !== 'false');
  const [order, setOrder] = useState(searchParams.get('orderBy') || 'value');
  const [limit, setLimit] = useState(searchParams.get('perPage') || 25);

  const {
    data,
    isLoading: isHoldersListLoading,
    isFetching: isHoldersListFetching,
  } = coinApi.useGetTokenHoldersListQuery({
    id: pathname.split('/')[2],
    des: desc ? '1' : '0',
    limit: +limit,
    order,
    page,
  });

  const [getTokenHolderListCSV, result] = coinApi.useGetTokenHolderListCSVMutation();

  const {
    data: tokensChanges,
    isLoading: isTokensChangesLoading,
    isFetching: isTokensChangesFetching,
  } = coinApi.useGetChangesOfTokensHoldersQuery({
    id: pathname.split('/')[2],
  });

  return (
    isSupportedBlockchain ? (
      <div className={styles.wrapper}>
        <div className="row">
          <div className="col-12 col-lg-6 col-xl-8">
            <HoldersCountHistory
              data={tokensChanges}
              isLoading={isTokensChangesLoading}
              isFetching={isTokensChangesFetching}
            />
          </div>
          <div className="col-12 col-lg-6 col-xl-4">
            <RelevantHolders
              holders={holders?.holder_count}
              relevantHolders={holders?.relevant_holder_count}
              isLoading={isLoading}
              isFetching={isFetching}
              title="Relevant Holders"
            />
          </div>
        </div>
        <div className="row mt-4">
          <HoldersList
            data={data}
            isLoading={isHoldersListLoading || isHoldersListFetching}
            limit={limit}
            setLimit={setLimit}
            setDesc={setDesc}
            setPage={setPage}
            setOrder={setOrder}
            page={page}
            desc={desc}
            order={order}
            result={result}
            downloadCSV={getTokenHolderListCSV}
          />
        </div>
      </div>
    ) : <NotSupportedBlockchain />
  );
};

export default Holders;
