import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Spinner from '../../../base/Spinner';
import DropdownMenu from '../../../base/DropdownMenu';
import { userApi } from '../../../../api/user';
import { showErrorMessage, showSuccessMessage } from '../../../base/Notifications';
import { handleActionAsset } from '../../../../utils/analiticsPage/assetActions';
import styles from '../TitleSection.module.scss';

const ToDashboardButton = ({ type, data, loading }) => {
  const { network, name } = useParams();

  const [location, setLocation] = useState('none');

  const selectButtonData = {
    assets: {
      text: 'Remove from My Assets',
      class: `btn-success ${styles.buttonSecondaryColor}`,
    },
    watchList: {
      text: 'Remove from WatchList',
      class: `btn-success ${styles.buttonSecondaryColor}`,
    },
    none: {
      text: 'Add to Dashboard',
      class: `btn-dark ${styles.buttonPrimaryColor}`,
    },
  };

  const [actionOnAsset, result] = userApi.useActionOnAssetMutation();

  useEffect(() => {
    if (data) {
      if (data[0].in_assets) {
        setLocation('assets');
      } else if (data[0].in_watch_list) {
        setLocation('watchList');
      } else {
        setLocation('none');
      }
    }
  }, [data]);

  useEffect(() => {
    if (result.isSuccess) {
      if (result.originalArgs.action === 'add') {
        if (result.originalArgs.place_type === 'asset') {
          setLocation('assets');
        }
        if (result.originalArgs.place_type === 'watchlist') {
          setLocation('watchList');
        }
        showSuccessMessage(result.data.detail || result.data.message);
        result.reset();
      }
      if (result.originalArgs.action === 'delete') {
        setLocation('none');
        showSuccessMessage(result?.data?.message || 'The object has been successfully removed');
        result.reset();
      }
    }
    if (result.isError) {
      showErrorMessage(result.error.data.detail || 'Something went wrong');
      result.reset();
    }
  }, [name, result, type]);

  const deleteHandler = location !== 'none'
    ? async () => {
      await actionOnAsset(handleActionAsset(type, { id: name, network }, selectButtonData[location].text));
    }
    : () => {};

  const menuData = [
    {
      name: 'My Assets',
      action: async (elem) => {
        await actionOnAsset(handleActionAsset(type, { id: name, network }, selectButtonData[location].text, elem));
      },
    },
    {
      name: 'Watchlist',
      action: async (elem) => {
        await actionOnAsset(handleActionAsset(type, { id: name, network }, selectButtonData[location].text, elem));
      },
    },
  ];

  return (
    <div
      className={`${styles.row} ${styles.popoverWrapper}`}
    >
      <button
        type="button"
        onClick={() => deleteHandler()}
        className={`btn ${styles.assetButton} ${selectButtonData[location].class}`}
      >
        {loading || result.isLoading || result.isFetching ? (
          <div className={styles.spinnerContainer}>
            <Spinner />
          </div>
        ) : (
          <span className={styles.buttonText}>{selectButtonData[location].text}</span>
        )}
      </button>

      {location === 'none'
        ? (
          <div className={styles.popoverContentButton}>
            <DropdownMenu menuData={menuData} />
          </div>
        )
        : null}
    </div>
  );
};

export default ToDashboardButton;
