import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { format } from 'date-fns';
import TitleSocials from './TitleSocials/TitleSocials';
import { useWindowSize } from '../../../hooks/app';
import { coinApi } from '../../../../api/coin';
import { selectSelectedAsset, setAssetSlug } from '../../../../store/reducers/app';
import ToDashboardButton from '../ToDashboardButton';
import { LoadingAvatar, LoadingLines } from '../../modals/SearchModal/LoadingList';
import { tooltipChainIconPicker } from '../../../../tools/ChainIconPicker';
import AddressesModal from '../../NotableInvestments/AddressesModal';
import uniqueId from '../../../../utils/uniqueId';
import Verification from '../../Verification';
import DefaultIcon from '../../DefaultIcon';
import { showSuccessMessage } from '../../../base/Notifications';
import Tooltip from '../../Tooltip';
import ethereumIcon from '../../../../assets/icons/eth.svg';
import polygonIcon from '../../../../assets/icons/polygon logo svg.svg';
import styles from '../TitleSection.module.scss';
import { getIsSearchModalOpen, setContractDeployer } from '../../../../store/reducers/search';
import { userApi } from '../../../../api/user';

const SingleNFTTitle = ({ isAssetLoading, singleNftData }) => {
  const dispatch = useDispatch();
  const selectedAsset = useSelector(selectSelectedAsset);

  const [readMoreIndicator, setReadMoreIndicator] = useState(false);
  const [imgError, setImgError] = useState(false);
  const [data, setData] = useState({});

  const { width } = useWindowSize();

  const { pathname } = useLocation();

  const {
    data: deployer,
  } = coinApi.useGetAssetDeployerQuery({
    assetType: 'nft',
    assetId: pathname.split('/')[3],
  });

  const {
    data: nftInAsset,
    isLoading,
    isFetching,
  } = userApi.useGetNFTsInAssetsQuery([{
    address: pathname.split('/')[3],
    network: pathname.split('/')[2],
  }]);

  useEffect(() => {
    if (isAssetLoading) {
      setReadMoreIndicator(false);
      setImgError(false);
    }
  }, [isAssetLoading]);

  useEffect(() => {
    if (!Object.keys(selectedAsset).length) {
      setData({});
    }
    if (Object.keys(selectedAsset).length && isAssetLoading) {
      setData(selectedAsset);
    }
    if (singleNftData && !isAssetLoading) {
      setData(singleNftData);
    }
  }, [isAssetLoading, selectedAsset, singleNftData]);

  const deployerContent = useMemo(() => {
    if (deployer && !isAssetLoading) {
      return (
        <div className={`${styles.description} ${data?.contractCount ? '' : 'mt-2'}`}>
          {deployer?.show_first ? 'First deployed by' : 'Deployed by'}
          {' '}
          <span
            role="presentation"
            onClick={() => {
              dispatch(getIsSearchModalOpen(true));
              dispatch(setContractDeployer(deployer?.deployer_address));
            }}
          >
            {`${deployer?.deployer_address?.slice(0, 6)}...${deployer?.deployer_address?.slice(-4)}`}
          </span>
          {' '}
          on
          {' '}
          {format(new Date(deployer?.deployed_at), 'MMM dd, yyyy')}
        </div>
      );
    }
    return null;
  }, [deployer, dispatch, isAssetLoading]);

  function handleCopyClick(network) {
    navigator.clipboard.writeText(data?.id)
      .then(() => {
        showSuccessMessage(`${network} address was copied to your clipboard`);
      });
  }

  return (
    <div className={`${styles.nftWrapper} ${styles.defaultHeight}`}>
      {data.slug && data.contractCount > 1 && <AddressesModal />}
      {width >= 640 && (
        <div className={styles.imageContainer}>
          {!data.logo
            ? <LoadingAvatar size={75} />
            : data.logo && !imgError
              ? (
                <img
                  src={data.logo}
                  width="75"
                  height="75"
                  alt="logo"
                  onError={() => setImgError(true)}
                />
              )
              : <DefaultIcon type="nft" size="75px" />}
        </div>
      )}
      <div className="d-flex flex-column">
        <div className={styles.row}>
          {width < 640 && (
            <div className="d-flex flex-column align-items-center">
              <div className="d-flex align-items-end">
                <div className={styles.imageContainer}>
                  {!data.logo && isAssetLoading
                    ? <LoadingAvatar size={75} />
                    : data.logo && !imgError
                      ? (
                        <img
                          src={data.logo}
                          width="75"
                          height="75"
                          alt="thumb"
                          onError={() => setImgError(true)}
                        />
                      )
                      : <DefaultIcon type="nft" size="75px" />}
                </div>
                <div className="d-flex mx-3 gap-1 align-items-start">
                  {data.symbol
                    ? (
                      <p className={styles.token}>
                        {`$${data.symbol}`}
                      </p>
                    )
                    : null}
                </div>
              </div>
              {data.network
                ? (
                  <div className="d-flex gap-1 mt-3">
                    <div
                      className={`${styles.nft_pill} px-3 d-flex align-items-center align-self-start`}
                    >
                      NFT Collection
                    </div>
                    <div className={styles.separator_icon} />
                    <div className="d-flex">
                      {data.network.includes('ethereum')
                        && tooltipChainIconPicker('ethereum', uniqueId('chain'))}
                      {data.network.includes('polygon-pos')
                        && tooltipChainIconPicker('polygon', uniqueId('chain'))}
                    </div>
                  </div>
                )
                : null}
            </div>
          )}
          {isAssetLoading && !data.name
            ? (
              <div className="w-25">
                <LoadingLines align="start" />
              </div>
            )
            : (
              <div className="d-flex gap-2 align-items-center">
                <p
                  className={`${styles.title} ${data.name?.length > 10 && styles.titleAdaptive}`}
                >
                  {data.name}
                </p>
                {data.isVerified ? <Verification /> : null}
              </div>
            )}
          <div className={styles.flexAlignRight}>
            <ToDashboardButton
              type="nft"
              data={nftInAsset}
              loading={isLoading || isFetching || isAssetLoading}
            />
          </div>
        </div>
        <div className={`${styles.row} ${styles.nftWrapper} d-flex`}>
          {width > 640 && (
            <>
              {data.symbol
                ? (
                  <p className={styles.token}>
                    {`$${data.symbol}`}
                  </p>
                )
                : null}
              {data.network
                ? (
                  <>
                    <div
                      className={`${styles.nft_pill} px-3 d-flex align-items-center align-self-start`}
                    >
                      NFT Collection
                    </div>
                    <div className={`${styles.separator_icon} d-flex align-self-baseline`} />
                    <div className="d-flex align-self-start" role="presentation">
                      {data.network.includes('ethereum')
                          && (
                            <div
                              role="presentation"
                              className="cursor-pointer"
                              onClick={() => handleCopyClick('Ethereum')}
                            >
                              <div
                                className="d-flex"
                                data-for="ethereumTT"
                                data-tip
                              >
                                <img src={ethereumIcon} alt="" width="24px" height="24px" />
                              </div>
                              <Tooltip
                                id="ethereumTT"
                                info="Click to copy Ethereum address"
                              />
                            </div>
                          )}
                      {data.network.includes('polygon')
                          && (
                            <div
                              role="presentation"
                              className="cursor-pointer"
                              onClick={() => handleCopyClick('Polygon')}
                            >
                              <div
                                className="d-flex"
                                data-for="polygonTT"
                                data-tip
                              >
                                <img src={polygonIcon} alt="" width="24px" height="24px" />
                              </div>
                              <Tooltip
                                id="polygonTT"
                                info="Click to copy Polygon address"
                              />
                            </div>
                          )}
                    </div>
                  </>
                )
                : null}
              {data.links
                ? (
                  <div className={`${styles.icons} ${styles.flexAlignRight}`}>
                    <TitleSocials links={data.links} />
                  </div>
                )
                : null}
            </>
          )}
        </div>
        {
          data.slug && data.contractCount > 1 ? (
            <div className={`${styles.collection} mb-1`}>
              A sub-collection of
              {' '}
              <span
                role="presentation"
                className="text-decoration-underline cursor-pointer"
                data-bs-toggle="modal"
                data-bs-target="#exModal"
                onClick={() => dispatch(setAssetSlug({
                  slug: data.slug,
                  name: data.collectionsName,
                  logo: data.logo,
                  symbol: data.symbol,
                  network: data.network,
                }))}
              >
                {data.collectionsName}
              </span>
            </div>
          ) : null
        }
        {deployerContent}
        {data.description
          ? (
            <div className={`${styles.row} ${styles.desription_length} ${deployer ? 'mt-2' : ''}`}>
              <p className={styles.description}>
                {!readMoreIndicator ? (
                  <>
                    {data.description.slice(0, 85)}
                    ...
                    {' '}
                    <span
                      role="presentation"
                      onClick={() => setReadMoreIndicator(true)}
                    >
                      Read More
                    </span>
                  </>
                ) : (
                  <>
                    {data.description}
                    {' '}
                    <span
                      role="presentation"
                      onClick={() => setReadMoreIndicator(false)}
                    >
                      Read Less
                    </span>
                  </>
                )}
              </p>
            </div>
          )
          : null}
        {data.links
          ? (
            <div className={styles.row}>
              {width < 640 && (
                <div className={`${styles.icons} ${styles.flexAlignRight}`}>
                  <TitleSocials links={data.links} />
                </div>
              )}
            </div>
          )
          : null}
      </div>
    </div>
  );
};

export default SingleNFTTitle;
