import React, { useState } from 'react';

import { ReactComponent as Polygon } from '../../../../assets/icons/polygon.svg';
import { ReactComponent as Ethereum } from '../../../../assets/icons/ethereum.svg';
import { convertToReadableFormat } from '../../../../tools/NumberConverterTool';
import Verification from '../../../../components/ui/Verification';
import DefaultIcon from '../../../../components/ui/DefaultIcon';
import ItemPreview from '../../../../components/ui/ItemPreview';
import styles from '../inputs/Input.module.scss';

const NftLabel = ({ val }) => {
  const [imgError, setImgError] = useState(false);
  return (
    <div className="d-flex cursor-pointer justify-content-between align-items-top w-100">
      <div className={`d-flex align-items-top flex-wrap ${styles.wrapper}`}>
        <div
          className="me-1"
          data-for={`preview_${val.value}_${val.network}`}
          data-tip="show"
        >
          {val.additionalData && !imgError ? (
            <img
              src={val.additionalData}
              onError={() => setImgError(true)}
              className="rounded-full"
              width="20"
              height="20"
              alt="coin"
            />
          ) : (
            <DefaultIcon size="20px" type="nft" />
          )}
        </div>
        <div>
          <div className={`${styles.label} me-2 text-truncate d-flex`}>
            <span
              className="me-1"
              data-for={`preview_${val.value}_${val.network}`}
              data-tip="show"
            >
              {val?.label}
            </span>
            <ItemPreview
              id={`preview_${val.value}_${val.network}`}
              data={{
                address: val.value,
                type: 'nft',
                blockchain: val.network,
                img: val.additionalData,
                name: val.label,
              }}
            />
            {val.verified
              ? <Verification width="16px" height="16px" />
              : null}
          </div>
          <div className={`${styles.suggest} d-flex gap-2 text-nowrap`}>
            <span>
              {convertToReadableFormat(val?.market_cap)}
              {' '}
              mcap
            </span>
            <span>
              {convertToReadableFormat(val?.holder_count)}
              {' '}
              holders
            </span>
          </div>
        </div>
      </div>
      <div>
        {val?.network === 'polygon'
          ? <Polygon width="20" height="20" />
          : <Ethereum width="20" height="20" />}
      </div>
    </div>
  );
};

export default NftLabel;
