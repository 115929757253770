import React, { useEffect, useMemo, useState } from 'react';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import { ethers } from 'ethers';
import { isCloseSendModal, selectIsCloseSendModal } from '../../../../store/reducers/flows';
import { flowsApi } from '../../../../api/flows';
import { showErrorMessage } from '../../../base/Notifications';
import { ReactComponent as TickCircle } from '../../../../assets/icons/tick_circle.svg';
import { ReactComponent as XCircle } from '../../../../assets/icons/x-circle.svg';
import Loader from '../../../base/Loader';
import CloseModalButton from '../../modals/components/CloseModalButton';
import { getUser } from '../../../../store/reducers/user';
import warning from '../../../../assets/icons/warning_rounded.svg';
import styles from './SendToFLowModal.module.scss';

const SendToFlowModal = ({
  result, resultSend, sendWalletInFlow, checkWalletInFlow,
}) => {
  const dispatch = useDispatch();
  const isCloseModal = useSelector(selectIsCloseSendModal);
  const user = useSelector(getUser);

  const [selectFlows, setSelectFlows] = useState([]);
  const [showAirDropWarning, setShowAirDropWarning] = useState(false);

  const { name } = useParams();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
  } = useForm();

  const {
    data: flows,
    isLoading,
    isFetching,
    error,
  } = flowsApi.useGetFlowsQuery(
    {
      limit: 1000000,
    },
    {
      skip: isCloseModal,
    },
  );

  const clearFlow = () => {
    setTimeout(() => {
      reset({
        flow_id: '',
        flow_name: '',
        isAirdropUsed: '',
      });
      dispatch(isCloseSendModal(true));
      setShowAirDropWarning(false);
    }, 500);
    clearTimeout();
  };

  const onSubmit = (onSubmitData) => {
    if (watch('isAirdropUsed') && !user.air_drop) {
      setShowAirDropWarning(true);
    } else {
      checkWalletInFlow({
        id: onSubmitData.flow_id,
        address: ethers.utils.isAddress(name) ? `?address=${name}` : `?alid=${name}`,
      });
    }
  };

  useEffect(() => {
    if (flows && flows?.results && flows?.results?.length) {
      setSelectFlows(flows?.results.filter((elem) => elem.status === 'running')
        .map((item) => ({
          value: item.id,
          label: item.name,
          isAirdropUsed: item?.has_airdrop_node,
        })));
    } else {
      setSelectFlows([]);
    }
  }, [flows]);

  useEffect(() => {
    if (result.isSuccess) {
      if (!result?.data?.wallet_in_flow) {
        sendWalletInFlow({
          id: watch('flow_id'),
          address: ethers.utils.isAddress(name) ? {
            address: name,
          } : {
            alid: name,
          },
        });
        result.reset();
      }
    }
    if (result.isError) {
      showErrorMessage('Something went wrong');
      result.reset();
    }
  }, [name, result, sendWalletInFlow, watch]);

  useEffect(() => {
    if (resultSend.isError) {
      showErrorMessage('Something went wrong');
    }
  }, [resultSend, setValue]);

  const content = useMemo(() => {
    if (error?.status === 403 || resultSend?.error?.status === 403) {
      return (
        <div className="p-3">
          <div className={`${styles.title} px-3 mt-2 d-flex justify-content-start`}>
            We are sorry...
          </div>
          <div className="text-md-regular px-3 py-2">
            {
              resultSend?.error?.data?.detail
                ? resultSend?.error?.data?.detail
                : (`The feature you’re trying to use has restricted access. 
                Please reach out to your Customer Success Manager.`)
            }
          </div>
          <div className="d-flex justify-content-end align-items-center px-3 mt-3 mb-3">
            <button
              className="regular-button"
              type="button"
              data-bs-dismiss="modal"
              onClick={() => clearFlow()}
            >
              Okay
            </button>
          </div>
        </div>
      );
    }
    if (result.isLoading || result.isFetching || resultSend.isLoading || resultSend.isFetching) {
      return (
        <div className={`${styles.loading} d-flex justify-content-center w-100 flex-column p-4 align-items-center`}>
          <span>Please wait...</span>
          <span>Sending can take up to a minute.</span>
          <div className="my-4">
            <Loader />
          </div>
        </div>
      );
    }
    if (result?.isSuccess && result?.data?.wallet_in_flow) {
      return (
        <div className="ps-4">
          <div className={`${styles.title} d-flex mt-2`}>
            Warning
          </div>
          <div className={`${styles.description} d-flex w-100 mt-3`}>
            <span>
              This wallet already went through the selected flow. Do you still want to proceed?
            </span>
          </div>
          <div className={`${styles.footer} my-4 d-flex align-self-end flex-row-reverse pe-3`}>
            <button
              type="button"
              className="regular-button"
              onClick={() => {
                result.reset();
                sendWalletInFlow({
                  id: watch('flow_id'),
                  address: ethers.utils.isAddress(name) ? {
                    address: name,
                  } : {
                    alid: name,
                  },
                });
              }}
            >
              Yes
            </button>
            <button
              type="button"
              className="outline-button border border-1"
              data-bs-dismiss="modal"
              onClick={() => reset({
                flow_id: '',
                flow_name: '',
                isAirdropUsed: '',
              })}
            >
              Cancel
            </button>
          </div>
        </div>
      );
    }
    if (resultSend.isSuccess) {
      return (
        <div
          className={
            `${styles.loading} d-flex justify-content-center w-100 flex-column px-4 pb-4 pt-2 align-items-center`
          }
        >
          <TickCircle className="mb-2" />
          <span>The wallet has been sent successfully. </span>
          <span
            className={`${styles.info} mb-4`}
          >
            {`The wallet has been sent to the “${watch('flow_name')}” flow.`}
          </span>
        </div>
      );
    }
    if (resultSend.isError) {
      return (
        <div className={
          `${styles.loading} d-flex justify-content-center w-100 flex-column px-4 pb-4 pt-2 align-items-center`
        }
        >
          <XCircle className="mb-2" />
          <span>The wallet wasn’t sent to the Flow.</span>
          <span className="mb-4">Please refresh the page and try again.</span>
        </div>
      );
    }
    return (
      <form className="ps-4" onSubmit={handleSubmit(onSubmit)}>
        <div className={`${styles.title} d-flex mt-2`}>
          Send to a Flow
        </div>
        <div className={`${styles.description} d-flex w-100 mt-3`}>
          <span>
            Select a flow to send this wallet to.
          </span>
        </div>
        <div className={`${styles.select} d-flex align-items-center w-100 mt-3 pe-3`}>
          <Select
            {...register('flow_id')}
            className="w-100"
            placeholder="Select..."
            maxMenuHeight={194}
            onChange={(option) => {
              setValue('flow_id', option.value);
              setValue('flow_name', option.label);
              setValue('isAirdropUsed', option.isAirdropUsed);
              setShowAirDropWarning(false);
            }}
            value={watch('flow_id') ? [{ value: watch('flow_id'), label: watch('flow_name') }] : ''}
            isLoading={isLoading || isFetching}
            options={isLoading || isFetching ? [] : selectFlows}
          />
        </div>
        {showAirDropWarning && (
          <div className={`d-flex w-100 gap-2 mt-2 ${styles.wrap_warning}`}>
            <img src={warning} alt="warning" width="24px" height="24px" className="mt-1 align-self-start" />
            <div className="d-flex text-start">
              Your account is not allowed to launch a Flow performing airdrops.
              Please select another flow or communicate with your CSM for details.
            </div>
          </div>
        )}
        <div className={`${styles.footer} my-4 d-flex align-self-end flex-row-reverse pe-3`}>
          <button
            type="submit"
            className="regular-button"
            disabled={!watch('flow_id')}
          >
            Send
          </button>
          <button
            type="button"
            className="outline-button border border-1"
            data-bs-dismiss="modal"
            onClick={() => clearFlow()}
          >
            Cancel
          </button>
        </div>
      </form>
    );
  }, [
    error?.status,
    result,
    resultSend?.isLoading,
    resultSend?.isFetching,
    resultSend?.isSuccess,
    resultSend?.isError,
    handleSubmit,
    onSubmit,
    clearFlow,
    register,
    showAirDropWarning, watch, isLoading, isFetching, selectFlows, sendWalletInFlow, name, reset, setValue]);

  return (
    <div
      className="modal fade"
      id="exampleModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      onClick={() => clearFlow()}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        role="presentation"
        className="modal-dialog modal-dialog-centered"
      >
        <div className="modal-content border-0 d-flex pt-4">
          <CloseModalButton onCancel={() => clearFlow()} />
          {content}
        </div>
      </div>
    </div>
  );
};

export default SendToFlowModal;
