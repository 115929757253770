import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import styles from './CSVUpload.module.scss';
import FileInput from './FileInput';
import CSVTable from './CSVTable';
import { audiencesApi } from '../../api/audiences';
import { clearCSVUpload } from '../../store/reducers/csvUpload';

const NewCustomList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [fields, setFields] = useState([]);
  const [file, setFile] = useState();

  const {
    data: attributesFields,
    isLoading,
    isFetching,
  } = audiencesApi.useGetCustomerDataFieldsQuery();

  return (
    <div>
      {!fields.length
        ? (
          <FileInput
            setFields={setFields}
            setFile={setFile}
          />
        )
        : (
          <CSVTable
            file={file}
            fields={fields}
            attributesFields={attributesFields}
            isLoading={isLoading || isFetching}
          />
        )}
      {!fields.length
        ? (
          <div
            className={`
              ${styles.nav}
              justify-content-end
              d-flex
              align-items-center`}
          >
            <div className="d-flex align-items-center gap-3">
              <button
                type="button"
                className="outline-button"
                onClick={() => {
                  dispatch(clearCSVUpload());
                  navigate('/audiences');
                }}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="regular-button"
                disabled
              >
                Save
              </button>
            </div>
          </div>
        )
        : null}
    </div>
  );
};

export default NewCustomList;
