import React, { useMemo } from 'react';
import Select from 'react-select';
import { ReactComponent as Trash } from '../../../assets/icons/trash.svg';
import styles from './ConditionForm.module.scss';
import Spinner from '../../../components/base/Spinner';
import { convertToReadableFormat } from '../../../tools/NumberConverterTool';
import { shouldSkipWalletFetch } from '../../../utils/segments';
import { placeholderStyle } from '../Condition/Condition.constants';

const ConditionForm = ({
  conditionName,
  edit,
  data,
  content,
  deleteCondition,
  firstSelectOptions,
  defineFirstSelectOptions,
  isTotalWalletCountLoading,
  isTotalWalletCountFetching,
  showAddresses,
  error,
  totalWalletCount,
  walletCountBody,
  page = 'add-segment',
}) => useMemo(
  () => {
    if (page === 'add-segment') {
      return (
        <div className={styles.wrapper}>
          <div className="d-flex align-items-center justify-content-between">
            <div className="text-md-bold">{conditionName}</div>
            {edit && (
              <Trash
                className="cursor-pointer position-relative"
                onClick={() => {
                  deleteCondition();
                }}
              />
            )}
          </div>
          <div
            className={`${styles.adaptive} align-items-end gap-3 mt-2`}
          >
            {firstSelectOptions && (
              <div className="w-100">
                <Select
                  styles={placeholderStyle}
                  maxMenuHeight={194}
                  defaultValue=""
                  options={[...firstSelectOptions]}
                  onChange={(val) => {
                    defineFirstSelectOptions(val.value);
                  }}
                  isDisabled={!edit}
                  value={firstSelectOptions.filter((opt) => opt.value === data?.name)[0] || ''}
                />
              </div>
            )}
            {content}
          </div>
          <div className={`ms-auto text-md-regular mt-2 ${styles.addresses}`}>
            {isTotalWalletCountLoading || isTotalWalletCountFetching ? <Spinner /> : (
              <>
                {(showAddresses && !error && !shouldSkipWalletFetch(walletCountBody))
                            && convertToReadableFormat(totalWalletCount?.profile_count)}
                {(showAddresses && !error && !shouldSkipWalletFetch(walletCountBody))
                            && (totalWalletCount?.profile_count === 1 ? ' profile' : ' profiles')}
              </>
            )}
          </div>
        </div>
      );
    }

    return (
      <div className="d-flex flex-column gap-2">
        <div className="d-flex align-items-center justify-content-between">
          <div className="title-h5-bold">{conditionName}</div>
          {edit && (
            <Trash
              className="cursor-pointer position-relative"
              onClick={() => {
                deleteCondition();
              }}
            />
          )}
        </div>
        {firstSelectOptions && (
          <div className="w-100">
            <Select
              styles={placeholderStyle}
              maxMenuHeight={194}
              defaultValue=""
              options={[...firstSelectOptions]}
              onChange={(val) => {
                defineFirstSelectOptions(val.value);
              }}
              isDisabled={!edit}
              value={firstSelectOptions.filter((opt) => opt.value === data?.name)[0] || ''}
            />
          </div>
        )}
        {content}
        <div className={`ms-auto text-md-regular mt-2 ${styles.addresses}`}>
          {isTotalWalletCountLoading || isTotalWalletCountFetching ? <Spinner /> : (
            <>
              {(showAddresses && !error && !shouldSkipWalletFetch(walletCountBody))
                  && convertToReadableFormat(totalWalletCount?.profile_count)}
              {(showAddresses && !error && !shouldSkipWalletFetch(walletCountBody))
                  && (totalWalletCount?.profile_count === 1 ? ' profile' : ' profiles')}
            </>
          )}
        </div>
      </div>
    );
  },
  [
    conditionName,
    content,
    data?.name,
    defineFirstSelectOptions,
    deleteCondition,
    edit,
    error,
    firstSelectOptions,
    isTotalWalletCountFetching,
    isTotalWalletCountLoading,
    page,
    showAddresses,
    totalWalletCount,
    walletCountBody,
  ],
);

export default ConditionForm;
