import React, { useState, useEffect, useMemo } from 'react';
import {
  useLocation, useParams, useSearchParams, Link,
} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ethFormat } from '../../../utils/singleAssetPage/parseData';
import Loader from '../../base/Loader';
import { ReactComponent as Snapshot } from '../../../assets/icons/camera.svg';
import { ReactComponent as SnapshotActive } from '../../../assets/icons/camera_active.svg';
import SaveSnapshotModal from '../modals/SaveSnapshotModal';
import Tooltip from '../Tooltip';
import uniqueId from '../../../utils/uniqueId';
import { getWalletAge } from '../../../utils/segments';
import { tooltipChainIconPicker } from '../../../tools/ChainIconPicker';
import DownloadModal from '../modals/DownloadModal';
import DownloadTooltip from '../DownloadTooltip';
import { counter } from '../../../tools/Counter';
import { showSuccessMessage, showErrorMessage } from '../../base/Notifications';
import { coinApi } from '../../../api/coin';
import Table from '../../base/Table';
import ExportCSV from '../../svgIcons/ExportCSV';
import ItemPreview from '../ItemPreview';
import ConfirmModal from '../modals/ConfirmModal';
import { createdCustomList } from '../../../store/reducers/segment';
import { aliasData } from '../../../utils/wallets';
import tableStyles from '../../base/Table/Table.module.scss';
import styles from './HoldersList.module.scss';

const warningsList = [
  'No more than 50,000 profiles will be downloaded',
];

const HoldersList = ({
  data,
  isLoading,
  page,
  setPage,
  setDesc,
  limit,
  setLimit,
  desc,
  order,
  setOrder,
  result,
  downloadCSV,
  nft,
}) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { name, network } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const [isDownloaded, setIsDownloaded] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [tableData, setTableData] = useState([]);

  const [isActive, setIsActive] = useState(false);
  const [snapshotItem, setSnapshotItem] = useState(false);
  const [snapshotName, setSnapshotName] = useState('');
  const [snapshotNft, snapshotNftResult] = coinApi.usePostSnapshotOfNftHolderListMutation();
  const [snapshotCoin, snapshotCoinResult] = coinApi.usePostSnapshotOfCoinHolderListMutation();

  const toolTipId = useMemo(() => uniqueId('asset-download'), []);
  const customListTipId = useMemo(() => uniqueId('asset-custom-list'), []);

  useEffect(() => {
    if (result.isLoading) {
      setIsOpen(true);
    }
    if (result.isSuccess && result.data) {
      const handleOpen = () => {
        setTimeout(() => {
          window.location.replace(result.data[0].link);
        }, 1000);
      };
      handleOpen();
    }
    if (result.error?.status === 403) {
      setIsOpen(false);
    }
    return () => clearTimeout();
  }, [result]);

  useEffect(() => {
    if (snapshotCoinResult.isSuccess) {
      showSuccessMessage('Custom List was successfully saved');
      dispatch(createdCustomList(snapshotCoinResult?.data?.id));
      setTimeout(() => {
        dispatch(createdCustomList(null));
      }, 10000);
      setSnapshotItem(false);
      snapshotCoinResult.reset();
    }
    if (snapshotCoinResult.isError) {
      showErrorMessage(snapshotCoinResult?.error?.data?.detail || 'Something went wrong');
      setSnapshotItem(false);
      snapshotCoinResult.reset();
    }
  }, [dispatch, snapshotCoinResult]);

  useEffect(() => {
    if (snapshotNftResult.isSuccess) {
      showSuccessMessage('Custom List was successfully saved');
      dispatch(createdCustomList(snapshotNftResult?.data?.id));
      setTimeout(() => {
        dispatch(createdCustomList(null));
      }, 10000);
      setSnapshotItem(false);
      snapshotNftResult.reset();
    }
    if (snapshotNftResult.isError) {
      showErrorMessage(snapshotNftResult?.error?.data?.detail || 'Something went wrong');
      setSnapshotItem(false);
      snapshotNftResult.reset();
    }
  }, [dispatch, snapshotNftResult]);

  const header = [
    {
      field: 'address_name',
      title: 'Address Name',
    },
    {
      field: '',
      title: 'Alias',
    },
    {
      field: 'token_qty',
      title: 'Token Qty',
    },
    {
      field: 'value',
      title: 'Value',
    },
    {
      field: 'share_of_portfolio',
      title: 'Share of Portfolio',
    },
    {
      field: 'portfolio_value',
      title: 'Portfolio value',
    },
    {
      field: 'age',
      title: 'Wallet age',
    },
    {
      field: '',
      title: (
        <div className="d-flex justify-content-center">
          Active on
        </div>
      ),
    },
  ];

  const handleAddressName = (address, nameAddress) => (address === nameAddress
    ? `${address.slice(0, 6)}...${address.slice(-4)}` : nameAddress);

  useEffect(() => {
    if (data?.result) {
      const temp = data.result.map((elem) => ({
        address_name: (
          <>
            <span
              className={`d-block ${tableStyles.underline_on_hover}`}
              data-for={`Name_${elem.ALID || elem.address_name || elem.address}`}
              data-tip
            >
              <Link
                role="presentation"
                className={`${styles.name} text-truncate`}
                onClick={() => {
                  setSearchParams({
                    ...Object.fromEntries([...searchParams]),
                    scroll: window.scrollY,
                  });
                }}
                to={`/profile/${elem.ALID || elem.address}`}
              >
                {handleAddressName(elem.address, elem.address_name)}
              </Link>
            </span>
            <ItemPreview
              id={`Name_${elem.ALID || elem.address_name || elem.address}`}
              data={{
                id: elem.address,
                name: elem.address,
                alid: elem.ALID || '',
                type: 'wallet',
              }}
            />
          </>
        ),
        alias: (
          elem.alias
            ? (
              <span className="d-block">
                <div
                  className="d-flex gap-2 align-items-baseline"
                >
                  <span>{aliasData[elem.alias_type]}</span>
                  <span>{elem.alias}</span>
                </div>
              </span>
            )
            : '-'
        ),
        token_qty: elem.token_qty && elem.token_qty >= 1
          ? nft
            ? ethFormat(elem.token_qty, 1)
            : ethFormat(elem.token_qty)
          : '<1',
        value: elem.value !== null ? `$${ethFormat(elem.value)}` : '?',
        share_of_portfolio: elem.share_of_portfolio && elem.share_of_portfolio >= 1
          ? `${elem.share_of_portfolio}%`
          : '<1%',
        portfolio_value: `$${ethFormat(elem.portfolio_value)}`,
        age: getWalletAge(elem.age),
        network: (
          <div className="d-flex justify-content-center">
            {elem.wallet_first_txn_timestamp_eth
              && tooltipChainIconPicker('ethereum', uniqueId('chain'))}
            {elem.wallet_first_txn_timestamp_polygon
              && tooltipChainIconPicker('polygon', uniqueId('chain'))}
          </div>
        ),
      }));
      setTableData(temp);
    }
  }, [data]);

  return (
    <div className={`${styles.wrapper} d-flex w-100 flex-column align-items-center`}>
      <div className={styles.title}>Holder List</div>
      {isLoading && !data
        ? (
          <div className="d-flex justify-content-center align-items-center p-5 h-75">
            <Loader />
          </div>
        )
        : (
          <>
            <div className={
              `${styles.content_info} d-flex align-items-center my-2 w-100`
            }
            >
              {counter(data?.count, page, limit)}
              <div className="d-flex flex-grow-1 gap-2 flex-row-reverse ml-auto">
                <button
                  type="button"
                  onClick={() => setSnapshotItem(true)}
                  className={
                    `btn d-flex align-items-center justify-content-center shadow-none m-o p-0 ${styles.button_wrapper}`
                  }
                >
                  <div
                    data-for={customListTipId}
                    data-tip
                    onMouseEnter={() => setIsActive(true)}
                    onMouseLeave={() => setIsActive(false)}
                    className={`${styles.snapshot_container} d-flex`}
                  >
                    {isActive
                      ? (
                        <SnapshotActive className="cursor-pointer" />
                      )
                      : (
                        <Snapshot className="cursor-pointer" />
                      )}
                  </div>
                </button>
                {!snapshotItem && (
                  <Tooltip
                    id={customListTipId}
                    info="Save snapshot as Custom List"
                  />
                ) }
                <button
                  type="button"
                  className="btn d-flex align-items-center justify-content-center shadow-none m-o p-0"
                  data-for={toolTipId}
                  data-tip
                  onMouseEnter={() => setIsDownloaded(true)}
                  onMouseLeave={() => setIsDownloaded(false)}
                  onClick={() => downloadCSV(
                    {
                      id: name,
                      wait: true,
                      blockchain: network || 'ethereum',
                    },
                  )}
                >
                  <ExportCSV hovered={isDownloaded} />
                </button>
                <DownloadTooltip
                  id={toolTipId}
                  header="Export profile list into CSV"
                  warningsList={warningsList}
                />
              </div>
            </div>
            <div className="w-100">
              <Table
                total={data?.count}
                data={tableData}
                desc={desc}
                setDesc={setDesc}
                ordering={order}
                setOrdering={setOrder}
                page={page}
                setPage={setPage}
                limit={limit}
                setLimit={setLimit}
                headers={header}
                isLoading={isLoading}
                min={25}
              />
            </div>
            {isOpen && result?.error?.status !== 403 ? <DownloadModal result={result} setIsOpen={setIsOpen} /> : null}
            {result?.error?.status === 403 ? (
              <ConfirmModal
                title="We are sorry..."
                description="The feature you’re trying to use has restricted access.
                 Please reach out to your Customer Success Manager."
                buttonName="Okay"
                onSubmit={() => result.reset()}
              />
            ) : null}
            {snapshotItem && (
              <SaveSnapshotModal
                value={snapshotName}
                setValue={setSnapshotName}
                onSubmit={() => {
                  try {
                    if (pathname.split('/')[1] === 'nfts') {
                      snapshotNft({ id: name, name: snapshotName, network });
                    } else {
                      snapshotCoin({ id: name, name: snapshotName });
                    }
                  } catch (err) {
                    showErrorMessage(err);
                  }
                }}
                onCancel={() => setSnapshotItem(false)}
                loading={
                  (snapshotCoinResult.isLoading || snapshotCoinResult.isFetching)
                  || (snapshotNftResult.isLoading || snapshotNftResult.isFetching)
                }
              />
            )}
          </>
        )}
    </div>
  );
};

export default HoldersList;
