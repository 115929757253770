import React, { useCallback, useMemo } from 'react';
import Chart from 'react-apexcharts';
import TitleComponent from '../../../../components/base/TitleComponent';
import Loader from '../../../../components/base/Loader';
import { convertNumber } from '../../../../tools/NumberConverterTool';
import NoData from '../../../../components/base/NoData';
import uniqueId from '../../../../utils/uniqueId';
import { ethFormat } from '../../../../utils/singleAssetPage/parseData';
import { legendData } from '../../../../utils/webSDK';
import styles from './UserTabContainer.module.scss';
import LogoDisplay from '../../../../components/ui/modals/SearchModal/LogoDisplay';

const UserTabContainer = ({
  title, isLoading, data, total, info,
}) => {
  const languageNamesInEnglish = new Intl.DisplayNames(['en'], { type: 'language' });
  const regionNamesInEnglish = new Intl.DisplayNames(['en'], { type: 'region' });
  const seriesDataCount = useCallback(() => {
    if (data && data.length && total) {
      if (title === 'Web3 Adoption') {
        const filterdData = data.filter((res) => res.value !== 'no_wallet_detected');
        const sumOfFilteredData = Object.values(filterdData).reduce((acc, value) => acc + value.visitor_count, 0);
        return filterdData.filter((res) => res.value !== 'no_wallet_detected').map((elem) => ({
          value: elem.value.replace('_', ' '),
          percent: ethFormat((elem.visitor_count / sumOfFilteredData) * 100),
          count: elem.visitor_count,
        }));
      }
      let result;
      if (title === 'Languages') {
        result = data.map((elem) => ({
          value: elem.value,
          fullName: languageNamesInEnglish.of(elem.value),
          percent: ethFormat((elem.visitor_count / total) * 100),
          count: elem.visitor_count,
        }));
      } else if (title === 'Countries') {
        result = data.map((elem) => ({
          value: elem.value,
          fullName: regionNamesInEnglish.of(elem.value),
          percent: ethFormat((elem.visitor_count / total) * 100),
          count: elem.visitor_count,
        }));
      } else {
        result = data.map((elem) => ({
          value: elem.value,
          percent: ethFormat((elem.visitor_count / total) * 100),
          count: elem.visitor_count,
        }));
      }
      return [...result, {
        value: 'Other',
        fullName: 'Other',
        percent: ethFormat(((total - data.map((elem) => elem.visitor_count)
          .reduce((acc, curr) => acc + curr)) / total) * 100),
        count: (total - data.map((elem) => elem.visitor_count)
          .reduce((acc, curr) => acc + curr)),
      }];
    }
    return [];
  }, [data, title, total]);
  const handleContent = (text) => {
    switch (text) {
      case 'Languages':
        return 'Most Frequent Language';
      case 'Wallet Types':
        return 'Most Frequent Wallet';
      case 'Chains':
        return 'Most Frequent Blockchain';
      case 'Web3 Adoption':
        return 'Visitors with wallets';
      default:
        return 'Most Frequent';
    }
  };

  const seriesFormatter = (y, seriesIndex) => (
    `${convertNumber(seriesDataCount()[seriesIndex].value)} (${y}%)`);

  const DONUTData = {
    options: {
      chart: {
        type: 'donut',
      },
      plotOptions: {
        pie: {
          expandOnClick: false,
        },
      },
      states: {
        normal: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
        hover: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: 'none',
            value: 0,
          },
        },
      },
      stroke: {
        width: 3,
        colors: ['#f1f4f8'],
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      tooltip: {
        enabled: true,
        marker: {
          show: false,
        },
        x: {
          show: false,
        },
        y: {
          formatter: (y, { ...chartData }) => seriesFormatter(y, chartData.seriesIndex),
          title: {
            formatter: () => '',
          },
        },
      },
      fixed: {
        enabled: false,
      },
      colors: ['#1C76FD', '#FFB946', '#2ED47A', '#885AF8', '#F7685B', '#90a0b7'],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
          },
        },
      ],
    },
  };

  const containerContent = useMemo(() => {
    if (isLoading) {
      return (
        <div className="p-5">
          <Loader />
        </div>
      );
    }
    return (
      <div className="d-flex flex-column h-100">
        {data && data.length && total
          ? (
            <div className="d-flex gap-2 mt-2 mb-2 flex-column w-100">
              <div
                className={`${styles.heightBLock} d-flex justify-content-between align-items-start w-100 my-3 gap-2`}
              >
                <div className={styles.info}>
                  {handleContent(title)}
                </div>
                {title === 'Web3 Adoption' ? (
                  <div>
                    <span className={styles.total}>
                      {(data.filter((elem) => elem.value === 'wallets_connected')[0].visitor_count
                              + data.filter((elem) => elem.value === 'wallets_not_connected')[0].visitor_count)
                        .toLocaleString()}
                      {' '}
                      (
                      {ethFormat(((data
                        .filter((elem) => elem.value === 'wallets_connected')[0].visitor_count
                                  + data
                                    .filter((elem) => elem.value === 'wallets_not_connected')[0].visitor_count)
                              / total) * 100)}
                      %)
                    </span>
                  </div>
                ) : (
                  <div className="d-flex gap-2 flex-column nowrap">
                    <span className={styles.total}>
                      {title === 'Languages'
                        ? languageNamesInEnglish.of(data[0].value) : title === 'Wallet Types'
                          ? data[0].value.replace(/Wallet/g, ' Wallet') : data[0].value}
                      {' '}
                      {ethFormat((data[0].visitor_count / total) * 100)}
                      %
                    </span>
                  </div>
                )}
              </div>
              {title !== 'Countries' ? (
                <div className="d-flex align-self-start w-100">
                  <Chart
                    options={DONUTData.options}
                    series={seriesDataCount().map((elem) => elem.percent)}
                    type="donut"
                    height={180}
                  />
                </div>
              ) : null}
              <div className="d-flex gap-1 flex-column w-100">
                {seriesDataCount().map((elem, index) => (
                  <div
                    className="d-flex align-items-center justify-content-between gap-2 asset-wrap text-nowrap bg-body"
                    key={uniqueId(title)}
                    title={`${elem.count?.toLocaleString()} visitors`}
                  >
                    <span className="d-flex align-items-center gap-2 w-50">
                      {title !== 'Countries' ? <div className={`dot ${legendData[index].color}`} /> : null}
                      {title === 'Countries' && elem.value !== 'Other' ? (
                        <LogoDisplay
                          logo={`/trackedWebsite/countryFlags/${elem.value.toLowerCase()}.svg`}
                          size="15px"
                          asset="address"
                          position="static"
                        />
                      ) : title === 'Wallet Types' && elem.value !== 'Other' ? (
                        <LogoDisplay
                          logo={`/trackedWebsite/walletIcons/wallet_${elem.value.toLowerCase()}.png`}
                          size="15px"
                          asset="address"
                          position="static"
                        />
                      ) : null}
                      <span className="text-capitalize">
                        {title === 'Countries' || title === 'Languages'
                          ? `${elem.fullName}`
                          : title === 'Wallet Types'
                            ? `${elem.value?.replace(/_/g, ' ')?.replace(/Wallet/g, ' Wallet')}`
                            : `${elem.value?.replace(/_/g, ' ')}`}
                      </span>
                    </span>
                    <span className={styles.spanValue}>
                      {`${parseFloat(elem.percent).toFixed(1)}%`}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          )
          : (
            <div className="d-flex justify-content-center align-items-center">
              <NoData />
            </div>
          )}
      </div>
    );
  }, [DONUTData.options, data, isLoading, seriesDataCount, title, total]);

  return (
    <div className={`${styles.wrapper} d-flex flex-column`}>
      <TitleComponent info={info}>{title}</TitleComponent>
      {containerContent}
    </div>
  );
};

export default UserTabContainer;
