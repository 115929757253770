import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Phone } from '@absolutelabs/react-component-library';
import {
  selectCurrPage,
  selectFieldSettings,
} from '../../../../../store/reducers/forms';
import styles from './PhoneField.module.scss';

const PhoneField = ({ ...props }) => {
  const currPage = useSelector(selectCurrPage);
  const fieldSettings = useSelector(selectFieldSettings);
  const [fieldConfig, setFieldConfig] = useState({});

  useEffect(() => {
    const currPageSettings = fieldSettings.find((o) => o.pageName === currPage);
    const config = currPageSettings?.fields?.find((x) => x.field_id === props?.field?.id);
    if (config) {
      setFieldConfig(config);
    }
  }, [fieldSettings, currPage, props?.field?.id]);

  return (
    <div className={styles.phoneFieldWrapper}>
      <Phone
        label={fieldConfig.label || 'Phone number'}
        placeholder={fieldConfig.placeholder || ''}
        subLabel={fieldConfig.subLabel || ''}
        required={fieldConfig.required || false}
        className="mb-3"
      />
    </div>
  );
};

export default PhoneField;
