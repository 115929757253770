import React from 'react';
import { ReactComponent as Intelligence } from '../../assets/icons/interests/artificial_intelligence.svg';
import { ReactComponent as Augmented } from '../../assets/icons/interests/augmented.svg';
import { ReactComponent as BigData } from '../../assets/icons/interests/big_data.svg';
import { ReactComponent as Charity } from '../../assets/icons/interests/charity.svg';
import { ReactComponent as Communication } from '../../assets/icons/interests/communication.svg';
import { ReactComponent as CEX } from '../../assets/icons/interests/cex.svg';
import { ReactComponent as DEX } from '../../assets/icons/interests/DEX.svg';
import { ReactComponent as Defi } from '../../assets/icons/interests/defi.svg';
import { ReactComponent as Derivatives } from '../../assets/icons/interests/derivatives.svg';
import { ReactComponent as Education } from '../../assets/icons/interests/education.svg';
import { ReactComponent as Energy } from '../../assets/icons/interests/energy.svg';
import { ReactComponent as Entertainment } from '../../assets/icons/interests/entertainment.svg';
import { ReactComponent as Fractionalized } from '../../assets/icons/interests/fractionalized_nft.svg';
import { ReactComponent as Gambling } from '../../assets/icons/interests/Gambling.svg';
import { ReactComponent as Gaming } from '../../assets/icons/interests/gaming.svg';
import { ReactComponent as Governance } from '../../assets/icons/interests/governance.svg';
import { ReactComponent as Healthcare } from '../../assets/icons/interests/healthcare.svg';
import { ReactComponent as Infrastructure } from '../../assets/icons/interests/infrastructure.svg';
import { ReactComponent as Insurance } from '../../assets/icons/interests/insurance.svg';
import { ReactComponent as IOT } from '../../assets/icons/interests/iot.svg';
import { ReactComponent as Launchpad } from '../../assets/icons/interests/Launchpad.svg';
import { ReactComponent as Borrowing } from '../../assets/icons/interests/Borrowing.svg';
import { ReactComponent as Marketing } from '../../assets/icons/interests/Marketing.svg';
import { ReactComponent as Meme } from '../../assets/icons/interests/Meme Tokens.svg';
import { ReactComponent as Metaverse } from '../../assets/icons/interests/metaverse.svg';
import { ReactComponent as Music } from '../../assets/icons/interests/Music.svg';
import { ReactComponent as Options } from '../../assets/icons/interests/Options.svg';
import { ReactComponent as Earn } from '../../assets/icons/interests/Pay to earn.svg';
import { ReactComponent as Privacy } from '../../assets/icons/interests/Privacy Coins.svg';
import { ReactComponent as Protocol } from '../../assets/icons/interests/protocol.svg';
import { ReactComponent as Estate } from '../../assets/icons/interests/real Estate.svg';
import { ReactComponent as Points } from '../../assets/icons/interests/Reddit points.svg';
import { ReactComponent as Retail } from '../../assets/icons/interests/Retail.svg';
import { ReactComponent as Sports } from '../../assets/icons/interests/Sports.svg';
import { ReactComponent as Science } from '../../assets/icons/interests/Technology & science.svg';
import { ReactComponent as Tourism } from '../../assets/icons/interests/Tourism.svg';
import { ReactComponent as Virtual } from '../../assets/icons/interests/Virtual Reality.svg';
import { ReactComponent as Wallets } from '../../assets/icons/interests/wallets.svg';
import { ReactComponent as Aggregator } from '../../assets/icons/interests/Yield aggregator.svg';
import { ReactComponent as Farming } from '../../assets/icons/interests/Yield Farming.svg';
import { ReactComponent as NFTs } from '../../assets/icons/interests/NFTs.svg';
import { ReactComponent as UtilityNFTs } from '../../assets/icons/interests/Utility NFTs.svg';
import { ReactComponent as Collectibles } from '../../assets/icons/interests/Collectibles.svg';
import { ReactComponent as DomainNames } from '../../assets/icons/interests/Domain Names.svg';
import { ReactComponent as Art } from '../../assets/icons/interests/ART.svg';
import { ReactComponent as Photography } from '../../assets/icons/interests/Photography.svg';
import { ReactComponent as TradingCards } from '../../assets/icons/interests/Trading Cards.svg';
import { ReactComponent as Membership } from '../../assets/icons/interests/Membership.svg';
import { ReactComponent as Email } from '../../assets/icons/alias/email.svg';
import { ReactComponent as Discord } from '../../assets/icons/alias/discrod.svg';
import { ReactComponent as Twitter } from '../../assets/icons/alias/twitter.svg';
import { ReactComponent as Name } from '../../assets/icons/alias/name.svg';
import { ReactComponent as Telegram } from '../../assets/icons/alias/telegram.svg';

export const interestData = {
  'Artificial Intelligence': <Intelligence />,
  'Augmented Reality': <Augmented />,
  'Big Data': <BigData />,
  'Centralized Exchange Token (CEX)': <CEX />,
  Charity: <Charity />,
  Communication: <Communication />,
  'Decentralized Exchange Token (DEX)': <DEX />,
  'Decentralized Finance (DeFi)': <Defi />,
  Derivatives: <Derivatives />,
  Education: <Education />,
  Energy: <Energy />,
  Entertainment: <Entertainment />,
  'Fractionalized NFT': <Fractionalized />,
  Gambling: <Gambling />,
  Gaming: <Gaming />,
  Governance: <Governance />,
  Healthcare: <Healthcare />,
  Infrastructure: <Infrastructure />,
  Insurance: <Insurance />,
  'Internet of Things (IOT)': <IOT />,
  Launchpad: <Launchpad />,
  'Lending/Borrowing': <Borrowing />,
  Marketing: <Marketing />,
  'Meme Tokens': <Meme />,
  Metaverse: <Metaverse />,
  Music: <Music />,
  Options: <Options />,
  'Play To Earn': <Earn />,
  'Privacy Coins': <Privacy />,
  Protocol: <Protocol />,
  'Real Estate': <Estate />,
  'Reddit Points': <Points />,
  Retail: <Retail />,
  Sports: <Sports />,
  'Technology & Science': <Science />,
  Tourism: <Tourism />,
  uTokens: <uTokens />,
  'Virtual Reality': <Virtual />,
  Wallets: <Wallets />,
  'Yield Aggregator': <Aggregator />,
  'Yield Farming': <Farming />,
  NFTs: <NFTs />,
  'Utility NFTs': <UtilityNFTs />,
  Collectibles: <Collectibles />,
  'Domain Names': <DomainNames />,
  Art: <Art />,
  Photography: <Photography />,
  'Trading Cards': <TradingCards />,
  Memberships: <Membership />,
  PFPs: <Collectibles />,
};

export const aliasData = {
  email: <Email />,
  name: <Name />,
  twitter_id: <Twitter />,
  discord_id: <Discord />,
  telegram_id: <Telegram />,
};
