import React, { useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import TitleSection from '../../components/ui/TitleSection';
import Assets from './Assets';
import Loader from '../../components/base/Loader';
import ComingSoon from '../../components/ComingSoon';
import InvestorProfile from './InvestorProfile';
import { brandsApi } from '../../api/brands';
import { coinApi } from '../../api/coin';
import { userApi } from '../../api/user';
import { assetData } from '../AssetDetails/dummy-data';
import valueCreatedImg from '../../assets/icons/value_created.svg';
import assetsOwnedImg from '../../assets/icons/assets_owned.svg';
import assetsPublishedImg from '../../assets/icons/assets_published.svg';
import { convertNumber, convertToReadableFormat } from '../../tools/NumberConverterTool';

const BrandDetails = () => {
  const { pathname } = useLocation();
  const [itemPage, setItemPage] = useState('Investor profile');

  const {
    data: brand,
    isLoading,
    isFetching,
  } = brandsApi.useGetBrandByIdQuery(pathname.split('/')[2]);

  const {
    data: teamAssets,
    isLoading: isTeamAssetsLoading,
    isFetching: isTeamAssetsFethcing,
    refetch: teamAssetsRefetch,
  } = userApi.useGetAssetsQuery();

  const { data: ethereum } = coinApi.useGetCoinByIdQuery('ethereum');

  useEffect(() => {
    teamAssetsRefetch();
  }, [teamAssetsRefetch]);

  const fetchRender = useMemo(() => {
    if (isLoading || isFetching || isTeamAssetsLoading || isTeamAssetsFethcing) {
      setItemPage('Investor profile');
      return (
        <div className="mt-5">
          <Loader />
        </div>
      );
    }
    if (brand) {
      const count = [...brand.coins.map((item) => item.holder_count), ...brand.nfts.map((item) => item.holder_count)]
        .filter((item) => !!(item));
      const holders = count.length ? count.reduce((acc, elem) => acc + elem) : null;

      const pages = [
        {
          label: 'Investor profile',
          component: <InvestorProfile id={brand.id} slug={brand.slug} holders={holders} />,
          id: 1,
        },
        { label: 'List', component: <ComingSoon />, id: 2 },
        { label: 'Assets published', component: <ComingSoon />, id: 3 },
        { label: 'Portfolio', component: <ComingSoon />, id: 4 },
        { label: 'News', component: <ComingSoon />, id: 5 },
        { label: 'Competitors', component: <ComingSoon />, id: 6 },
      ];

      const ownerCoins = brand.coins.filter((owner) => owner.type === 'owned');
      const publishCoins = brand.coins.filter((publish) => publish.type === 'published');
      const ownerTokens = brand.nfts.filter((owner) => owner.type === 'owned');
      const publishTokens = brand.nfts.filter((publish) => publish.type === 'published');
      const ownerAssets = [...ownerTokens, ...ownerCoins];
      const publishAssets = [...publishTokens, ...publishCoins];
      const valueCreated = brand.coins
        .reduce((acc, elem) => acc + ((elem.type === 'published' && elem.market_cap) || 0), 0);
      const widgetData = [
        {
          id: 5,
          name: 'Value created',
          number: valueCreated ? `$${convertToReadableFormat(valueCreated)}` : '-',
          info: 'Cumulated market cap of all published assets',
          icon: valueCreatedImg,
        },
        {
          id: 6,
          name: 'Assets published',
          number: convertNumber(publishAssets.length),
          icon: assetsPublishedImg,
        },
        {
          id: 7,
          name: 'Assets owned',
          number: convertNumber(ownerAssets.length),
          icon: assetsOwnedImg,
        },
      ];

      const brandsData = {
        id: brand.id,
        title: brand.title,
        logo: brand.logo,
        symbol: 'Brand',
        social: brand.social_networks,
        icons: assetData.icons,
      };

      return (
        <>
          <TitleSection
            type={pathname.split('/')[1].slice(0, -1)}
            assetData={brandsData}
            teamAssets={teamAssets}
          />
          <div className="asset-section mt-3">
            <Assets
              widgetData={widgetData}
              ownedAssets={ownerAssets}
              publishedAssets={publishAssets}
              exchangeUsd={ethereum?.market_data.current_price.usd}
            />
          </div>
          <div className="row asset-section m-o mt-3">
            <div className="title-gap asset-navigation">
              <ul className="nav nav-pills gap-2">
                {pages.map((page) => (
                  <li
                    role="presentation"
                    className="nav-item"
                    key={page.id}
                    onClick={() => setItemPage(page.label)}
                  >
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <Link
                      className={`nav-link ${
                        page.label === itemPage ? 'active' : ''
                      }`}
                      aria-current="page"
                      to="#"
                    >
                      {page.label}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="dropdown-divider w-100" />
            {pages.map((item) => (item.label === itemPage ? (
              <div key={item.id}>{item.component}</div>
            ) : null))}
          </div>
        </>
      );
    }

    return (
      <>
      </>
    );
  }, [
    isLoading,
    isFetching,
    isTeamAssetsLoading,
    isTeamAssetsFethcing,
    brand,
    pathname,
    teamAssets,
    ethereum?.market_data.current_price.usd,
    itemPage,
  ]);

  return fetchRender;
};
export default BrandDetails;
