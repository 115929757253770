import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { localeStorageCache } from '../../utils/localeStorage';

const BASE_API = 'api/contracts';

export const contractsApi = createApi({
  reducerPath: 'contractsAPI',
  baseQuery: fetchBaseQuery({
    prepareHeaders: (headers) => {
      const authKey = localeStorageCache.getAuth0Key();
      const authData = localeStorageCache.get(authKey);

      if (authData && authData.body) {
        const { id_token: idToken } = authData.body;
        if (idToken) {
          headers.set('Authorization', `Bearer ${idToken}`);
        }
      }
      return headers;
    },
    baseUrl: import.meta.env.VITE_APP_SERVER_DOMAIN,
  }),
  tagTypes: ['Contracts'],
  endpoints: (build) => ({
    getContracts: build.query({
      query: () => ({
        url: `${BASE_API}/`,
        method: 'GET',
      }),
    }),
    createNFT: build.mutation({
      query: (nftData) => ({
        url: `${BASE_API}/`,
        method: 'POST',
        body: nftData,
      }),
    }),
    deleteNFT: build.mutation({
      query: (id) => ({
        url: `${BASE_API}/${id}/`,
        method: 'DELETE',
      }),
    }),
  }),
});
