import React from 'react';
import Chart from 'react-apexcharts';
import TitleComponent from '../../../../components/base/TitleComponent';
import './Distribution.scss';
import { coinApi } from '../../../../api/coin';
import Loader from '../../../../components/base/Loader';
import { convertNumber } from '../../../../tools/NumberConverterTool';
import NoData from '../../../../components/base/NoData';
import uniqueId from '../../../../utils/uniqueId';

const legendData = [
  {
    color: 'orange',
    title: 'Top 3 holders',
  },
  {
    color: 'green',
    title: 'Holders 4 to 10',
  },
  {
    color: 'purple',
    title: 'Holders 11 to 25',
  },
  {
    color: 'red',
    title: 'Holders 26 to 100',
  },
  {
    color: '',
    title: 'Others',
  },
];

const Distribution = ({ address, total, network }) => {
  const {
    data,
    isLoading,
    isFetching,
  } = coinApi.useGetNftTopHoldersQuery({ address, chain: network });

  const seriesDataCount = () => {
    if (data) {
      const [holdersTokensCount] = data.reduce((acc, obj) => {
        acc[0] += obj.token_qty;
        return acc;
      }, [0]);
      const [first3] = data.slice(0, 3).reduce((acc, obj) => {
        acc[0] += obj.token_qty;
        return acc;
      }, [0]);
      const [first10] = data.slice(3, 10).reduce((acc, obj) => {
        acc[0] += obj.token_qty;
        return acc;
      }, [0]);
      const [first25] = data.slice(10, 25).reduce((acc, obj) => {
        acc[0] += obj.token_qty;
        return acc;
      }, [0]);
      const [first100] = data.slice(25, 100).reduce((acc, obj) => {
        acc[0] += obj.token_qty;
        return acc;
      }, [0]);
      const othersCount = total - holdersTokensCount;
      const othersPercent = ((othersCount * 100) / total).toFixed(2);
      const first3Percent = (first3 * 100) / total;
      const first10Percent = (first10 * 100) / total;
      const first25Percent = (first25 * 100) / total;
      const first100Percent = (first100 * 100) / total;

      return [
        {
          count: first3,
          percent: first3Percent >= 0 ? +first3Percent.toFixed(2) : 0,
        },
        {
          count: first10,
          percent: first10Percent >= 0 ? +first10Percent.toFixed(2) : 0,
        },
        {
          count: first25,
          percent: first25Percent >= 0 ? +first25Percent.toFixed(2) : 0,
        },
        {
          count: first100,
          percent: first100Percent >= 0 ? +first100Percent.toFixed(2) : 0,
        },
        {
          count: othersCount,
          percent: othersPercent >= 0 ? +(+othersPercent).toFixed(2) : 0,
        },
      ];
    }
    return [];
  };

  const seriesFormatter = (y, seriesIndex) => (
    `${convertNumber(seriesDataCount()[seriesIndex].count)} items (${y}%)`);

  const DONUTDistribution = {
    options: {
      chart: {
        type: 'donut',
      },
      plotOptions: {
        pie: {
          expandOnClick: false,
        },
      },
      states: {
        normal: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
        hover: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: 'none',
            value: 0,
          },
        },
      },
      stroke: {
        width: 5,
        colors: ['#f1f4f8'],
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      tooltip: {
        enabled: true,
        marker: {
          show: false,
        },
        x: {
          show: false,
        },
        y: {
          formatter: (y, { ...chartData }) => seriesFormatter(y, chartData.seriesIndex),
          title: {
            formatter: () => '',
          },
        },
      },
      fixed: {
        enabled: false,
      },
      colors: ['#FFB946', '#2ED47A', '#885AF8', '#F7685B', '#1C76FD'],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
          },
        },
      ],
    },
  };

  return (
    <div className="distribution-wrapper d-flex flex-column">
      <TitleComponent>Distribution</TitleComponent>
      {isLoading || isFetching
        ? (
          <div className="p-5">
            <Loader />
          </div>
        )
        : (
          <div className="d-flex flex-column h-100 justify-content-center">
            {data.length && total
              ? (
                <>
                  <div className="d-flex gap-2 distribution-chart mt-2 mb-2">
                    <div className="distribution-donut">
                      {data
                        ? (
                          <Chart
                            options={DONUTDistribution.options}
                            series={seriesDataCount().map((elem) => elem.percent)}
                            type="donut"
                            height={180}
                          />
                        )
                        : null}
                    </div>
                    <div className="d-flex gap-1 flex-column">
                      {seriesDataCount().map((elem, index) => (
                        <div className="d-flex align-items-center gap-2 asset-wrap text-nowrap" key={uniqueId('asset')}>
                          <div className={`dot ${legendData[index].color}`} />
                          <span>
                            {`${legendData[index].title} (${elem.percent}%)`}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="mt-4 d-flex gap-2 align-items-center">
                    <span className="info-distribution">
                      Owned by top 10 wallets
                    </span>
                    <span
                      className="count-distribution"
                    >
                      {data && convertNumber(data.slice(0, 10).reduce((acc, obj) => {
                        acc[0] += obj.token_qty;
                        return acc;
                      }, [0]))}
                      &nbsp;
                      items
                      (
                      {data && (seriesDataCount()[0].percent
                      + seriesDataCount()[1].percent).toFixed(2)}
                      %)
                    </span>
                  </div>
                </>
              )
              : (
                <div className="d-flex justify-content-center align-items-center">
                  <NoData />
                </div>
              )}
          </div>
        )}
    </div>
  );
};

export default Distribution;
