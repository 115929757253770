import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { localeStorageCache } from '../../utils/localeStorage';

const BASE_API = 'api/brands';

export const brandsApi = createApi({
  reducerPath: 'brandsAPI',
  baseQuery: fetchBaseQuery({
    prepareHeaders: (headers) => {
      const authKey = localeStorageCache.getAuth0Key();
      const authData = localeStorageCache.get(authKey);

      if (authData && authData.body) {
        const { id_token: idToken } = authData.body;
        if (idToken) {
          headers.set('Authorization', `Bearer ${idToken}`);
        }
      }
      return headers;
    },
    baseUrl: import.meta.env.VITE_APP_SERVER_DOMAIN,
  }),
  tagTypes: ['Brands'],
  endpoints: (build) => ({
    getBrands: build.query({
      query: () => ({
        url: `${BASE_API}/list/`,
        method: 'GET',
      }),
      providesTags: () => ['Brands'],
    }),
    addBrand: build.mutation({
      query: (brandData) => ({
        url: `${BASE_API}/`,
        method: 'POST',
        body: brandData,
      }),
      invalidatesTags: ['Brands'],
    }),
    getBrandById: build.query({
      query: (slug) => ({
        url: `${BASE_API}/list/${slug}/`,
        method: 'GET',
      }),
      providesTags: () => ['Brands'],
    }),
    getBrandActiveAddresses: build.query({
      query: (slug) => ({
        url: `${BASE_API}/${slug}/active-addresses-count/`,
        method: 'GET',
      }),
      providesTags: () => ['Brands'],
    }),
    getBrandTraders: build.query({
      query: (arg) => ({
        url: `${BASE_API}/traders/${arg.slug}?duration=${arg.days}`,
        method: 'GET',
      }),
      providesTags: () => ['Brands'],
    }),
    changeBrand: build.mutation({
      query: (id, brandData) => ({
        url: `${BASE_API}/${id}/`,
        method: 'PUT',
        body: brandData,
      }),
      invalidatesTags: ['Brands'],
    }),
    patchBrand: build.mutation({
      query: (id, brandData) => ({
        url: `${BASE_API}/${id}/`,
        method: 'PATCH',
        body: brandData,
      }),
      invalidatesTags: ['Brands'],
    }),
    deleteBrand: build.mutation({
      query: (id) => ({
        url: `${BASE_API}/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Brands'],
    }),
    searchBrandByName: build.query({
      query: (name) => ({
        url: `/api/brands/list/?search=${name}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 5,
    }),
  }),
});
