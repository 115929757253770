import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as SegmentIllustration } from '../../../assets/icons/Illustration_segment.svg';
import { ReactComponent as CustomListIllustration } from '../../../assets/icons/Illustration_custom_list.svg';
import styles from './NoSegments.module.scss';

const NoSegments = () => {
  const navigate = useNavigate();

  return (
    <div className={`${styles.adaptive_wrapper} asset-section vh-75 d-flex justify-content-center align-items-center`}>
      <div className="d-flex flex-column align-items-center gap-5 w-75">
        <div className="d-flex flex-column align-items-center justify-content-center">
          <span className="p-1 title-h3-bold text-center">
            You do not have any saved audience yet
          </span>
          <span className={`${styles.color_title} title-h4 text-center`}>
            Create a Segment or a Profile List
          </span>
        </div>
        <div className={`${styles.adaptive} d-flex w-100 justify-content-evenly align-items-center gap-4`}>
          <div className="d-flex align-items-center flex-column gap-4">
            <SegmentIllustration />
            <button
              type="button"
              className="outline-blue-button"
              onClick={() => {
                navigate('/add-segment');
              }}
            >
              Create a Segment
            </button>
          </div>
          <div className="d-flex align-items-center flex-column gap-4">
            <CustomListIllustration />
            <div>
              <button
                type="button"
                className="outline-blue-button"
                onClick={() => {
                  navigate('/csv-upload');
                }}
              >
                Import Profiles
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoSegments;
