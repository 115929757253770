import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Name } from '@absolutelabs/react-component-library';
import {
  selectCurrPage,
  selectFieldSettings,
} from '../../../../../store/reducers/forms';

const NameField = ({ ...props }) => {
  const currPage = useSelector(selectCurrPage);
  const fieldSettings = useSelector(selectFieldSettings);
  const [fieldConfig, setFieldConfig] = useState({});

  useEffect(() => {
    const currPageSettings = fieldSettings.find((o) => o.pageName === currPage);
    const config = currPageSettings?.fields?.find((x) => x.field_id === props?.field?.id);
    if (config) {
      setFieldConfig(config);
    }
  }, [fieldSettings, currPage, props?.field?.id]);

  return (
    <Name
      label={fieldConfig.label || 'Name'}
      nameType={fieldConfig.nameType || 'first'}
      firstPlaceholder={fieldConfig.firstPlaceholder || ''}
      lastPlaceholder={fieldConfig.lastPlaceholder || ''}
      firstSubLabel={fieldConfig.firstSubLabel || ''}
      lastSubLabel={fieldConfig.lastSubLabel || ''}
      required={fieldConfig.required || false}
    />
  );
};

export default NameField;
