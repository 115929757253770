import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { handlePlatforms } from '../../../../utils/search/preventSearch';
import { ReactComponent as Ethereum } from '../../../../assets/icons/eth.svg';
import { ReactComponent as Polygon } from '../../../../assets/icons/polygon logo svg.svg';
import { ethFormat } from '../../../../utils/singleAssetPage/parseData';
import Tooltip from '../../../../components/ui/Tooltip';
import styles from './AssetsCard.module.scss';
import DefaultIcon from '../../../../components/ui/DefaultIcon';
import ItemPreview from '../../../../components/ui/ItemPreview';

const AssetsCard = ({ data, portfolioValue }) => {
  const [onLogoError, setOnLogoError] = useState(false);
  return (
    <div className="d-flex justify-content-between">
      <div className="d-flex gap-3 align-items-center">
        <Link
          to={data.asset_type === 'token'
            ? `/coins/${data.coin_id}`
            : `/nfts/${data.blockchains && data.blockchains[0]}/${data.addresses && data.addresses[0]}`}
          data-for={data.asset_type === 'nft'
            && `preview_${data.addresses && data.addresses[0]}_${data.blockchains && data.blockchains[0]}`}
          data-tip="show"
        >
          {!onLogoError && data.logo ? (
            <img
              className="rounded-circle cursor-pointer"
              src={data.logo}
              alt="logo"
              width="40px"
              height="40px"
              onError={() => setOnLogoError(true)}
            />
          ) : (
            <div
              className={`${styles.wrapper_img} d-flex justify-content-center align-items-center`}
            >
              <DefaultIcon type={data.asset_type} size="40px" />
            </div>
          )}
        </Link>
        <div className="d-flex flex-column align-items-start gap-1">
          <div
            className={styles.info}
            data-for={data.asset_type === 'nft' && `preview_${data.address}_${data.platform && data.platform[0]}`}
            data-tip="show"
          >
            {data?.opensea_slug_contract_count && data.opensea_slug_contract_count > 1
              ? data.contract_name || data.name
              : data.name}
          </div>
          {data.asset_type === 'nft'
            ? (
              <ItemPreview
                id={`preview_${data.addresses && data.addresses[0]}_${data.blockchains && data.blockchains[0]}`}
                data={{
                  address: data.addresses && data.addresses[0],
                  type: data.asset_type,
                  blockchain: data.blockchains && data.blockchains[0],
                  img: data.logo,
                  name: data.name,
                }}
              />
            )
            : null}
          <div className="d-flex gap-2 align-items-center">
            <div
              className={`${styles.pills} ${data.asset_type.includes('token') ? styles.red : ''}
                                 px-3 d-flex align-items-center`}
            >
              {data.asset_type.includes('token') ? 'Token' : 'NFT Collection'}
            </div>
            {data?.platform ? (
              <>
                <div className={styles.separator_icon} />
                <div className="d-flex gap-1">
                  {handlePlatforms(data.platform).ethereum && (
                    <>
                      <div
                        data-for={`ethereum_${data.asset_type.includes('token') ? data.coin_id : data.address}`}
                        data-tip
                      >
                        <Ethereum />
                      </div>
                      <Tooltip
                        id={`ethereum_${data.asset_type.includes('token') ? data.coin_id : data.address}`}
                        info={`${data.assets_on_eth !== null
                          ? `${ethFormat(data.assets_on_eth) < 1 ? '<1' : ethFormat(data.assets_on_eth)}
                    ${data.asset_type.includes('token')
                      ? `Token${data.assets_on_eth <= 1 ? '' : 's'}`
                      : `NFT${data.assets_on_eth === 1 ? '' : 's'}`}`
                          : '?'} held on Ethereum`}
                      />
                    </>
                  )}
                  {handlePlatforms(data.platform).polygon && (
                    <>
                      <div
                        data-for={`polygon_${data.asset_type.includes('token') ? data.coin_id : data.address}`}
                        data-tip
                      >
                        <Polygon />
                      </div>
                      <Tooltip
                        id={`polygon_${data.asset_type.includes('token') ? data.coin_id : data.address}`}
                        info={`${data.assets_on_pol !== null
                          ? `${ethFormat(data.assets_on_pol) < 1 ? '<1' : ethFormat(data.assets_on_pol)}
                    ${data.asset_type.includes('token')
                      ? `Token${data.assets_on_pol <= 1 ? '' : 's'}`
                      : `NFT${data.assets_on_pol === 1 ? '' : 's'}`}`
                          : '?'} held on Polygon`}
                      />
                    </>
                  )}
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
      <div className="d-flex align-items-end flex-column">
        <span className={styles.info}>{data.value !== null ? `$${ethFormat(data.value, 1)}` : '?'}</span>
        <span className={styles.info_data}>
          {data.token_qty_decimal !== null
            ? `${ethFormat(data.token_qty_decimal) < 1 ? '<1' : ethFormat(data.token_qty_decimal)}
           ${data.asset_type.includes('token')
      ? `Token${data.token_qty_decimal <= 1 ? '' : 's'}` : `NFT${data.token_qty_decimal === 1 ? '' : 's'}`}`
            : '?'}
        </span>
        <span className={styles.info_portfolio}>
          {portfolioValue && data.value !== null
            ? `${((data.value / portfolioValue) * 100) < 1
              ? '<1' : ethFormat((data.value / portfolioValue) * 100)}% of portfolio`
            : '?'}
        </span>
      </div>
    </div>
  );
};

export default AssetsCard;
