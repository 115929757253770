import React from 'react';
import NoResult from '../../../assets/images/NoResuls.svg';
import styles from './NoSearch.module.scss';

const NoSearch = () => (
  <div className="d-flex flex-column align-items-center justify-content-center gap-3 m-4 h-40">
    <div>
      <img src={NoResult} alt="NoData" width="500px" height="200px" />
    </div>
    <div className="d-flex flex-column align-items-center justify-content-center gap-1">
      <div className={styles.text}>Sorry, we did not find anything. Please try again.</div>
      <div className={styles.text_grey}>
        <p>Check your spelling.</p>
        <p>Use a different keyword.</p>
      </div>
    </div>
  </div>
);

export default NoSearch;
