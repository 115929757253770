import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Instagram } from '@absolutelabs/react-component-library';
import {
  selectCurrPage,
  selectFieldSettings,
} from '../../../../../store/reducers/forms';

const InstagramField = ({ ...props }) => {
  const currPage = useSelector(selectCurrPage);
  const fieldSettings = useSelector(selectFieldSettings);
  const [fieldConfig, setFieldConfig] = useState({});

  useEffect(() => {
    const currPageSettings = fieldSettings.find((o) => o.pageName === currPage);
    const config = currPageSettings?.fields?.find((x) => x.field_id === props?.field?.id);
    if (config) {
      setFieldConfig(config);
    }
  }, [fieldSettings, currPage, props?.field?.id]);

  return (
    <Instagram
      label={fieldConfig.label || 'Instagram'}
      placeholder={fieldConfig.placeholder || ''}
      subLabel={fieldConfig.subLabel || ''}
      required={fieldConfig.required || false}
    />
  );
};

export default InstagramField;
