import React, {
  useMemo,
  useEffect,
  useCallback,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Search from '../../components/Search';
import Asset from './Asset';
import SelectAsset from './SelectAsset';
import { coinApi } from '../../api/coin';
import {
  addAsset, deleteAsset,
  selectDashboardData, setUserAssets,
} from '../../store/reducers/coin';
import Illustrate from '../../assets/images/Illustrate.png';
import Loader from '../../components/base/Loader';
import { useDebounce } from '../../components/hooks/app';
import './Homepage.scss';
// import { brandsApi } from '../../api/brands';
import {
  addPill,
  clearPills,
  deletePill,
  selectDataToRender,
  selectPills,
  setSearchData,
} from '../../store/reducers/search';
import { userApi } from '../../api/user';
import { showErrorMessage } from '../../components/base/Notifications';
import { getAssetIdAfterFetch, getNetworkAfterFetch } from '../../utils/assetsData/formatAssetData';
import uniqueId from '../../utils/uniqueId';
import { selectAssetName } from '../../utils/search/selectName';

const Homepage = () => {
  const [debouncedText, setDebouncedText] = useDebounce('');
  const dispatch = useDispatch();
  const { assets, watchList } = useSelector(selectDashboardData);
  const renderData = useSelector(selectDataToRender);
  const pills = useSelector(selectPills);

  const [searchParams] = useSearchParams();

  const {
    currentData: allAssets,
    isLoading: isAssetsLoading,
    isFetching: isAssetsFetching,
    refetch: allAssetsRefetch,
  } = userApi.useGetAssetsQuery();

  const {
    currentData: coins,
    isLoading: isCoinsLoading,
    isFetching: isCoinsFetching,
  } = coinApi.useSearchCoinQuery(debouncedText, {
    skip: !debouncedText,
  });

  // const {
  //   currentData: brands,
  //   isLoading: isBrandsLoading,
  //   isFetching: isBrandsFetching,
  // } = brandsApi.useSearchBrandByNameQuery(debouncedText, {
  //   skip: !debouncedText,
  // });

  const {
    currentData: nfts,
    isLoading: isNftLoading,
    isFetching: isNftFetching,
  } = coinApi.useSearchNftQuery(debouncedText, {
    skip: !debouncedText,
  });

  const [actionOnAsset, result] = userApi.useActionOnAssetMutation();

  const onAddOrDeleteAsset = useCallback((data) => {
    actionOnAsset(data);
  }, [actionOnAsset]);

  useEffect(() => {
    if (['fulfilled'].includes(result.status)) {
      if (!isAssetsFetching && !isAssetsLoading) {
        result.reset();
      }
    }
  }, [isAssetsFetching, isAssetsLoading, result]);

  useEffect(() => {
    if (result.isSuccess) {
      const id = getAssetIdAfterFetch(result.originalArgs);
      const network = getNetworkAfterFetch(result.originalArgs);
      if (result.originalArgs.action === 'add') {
        let assetToAdd = null;
        renderData.forEach((elem) => {
          if (elem.id === id || (elem.contract_address === id && elem.blockchain === network)) {
            assetToAdd = {
              ...result.originalArgs,
              metadata: { ...elem },
              asset_id: id,
              network,
              asset_type: elem.type,
            };
          }
        });
        dispatch(addAsset(assetToAdd));

        dispatch(addPill(assetToAdd));
        result.reset();
      }
      if (result.originalArgs.action === 'delete') {
        dispatch(deleteAsset(result.originalArgs));
        dispatch(deletePill({ id, network }));
        result.reset();
      }
    }
    if (result.isError) {
      showErrorMessage(Object.keys(result.error.data).length !== 0 && result.error.data.detail);
      result.reset();
    }
  }, [dispatch, renderData, result]);

  useEffect(() => {
    if (allAssets && Object.keys(allAssets).length !== 0) {
      dispatch(setUserAssets(allAssets));
    }
  }, [allAssets, dispatch]);

  useEffect(() => {
    // let brandsData = [];
    let nftData = [];
    let coinsData = [];
    // if (brands) {
    //   brandsData = [...brands.results.slice(0, 3)].map((elem) => ({ ...elem, type: 'brand' }));
    // }
    if (nfts) {
      nftData = [...nfts.slice(0, 3)].map((elem) => ({ ...elem, type: 'nft' }));
    }
    if (coins) {
      coinsData = [...coins.slice(0, 3)].map((elem) => ({ ...elem, type: 'coin' }));
    }
    // ...brandsData,
    dispatch(setSearchData([...nftData, ...coinsData]));
  }, [coins, dispatch, nfts]);
  // brands

  useEffect(() => {
    allAssetsRefetch();
  }, [allAssetsRefetch]);

  useEffect(() => () => dispatch(clearPills()), [dispatch]);

  const handleChange = (event) => {
    setDebouncedText(event.target.value);
  };

  const fetchRender = useMemo(() => {
    // || isBrandsLoading
    // || isBrandsFetching
    if (
      isCoinsLoading || isNftLoading
      || isCoinsFetching || isNftFetching
    ) {
      return (
        <div className="mt-5">
          <Loader />
        </div>
      );
    }
    if (renderData && renderData.length !== 0) {
      return (
        <div className="assets-container d-grid gap-5">
          {renderData.map((item) => (
            <Asset
              onAddOrDeleteAsset={onAddOrDeleteAsset}
              type={item.type}
              key={uniqueId('search-asset')}
              item={item}
              platforms={item.type === 'coin'
                ? (item?.platforms ? Object.keys(item.platforms) : [])
                : [item.blockchain].filter((elem) => !!elem)}
              flag={
                searchParams.get('to') === 'watchlist' ? 'watchlist' : 'asset'
              }
              loading={
                ['fulfilled', 'pending'].includes(result.status)
                && (item.id === getAssetIdAfterFetch(result.originalArgs)
                || (item.contract_address === getAssetIdAfterFetch(result.originalArgs)
                    && item.blockchain === getNetworkAfterFetch(result.originalArgs))
                )
              }
              addedAssets={[...assets, ...watchList]}
            />
          ))}
        </div>
      );
    }
    return null;
  }, [
    isCoinsLoading,
    // isBrandsLoading,
    isNftLoading,
    isCoinsFetching,
    isNftFetching,
    // isBrandsFetching,
    renderData,
    onAddOrDeleteAsset,
    searchParams,
    result.status,
    result.originalArgs,
    assets,
    watchList,
  ]);

  return (
    <div className="homepage vh-75">
      <div className="wrapper-content d-flex flex-column align-items-center pb-5">
        <Search handleChange={handleChange} searchParams={searchParams} />
        <div className="d-flex gap-1 select-wrap">
          {pills.length
            ? pills.map((item) => (
              <div className="select-wrapper d-grid mt-3" key={uniqueId('asset')}>
                <SelectAsset
                  name={selectAssetName(item.asset_type, item.metadata)}
                  id={item.asset_id}
                  img={item.metadata.image || item.metadata.image_url || item.metadata.logo}
                  assetType={item.asset_type}
                  network={item.metadata.blockchain}
                  onAddOrDeleteAsset={onAddOrDeleteAsset}
                  flag={
                    searchParams.get('to') === 'watchlist'
                      ? 'watchlist'
                      : 'asset'
                  }
                />
              </div>
            ))
            : null}
        </div>
        {debouncedText ? (
          <div className="homepage-wrapper d-flex flex-column align-items-center">
            {fetchRender}
          </div>
        ) : (
          <div className="d-flex w-75 illustration flex-row-reverse mb-5">
            <img src={Illustrate} height="277" width="328" alt="illustration" />
          </div>
        )}
      </div>
    </div>
  );
};

export default Homepage;
