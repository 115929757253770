import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearActiveSettings,
  selectCurrPage,
  selectFieldSettings,
  setFieldSettings,
} from '../../../../../../store/reducers/forms';
import styles from './InstagramSettings.module.scss';
import formStyles from '../../FormSettings.module.scss';
import { ReactComponent as Close } from '../../../../../../assets/icons/close.svg';

const InstagramSettings = ({ activeSettings }) => {
  const dispatch = useDispatch();
  const currPage = useSelector(selectCurrPage);
  const initialized = useRef(false);
  const fieldSettings = useSelector(selectFieldSettings);
  const [label, setLabel] = useState('Instagram');
  const [placeholder, setPlaceholder] = useState('Type here');
  const [subLabel, setSubLabel] = useState('');
  const [required, setRequired] = useState(true);
  const [change, setChange] = useState(false);

  const handleChange = useCallback(() => {
    setChange(false);
    const obj = {
      name: 'instagram',
      label,
      placeholder,
      subLabel,
      required,
      type: 'instagram',
    };
    dispatch(setFieldSettings({ ...obj, field_id: activeSettings.field_id }));
  }, [
    label,
    placeholder,
    subLabel,
    required,
    dispatch,
    activeSettings,
  ]);

  const setOptions = (config) => {
    setLabel(config.label);
    setPlaceholder(config.placeholder);
    setSubLabel(config.subLabel);
    setRequired(config.required);
  };

  useEffect(() => {
    if (initialized.current) return;
    const currPageSettings = fieldSettings.find((o) => o.pageName === currPage);
    if (currPageSettings) {
      const config = currPageSettings.fields?.find((x) => x.field_id === activeSettings.field_id);
      if (config) {
        setOptions(config);
      } else {
        handleChange();
      }
    }
    initialized.current = true;
  }, [activeSettings, currPage, fieldSettings]);

  useEffect(() => {
    if (change) {
      handleChange();
    }
  }, [label, placeholder, subLabel, required]);

  return (
    <div className={`${styles.wrapper} h-100`}>
      <div className="d-flex flex-column justify-content-between h-100">
        <div>
          <div className={`${formStyles.title} d-flex align-items-center gap-2`}>
            Instagram Properties
          </div>
          <div className={`${styles.close} d-flex justify-content-end position-absolute`}>
            <Close
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => dispatch(clearActiveSettings())}
            />
          </div>
        </div>

        <div className="d-flex flex-column w-100 gap-1 mb-3">
          <div className={`${formStyles.title_input}`}>
            Label
          </div>
          <div className={`${formStyles.text_input}`}>
            <input
              placeholder="Type a label"
              type="text"
              className="w-100"
              value={label}
              onChange={(e) => {
                setLabel(e.currentTarget.value);
                setChange(true);
              }}
            />
          </div>
          <div className={`${formStyles.title_input}`}>
            Placeholder
          </div>
          <div className={`${formStyles.text_input}`}>
            <input
              placeholder="Type here"
              type="text"
              className="w-100"
              value={placeholder}
              onChange={(e) => {
                setPlaceholder(e.currentTarget.value);
                setChange(true);
              }}
            />
          </div>
          <div className={`d-flex align-items-center justify-content-between ${formStyles.title_select} w-100`}>
            <div>Sublabel</div>
            { !required && <div className={`${formStyles.optionalText} `}>optional</div> }
          </div>
          <div className={`${formStyles.text_input}`}>
            <input
              placeholder="Type here"
              type="text"
              className="w-100"
              value={subLabel}
              onChange={(e) => {
                setSubLabel(e.currentTarget.value);
                setChange(true);
              }}
            />
          </div>
          <div className={`${formStyles.checkbox_input}`}>
            <input
              className="form-check-input shadow-none cursor-pointer"
              type="checkbox"
              value=""
              id="flexCheckChecked"
              checked={required}
              onChange={(e) => {
                setRequired(e.currentTarget.checked);
                setChange(true);
              }}
            />
            <div className={`${formStyles.labelText}`}>Required Field</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstagramSettings;
