import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import DappUsageTable from '../../../components/ui/DappUsageTable';
import { audiencesApi } from '../../../api/audiences';

const DappUsage = ({ holders }) => {
  const { id } = useParams();
  const [page, setPage] = useState(1);
  const [desc, setDesc] = useState(true);
  const [orderBy, setOrder] = useState('number_of_users_last_30d');
  const [limit, setLimit] = useState(25);

  const header = [
    {
      field: '',
      title: '#',
      width: '32px',
    },
    {
      field: 'dapp_name',
      title: 'Name',
      width: '250px',
    },
    {
      field: 'category',
      title: 'Category',
    },
    {
      field: 'total_number_of_users',
      title: 'Total users',
    },
    {
      field: 'number_of_users_last_7d',
      title: 'Users last 7d',
    },
    {
      field: 'number_of_users_last_30d',
      title: 'Users last 30d',
    },
    {
      field: 'number_of_users_last_90d',
      title: 'Users last 90d',
    },
  ];

  const {
    data: dappData,
    isLoading: isDappDataLoading,
    isFetching: isDappDataFetching,
  } = audiencesApi.useGetCustomListDappUsageDataQuery({
    id,
    limit,
    offset: (page - 1) * limit,
    order: desc ? 'desc' : 'asc',
    orderBy,
  });

  return (
    <div className="p-2">
      <DappUsageTable
        holders={holders}
        header={header}
        data={dappData}
        isLoading={isDappDataLoading || isDappDataFetching}
        setPage={setPage}
        setDesc={setDesc}
        setOrder={setOrder}
        setLimit={setLimit}
        page={page}
        limit={limit}
        desc={desc}
        orderBy={orderBy}
      />
    </div>
  );
};

export default DappUsage;
