import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ReactComponent as Segments } from '../../../../assets/icons/flows/segments.svg';
import { ReactComponent as Webhook } from '../../../../assets/icons/flows/webhook.svg';
import { ReactComponent as List } from '../../../../assets/icons/flows/list.svg';
import { ReactComponent as Transaction } from '../../../../assets/icons/flows/transaction.svg';
import { ReactComponent as RandomSplit } from '../../../../assets/icons/flows/random.svg';
import { ReactComponent as Condition } from '../../../../assets/icons/flows/condition.svg';
import { ReactComponent as Wait } from '../../../../assets/icons/flows/wait.svg';
import { ReactComponent as Until } from '../../../../assets/icons/flows/until.svg';
import { ReactComponent as WebhookAct } from '../../../../assets/icons/flows/webhook_activation.svg';
import { ReactComponent as AirDrop } from '../../../../assets/icons/flows/airdrop.svg';
import { ReactComponent as AddToList } from '../../../../assets/icons/flows/add_to_list.svg';
import { ReactComponent as Email } from '../../../../assets/icons/flows/email.svg';
import { ReactComponent as Push } from '../../../../assets/icons/flows/push.svg';
import { ReactComponent as SMS } from '../../../../assets/icons/flows/sms.svg';
import { ReactComponent as Twitter } from '../../../../assets/icons/flows/twitter.svg';
import { ReactComponent as Discord } from '../../../../assets/icons/flows/discord.svg';
import { ReactComponent as Connector } from '../../../../assets/icons/flows/connector.svg';
import { ReactComponent as Arrow } from '../../../../assets/icons/flows/arrow_down.svg';
import { ReactComponent as Chevrons } from '../../../../assets/icons/chevrons-left.svg';
import { ReactComponent as XMTP } from '../../../../assets/icons/flows/xmtp.svg';
import Tooltip from '../../../../components/ui/Tooltip';
import uniqueId from '../../../../utils/uniqueId';
import styles from './ToolBox.module.scss';
import { userApi } from '../../../../api/user';
import { setSenderEmailSetUp } from '../../../../store/reducers/flows';
import { useWindowSize } from '../../../../components/hooks/app';

const onDragStart = (event, nodeType) => {
  event.dataTransfer.setData('application/reactflow', nodeType);
  event.dataTransfer.effectAllowed = 'move';
};

const entryNodes = [
  {
    name: 'Segments',
    component: <Segments />,
    behavior: 'segmentsNode',
  },
  {
    name: 'Webhook',
    component: <Webhook />,
    behavior: 'webhookESNode',
  },
  {
    name: 'List',
    component: <List />,
    behavior: 'listNode',
  },
  {
    name: 'Transaction',
    component: <Transaction />,
    behavior: 'transactionNode',
  },
];

const splitNodes = [
  {
    name: 'Random Split',
    component: <RandomSplit />,
    behavior: 'randomSplitNode',
  },
  {
    name: 'Condition',
    component: <Condition />,
    behavior: 'conditionNode',
  },
];

const delayNodes = [
  {
    name: 'Wait until',
    component: <Until />,
    behavior: 'waitUntilNode',
  },
  {
    name: 'Wait for',
    component: <Wait />,
    behavior: 'waitForNode',
  },
];

const dataNodes = [
  {
    name: 'Add to list',
    component: <AddToList />,
    behavior: 'addToListNode',
  },
];

const channelsNodes = [
  {
    name: 'Webhook ',
    component: <WebhookAct />,
    behavior: 'webhookNode',
  },
  {
    name: 'AirDrop',
    component: <AirDrop />,
    behavior: 'airDropNode',
  },
  {
    name: 'Email',
    component: <Email />,
    behavior: 'emailNode',
  },
  {
    name: 'XMTP',
    component: <XMTP />,
    behavior: 'XMTPNode',
  },
  {
    name: 'SMS',
    component: <SMS />,
    behavior: 'smsNode',
  },
  {
    name: 'Push',
    component: <Push />,
    behavior: 'pushNode',
    disabled: true,
  },
  {
    name: 'Twitter',
    component: <Twitter />,
    behavior: 'twitterNode',
    disabled: true,
  },
  {
    name: 'Discord',
    component: <Discord />,
    behavior: 'discordNode',
    disabled: true,
  },
  {
    name: 'Connector',
    component: <Connector />,
    behavior: 'connectorNode',
    disabled: true,
  },
];

const ToolBox = () => {
  const dispatch = useDispatch();
  const { width } = useWindowSize();

  const [isShowEntry, setIsShowEntry] = useState(false);
  const [isShowChannels, setIsShowChannels] = useState(false);
  const [isMinimized, setIsMinimized] = useState(localStorage.getItem('toolbox_minimized'));

  const {
    data: teamInfo,
  } = userApi.useGetTeamInfoQuery();

  const isDemoActive = teamInfo?.flows_demo;

  if (width <= 700 && !isMinimized) {
    setIsMinimized(true);
    localStorage.setItem('toolbox_minimized', 'true');
  }

  useEffect(() => {
    if (teamInfo) {
      dispatch(setSenderEmailSetUp(teamInfo.sender_email));
    }
  }, [dispatch, teamInfo]);

  return (
    <div
      className={`
      ${styles.wrapper}
      ${isMinimized ? styles.wrapper_min : ''}`}
    >
      <div
        className={`
          d-flex
          ${isMinimized ? 'justify-content-center' : 'justify-content-between'}`}
      >
        {isMinimized
          ? null
          : <div className={styles.description}>TOOL BOX</div>}
        <div className={`
          d-flex
          align-items-center
          gap-3
          ${isMinimized ? 'flex-column-reverse mb-3' : 'd-flex'}`}
        >
          <div
            className={`
              ${styles.minimize}
              ${isMinimized ? styles.minimize_rotate : ''}
              d-flex
              align-items-center
              justify-content-center
              cursor-pointer`}
            role="presentation"
            onClick={() => {
              setIsMinimized(!isMinimized);
              if (isMinimized) {
                localStorage.removeItem('toolbox_minimized');
              } else {
                localStorage.setItem('toolbox_minimized', 'true');
              }
            }}
          >
            <Chevrons />
          </div>
        </div>
      </div>
      <div className={styles.flows_group}>
        {isMinimized
          ? null
          : (
            <div className={styles.flows_group_title}>
              Entry Sources
            </div>
          )}
        {entryNodes.slice(0, !isShowEntry && !isMinimized ? 2 : entryNodes.length).map((elem) => (
          <div key={uniqueId('entry')}>
            <div
              className={`
              ${styles.flow}
              ${isMinimized ? styles.flow_min : ''}
              ${!isDemoActive && elem.disabled ? styles.disabled : ''}
              `}
              onDragStart={isDemoActive || !elem.disabled ? (event) => onDragStart(event, elem.behavior) : () => {}}
              draggable
              data-for={elem.name}
              data-tip
            >
              {elem.component}
              {!isMinimized && elem.name}
            </div>
            {!isDemoActive && elem.disabled ? <Tooltip id={elem.name} info="Coming soon" position="unset" /> : null}
          </div>
        ))}
        {isMinimized
          ? null
          : (
            <div
              className="d-flex align-item-center justify-content-center gap-1 w-100 cursor-pointer"
              role="presentation"
              onClick={() => setIsShowEntry(!isShowEntry)}
            >
              <div className={styles.view}>
                {isShowEntry ? 'View less' : 'View more'}
              </div>
              <div className={styles.arrow}>
                {isShowEntry ? <Arrow className={styles.rotate} /> : <Arrow />}
              </div>
            </div>
          )}
      </div>
      <div className={styles.flows_group}>
        {isMinimized
          ? <div className={styles.divider} />
          : (
            <div className={styles.flows_group_title}>
              Split
            </div>
          )}
        {splitNodes.map((elem) => (
          <div key={uniqueId('split')}>
            <div
              className={`
              ${styles.flow}
              ${isMinimized ? styles.flow_min : ''}
              ${!isDemoActive && elem.disabled ? styles.disabled : ''}
              `}
              onDragStart={isDemoActive || !elem.disabled ? (event) => onDragStart(event, elem.behavior) : () => {}}
              draggable
              data-for={elem.name}
              data-tip
            >
              {elem.component}
              {!isMinimized && elem.name}
            </div>
            {!isDemoActive && elem.disabled ? <Tooltip id={elem.name} info="Coming soon" position="unset" /> : null}
          </div>
        ))}
      </div>
      <div className={styles.flows_group}>
        {isMinimized
          ? <div className={styles.divider} />
          : (
            <div className={styles.flows_group_title}>
              Delay
            </div>
          )}
        {delayNodes.map((elem) => (
          <div key={uniqueId('delay')}>
            <div
              className={`
              ${styles.flow}
              ${isMinimized ? styles.flow_min : ''}
              ${!isDemoActive && elem.disabled ? styles.disabled : ''}
              `}
              onDragStart={isDemoActive || !elem.disabled ? (event) => onDragStart(event, elem.behavior) : () => {}}
              draggable
              data-for={elem.name}
              data-tip
            >
              {elem.component}
              {!isMinimized && elem.name}
            </div>
            {!isDemoActive && elem.disabled ? <Tooltip id={elem.name} info="Coming soon" position="unset" /> : null}
          </div>
        ))}
      </div>
      <div className={styles.flows_group}>
        {isMinimized
          ? <div className={styles.divider} />
          : (
            <div className={styles.flows_group_title}>
              Data
            </div>
          )}
        {dataNodes.map((elem) => (
          <div key={uniqueId('data')}>
            <div
              className={`
              ${styles.flow}
              ${isMinimized ? styles.flow_min : ''}
              ${!isDemoActive && elem.disabled ? styles.disabled : ''}
              `}
              onDragStart={isDemoActive || !elem.disabled ? (event) => onDragStart(event, elem.behavior) : () => {}}
              draggable
              data-for={elem.name}
              data-tip
            >
              {elem.component}
              {!isMinimized && elem.name}
            </div>
            {!isDemoActive && elem.disabled ? <Tooltip id={elem.name} info="Coming soon" position="unset" /> : null}
          </div>
        ))}
      </div>
      <div className={styles.flows_group}>
        {isMinimized
          ? <div className={styles.divider} />
          : (
            <div className={styles.flows_group_title}>
              Channels
            </div>
          )}
        {channelsNodes.slice(0, !isShowChannels && !isMinimized ? 2 : channelsNodes.length).map((elem) => (
          <div key={uniqueId('channels')}>
            <div
              className={`
              ${styles.flow}
              ${isMinimized ? styles.flow_min : ''}
              ${!isDemoActive && elem.disabled ? styles.disabled : ''}
              `}
              onDragStart={isDemoActive || !elem.disabled ? (event) => onDragStart(event, elem.behavior) : () => {}}
              draggable
              data-for={elem.name}
              data-tip
            >
              {elem.component}
              {!isMinimized && elem.name}
            </div>
            {!isDemoActive && elem.disabled ? <Tooltip id={elem.name} info="Coming soon" position="unset" /> : null}
          </div>
        ))}
        {isMinimized
          ? null
          : (
            <div
              className="d-flex align-item-center justify-content-center gap-1 w-100 cursor-pointer"
              role="presentation"
              onClick={() => setIsShowChannels(!isShowChannels)}
            >
              <div className={styles.view}>
                {isShowChannels ? 'View less' : 'View more'}
              </div>
              <div className={styles.arrow}>
                {isShowChannels ? <Arrow className={styles.rotate} /> : <Arrow />}
              </div>
            </div>
          )}
      </div>
    </div>
  );
};

export default ToolBox;
