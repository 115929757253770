import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ConnectWalletButton } from '@absolutelabs/react-component-library';
import {
  selectCurrPage,
  selectFieldSettings,
} from '../../../../../store/reducers/forms';

const ConnectWallet = ({ ...props }) => {
  const currPage = useSelector(selectCurrPage);
  const fieldSettings = useSelector(selectFieldSettings);
  const [fieldConfig, setFieldConfig] = useState({});

  useEffect(() => {
    const currPageSettings = fieldSettings.find((o) => o.pageName === currPage);
    const config = currPageSettings?.fields?.find((x) => x.field_id === props?.field?.id);
    if (config) {
      setFieldConfig(config);
    }
  }, [fieldSettings, currPage, props?.field?.id]);

  return (
    <ConnectWalletButton
      label={fieldConfig.label || 'Connect Wallet'}
      buttonStyle={fieldConfig.buttonStyle || 'filled'}
      textAlignment={fieldConfig.textAlignment || 'centre'}
      requiresSignature={fieldConfig.requiresSignature || false}
      signatureMessage={fieldConfig.signatureMessage || 'Please sign the message to confirm your identity'}
      requiredField={fieldConfig.requiredField || false}
    />
  );
};

export default ConnectWallet;
