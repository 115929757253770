import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Image } from '@absolutelabs/react-component-library';
import {
  selectCurrPage,
  selectFieldSettings,
} from '../../../../../store/reducers/forms';

const ImageField = ({ ...props }) => {
  const currPage = useSelector(selectCurrPage);
  const fieldSettings = useSelector(selectFieldSettings);
  const [fieldConfig, setFieldConfig] = useState({});

  useEffect(() => {
    const currPageSettings = fieldSettings.find((o) => o.pageName === currPage);
    const config = currPageSettings?.fields?.find((x) => x.field_id === props?.field?.id);
    if (config) {
      setFieldConfig(config);
    }
  }, [fieldSettings, currPage, props?.field?.id]);

  useEffect(() => {
  }, [fieldConfig]);

  return (
    <Image
      url={fieldConfig.url || 'https://picsum.photos/200/300'}
      height={fieldConfig.height || 300}
      width={fieldConfig.width || 100}
      imageFit={fieldConfig.imageFit}
      imageAlign={fieldConfig.imageAlign}
      caption={fieldConfig.caption || 'Image Caption'}
      editMode={props.editMode}
    />
  );
};

export default ImageField;
