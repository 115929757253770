import React, { useEffect, useState } from 'react';
import { formatDistanceStrict } from 'date-fns';
import { ethFormat } from '../../../../utils/singleAssetPage/parseData';
import { counter } from '../../../../tools/Counter';
import Table from '../../../../components/base/Table';
import styles from './DappUsageTable.module.scss';

const DappUsageTable = ({
  data, isLoading, setDesc, setPage, setLimit, setOrder, limit, desc, orderBy, page,
}) => {
  const [tableData, setTableData] = useState([]);

  const header = [
    {
      field: '',
      title: '#',
      width: '32px',
    },
    {
      field: '',
      title: 'Name',
      width: '250px',
    },
    {
      field: '',
      title: 'Category',
    },
    {
      field: 'first_transaction',
      title: 'First Txn',
    },
    {
      field: 'last_transaction',
      title: 'Last Txn',
    },
    {
      field: 'total_number_of_transactions',
      title: 'Total Txn count',
    },
    {
      field: 'number_of_transactions_last_7d',
      title: 'Last 7d',
    },
    {
      field: 'number_of_transactions_last_30d',
      title: 'Last 30d',
    },
    {
      field: 'number_of_transactions_last_90d',
      title: 'Last 90d',
    },
  ];

  useEffect(() => {
    if (data?.result?.length) {
      const temp = data.result.map((elem, index) => ({
        pageNumber: (
          <span className={styles.number}>{(page - 1) * limit + index + 1}</span>
        ),
        name: (
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex gap-2 align-items-center">
              <span className={`${styles.content_name} text-truncate`}>
                {elem.name}
              </span>
            </div>
          </div>
        ),
        category: (
          <div className={`${styles.pill} ${styles[elem.category]} px-3 d-flex align-items-center`}>
            {elem.category}
          </div>
        ),
        first_transaction: elem.first_transaction
          ? `${formatDistanceStrict(new Date(), new Date(elem.first_transaction))} ago`
          : '?',
        last_transaction: elem.last_transaction
          ? `${formatDistanceStrict(new Date(), new Date(elem.last_transaction))} ago`
          : '?',
        total_number_of_transactions: ethFormat(elem.total_number_of_transactions),
        number_of_transactions_last_7d: ethFormat(elem.number_of_transactions_last_7d),
        number_of_transactions_last_30d: ethFormat(elem.number_of_transactions_last_30d),
        number_of_transactions_last_90d: ethFormat(elem.number_of_transactions_last_90d),
      }));
      setTableData(temp);
    }
  }, [data]);

  return (
    <div className={`${styles.wrapper} d-flex w-100 flex-column align-items-center`}>
      <div className="d-flex flex-column align-self-start">
        <div className={styles.title}>Dapp Usage</div>
        <div className="d-flex w-100 justify-content-between align-items-center mb-4">
          <div className={styles.content_info}>
            {counter(data?.count, page, limit)}
          </div>
        </div>
      </div>
      <div className="w-100">
        <Table
          total={data?.count || 0}
          data={tableData}
          desc={desc}
          setDesc={setDesc}
          ordering={orderBy}
          setOrdering={setOrder}
          page={page}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
          headers={header}
          isLoading={isLoading}
          min={25}
        />
      </div>
    </div>
  );
};

export default DappUsageTable;
