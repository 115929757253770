import React, { useMemo, useState } from 'react';
import { formsApi } from '../../api/forms';
import NoForm from './NoForm';
import Loader from '../../components/base/Loader';
import FormsList from './FormsList';

const Forms = () => {
  const [ordering, setOrdering] = useState('created_at');
  const [page, setPage] = useState(1);
  const [desc, setDesc] = useState(true);
  const [limit, setLimit] = useState(10);

  const {
    data: forms,
    isLoading,
    isFetching,
    refetch,
  } = formsApi.useGetFormsQuery(
    {
      limit, offset: (page - 1) * limit, ordering: `${desc ? '-' : ''}${ordering}`,
    },
  );

  return useMemo(() => {
    if ((isLoading || isFetching) && !forms) {
      return <Loader />;
    }

    if (!forms?.results?.length) {
      return <NoForm />;
    }

    if (forms?.results?.length) {
      return (
        <FormsList
          data={forms.results}
          count={forms.count}
          limit={limit}
          setLimit={setLimit}
          isLoading={isLoading || isFetching}
          refetch={refetch}
          setDesc={setDesc}
          desc={desc}
          ordering={ordering}
          setOrdering={setOrdering}
          page={page}
          setPage={setPage}
        />
      );
    }

    return null;
  }, [isLoading, isFetching, forms, limit, refetch, desc, ordering, page]);
};

export default Forms;
