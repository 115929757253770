import React, { useMemo } from 'react';
import Chart from 'react-apexcharts';
import { useDispatch } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';
import { setAssetSlug, setSelectedAsset } from '../../../store/reducers/app';
import { convertToReadableFormat } from '../../../tools/NumberConverterTool';
import TitleComponent from '../../base/TitleComponent';
import Loader from '../../base/Loader';
import NoData from '../../base/NoData';
import uniqueId from '../../../utils/uniqueId';
import AssetImg from './AssetImg';
import AddressesModal from './AddressesModal';
import { ReactComponent as Collection } from '../../../assets/icons/layers.svg';
import styles from './NotableInvestments.module.scss';
import Tooltip from '../Tooltip';
import ItemPreview from '../ItemPreview';

const NotableInvestments = ({
  text, symbol, data, isNotableInvestmentsFetching, isNotableInvestmentsLoading, holders, info,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const isWallet = useMemo(
    () => location.pathname.includes('wallet'),
    [location.pathname],
  );

  const percentageToRender = (number) => {
    if (number < 1) {
      return '<1%';
    }
    if (number < 10) {
      return `${+number.toFixed(2)}%`;
    }
    if (number <= 99) {
      return `${+number.toFixed(1)}%`;
    }
    return '>99%';
  };

  const percentageHolders = (holder, qty, symb, value) => {
    if (data && holders) {
      const res = (holder / holders) * 100;
      const filterNumberLessOne = Math.max(...data
        .map((elem) => ((elem.number_holders || elem.wallet_value || elem.value) / holders) * 100)
        .filter((elem) => elem < 1));
      const maxValue = Math.max(
        ...data.map((item) => ((item.number_holders || item.wallet_value || item.value) / holders) * 100),
      );
      return {
        result: percentageToRender(res),
        series: [
          {
            data: [{
              x: '',
              y: res < 1
                ? Math.ceil(filterNumberLessOne)
                : res > 99
                  ? Math.ceil(maxValue)
                  : res === maxValue
                    ? Math.ceil(res) : res,
            }],
          },
        ],
        options: {
          chart: {
            width: '100%',
            toolbar: {
              show: false,
            },
          },
          states: {
            normal: {
              filter: {
                type: 'none',
                value: 0,
              },
            },
            hover: {
              filter: {
                type: 'none',
                value: 0,
              },
            },
            active: {
              allowMultipleDataPointsSelection: false,
              filter: {
                type: 'none',
                value: 0,
              },
            },
          },
          plotOptions: {
            bar: {
              expandOnClick: false,
              borderRadius: 4,
              horizontal: true,
            },
          },
          grid: {
            show: false,
          },
          dataLabels: {
            enabled: false,
          },
          tooltip: {
            followCursor: true,
            fixed: {
              position: 'topRight',
            },
            style: {
              fontSize: '12px',
              color: '#109CF1',
            },
            custom: () => (
              '<div style="padding: 8px; font-size: 12px; color: #6A707E; font-weight: 500;">'
              + '<div>'
              + `${!isWallet ? 'Audience collectively' : 'Wallet'} owns`
              + '</div>'
              + '<div>'
              + `${qty > 0
                ? convertToReadableFormat(qty) : '?'} ${symb ? symb?.toUpperCase()
                : ''}
              ${text.includes('NFT') ? 'NFTs,' : 'tokens,'} worth $${convertToReadableFormat(value)}`
              + '</div>'
              + '<div>'
              + `${text.includes('NFT') ? 'at floor price' : ''}`
              + '</div>'
            + '</div>'
            ),
            marker: {
              show: false,
            },
            x: {
              show: false,
            },
            y: {
              title: {
                formatter: () => '',
              },
            },
          },
          xaxis: {
            labels: {
              show: false,
            },
            crosshairs: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
            tooltip: {
              enabled: false,
            },
            axisTicks: {
              show: false,
            },
          },
          yaxis: {
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            min: 0,
            max: Math.ceil(maxValue),
            labels: {
              show: false,
              style: {
                width: 0,
              },
            },
          },
        },
      };
    }
    return 0;
  };

  return (
    <div className={`${styles.wrapper} h-100`}>
      <AddressesModal />
      <TitleComponent info={info}>{`Notable ${text} in portfolio${symbol ? 's' : ''}`}</TitleComponent>
      {isNotableInvestmentsLoading || isNotableInvestmentsFetching ? (
        <div className="d-flex justify-content-center align-items-center p-5 h-75">
          <Loader />
        </div>
      ) : (
        holders && holders !== 0 && data && data.length ? (
          <>
            {data.map((item) => (
              <div className="d-flex w-100 my-3 flex-column" key={uniqueId('Investments')}>
                <div key={item.name} className="d-flex w-100 align-items-start gap-2">
                  {(text.includes('NFT') && item?.contract_addresses?.length > 1) ? (
                    <div
                      role="presentation"
                      data-bs-toggle="modal"
                      data-bs-target="#exModal"
                      className={`${styles.img_wrapper} cursor-pointer position-relative`}
                      onClick={() => dispatch(setAssetSlug({
                        slug: item.opensea_slug,
                        name: item.name,
                        logo: item.logo,
                        symbol: item.symbol,
                        network: item.blockchain || item.platform,
                      }))}
                      data-for={item.opensea_slug}
                      data-tip="show"
                    >
                      <AssetImg text={text} type={text.includes('NFT') ? 'nft' : 'token'} logo={item.logo} />
                    </div>
                  ) : (
                    <Link
                      className={`${styles.img_wrapper} cursor-pointer`}
                      onClick={() => dispatch(setSelectedAsset({
                        type: text.includes('NFT') ? 'nft' : 'token',
                        name: item.name,
                        logo: item.logo,
                        symbol: item.symbol?.toUpperCase(),
                        network: item.blockchain || item.platform,
                      }))}
                      to={text.includes('NFT')
                        ? `/nfts/${item.blockchain || item.platform}/${item.contract_addresses}`
                        : `/coins/${item.coin_id}`}
                      role="presentation"
                      data-for={text.includes('NFT')
                        && `preview_${item.contract_addresses}_${item.blockchain || item.platform}`}
                      data-tip="show"
                    >
                      <AssetImg text={text} type={text.includes('NFT') ? 'nft' : 'token'} logo={item.logo} />
                    </Link>
                  )}
                  <div
                    className={`${styles.content} d-flex align-items-center justify-content-between pt-1`}
                  >
                    <div
                      className={`${styles.chart_name} d-flex`}
                      data-for={text.includes('NFT')
                        && `preview_${item.contract_addresses}_${item.blockchain || item.platform}`}
                      data-tip="show"
                    >
                      {text.includes('NFT') && item?.contract_addresses?.length > 1
                        ? (
                          <>
                            <span
                              className={`${styles.notable_name} text-truncate cursor-pointer`}
                              role="presentation"
                              data-bs-toggle="modal"
                              data-bs-target="#exModal"
                              data-for={item.opensea_slug}
                              data-tip="show"
                              onClick={() => dispatch(setAssetSlug({
                                slug: item.opensea_slug,
                                name: item.name,
                                logo: item.logo,
                                symbol: item.symbol,
                                network: item.blockchain || item.platform,
                              }))}
                            >
                              {item.name}
                            </span>
                            <ItemPreview
                              id={item.opensea_slug}
                              data={{
                                address: item.contract_addresses,
                                type: 'nft',
                                blockchain: item.blockchain || item.platform,
                                img: item.logo,
                                name: item.name,
                              }}
                              collection={item.opensea_slug}
                            />
                            <div
                              className={`d-flex align-items-center px-1 ${styles.collection}`}
                              data-for="collection"
                              data-tip
                            >
                              <Collection />
                            </div>
                            <Tooltip
                              id="collection"
                              info="This collection consists of multiple sub-collections"
                            />
                          </>
                        ) : (
                          <span
                            className={`${styles.notable_name} text-truncate cursor-pointer`}
                            role="presentation"
                            onClick={() => dispatch(setSelectedAsset({
                              type: text.includes('NFT') ? 'nft' : 'token',
                              name: item.name,
                              logo: item.logo,
                              symbol: item.symbol?.toUpperCase(),
                              network: item.blockchain || item.platform,
                            }))}
                          >
                            {item.name}
                          </span>
                        )}
                    </div>
                    {text.includes('NFT') && item?.contract_addresses?.length <= 1
                      ? (
                        <ItemPreview
                          id={`preview_${item.contract_addresses}_${item.blockchain || item.platform}`}
                          data={{
                            address: item.contract_addresses,
                            type: 'nft',
                            blockchain: item.blockchain || item.platform,
                            img: item.logo,
                            name: item.name,
                          }}
                        />
                      )
                      : null}
                    <span className={styles.notable_count}>
                      {percentageHolders(item.number_holders || item.wallet_value || item.value).result}
                    </span>
                  </div>
                </div>
                <div className="w-100 d-flex position-relative">
                  <div className={`position-absolute ${styles.chart}`}>
                    <Chart
                      type="bar"
                      options={percentageHolders(
                        (item.number_holders || item.wallet_value || item.value),
                        item.token_qty_decimal,
                        item.symbol,
                        item.value || item.wallet_value,
                      ).options}
                      series={percentageHolders(item.number_holders || item.wallet_value || item.value).series}
                      height={55}
                    />
                  </div>
                </div>
              </div>
            ))}
            {symbol && (
              <div className="d-flex">
                <div className="d-flex align-items-center gap-2 asset-wrap">
                  <div className="dot" />
                  <span>
                    {`Share of ${symbol
                      ? (symbol === 'audience' || symbol === 'detected wallets'
                        ? `${symbol} holding`
                        : `$${symbol?.toUpperCase()} holders also holding`)
                      : ''}`}
                  </span>
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="h-100 d-flex align-items-center justify-content-center">
            <NoData />
          </div>
        ))}
    </div>
  );
};

export default React.memo(NotableInvestments);
