import React from 'react';
import Chart from 'react-apexcharts';
import TitleComponent from '../../base/TitleComponent';
import Loader from '../../base/Loader';
import { convertNumber } from '../../../tools/NumberConverterTool';
import styles from './TradersAndInvestComponent.module.scss';
import NoData from '../../base/NoData';

const RadialBarData = {
  options: {
    chart: {
      type: 'donut',
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
        donut: {
          size: '77%',
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    stroke: {
      width: 0,
      colors: ['#f1f4f8'],
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    fixed: {
      enabled: false,
    },
    tooltip: {
      enabled: true,
      style: {
        fontSize: '12px',
        background: ['rgba(0, 143, 251, 0.85)', 'rgba(194, 207, 224, 0.85)'],
      },
      marker: {
        show: false,
      },
      x: {
        show: false,
      },
      y: {
        formatter: (y) => `${y}%`,
        title: {
          formatter: () => '',
        },
      },
    },
    colors: ['rgba(0, 143, 251, 0.85)', 'rgba(194, 207, 224, 0.85)'],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
        },
      },
    ],
  },
};

const TradersAndInvestComponent = ({
  holders, title, data, isFetching, isLoading, info, pctHeldOver30days,
}) => {
  const soldOut = (value, isHandled = false, isNumber = false) => {
    if (!value) return isNumber ? 0 : '0%';
    // TODO: uncomment when month is also transformed
    // if (value > 100) return '?';
    if (!holders) return isNumber ? 0 : '0%';
    const result = isHandled ? value : ((value / holders) * 100);
    if (result > 100) return isNumber ? 100 : '100%';
    if (result > 1) {
      return isHandled
        ? isNumber ? result.toFixed(2) : `${result.toFixed(2)}%`
        : isNumber ? Math.round(result) : `${Math.round(result)}%`;
    }
    return isNumber ? result.toFixed(2) : `${result.toFixed(2)}%`;
  };
  const pctHandleForChart = (value) => {
    if (!value || value < 0) return [0, 100];
    if (value > 100) return [100, 0];
    return [value, +(100 - value).toFixed(2)];
  };

  const risk = (value, number) => {
    if (!number) return 0;
    const result = ((value / number) * 100);
    if (result > 100) return 100;
    return result > 1 ? Math.round(result) : result.toFixed(2);
  };

  return (
    <div className={styles.invest_wrapper}>
      <TitleComponent info={info}>{title}</TitleComponent>
      { isLoading || isFetching
        ? (
          <div className="p-5">
            <Loader />
          </div>
        )
        : data || pctHeldOver30days !== null ? (
          <>
            <div className="d-flex align-items-center justify-content-start my-2 gap-1">
              <div className={styles.chart}>
                <Chart
                  options={RadialBarData.options}
                  series={
                    title.includes('Invest')
                      ? pctHeldOver30days !== undefined
                        ? pctHandleForChart(pctHeldOver30days)
                        : [+soldOut(data?.last_month, false, true),
                          +(100 - (+soldOut(data?.last_month, false, true))).toFixed(2)]
                      : [
                        +risk(data?.high_risk_addresses, holders),
                        100 - (+risk(data?.high_risk_addresses, holders)),
                      ]
                  }
                  type="donut"
                  height={180}
                />
              </div>
              <div>
                {!title.includes('Invest')
                  && (
                    <div className={styles.count_invest}>
                      {`${convertNumber(holders)} Holders`}
                    </div>
                  )}
                <div className="d-flex gap-2 align-items-center mt-1">
                  <div className={`${styles.percent_info_circle} ${styles.blue}`} />
                  <span className={styles.info_invest}>
                    {title.includes('Invest') ? 'Long term' : 'High'}
                  </span>
                </div>
                <div className="d-flex gap-2 align-items-center">
                  <div className={`${styles.percent_info_circle}`} />
                  <span className={styles.info_invest}>
                    {title.includes('Invest') ? 'Short term' : 'Low'}
                  </span>
                </div>
              </div>
            </div>
            <div className="d-flex gap-2 align-items-center">
              <span className={styles.info_invest}>
                {title.includes('Invest') ? 'Hold for at least a month' : 'Swing traders'}
              </span>
              <span className={styles.count_invest}>
                {holders
                  ? pctHeldOver30days !== undefined
                    ? (pctHeldOver30days && pctHeldOver30days > 0 && pctHeldOver30days <= 100)
                      ? `${pctHeldOver30days}%`
                      : '?'
                    : `${soldOut(title.includes('Invest') ? data?.last_month : data?.swing_traders || '')}`
                  : '?'}
              </span>
            </div>
            <div className="d-flex gap-2 align-items-center">
              <span className={styles.info_invest}>
                {title.includes('Invest') ? 'Sold within a week' : 'Transact every day'}
              </span>
              <span className={styles.count_invest}>
                {holders
                  ? `${title.includes('Invest')
                    ? soldOut(data?.last_week, true)
                    : soldOut(data?.active_addresses || '')}`
                  : '?'}
              </span>
            </div>
          </>
        ) : (
          <NoData />
        )}
    </div>
  );
};

export default React.memo(TradersAndInvestComponent);
