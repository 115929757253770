import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { localeStorageCache } from '../../utils/localeStorage';

const BASE_API = 'api/forms';

export const formsApi = createApi({
  reducerPath: 'formsAPI',
  baseQuery: fetchBaseQuery({
    prepareHeaders: (headers) => {
      const authKey = localeStorageCache.getAuth0Key();
      const authData = localeStorageCache.get(authKey);

      if (authData && authData.body) {
        const { id_token: idToken } = authData.body;
        if (idToken) {
          headers.set('Authorization', `Bearer ${idToken}`);
        }
      }
      return headers;
    },
    baseUrl: import.meta.env.VITE_APP_SERVER_DOMAIN,
  }),
  tagTypes: ['Form'],
  endpoints: (build) => ({
    getForms: build.query({
      query: (arg) => ({
        url: `${BASE_API}/?limit=${arg.limit}${
          arg.offset ? `&offset=${arg.offset}` : ''}${
          arg.ordering ? `&ordering=${arg.ordering}` : ''}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 1,
      providesTags: () => ['Form'],
    }),
    getFormById: build.query({
      query: (arg) => ({
        url: `${BASE_API}/${arg.id}/`,
        method: 'GET',
      }),
      keepUnusedDataFor: 1,
      providesTags: () => ['Form'],
    }),
    saveForm: build.mutation({
      query: (payload) => ({
        url: `${BASE_API}/`,
        method: 'POST',
        body: payload,
      }),
      providesTags: () => ['Form'],
    }),
    changeForm: build.mutation({
      query: (arg) => ({
        url: `${BASE_API}/${arg.id}/`,
        method: 'PATCH',
        body: arg.payload,
      }),
      providesTags: () => ['Form'],
    }),
    deleteForm: build.mutation({
      query: (id) => ({
        url: `${BASE_API}/${id}/`,
        method: 'DELETE',
      }),
      providesTags: () => ['Form'],
    }),
    copyForm: build.mutation({
      query: (id) => ({
        url: `${BASE_API}/copy/${id}/`,
        method: 'GET',
      }),
      providesTags: () => ['Form'],
    }),
    uploadImage: build.mutation({
      query: (payload) => ({
        url: `${BASE_API}/form-image/`,
        method: 'POST',
        body: payload,
      }),
      providesTags: () => ['Form'],
    }),
  }),
});
