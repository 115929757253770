import React, { useMemo } from 'react';
import Chart from 'react-apexcharts';
// import { useParams } from 'react-router-dom';
import NoData from '../../../../../components/base/NoData';
import RoundSpinner from '../../../../../components/base/RoundSpinner';
import { defaultOptions } from '../../../../../components/ui/Market/Price';
import styles from './DailyChart.module.scss';

const DailyChart = () => {
  // const { id } = useParams();

  // const {
  //   data: graphData,
  //   isLoading: isGraphDataLoading,
  //   isFetching: isGraphDataFetching,
  //   isSuccess,
  // } = webSDKApi.useGetWebSDKDataForChartQuery(id);

  const graphData = useMemo(() => ([
    {
      day: '2023-09-10',
      views: 100,
      responses: 20,
    },
    {
      day: '2023-09-11',
      views: 110,
      responses: 22,
    },
    {
      day: '2023-09-12',
      views: 95,
      responses: 18,
    },
    {
      day: '2023-09-13',
      views: 120,
      responses: 25,
    },
    {
      day: '2023-09-14',
      views: 130,
      responses: 28,
    },
    {
      day: '2023-09-15',
      views: 125,
      responses: 26,
    },
    {
      day: '2023-09-16',
      views: 110,
      responses: 10,
    },
    {
      day: '2023-09-17',
      views: 140,
      responses: 60,
    },
    {
      day: '2023-09-18',
      views: 40,
      responses: 50,
    },
    {
      day: '2023-09-19',
      views: 190,
      responses: 90,
    },
    {
      day: '2023-09-20',
      views: 190,
      responses: 30,
    },
    {
      day: '2023-09-21',
      views: 70,
      responses: 35,
    },
    {
      day: '2023-09-22',
      views: 90,
      responses: 50,
    },
    {
      day: '2023-09-23',
      views: 70,
      responses: 30,
    },
    {
      day: '2023-09-24',
      views: 10,
      responses: 3,
    },
    {
      day: '2023-09-25',
      views: 110,
      responses: 34,
    },
    {
      day: '2023-09-26',
      views: 190,
      responses: 30,
    },
    {
      day: '2023-09-27',
      views: 110,
      responses: 35,
    },
    {
      day: '2023-09-28',
      views: 120,
      responses: 70,
    },
    {
      day: '2023-09-29',
      views: 100,
      responses: 30,
    },
    {
      day: '2023-09-30',
      views: 100,
      responses: 130,
    },
    {
      day: '2023-10-01',
      views: 140,
      responses: 50,
    },
    {
      day: '2023-10-02',
      views: 10,
      responses: 30,
    },
    {
      day: '2023-10-03',
      views: 19,
      responses: 3,
    },
    {
      day: '2023-10-04',
      views: 195,
      responses: 30,
    },
    {
      day: '2023-10-05',
      views: 160,
      responses: 37,
    },
    {
      day: '2023-10-06',
      views: 100,
      responses: 36,
    },
  ]), []);

  // const graphData = null;
  const isGraphDataLoading = false;
  const isGraphDataFetching = false;
  const isSuccess = true;

  const handleData = (data) => ({
    series: [{
      name: 'Views',
      type: 'column',
      data: data.map((elem) => elem.views),
    }, {
      name: 'Responses',
      type: 'column',
      data: data.map((elem) => elem.responses),
    }],
    options: {
      chart: {
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
        type: 'arena',
        stacked: false,
      },
      stroke: {
        width: [1, 1, 1],
        curve: 'smooth',
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 1,
          columnWidth: '35%',
        },
      },
      grid: {
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      colors: ['#10bdf1', '#ffb946'],
      fill: {
        opacity: 1,
      },
      markers: {
        size: 0,
      },
      xaxis: {
        type: 'datetime',
        categories: data.map((elem) => elem.day),
        tickPlacement: 'on',
        // labels: {
        //   formatter: (x) => new Date(x).toUTCString().substr(4, 8),
        // },
        axisBorder: {
          show: true,
          color: '#6A707E',
        },
        axisTicks: {
          show: true,
          color: '#6A707E',
          // width: 4,
        },
        crosshairs: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: {
        axisBorder: {
          show: true,
          color: '#6A707E',
        },
        axisTicks: {
          show: true,
          color: '#6A707E',
          width: 4,
        },
        min: 0,
      },
      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter: (y, { ...chartData }) => (!chartData.seriesIndex ? `${y}` : y),
        },
      },
      legend: {
        show: false,
      },
    },
  });

  const chartStatus = useMemo(() => {
    if ((isGraphDataLoading || isGraphDataFetching) && !graphData) {
      return (
        <div className={`${styles.load_wrap} position-absolute`}>
          <RoundSpinner position="position-absolute" theme="light" />
        </div>
      );
    } if (!graphData && isSuccess) {
      return (
        <div className={`${styles.load_wrap} position-absolute`}>
          <NoData />
        </div>
      );
    } return null;
  }, [graphData, isGraphDataFetching, isGraphDataLoading, isSuccess]);

  return useMemo(() => (
    <>
      <div id="chart" className="position-relative">
        {chartStatus}
        <Chart
          options={isGraphDataLoading || isGraphDataFetching
            ? defaultOptions.options
            : graphData ? handleData(graphData).options : defaultOptions.options}
          series={(isGraphDataLoading || isGraphDataFetching)
            ? defaultOptions.series
            : graphData ? handleData(graphData).series : defaultOptions.series}
          type="line"
          height={400}
        />
      </div>
      <div className="d-flex gap-2">
        <div className="d-flex align-items-center gap-2 asset-wrap">
          <div className="dot" />
          <span>Views</span>
        </div>
        <div className="d-flex align-items-center gap-2 asset-wrap">
          <div className="dot-2" />
          <span>Responses</span>
        </div>
      </div>
    </>
  ), [chartStatus, graphData, isGraphDataFetching, isGraphDataLoading]);
};

export default DailyChart;
