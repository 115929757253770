import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { formsApi } from '../../../api/forms';
import { useWindowSize } from '../../../components/hooks/app';
import { counter } from '../../../tools/Counter';
import { ReactComponent as Trash } from '../../../assets/icons/table/trash.svg';
import { ReactComponent as DisabledTrash } from '../../../assets/icons/disabled_trash.svg';
import { ReactComponent as Copy } from '../../../assets/icons/table/copy.svg';
import { showErrorMessage, showSuccessMessage } from '../../../components/base/Notifications';
import ConfirmModal from '../../../components/ui/modals/ConfirmModal';
import Tooltip from '../../../components/ui/Tooltip';
import Table from '../../../components/base/Table';
import Settings from '../../../assets/images/dashboard/settings.png';
import tableStyles from '../../../components/base/Table/Table.module.scss';
import styles from './FormsList.module.scss';

const FormsList = ({
  data, count, refetch, ordering, setOrdering, desc, setDesc, page, setPage, isLoading, limit, setLimit,
}) => {
  const navigate = useNavigate();
  const { width } = useWindowSize();

  const [clickForm, setClickForm] = useState();
  const [copy, setCopy] = useState(null);
  const [tableData, setTableData] = useState([]);

  const [deleteTeamForm, deleteResult] = formsApi.useDeleteFormMutation();

  const [copyTeamForm, copyResult] = formsApi.useCopyFormMutation();

  const header = [
    {
      field: 'name',
      title: 'Form name',
      width: '400px',
    },
    {
      field: 'status',
      title: 'Status',
    },
    {
      field: 'created_by',
      title: 'Creator',
    },
    {
      field: 'submissions_count',
      title: 'Forms submitted',
    },
    {
      field: 'created_at',
      title: 'Creation date',
    },
    {
      title: (
        <div className="d-flex justify-content-center">
          Actions
        </div>
      ),
    },
  ];

  const handleDate = (date) => date.slice(0, 10);

  const handleCopyURL = (id) => {
    const url = `/add-form/${id}`; // Replace with your base URL
    navigator.clipboard.writeText(url)
      .then(() => {
        showSuccessMessage('URL copied to clipboard!');
      })
      .catch((err) => {
        showErrorMessage('Failed to copy URL: ', err);
      });
  };

  const onResultReceive = useCallback((response, type) => {
    if (response.isSuccess) {
      showSuccessMessage(`Form was successfully ${type}`);
      if (type === 'copied') {
        setCopy(null);
      }
      if (type === 'deleted') {
        setClickForm(null);
      }
      if (data.length === 1 && page > 1) {
        setPage(page - 1);
      } else {
        refetch();
      }
      response.reset();
    }
    if (response.isError) {
      showErrorMessage(Object.keys(response.error.data).length !== 0 && response.error.data.detail);
      response.reset();
    }
  }, [data.length, page, refetch, setPage]);

  useEffect(() => {
    onResultReceive(copyResult, 'copied');
  }, [copyResult, onResultReceive]);

  useEffect(() => {
    onResultReceive(deleteResult, 'deleted');
  }, [deleteResult, onResultReceive]);

  useEffect(() => {
    if (data) {
      const temp = data.map((elem) => ({
        name: (
          <>
            <span
              className={`d-block ${tableStyles.underline_on_hover}`}
              data-for={`Name_${elem.id}`}
              data-tip
            >
              <div
                role="presentation"
                onClick={() => {
                  if (elem.status.toLowerCase() === 'draft') {
                    navigate(`/add-form/${elem.id}`);
                  } else {
                    navigate(`/form-analytics/${elem.id}`);
                  }
                }}
                className={`${styles.name} text-truncate`}
              >
                {elem.name}
              </div>
            </span>
            {elem.name.length > width / 50 && (
              <Tooltip
                truncate
                id={`Name_${elem.id}`}
                info={elem.name}
              />
            )}
          </>
        ),
        status: (
          <div
            className={`
              ${styles.asset}
              ${styles[elem.status.toLowerCase()]}
              justify-content-center align-items-center`}
          >
            {elem.status}
          </div>
        ),
        created_by: elem.creator,
        submissions_count: elem.submissions_count,
        created_at: handleDate(elem.created_at),
        button: (
          <div className={`ms-auto ${tableStyles.popover_wrapper} text-center position-relative cursor-pointer`}>
            <img
              src={Settings}
              className="cursor-pointer"
              height="25"
              width="25"
              alt="settings"
            />
            <div className={`${tableStyles.invisible_dropdown} position-absolute top-0 right-0`}>
              <div className={`${tableStyles.popover_content} position-absolute`}>
                {elem.status === 'Active' ? (
                  <div
                    className={`
                      d-flex
                      justify-content-start
                      align-items-center
                      gap-1
                      ${tableStyles.modal_row}`}
                    onClick={() => handleCopyURL(elem.id)}
                    role="presentation"
                  >
                    CopyURL
                  </div>
                ) : (
                  null
                )}
                <div
                  className={`
                    d-flex
                    justify-content-start
                    align-items-center
                    gap-1
                    ${tableStyles.modal_row}`}
                  onClick={() => setCopy({ name: elem.name, id: elem.id })}
                  role="presentation"
                >
                  <Copy />
                  Duplicate
                </div>
                <div
                  className={`
                        d-flex
                        justify-content-start
                        align-items-center
                        gap-1
                        ${tableStyles.modal_row}`}
                  onClick={() => {
                    setClickForm({
                      name: elem.name,
                      id: elem.id,
                    });
                  }}
                  role="presentation"
                >
                  <Trash />
                  Delete
                </div>
              </div>
            </div>
          </div>),
      }));
      setTableData(temp);
    }
  }, [data]);

  return (
    <div className={`${styles.wrapper} asset-section d-flex flex-column justify-content-between`}>
      <div>
        { copy && (
          <ConfirmModal
            title="Confirm copy"
            description={`Are you sure you want to copy the “${copy.name}” form with its configurations?`}
            buttonName="Copy"
            onSubmit={() => copyTeamForm(copy.id)}
            onCancel={() => setCopy(null)}
            loading={copyResult.isLoading || copyResult.isFetching}
          />
        )}
        { clickForm && (
          <ConfirmModal
            title="Confirm delete"
            description={`Are you sure you want to delete “${clickForm?.name?.length > 30
              ? `${clickForm?.name.slice(0, 30)}...` : clickForm?.name}”?`}
            buttonName="Delete"
            onSubmit={() => deleteTeamForm(clickForm.id)}
            onCancel={() => setClickForm(null)}
            loading={deleteResult.isLoading || deleteResult.isFetching}
          />
        )}
        <div
          className={`d-flex justify-content-between align-items-center ${styles.responsive}`}
        >
          <div className={`${styles.showing} d-flex justify-content-between flex-column mb-3`}>
            <div className="title-h4-bold mb-2">Saved forms</div>
            {counter(count, page, limit)}
          </div>
          <button
            className="regular-button mb-3"
            type="button"
            onClick={() => {
              navigate('/add-form');
            }}
          >
            Create a Form
          </button>
        </div>
        <div className="w-100">
          <Table
            total={count}
            data={tableData}
            desc={desc}
            setDesc={setDesc}
            ordering={ordering}
            setOrdering={setOrdering}
            page={page}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
            headers={header}
            isLoading={isLoading}
            min={10}
          />
        </div>
      </div>
    </div>
  );
};

export default FormsList;
