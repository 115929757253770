import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import Chart from 'react-apexcharts';
import { selectIsRevenueLoading } from '../../../../store/reducers/coin';
import TitleComponent from '../../../../components/base/TitleComponent';
import { ethFormat } from '../../../../utils/singleAssetPage/parseData';
import NoData from '../../../../components/base/NoData';
import styles from './Revenue.module.scss';
import { convertWeiToEth } from '../../../../utils/segments';
import Loader from '../../../../components/base/Loader';

const Revenue = ({ revenueData, info }) => {
  const isLoading = useSelector(selectIsRevenueLoading);

  const DONUTRevenue = {
    series: [revenueData.mint ? +convertWeiToEth(revenueData.mint) : 0, revenueData.week, revenueData.royaltiesAfter],
    options: {
      chart: {
        type: 'donut',
        background: 'transparent',
      },
      plotOptions: {
        pie: {
          expandOnClick: false,
        },
      },
      states: {
        normal: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
        hover: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: 'none',
            value: 0,
          },
        },
      },
      stroke: {
        width: 8,
        colors: ['#f1f4f8'],
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
      fixed: {
        enabled: false,
      },
      colors: ['#1C76FD', '#FFB946', '#F7685B'],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
          },
        },
      ],
    },
  };

  const handleData = () => revenueData.royalties && !(
    revenueData.total === null
    && revenueData.mint === null
    && revenueData.week === null
    && revenueData.royaltiesAfter === null
    && revenueData.weekRevenue === null
    && revenueData.monthRevenue === null
  );

  const revenueContent = useMemo(() => {
    if (isLoading) {
      return (
        <div className="p-5">
          <Loader />
        </div>
      );
    }
    return (
      handleData() ? (
        <div className="d-flex flex-column">
          <div className="mt-3 align-items-baseline d-flex">
            <span className={styles.total_revenue}>
              TOTAL:
            </span>
            <span className={`${styles.count_revenue} px-1`}>
              {revenueData.total === null && revenueData.mint === null
                ? '?'
                : `${ethFormat((revenueData.total || 0) + (+convertWeiToEth(revenueData.mint || 0)))}
                 ETH ($${ethFormat(((revenueData.totalUsd || 0) + (revenueData.mintUsd || 0)))})`}
            </span>
          </div>
          <div className={`${styles.revenue_chart} d-flex gap-2 mt-2 mb-2`}>
            <div className={styles.chart_donut}>
              <Chart options={DONUTRevenue.options} series={DONUTRevenue.series} type="donut" height={150} />
            </div>
            <div className="d-flex flex-column align-items-start mt-2">
              {revenueData.mint !== null ? (
                <div className="d-flex gap-2 align-items-baseline">
                  <div className={`${styles.percent_info_circle} ${styles.blue}`} />
                  <span className={styles.info_revenue}>Mint</span>
                  <span className={styles.count_revenue}>
                    {ethFormat(+convertWeiToEth(revenueData.mint))}
                    {' '}
                    ETH ($
                    {ethFormat(revenueData.mintUsd)}
                    )
                  </span>
                </div>
              ) : null }
              <div className="d-flex gap-2 align-items-baseline mt-2">
                <div className={`${styles.percent_info_circle} ${styles.orange}`} />
                <span className={styles.info_revenue}>1st week royalties</span>
                <span className={styles.count_revenue}>
                  {revenueData.week !== null
                    ? `${ethFormat(revenueData.week)} ETH ($${ethFormat(revenueData.weekUsd)})`
                    : '?'}
                </span>
              </div>
              <div className="d-flex gap-2 align-items-baseline mt-2">
                <div className={`${styles.percent_info_circle} ${styles.red}`} />
                <span className={styles.info_revenue}>Royalties after</span>
                <span className={styles.count_revenue}>
                  {revenueData.royaltiesAfter !== null
                    ? `${ethFormat(revenueData.royaltiesAfter)} ETH ($${ethFormat(revenueData.royaltiesAfterUsd)})`
                    : '?'}
                </span>
              </div>
            </div>
          </div>
          <div className="mt-2 d-flex gap-2 align-items-baseline">
            <span className={styles.info_revenue}>Last 7d revenue</span>
            <span className={styles.count_revenue}>
              {revenueData.weekRevenue !== null
                ? `${ethFormat(revenueData.weekRevenue)} ETH ($${ethFormat(revenueData.weekRevenueUsd)})`
                : '?'}
            </span>
          </div>
          <div className="mt-1 d-flex gap-2 align-items-baseline">
            <span className={styles.info_revenue}>Last 30d revenue</span>
            <span className={styles.count_revenue}>
              {revenueData.monthRevenue !== null
                ? `${ethFormat(revenueData.monthRevenue)} ETH ($${ethFormat(revenueData.monthRevenueUsd)})`
                : '?'}
            </span>
          </div>
          <div className="mt-1 d-flex gap-2 align-items-baseline">
            <span className={styles.info_revenue}>Royalties</span>
            <span className={styles.count_revenue}>
              {revenueData.royalties !== null
                ? `${ethFormat(revenueData.royalties)}%`
                : '?'}
            </span>
          </div>
        </div>
      ) : (
        <div className="d-flex justify-content-center align-items-center">
          <NoData />
        </div>
      )
    );
  }, [
    DONUTRevenue?.options,
    DONUTRevenue?.series,
    handleData,
    isLoading,
    revenueData?.mint,
    revenueData?.mintUsd,
    revenueData?.monthRevenue,
    revenueData?.monthRevenueUsd,
    revenueData?.royalties,
    revenueData?.royaltiesAfter,
    revenueData?.royaltiesAfterUsd,
    revenueData?.total,
    revenueData?.totalUsd,
    revenueData?.week, revenueData?.weekRevenue, revenueData?.weekRevenueUsd, revenueData?.weekUsd]);

  return (
    <div className={`${styles.revenue_wrapper} d-flex flex-column`}>
      <TitleComponent info={info}>Revenue</TitleComponent>
      {revenueContent}
    </div>
  );
};

export default Revenue;
