import React from 'react';

import { ReactComponent as Polygon } from '../../../assets/icons/polygon.svg';
import { ReactComponent as Ethereum } from '../../../assets/icons/ethereum.svg';

import sharedStyles from './Shared.module.scss';

const IconNearby = ({ val }) => (
  <div className="d-flex cursor-pointer justify-content-between align-items-center">
    <div className="d-flex align-items-center flex-wrap">
      <div className={`${sharedStyles.indicatorWrapper} pe-1`}>
        {(val?.network === 'polygon' || val?.network === 'mumbai')
          ? <Polygon width="20" height="20" />
          : <Ethereum width="20" height="20" />}
      </div>
      <span className={`${sharedStyles.label} me-2`}>{val?.label}</span>
    </div>
  </div>
);

export default IconNearby;
