import React from 'react';
import Title from '../../../components/base/Title';
import Asset from './Asset';
import Widget from './Widget';

const Assets = ({
  widgetData, publishedAssets, ownedAssets, exchangeUsd,
}) => (
  <div className="asset-container">
    <div className="title-gap">
      <Title>Assets</Title>
    </div>
    <div className="dropdown-divider w-100" />
    <div className="info-wrapper-top d-flex justify-content-between gap-4">
      {widgetData.map((item) => (
        <Widget item={item} key={item.id} />
      ))}
    </div>
    <div className="info-price-wrapper d-flex flex-column gap-3">
      {publishedAssets.length
        ? (
          <>
            <Title>Main published assets</Title>
            <div className="grid-helper">
              {publishedAssets.map((asset) => (
                <Asset key={asset.id || asset.address} item={asset} exchangeUsd={exchangeUsd} />
              ))}
            </div>
          </>
        )
        : null}
      {ownedAssets.length
        ? (
          <>
            <Title>Main owned assets</Title>
            <div className="grid-helper">
              {ownedAssets.map((asset) => (
                <Asset key={asset.id || asset.address} item={asset} exchangeUsd={exchangeUsd} />
              ))}
            </div>
          </>
        )
        : null}
    </div>
  </div>
);

export default Assets;
