import React, {
  useState,
  useCallback,
  useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select, { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import { ethFormat } from '../../../../../../utils/singleAssetPage/parseData';
import ProvideTokensModal from './ProvideTokensModal';
import { setERC20Address } from '../../../../../../store/reducers/web3';
import { getAirdropContractAddress } from '../../../../../../utils/web3/loadContract';
import { coinApi } from '../../../../../../api/coin';
import { userApi } from '../../../../../../api/user';
import { flowsApi } from '../../../../../../api/flows';
import McapLabel from '../../../Components/McapLabel';
import { debounce } from '../../../../../../utils/debounce';
import { isValidContractAddress } from '../../../../../../tools/EtherTool';
import { IconNearby } from '../../../../../../components/base/SelectLabels';
import { getFormattedCoinData } from '../../../../../../utils/segments/getCoinNftData';
import { getUser } from '../../../../../../store/reducers/user';
import warning from '../../../../../../assets/icons/warning_rounded.svg';
import styles from './TokenAirdrop.module.scss';
import { convertSciToDec } from '../../../../../../tools/NumberConverterTool';
import { setTeamBalanceReducer } from '../../../../../../store/reducers/flows';

const ControlComponent = ({ props }) => {
  const propsToSet = { ...props };
  if (props.hasValue) {
    propsToSet.innerProps.className = null;
    propsToSet.selectProps.selectProps.head = true;
    delete propsToSet.selectProps.selectProps.description;
  }
  return (
    <div>
      <components.Control {...propsToSet} />
    </div>
  );
};

const inputStyles = {
  valueContainer: (style) => ({
    ...style,
    padding: '0 10px',
    minHeight: '46px',
  }),
  singleValue: (style) => ({
    ...style,
    padding: 0,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    opacity: 1,
  }),
  placeholder: (style) => ({
    ...style,
    fontSize: '14px',
  }),
  option: (style) => ({
    ...style,
    padding: '12px',
    boxShadow: '1px',
    border: '1px solid #F1F4F8',
  }),
  menuList: (style) => ({
    ...style,
    paddingTop: 0,
    paddingBottom: 0,
  }),
};

const blockChainOptions = [
  { label: 'Ethereum', value: 'eth', network: 'ethereum' },
  { label: 'Polygon', value: 'matic', network: 'polygon' },
  { label: 'Goerli', value: 'eth-goerli', network: 'goerli' },
  { label: 'Mumbai', value: 'matic-mumbai', network: 'mumbai' },
];

const TokensAirdrop = ({
  selectedBlockchain,
  setSelectedBlockchain,
  setAmountToAirdrop,
  register,
  setSelectedItem,
  selectedItem,
  setValue,
  getValues,
  disableChangeStatus,
  openWeb3Modal,
}) => {
  const dispatch = useDispatch();
  const [showProvideTokenModal, setShowProvideTokenModel] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(selectedItem?.contract_address);
  const [showAirDropWarning, setShowAirDropWarning] = useState(false);
  const [teamBalance, setTeamBalance] = useState(0);
  const [tokenAmount, setTokenAmount] = useState(0);
  const [coinOptions, setCoinOptions] = useState([]);
  const [isFocused, setIsFocused] = useState(false);
  const user = useSelector(getUser);

  const {
    data: searchCoin,
    isLoading: searchCoinIsLoading,
    isFetching: searchCoinIsFetching,
  } = coinApi.useSearchERC20TokenQuery(
    {
      query: '',
    },
  );

  const {
    data: teamData,
    isLoading: teamDataIsLoading,
    isFetching: teamDataIsFetching,
  } = userApi.useGetAssetsSearchQuery();

  const fetchCoinOptions = useCallback(() => {
    const formattedCoinData = getFormattedCoinData(teamData, searchCoin);
    setCoinOptions(formattedCoinData[0] && formattedCoinData[0].length
      ? formattedCoinData[0] : formattedCoinData[1].slice(0, 5));
  }, [teamData, searchCoin]);

  useEffect(() => {
    fetchCoinOptions();
  }, [fetchCoinOptions, teamData, searchCoin]);

  const getBalance = async () => {
    if (user.team && selectedAddress && selectedBlockchain) {
      const airdropContractAddress = getAirdropContractAddress(selectedBlockchain);
      const response = await dispatch(
        flowsApi
          .endpoints
          .getTokenAmountForAirdrop
          .initiate({
            address: airdropContractAddress,
            blockchain: selectedBlockchain,
            wallet_address: selectedAddress,
          }, { forceRefetch: true }),
      );
      if (response?.error) {
        setTeamBalance(0);
        dispatch(setTeamBalanceReducer(null));
      } else {
        let result = response.data?.balance;
        if (!Number.isNaN(result)) {
          result /= 10 ** 18;
          setTeamBalance(result);
          dispatch(setTeamBalanceReducer(result));
        }
      }
    }
  };

  useEffect(() => {
    getBalance();
  }, [dispatch, user.team, selectedAddress, selectedBlockchain]);

  const getUserInputData = useCallback(async (val) => {
    if (isValidContractAddress(val)) {
      return ([{
        value: val,
        label: val,
        head: true,
      }]);
    }

    const result = await dispatch(
      coinApi.endpoints.searchERC20Token.initiate({ query: val }),
    );

    if (result.status === 'fulfilled') {
      return result.data.map((item) => ({
        ...item,
        value: item.contract_address,
        label: item.name,
      }));
    }
    return null;
  }, [dispatch]);

  const loadUserInputData = useCallback(debounce((val) => getUserInputData(val), 1000), [getUserInputData]);
  const getMcapLabel = (val) => <McapLabel val={val} type="token" />;
  const getChainLabel = useCallback((val) => <IconNearby val={val} />, []);

  return (
    <>
      {
        showProvideTokenModal && window.ethereum
        && (
          <ProvideTokensModal
            selectedItem={selectedItem}
            getValues={getValues}
            setShowProvideTokenModel={setShowProvideTokenModel}
            setTeamBalanceOnSettings={setTeamBalance}
            openWeb3Modal={openWeb3Modal}
            teamBalance={teamBalance}
            getTeamBalance={getBalance}
          />
        )
      }
      <AsyncSelect
        className="w-100 mb-3"
        styles={inputStyles}
        maxMenuHeight={300}
        isOptionSelected={false}
        loadOptions={loadUserInputData}
        components={
          {
            // eslint-disable-next-line react/no-unstable-nested-components
            Control: (props) => <ControlComponent props={props} />,
          }
        }
        selectProps={selectedItem}
        getOptionLabel={(val) => getMcapLabel(val)}
        onChange={(e) => {
          if (!e.blockchain) {
            setSelectedAddress(e.value);
            setSelectedBlockchain(null);
            setTokenAmount('');
            setValue('address', e.value);
            setValue('blockchain', null);
            setValue('token_amount', '');
            setSelectedItem({
              ...e,
              label: `${e.label.slice(0, 21)}...${e.label.slice(-5)}`,
            });
          } else {
            setSelectedItem(e);
            setSelectedAddress(e.value);
            setValue('address', e.value);
            setValue('blockchain', e.blockchain);
            setValue('asset_name', e.label);
            setSelectedBlockchain(e.blockchain);
          }
          dispatch(setERC20Address(e.platforms?.ethereum || e.value));
        }}
        isLoading={teamDataIsLoading || teamDataIsFetching || searchCoinIsLoading || searchCoinIsFetching}
        placeholder="Token name or contract address"
        value={selectedItem}
        defaultOptions={coinOptions}
        isDisabled={disableChangeStatus}
      />
      <div className="mb-3">
        {!selectedItem?.image_url && selectedItem?.value && (
          <div className="my-3">
            <div className={`my-1 ${styles.block_title}`}>Select blockchain</div>
            <Select
              {...register('blockchain')}
              className="w-100"
              styles={inputStyles}
              value={blockChainOptions.filter((elem) => elem.network === getValues('blockchain'))}
              onChange={(val) => {
                setSelectedBlockchain(val.network);
                setValue('blockchain', val.network);
              }}
              getOptionLabel={(val) => getChainLabel(val)}
              options={blockChainOptions}
              isDisabled={disableChangeStatus}
            />
          </div>
        )}
        {getValues('blockchain') && getValues('address') && (
          <>
            <div className="d-flex justify-content-between align-items-center mb-1">
              <div className={styles.block_title}>
                Balance available for airdrop:
              </div>
              <div className={styles.balance_count}>
                {teamBalance || 0}
                {' '}
                {!selectedItem.symbol ? '' : selectedItem?.symbol?.toUpperCase() || ''}
              </div>
            </div>
            <div className="d-flex gap-3">
              <button
                type="button"
                className={`${styles.wallet_btn}
                outline-blue-button`}
                onClick={() => {
                  if (!user.air_drop) {
                    setShowAirDropWarning(true);
                  } else {
                    setShowProvideTokenModel(true);
                  }
                }}
                disabled={disableChangeStatus}
              >
                {`Provide ${!selectedItem.symbol ? '' : selectedItem?.symbol?.toUpperCase() || ''} tokens`}
              </button>
            </div>
            {showAirDropWarning && (
              <div className={`d-flex w-100 gap-2 mt-2 ${styles.wrap_warning}`}>
                <img src={warning} alt="warning" width="24px" height="24px" className="mt-1 align-self-start" />
                <div className="d-flex text-start w-75">
                  Your account is not allowed to launch a Flow performing airdrops.
                  Please communicate with your CSM for details.
                </div>
              </div>
            )}
          </>
        )}
      </div>
      {getValues('blockchain') && getValues('address') && (
        <div className="mb-3">
          <div className={`${styles.block_title} mb-1`}>
            Number of tokens to send to each wallets entering
          </div>
          <div className="d-flex align-items-center gap-3">
            <input
              type="number"
              step=".00000001"
              className={styles.input_number}
              value={isFocused
                ? getValues('token_amount' || tokenAmount) : convertSciToDec(getValues('token_amount' || tokenAmount))}
              onChange={(e) => {
                setValue('asset_name', selectedItem.label);
                setValue('token_amount', e.target.value);
                setTokenAmount(e.target.value);
                setAmountToAirdrop(e.target.value);
              }}
              min="0"
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              onWheel={(e) => e.target.blur()}
              disabled={disableChangeStatus}
            />
            <div className={styles.price}>
              {selectedItem?.current_price
                && `$${ethFormat(selectedItem.current_price * tokenAmount)}`}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TokensAirdrop;
