import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';

import styles from './PageContent.module.scss';
import DropdownMenu from '../../../components/base/DropdownMenu';
import Alert from '../../../components/ui/Alerts/Alert';
import Loader from '../../../components/base/Loader';
import Condition from '../Condition';
import Connector from '../Connector';
import WalletList from '../WalletList';

const PageContent = ({
  isSegmentDataLoading,
  editMode,
  createdConditions,
  selectedPage,
  userSegment,
  setShowDialog,
  isInRunning,
  getFilters,
  formattedTeamData,
  teamDataIsLoading,
  teamDataIsFetching,
  menuData,
  alertData,
  alertReachData,
  alertChannelsData,
}) => {
  const [isSticky, setSticky] = useState(false);
  const ref = useRef(null);

  const isStickyElem = () => {
    if (!ref.current) return;
    if (window.scrollY > 320) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', isStickyElem);
    return () => {
      window.removeEventListener('scroll', isStickyElem);
    };
  }, [isSticky]);

  return useMemo(() => {
    if (isSegmentDataLoading) {
      return (
        <div className="p-4">
          <Loader />
        </div>
      );
    }
    if (selectedPage === 2) {
      return <WalletList userSegment={userSegment} setShowDialog={setShowDialog} />;
    }
    return (
      <div className={`${styles.wrapper} d-flex flex-column p-0`}>
        <div className={styles.body}>
          <div>
            {createdConditions.length > 0 && createdConditions.map((condition, index) => (
              <div key={condition.id}>
                <Condition
                  id={condition.id}
                  conditionName={condition.category}
                  edit={editMode && !isInRunning}
                  filters={getFilters(condition.category)}
                  data={condition}
                  teamData={formattedTeamData}
                  teamDataIsLoading={teamDataIsLoading}
                  teamDataIsFetching={teamDataIsFetching}
                />
                {index + 1 !== createdConditions.length && <Connector />}
              </div>
            ))}

            {!isInRunning
              ? (
                <div className={styles.popover_wrapper}>
                  <button
                    className={`regular-button ${styles.button}`}
                    type="button"
                    data-bs-toggle="dropdown"
                    disabled={!editMode}
                  >
                    Add condition
                  </button>
                  <ul className={`${styles.popover_content} dropdown-menu`} data-bs-auto-close="false">
                    <DropdownMenu menuData={menuData} />
                  </ul>
                </div>
              )
              : null}

            {!createdConditions.length
              && (
                <div className={`title-h4-bold text-center my-auto ${styles.get_started}`}>
                  To get started with this new segment, add your first condition.
                </div>
              )}
          </div>
          <div className={`${styles.alert_wrapper} ${isSticky ? styles.isSticky : ''}`} ref={ref}>
            <Alert alertData={alertData} />
            <Alert alertData={alertReachData} />
            <Alert alertData={alertChannelsData} />
          </div>

        </div>
      </div>
    );
  }, [alertChannelsData,
    alertData,
    alertReachData,
    createdConditions,
    editMode,
    formattedTeamData,
    getFilters,
    isInRunning,
    isSegmentDataLoading,
    isSticky,
    menuData,
    selectedPage,
    setShowDialog,
    teamDataIsFetching, teamDataIsLoading, userSegment]);
};

export default PageContent;
