import React, { useMemo } from 'react';
import Chart from 'react-apexcharts';
import TitleComponent from '../../../../components/base/TitleComponent';
import NoData from '../../../../components/base/NoData';
import uniqueId from '../../../../utils/uniqueId';
import { convertToReadableFormat } from '../../../../tools/NumberConverterTool';
import styles from './PortfolioDistribution.module.scss';
import { ethFormat } from '../../../../utils/singleAssetPage/parseData';
import Loader from '../../../../components/base/Loader';

const PortfolioDistribution = ({ distributionData, total }) => {
  const seriesDataCount = () => (distributionData?.chart.filter((elem) => elem.percent !== null).length
    ? distributionData.chart.filter((elem) => !!elem.percent)
    : []);
  const DONUTDistribution = {
    options: {
      chart: {
        type: 'donut',
      },
      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            size: '75%',
          },
        },
      },
      states: {
        normal: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
        hover: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: 'none',
            value: 0,
          },
        },
      },
      stroke: {
        width: 1,
        colors: ['#f1f4f8'],
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      tooltip: {
        enabled: true,
        marker: {
          show: false,
        },
        x: {
          show: false,
        },
        y: {
          formatter: (y) => (`${+y.toFixed(2)}%`),
          title: {
            formatter: () => '',
          },
        },
      },
      fixed: {
        enabled: false,
      },
      colors: seriesDataCount().map((elem) => elem.chartColor),
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
          },
        },
      ],
    },
  };

  const distributionContent = useMemo(() => {
    if (!distributionData) {
      return (
        <div className="d-flex justify-content-center align-items-center p-5 h-75">
          <Loader />
        </div>
      );
    }
    if (seriesDataCount().length && total) {
      return (
        <>
          <div className="d-flex gap-2 distribution-chart mt-2 mb-2 flex-column flex-md-col align-items-center">
            <div className="distribution-donut w-100">
              <Chart
                options={DONUTDistribution.options}
                series={[...seriesDataCount().map((elem) => Math.floor(elem.percent))]}
                type="donut"
                height={180}
              />
            </div>
            <div className="d-flex gap-1 flex-column">
              {seriesDataCount().map((elem) => (
                <div className="d-flex align-items-center gap-2 asset-wrap text-nowrap" key={uniqueId('wallet')}>
                  <div className={`dot ${elem.color}`} />
                  <span>
                    {`$${convertToReadableFormat(elem.total)} in ${elem.title}`}
                  </span>
                </div>
              ))}
            </div>
          </div>
          <div className="d-flex flex-column align-items-center justify-content-between w-100 mt-3">
            <div className="d-flex flex-column w-100">
              <div className={`d-flex justify-content-between w-100 ${styles.chain}`}>
                <span>Ethereum</span>
                <span>Polygon</span>
              </div>
              <div className={`d-flex justify-content-between w-100 ${styles.data_value}`}>
                <span>
                  {distributionData.ethereumShare !== null ? `${distributionData.ethereumShare}%` : '?'}
                </span>
                <span>
                  {distributionData.ethereumShare !== null
                    ? `${ethFormat(100 - distributionData.ethereumShare)}%`
                    : distributionData.polygonShare !== null
                      ? `${distributionData.polygonShare}%`
                      : '?'}
                </span>
              </div>
              <div className="d-flex w-100 mt-1">
                {distributionData.ethereumShare ? (
                  <div
                    className={`${styles.eth} d-flex align-items-center px-1`}
                    style={{ width: `${distributionData.ethereumShare}%` }}
                  />
                ) : null}
                {distributionData.polygonShare ? (
                  <div
                    className={`${styles.polygon} d-flex align-items-center justify-content-end px-1`}
                    style={{
                      width: `${distributionData.ethereumShare !== null
                        ? 100 - distributionData.ethereumShare
                        : distributionData.ethereumShare}%`,
                    }}
                  />
                ) : null}
              </div>
              <div className={`${styles.data_value} d-flex align-items-center justify-content-between w-100 mt-3`}>
                <span>{distributionData.portfolioEth}</span>
                <span className={styles.description}>Portfolio Value</span>
                <span>{distributionData.portfolioPolygon}</span>
              </div>
              <div className={`${styles.data_value} d-flex align-items-center justify-content-between w-100 mt-2`}>
                <span>{distributionData.nativeBalanceEth}</span>
                <span className={styles.description}>Native Token Balance</span>
                <span>{distributionData.nativeBalancePolygon}</span>
              </div>
              <div className={`${styles.data_value} d-flex align-items-center justify-content-between w-100 mt-2`}>
                <span>{distributionData.transactionEth}</span>
                <span className={styles.description}># of Transaction</span>
                <span>{distributionData.transactionPolygon}</span>
              </div>
            </div>
          </div>
        </>
      );
    }
    return (
      <div className="d-flex justify-content-center align-items-center">
        <NoData />
      </div>
    );
  }, [DONUTDistribution?.options, distributionData, seriesDataCount, total]);

  return (
    <div className={`${styles.wrapper} d-flex flex-column h-100`}>
      <TitleComponent>Portfolio Distribution</TitleComponent>
      <div className="d-flex flex-column justify-content-center mt-4">
        {distributionContent}
      </div>
    </div>
  );
};

export default PortfolioDistribution;
