import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useImmer } from 'use-immer';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import {
  DndContext,
  DragOverlay,
  pointerWithin,
  closestCenter,
  rectIntersection,
  useSensors,
  useSensor,
  PointerSensor,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import * as Bootstrap from 'bootstrap';
import ToolBox, { ToolboxOverlayField } from './ToolBox';
import {
  clearFormState,
  clearActiveSettings,
  selectCurrPage,
  selectFormName,
  selectFormUrl,
  selectAccessType,
  selectFormStyle,
  selectFieldSettings,
  setErrors,
  setFormName,
  setFormUrl,
  loadFieldSettings,
  removeFieldSettings,
  initializeFieldSettings,
  setActiveSettings,
  updateFieldSettingsForPage,
  selectBackgroundImage,
  selectFormImage,
  selectFormColor,
  selectFormTextColor,
  selectButtonColor,
  selectButtonTextColor,
  selectBackgroundColor,
  selectFormImageUrl,
  selectBackgroundImageUrl,
  setFormStyle,
} from '../../../store/reducers/forms';
import styles from './CreateForm.module.scss';
import FormSettings from './FormSettings';
import Navigation from './Components/Navigation';
import FormsBuilder, { FormBuilderOverlayField } from './Components/FormsBuilder';
import Announcements from './Components/Announcements';
import './Style/Styles.scss';
import { formsApi } from '../../../api/forms';
import { showErrorMessage } from '../../../components/base/Notifications';
import { getUser } from '../../../store/reducers/user';
import { trackFormCreated } from '../../../utils/mixpanel/mixpanelEvents';

function getData(prop) {
  return prop?.data?.current ?? {};
}

function createSpacer({ id }) {
  return {
    id,
    type: 'spacer',
    title: 'spacer',
  };
}

const CreateForm = () => {
  const navigate = useNavigate();
  const [sidebarFieldsRegenKey, setSidebarFieldsRegenKey] = useState(Date.now());
  const spacerInsertedRef = useRef();
  const currentDragFieldRef = useRef();
  const [activeSidebarField, setActiveSidebarField] = useState();
  const [activeField, setActiveField] = useState();
  const [data, updateData] = useImmer({ fields: [] });
  const dispatch = useDispatch();
  const { formId } = useParams();
  const currPage = useSelector(selectCurrPage);
  const formName = useSelector(selectFormName);
  const formurl = useSelector(selectFormUrl);
  const accessType = useSelector(selectAccessType);
  const formStyle = useSelector(selectFormStyle);
  const fieldSettings = useSelector(selectFieldSettings);
  const [saveForm, result] = formsApi.useSaveFormMutation();
  const [showDialog, setShowDialog] = useState(false);
  const [prevPage, setPrevPage] = useState(null);
  const backgroundImage = useSelector(selectBackgroundImage);
  const formImage = useSelector(selectFormImage);
  const formColor = useSelector(selectFormColor);
  const formTextColor = useSelector(selectFormTextColor);
  const buttonColor = useSelector(selectButtonColor);
  const buttonTextColor = useSelector(selectButtonTextColor);
  const backgroundColor = useSelector(selectBackgroundColor);
  const formImageUrl = useSelector(selectFormImageUrl);
  const backgroundImageUrl = useSelector(selectBackgroundImageUrl);
  const user = useSelector(getUser);

  const {
    currentData: form,
    isLoading: isFormLoading,
    isFetching: isFormFetching,
  } = formsApi.useGetFormByIdQuery(
    {
      id: formId,
    },
    { skip: !formId },
  );

  const { fields } = data;

  useEffect(() => {
    setPrevPage(currPage);
  }, [currPage]);

  // Page Rendering Logic
  const mainContainer = document.getElementsByClassName('main');
  mainContainer[0].style.padding = 0;
  useEffect(() => () => {
    if (mainContainer.length > 0) {
      mainContainer[0].style.padding = '0 4em'; // error here (reading style of null)
    }
  }, [mainContainer]);

  useEffect(() => {
    if (formColor) {
      document.documentElement.style.setProperty('--form-background', formColor);
    }
    if (backgroundColor) {
      document.documentElement.style.setProperty('--modal-background', backgroundColor);
    }
  }, [formColor, backgroundColor]);

  useEffect(() => {
    if (backgroundImage && backgroundImageUrl) {
      document.documentElement.style.setProperty('--modal-background', `url(${backgroundImageUrl})`);
    } else if (backgroundColor) {
      document.documentElement.style.setProperty('--modal-background', backgroundColor);
    }
    if (formImage && formImageUrl) {
      document.documentElement.style.setProperty('--form-background', `url(${formImageUrl})`);
    } else if (formColor) {
      document.documentElement.style.setProperty('--form-background', formColor);
    }
    if (formTextColor) {
      document.documentElement.style.setProperty('--form-text-color', formTextColor);
    }
    if (buttonColor) {
      document.documentElement.style.setProperty('--form-button-background', buttonColor);
    }
    if (buttonTextColor) {
      document.documentElement.style.setProperty('--form-button-text-color', buttonTextColor);
    }
  }, [
    formColor,
    backgroundColor,
    backgroundImage,
    formImage,
    formImageUrl,
    backgroundImageUrl,
    formTextColor,
    buttonTextColor,
    buttonColor]);

  useEffect(() => () => dispatch(clearFormState()), [dispatch]);

  // Loads Existing Form Data
  useEffect(() => {
    if (form && !isFormLoading && !isFormFetching) {
      dispatch(setFormName(form.name));
      dispatch(setFormUrl(form.url));
      form.field_settings.forEach((pageSetting) => {
        pageSetting.fields.forEach((field) => {
          dispatch(loadFieldSettings({ pageName: pageSetting.pageName, field }));
        });
      });
      const mainPageSetting = form.field_settings.find((o) => o.pageName === 'main-form');
      if (mainPageSetting) {
        updateData((draft) => {
          draft.fields = mainPageSetting.fields;
        });
      }
      // Load Form Styles
      dispatch(setFormStyle({
        formStyle: {
          formColour: form.style.formStyle.formColour,
          formImageUrl: form.style.formStyle.formImageUrl,
          colourTheme: form.style.formStyle.colourTheme,
          textColour: form.style.formStyle.textColour,
        },
        buttonStyle: {
          buttonColour: form.style.buttonStyle.buttonColour,
          textColour: form.style.buttonStyle.textColour,
        },
        backgroundStyle: {
          backgroundColour: form.style.backgroundStyle.backgroundColour,
          backgroundImageUrl: form.style.backgroundStyle.backgroundImageUrl,
        },
      }));
    } else {
      dispatch(initializeFieldSettings());
    }
  }, [isFormLoading, isFormFetching]);

  const handleFieldRearrange = (updatedFields) => {
    if (!prevPage) {
      return;
    }
    const orderedFieldIds = updatedFields.map((field) => field.id);
    const previousPageSettings = fieldSettings.find((page) => page.pageName === prevPage);
    if (previousPageSettings) {
      const reorderedFields = orderedFieldIds.map((fieldId) => {
        const foundField = previousPageSettings.fields.find((field) => field.field_id === fieldId);
        if (!foundField) {
          return null;
        }
        return foundField;
      }).filter(Boolean);
      dispatch(updateFieldSettingsForPage({
        pageName: prevPage,
        fields: reorderedFields,
      }));
    }
  };

  useEffect(() => {
    if (prevPage === currPage) {
      return;
    }
    if (prevPage) {
      handleFieldRearrange(data.fields);
    }
    const currentPageFieldsSettings = fieldSettings.find((page) => page.pageName === currPage);
    if (currentPageFieldsSettings && currentPageFieldsSettings.fields) {
      const transformedFields = currentPageFieldsSettings.fields.map((field) => {
        const {
          // eslint-disable-next-line camelcase
          field_id, type, name, ...restOfFields
        } = field;

        return {
          // eslint-disable-next-line camelcase
          id: field_id,
          type,
          name: `${name}`,
          options: restOfFields,
        };
      });
      updateData((draft) => {
        draft.fields = transformedFields;
      });
      setPrevPage(currPage);
    } else {
      updateData((draft) => {
        draft.fields = [];
      });
    }
  }, [currPage, data.fields, fieldSettings, handleFieldRearrange]);

  // Saving: Perform Navgation after Save (or Draft)
  const onResultReceive = useCallback((response) => {
    if (response.isLoading || response.isFetching) {
      // setStartAndStopDateFlow(false);
    }
    if (response.isSuccess) {
      if (response.status === 'fulfilled') {
        trackFormCreated(user, response.data);
      }
      if (response.data.errors) {
        setShowDialog(true);
        dispatch(setErrors(response.data.errors));
        const myModal = new Bootstrap.Modal(document.getElementById('errorsModal'));
        myModal.toggle();
      }
      if ((!response.data.errors)
        || response.originalArgs.status === 'draft'
        || response.originalArgs.payload?.status === 'draft') {
        navigate('/forms');
      }
      response.reset();
    }
    if (response.isError) {
      if (response.error.status === 400) {
        showErrorMessage(Object.keys(response.error.data).length !== 0 && 'The Form with the same name already exists');
      } else {
        showErrorMessage(Object.keys(response.error.data).length !== 0 && 'Something went wrong');
      }
      setShowDialog(true);
      response.reset();
    }
  }, [dispatch, navigate]);

  useEffect(() => {
    onResultReceive(result);
  }, [result, onResultReceive]);

  const onSubmit = (status) => {
    handleFieldRearrange(data.fields);
    const updatedFieldSettings = fieldSettings.map((page) => {
      const formattedFields = page.fields.map((field) => {
        const {
          field_id: fieldId, name, type, ...options
        } = field;
        return {
          id: fieldId,
          name,
          type,
          options,
        };
      });

      return {
        ...page,
        fields: formattedFields,
      };
    });

    const dataToUpload = {
      name: formName,
      field_settings: updatedFieldSettings,
      url: formurl,
      accessType,
      style: formStyle,
      errors: [],
      isUnsaved: false,
      status,
    };
    saveForm(dataToUpload);
  };

  // Clean after drag and drop
  const cleanUp = () => {
    setActiveSidebarField(null);
    setActiveField(null);
    currentDragFieldRef.current = null;
    spacerInsertedRef.current = false;
  };

  const isFieldTypeAdded = (fieldType) => {
    const uniqueFields = [
      'email',
      'twitter',
      'instagram',
      'phone',
      'name',
      'discord',
      'telegram',
      'languages',
      'country'];
    return uniqueFields.includes(fieldType) && data.fields.some((f) => f.type === fieldType);
  };
  const handleDragStart = (e) => {
    cleanUp();
    dispatch(clearActiveSettings());
    const { active } = e;
    const activeData = getData(active);

    if (activeData.fromSidebar) {
      const { field } = activeData;
      const { type } = field;
      if (isFieldTypeAdded(type)) {
        return;
      }
      setActiveSidebarField(field);
      currentDragFieldRef.current = {
        id: active.id,
        type,
        name: `${type}${fields.length + 1}`,
        options: {},
      };
      return;
    }
    const { field, index } = activeData;
    setActiveField(field);
    currentDragFieldRef.current = field;
    updateData((draft) => {
      draft.fields.splice(index, 1, createSpacer({ id: active.id }));
    });
  };

  const handleDragOver = (e) => {
    const { active, over } = e;
    if (activeSidebarField) {
      if (!over) {
        updateData((draft) => {
          draft.fields = draft.fields.filter((f) => f.type !== 'spacer');
        });
        spacerInsertedRef.current = false;
        return;
      }
      const overData = getData(over);
      if (!spacerInsertedRef.current) {
        const spacer = createSpacer({
          id: `${active.id}-spacer`,
        });
        updateData((draft) => {
          if (!draft.fields.length) {
            draft.fields.push(spacer);
          } else {
            const nextIndex = overData.index > -1 ? overData.index : draft.fields.length;
            draft.fields.splice(nextIndex, 0, spacer);
          }
          spacerInsertedRef.current = true;
        });
      } else {
        updateData((draft) => {
          const spacerIndex = draft.fields.findIndex(
            (f) => f.id === `${active.id}-spacer`,
          );
          const nextIndex = overData.index > -1 ? overData.index : draft.fields.length - 1;
          if (nextIndex === spacerIndex) {
            return;
          }
          draft.fields = arrayMove(draft.fields, spacerIndex, overData.index);
        });
      }
    }
  };

  const handleDragEnd = (e) => {
    const { over } = e;
    if (!over) {
      cleanUp();
      updateData((draft) => {
        draft.fields = draft.fields.filter((f) => f.type !== 'spacer');
      });
      return;
    }

    // Swap Ref Id with Spacer (in Canvas)
    const nextField = currentDragFieldRef.current;
    if (nextField) {
      const overData = getData(over);
      updateData((draft) => {
        const spacerIndex = draft.fields.findIndex((f) => f.type === 'spacer');
        draft.fields.splice(spacerIndex, 1, nextField);
        if (!activeSidebarField) {
          draft.fields = arrayMove(
            draft.fields,
            spacerIndex,
            overData.index || 0,
          );
        }
      });
    }
    setSidebarFieldsRegenKey(Date.now());
    if (activeSidebarField) {
      dispatch(setActiveSettings({ type: currentDragFieldRef.current.type, field_id: currentDragFieldRef.current.id }));
    }
    if (!activeSidebarField) {
      handleFieldRearrange(data.fields);
    }

    cleanUp();
  };

  const handleRemove = (id) => {
    updateData((draft) => {
      draft.fields = draft.fields.filter((f) => f.id !== id);
    });
    dispatch(removeFieldSettings({ pageName: currPage, field: { id } }));
  };

  const customCollisionDetectionAlgorithm = (args) => {
    const pointerCollisions = pointerWithin(args);
    if (pointerCollisions.length > 0) {
      return pointerCollisions;
    }
    const closestCentered = closestCenter(args);
    if (closestCentered.length > 0 && closestCentered[0].data.value < 250) {
      return closestCentered;
    }
    return rectIntersection(args);
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
  );

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Forms - Absolute Labs Platform
        </title>
      </Helmet>
      <div className={styles.wrapper}>
        <div className={styles.dndform}>
          <div className={styles.reactform_wrapper}>
            <FormSettings />
            <DndContext
              onDragStart={handleDragStart}
              onDragOver={handleDragOver}
              onDragEnd={handleDragEnd}
              autoScroll
              collisionDetection={customCollisionDetectionAlgorithm}
              sensors={sensors}
            >
              <Announcements />
              <ToolBox fieldsRegKey={sidebarFieldsRegenKey} />
              <div className="w-100" style={{ overflowY: 'auto' }}>
                <div className={`${styles.dndformbuilder} card mx-auto my-5 p-4`}>
                  <SortableContext
                    strategy={verticalListSortingStrategy}
                    items={fields.map((f) => f.id)}
                    removable
                    handle
                  >
                    <FormsBuilder fields={fields} onRemove={handleRemove} />
                  </SortableContext>
                  {currPage === 'main-form' ? (
                    <button
                      type="button"
                      className={`regular-button ${styles.submitButton}`}
                      style={{ background: buttonColor, color: buttonTextColor }}
                      disabled
                    >
                      Submit
                    </button>
                  ) : null}
                  <Navigation />
                </div>
                <DragOverlay dropAnimation={false}>
                  {activeSidebarField ? (
                    <ToolboxOverlayField overlay field={activeSidebarField} />
                  ) : null}
                  {activeField ? <FormBuilderOverlayField overlay field={activeField} /> : null}
                </DragOverlay>
              </div>
            </DndContext>
          </div>
        </div>
      </div>
      <div className={`${styles.nav} d-flex justify-content-end align-items-center`}>
        <button
          type="button"
          className="outline-button"
          onClick={() => navigate('/forms')}
        >
          Cancel
        </button>
        <div className={styles.nav_save}>
          <button
            type="submit"
            className="outline-blue-button"
            onClick={() => { onSubmit('Draft'); }}
          >
            Save as Draft
          </button>
          <button
            type="submit"
            className="regular-button"
            onClick={() => { onSubmit('Active'); }}
          >
            Publish Form
          </button>
        </div>
      </div>
    </>
  );
};

export default CreateForm;
