import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { audiencesApi } from '../../../../api/audiences';
import { showErrorMessage } from '../../../base/Notifications';
import {
  clearState, createdSegment, getSegmentId, getUserSegment, getUserSegmentName, onRequest,
} from '../../../../store/reducers/segment';
import { getUser } from '../../../../store/reducers/user';
import { trackAudienceCreated } from '../../../../utils/mixpanel/mixpanelEvents';

const Segments = () => {
  const dispatch = useDispatch();
  const userSegment = useSelector(getUserSegment);
  const segmentId = useSelector(getSegmentId);
  const userSegmentName = useSelector(getUserSegmentName);
  const [createUserSegment, createUserSegmentResult] = audiencesApi.useCreateSegmentMutation();
  const [changeUserSegment, changeUserSegmentResult] = audiencesApi.useChangeSegmentMutation();
  const navigate = useNavigate();
  const user = useSelector(getUser);

  const createSegment = async () => {
    const segmentToPush = { ...userSegment, name: userSegmentName };
    if (!segmentToPush.name) {
      showErrorMessage('Add a segment name and try again.');
      return;
    }
    try {
      if (!segmentId) {
        const result = await createUserSegment(segmentToPush);
        if (result.error) {
          dispatch(onRequest(false));
          showErrorMessage(result.error.data.error);
          return;
        }
        dispatch(clearState());
        dispatch(onRequest(false));
        if (result?.data) {
          dispatch(createdSegment(result?.data?.id));
        }

        navigate('/audiences');
      } else if (segmentId) {
        const result = await changeUserSegment({ id: segmentId, ...segmentToPush });
        if (result.error) {
          dispatch(onRequest(false));
          showErrorMessage(result.error.data.error);
          return;
        }
        dispatch(clearState());
        dispatch(onRequest(false));
        if (result?.data?.status === 200) {
          dispatch(createdSegment(segmentId));
        }

        navigate('/audiences');
      }
    } catch (err) {
      showErrorMessage(err.message);
    }
  };

  useEffect(() => {
    if (createUserSegmentResult.data?.user && createUserSegmentResult.status === 'fulfilled') {
      trackAudienceCreated(user, createUserSegmentResult.data);
    }
    if (changeUserSegmentResult.isLoading || changeUserSegmentResult.isFetching
      || createUserSegmentResult.isLoading || createUserSegmentResult.isFetching) {
      dispatch(onRequest(true));
    } else {
      dispatch(onRequest(false));
    }
  }, [createUserSegmentResult, changeUserSegmentResult, dispatch]);

  const footerStyle = {
    left: '0px',
    marginTop: '100px',
    zIndex: 8,
  };

  return (
    <footer
      style={{ ...footerStyle }}
      className="wrapper-footer d-flex align-items-center justify-content-end gap-2"
    >
      <button className="outline-button" type="button" onClick={() => { navigate('/audiences'); }}>
        Cancel
      </button>
      <button className="regular-button" type="button" onClick={createSegment}>
        {segmentId ? 'Confirm changes' : 'Save segment'}
      </button>
    </footer>
  );
};

export default Segments;
