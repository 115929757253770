import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  useAccount,
  useNetwork,
  useProvider,
  useSigner,
} from 'wagmi';
import { ReactComponent as Close } from '../../../../../../../assets/icons/close.svg';
import styles from './ProvideNFTsModal.module.scss';
import { ReactComponent as Plus } from '../../../../../../../assets/icons/flows/plus_modal.svg';
import { ReactComponent as Equals } from '../../../../../../../assets/icons/flows/equals_modal.svg';
import { truncateAddress } from '../../../../../../../utils/web3/truncateAddress';
import { convertToReadableFormat } from '../../../../../../../tools/NumberConverterTool';
import { onPaste, validateInput } from '../../../../../../../utils/segments';
import { showErrorMessage } from '../../../../../../../components/base/Notifications';
import { loadNFTAidropContract } from '../../../../../../../utils/web3/loadContract';
import { getGasPrice } from '../../../../../../../utils/web3/getGasPrice';
import { metamaskErrorWrap } from '../../../../../../../utils/web3/metamaskErrorWrap';
import RoundSpinner from '../../../../../../../components/base/RoundSpinner';
import { getUser } from '../../../../../../../store/reducers/user';
import {
  getPendingTransactions,
  fetchPendingTxns,
  clearPendingTxn,
  isPendingTxn,
} from '../../../../../../../store/reducers/web3';

const ProvideNFTsModal = ({
  setShowProvideTokenModel,
  getTeamBalance,
  nftContract,
  selectedItem,
  teamBalance,
  userBalance,
  isERC1155,
  isApprovedForAll,
  approveForAll,
  openWeb3Modal,
  tokenIds,
  assetType = false,
}) => {
  const dispatch = useDispatch();
  const { address } = useAccount();
  const { chain } = useNetwork();
  const provider = useProvider();
  const pendingTxns = useSelector(getPendingTransactions);
  const [inputValue, setInputValue] = useState('');
  const { data: signer } = useSigner({ chainId: chain?.id });
  const [isApproving, setIsApproving] = useState(false);
  const user = useSelector(getUser);
  const fundText = 'Fund NFT Contract';
  const fundType = 'fund_NFT_contract';
  const airdropContract = loadNFTAidropContract(signer, isERC1155, chain?.id);

  const fundAirdropContract = async () => {
    // temporarily approve for all
    let temporarilyApproved = false;
    if (!isApprovedForAll) {
      setIsApproving(true);
      temporarilyApproved = true;
      await approveForAll(true);
      setIsApproving(false);
    }
    // Send Txn
    let fundTx;
    try {
      const gasPrice = await getGasPrice(provider);

      if (!isERC1155) {
        const tokensToSend = tokenIds.slice(0, Number(inputValue)).sort((a, b) => a - b);
        if (tokensToSend) {
          fundTx = await airdropContract.fundERC721(String(user.team), nftContract.address, tokensToSend, { gasPrice });
        }
      } else {
        // This isn't working yet
        fundTx = await airdropContract.fundERC1155(String(user.team), nftContract.address, '2', '1', { gasPrice });
      }

      dispatch(fetchPendingTxns({ txnHash: fundTx.hash, fundText, type: fundType }));
      await fundTx.wait();
    } catch (err) {
      return metamaskErrorWrap(err, showErrorMessage);
    } finally {
      if (fundTx) {
        dispatch(clearPendingTxn(fundTx.hash));
      }
      await getTeamBalance(isERC1155, airdropContract.address);
      setShowProvideTokenModel(false);
    }
    if (isApprovedForAll && temporarilyApproved) { await approveForAll(true); }
  };

  return (
    <div
      className={`${styles.modal_wrapper} modal modal-dialog-centered`}
      onMouseDown={() => { setShowProvideTokenModel(false); }}
      id="provideModal"
      tabIndex="-1"
      aria-labelledby="provideModalLabel"
      aria-hidden="true"
    >
      <div
        onMouseDown={(e) => {
          e.stopPropagation();
        }}
        role="presentation"
        className="modal modal-md modal-dialog modal-dialog-centered"
      >
        <div className={`${styles.content} modal-content border-0 position-relative`}>
          <div className={`${styles.close} position-absolute`}>
            <Close
              onClick={() => { setShowProvideTokenModel(false); }}
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className={styles.title}>
            {`Provide ${assetType ? 'NFTs' : 'Tokens'} for airdrop`}
          </div>
          <div className={styles.row}>
            <div className={styles.block_title}>
              {`Remaining ${assetType ? 'NFTs' : 'tokens'} available for airdrop:`}
            </div>
            <div className="d-flex mt-1 align-items-center gap-2">
              {teamBalance && (
                <span className={` ${styles.main_price}`}>
                  {teamBalance}
                  {' '}
                  {selectedItem?.symbol && selectedItem?.symbol?.toUpperCase()}
                </span>
              )}
            </div>
          </div>
          <div className={`${styles.row_button} d-flex justify-content-center`}>
            <Plus />
          </div>
          <div className={styles.row}>
            <div className={styles.block_title}>
              Enter amount to top up the airdrop balance
            </div>
            <div className="d-flex mt-2 mb-1 align-items-center gap-2">
              <input
                value={inputValue}
                type="number"
                min="0"
                max={userBalance}
                step="0.001"
                onPaste={onPaste}
                onWheel={(e) => e.target.blur()}
                onChange={(e) => {
                  const ifValidValue = validateInput(e.target.value, false, true);
                  if (!ifValidValue) {
                    return;
                  }
                  setInputValue(e.target.value);
                }}
                className={styles.balance_input}
              />
            </div>
            {address && chain && (
              Number(userBalance) >= Number(inputValue) && Number(userBalance) > 0
                ? (
                  <div className={styles.balance}>
                    Your balance:
                    {' '}
                    {userBalance}
                    {' '}
                    {selectedItem?.symbol && selectedItem?.symbol?.toUpperCase()}
                  </div>
                ) : (
                  <div className={styles.insufficient_balance}>
                    Insufficient
                    {' '}
                    {selectedItem?.symbol && selectedItem?.symbol?.toUpperCase()}
                    {' '}
                    balance
                  </div>
                )
            )}
          </div>
          <div className={`${styles.row_button} d-flex justify-content-center`}>
            <Equals />
          </div>
          <div className={styles.row}>
            <div className={styles.block_title}>
              Total balance available to airdrop after transfer:
            </div>
            <div className="d-flex mt-1 align-items-center gap-2">
              {teamBalance ? (
                <span className={styles.main_price}>
                  {convertToReadableFormat(Number(inputValue) + Number(teamBalance))}
                  {' '}
                  {selectedItem?.symbol && selectedItem?.symbol?.toUpperCase()}
                </span>
              )
                : <span className={`${styles.main_price} text-center`}>Connect your wallet</span>}
            </div>
          </div>
          <div className={`${styles.footer} mt-4 d-flex flex-column justify-content-center align-items-center gap-2`}>
            {
              address
                ? (
                  <button
                    type="button"
                    disabled={(
                      address
                      && (Number(userBalance) < Number(inputValue)
                        || Number(userBalance) === 0
                        || isPendingTxn(pendingTxns, fundType)))}
                    className="regular-button w-40 mx-auto"
                    onClick={async () => { fundAirdropContract(); }}
                  >
                    {isPendingTxn(pendingTxns, fundType) || isApproving ? (
                      <div>
                        <RoundSpinner position="position-absolute" theme="light" />
                        {
                          isApproving ? 'Approving...' : 'Funding...'
                        }
                      </div>
                    ) : 'Provide NFTs'}
                  </button>
                )
                : (
                  <button
                    type="button"
                    className="regular-button w-40 mx-auto"
                    onClick={async () => { openWeb3Modal(); }}
                  >
                    Connect Wallet
                  </button>
                )
            }
            {address && (
              <div className={styles.wallet}>
                Connected wallet:
                {' '}
                <div
                  role="presentation"
                  className={styles.wallet_address}
                  onClick={() => openWeb3Modal()}
                >
                  {truncateAddress(address)}
                </div>
                {' '}
              </div>
            )}
            {chain && (
              <div className={styles.wallet}>
                Current Chain:
                {' '}
                {chain.name}
                {' '}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProvideNFTsModal;
