import React, {
  useState, useCallback, useRef, useMemo,
} from 'react';
import Select from 'react-select';
import { useForm } from 'react-hook-form';
// import { useSelector } from 'react-redux';
import { useWeb3Modal } from '@web3modal/react';
import { ethers } from 'ethers';
import {
  useAccount,
  useNetwork,
  useSwitchNetwork,
  useSigner,
} from 'wagmi';
import { nftCollectionContractAbi, bytecode } from '../../../../utils/web3/nftCollectionContractAbi';
import useKeydown from '../../../hooks/app/useKeydown';
import RoundSpinner from '../../../base/RoundSpinner';
import CloseModalButton from '../components/CloseModalButton';
// import { getUser } from '../../../../store/reducers/user';
import { IconNearby } from '../../../base/SelectLabels';
import { ReactComponent as Arrow } from '../../../../assets/icons/select_arrow_black.svg';
import { ReactComponent as Upload } from '../../../../assets/icons/upload.svg';
import { ReactComponent as InfoIcon } from '../../../../assets/icons/info.svg';
import { ReactComponent as Trash } from '../../../../assets/icons/trash_asset.svg';
import {
  onPaste, preventInvalidSymbols,
} from '../../../../utils/segments';
import Tooltip from '../../Tooltip';
import styles from './NFTCollectionCreationModal.module.scss';

const blockChainOptions = [
  { label: 'Ethereum', value: 'eth', network: 'ethereum' },
  { label: 'Polygon', value: 'matic', network: 'polygon' },
  { label: 'Goerli', value: 'eth-goerli', network: 'goerli' },
  { label: 'Mumbai', value: 'matic-mumbai', network: 'mumbai' },
];

const inputStyles = {
  valueContainer: (style) => ({
    ...style,
    padding: '0 10px',
    minHeight: '46px',
  }),
  singleValue: (style) => ({
    ...style,
    padding: 0,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    opacity: 1,
  }),
  placeholder: (style) => ({
    ...style,
    fontSize: '14px',
  }),
  option: (style) => ({
    ...style,
    padding: '12px',
    boxShadow: '1px',
    border: '1px solid #F1F4F8',
  }),
  menuList: (style) => ({
    ...style,
    paddingTop: 0,
    paddingBottom: 0,
  }),
};

const NFTCollectionCreationModal = ({
  onCancel,
  loading,
}) => {
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
  const [dragActive, setDragActive] = useState(false);
  const [isDeploying, setIsDeploying] = useState(false);
  const [file, setFile] = useState(null);
  const [urlImage, setUrlImage] = useState('');
  const inputRef = useRef(null);

  const { open } = useWeb3Modal();
  const { isConnected } = useAccount();
  const { chain } = useNetwork();
  const { chains, switchNetwork } = useSwitchNetwork();
  const { data: signer } = useSigner({ chainId: chain?.id });

  // const user = useSelector(getUser);

  const {
    register, handleSubmit, setValue, watch,
  } = useForm();

  const openWeb3Modal = (async () => {
    await open();
  });

  const getCoinLabel = useCallback((val) => <IconNearby val={val} />, []);

  // const [createNFT, creationResult] = contractsApi.useCreateNFTMutation();

  const handleChange = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setUrlImage(URL.createObjectURL(e.target.files[0]));
      setFile(e.target.files[0]);
    }
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setUrlImage(URL.createObjectURL(e.dataTransfer.files[0]));
      setFile(e.dataTransfer.files[0]);
    }
  };

  const clearAsset = () => {
    setUrlImage('');
    setFile(null);
  };

  const onButtonClick = () => {
    inputRef.current.click();
  };

  const onSubmit = async (onSubmitData) => {
    setIsDeploying(true);
    // createNFT(formData);
    const currNetwork = onSubmitData.blockchain === 'mumbai' ? 'maticmum'
      : onSubmitData.blockchain === 'ethereum' ? 'homestead' : onSubmitData.blockchain;
    if (currNetwork !== chain?.network) {
      switchNetwork(chains.find((c) => c.network === currNetwork).id);
    }
    // Deploy
    const factory = new ethers.ContractFactory(nftCollectionContractAbi, bytecode, signer);
    const address = await signer.getAddress();
    const contract = await factory.deploy(
      onSubmitData.contract_name,
      onSubmitData.contract_symbol,
      onSubmitData.collection_name,
      onSubmitData.collection_description,
      'https://www.google.com',
      '',
      onSubmitData.transferable === 'yes',
      onSubmitData.max_supply,
      address,
    );
    await contract.deployTransaction.wait();
    setIsDeploying(false);
    window.open(`/nfts/${onSubmitData.blockchain}/${contract.address}`, '_self');
  };

  const generateSymbol = (string) => {
    let result = '';
    const array = string.split(' ');
    if (array.length > 1) {
      result = array.map((elem) => elem[0]).join('');
    } else {
      result = string.slice(0, 20);
    }
    return result;
  };

  const assetsContent = useMemo(() => {
    if (file) {
      return (
        <div className={styles.label_file_showing}>
          <div className="d-flex align-items-center w-75 gap-2">
            <div className={styles.img_asset}>
              <img src={urlImage} alt="img" width="50px" height="50px" />
            </div>
            <span className="text-truncate">{file?.name || ''}</span>
          </div>
          <div className="d-flex align-items-center justify-content-center">
            <button
              type="button"
              className={`${styles.upload_button} cursor-pointer`}
              onClick={() => clearAsset()}
            >
              <Trash />
            </button>
            <label {...register('media')} htmlFor="input-file-upload">
              <input
                id="input-file-upload"
                ref={inputRef}
                type="file"
                accept=".jpg,.png,.gif,.svg,.mp4,.webm,.mp3,.wav,.ogg,.glb,.gltf"
                className={styles.input_file_upload}
                onChange={handleChange}
              />
              <button
                type="button"
                className={`${styles.upload_button} ${styles.upload_color}`}
                onClick={onButtonClick}
              >
                <Upload />
              </button>
            </label>
          </div>
        </div>
      );
    }
    return (
      <label
        htmlFor="input-file-upload"
        className={styles.label_file_upload}
      >
        <input
          id="input-file-upload"
          ref={inputRef}
          type="file"
          accept=".jpg,.png,.gif,.svg,.mp4,.webm,.mp3,.wav,.ogg,.glb,.gltf"
          className={styles.input_file_upload}
          onChange={handleChange}
        />
        <button
          type="button"
          className={styles.upload_button}
          onClick={onButtonClick}
        >
          <div className="d-flex align-items-center justify-content-center gap-3">
            <Upload />
            <span>Drag & Drop Image, Video, Audio, 3D Model, or click to upload</span>
          </div>
        </button>
      </label>
    );
  }, [file]);

  useKeydown('Escape', onCancel);
  return (
    <form
      className={`modal modal-dialog-centered ${styles.modal_wrapper}`}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="modal-dialog modal-md modal-phone w-100 position-relative">
        <div
          className={`${styles.body} modal-content position-relative border-0`}
          onDragEnter={handleDrag}
        >
          {loading
            ? <RoundSpinner />
            : null}
          <CloseModalButton onCancel={onCancel} />
          {dragActive
            && (
              <div
                className={styles.drag_file_element}
                onDragEnter={handleDrag}
                onDragLeave={handleDrag}
                onDragOver={handleDrag}
                onDrop={handleDrop}
              >
                <div className="d-flex align-items-center justify-content-center gap-2 h-100 w-100">
                  <div className={styles.upload_img} />
                  Drop file anywhere to upload
                </div>
              </div>
            )}
          <div>
            <div className={`${styles.title} d-flex justify-content-start`}>
              Create a new NFT Collection
            </div>
          </div>
          <div className={`${styles.scroll} d-flex flex-column w-100`}>
            <div className="d-flex flex-column w-100 gap-1 mb-3">
              <div className={styles.title_input}>
                Collection name
              </div>
              <div className={styles.link_area}>
                <input
                  {...register('collection_name', { required: true })}
                  maxLength={50}
                  onChange={(e) => {
                    setValue('collection_name', e.target.value);
                    setValue('contract_name', e.target.value.slice(0, 20));
                    setValue('contract_symbol', generateSymbol(e.target.value));
                  }}
                  placeholder="Enter name"
                  type="text"
                  className="w-100 p-2"
                />
              </div>
            </div>
            <div className="d-flex flex-column w-100 gap-1 mb-3">
              <div className={styles.title_input}>
                Collection Description
              </div>
              <textarea
                {...register('collection_description', { required: true })}
                rows={3}
                placeholder="Enter description"
                onChange={(e) => setValue('collection_description', e.target.value)}
                className={`shadow-none form-control w-100 ${styles.text_area}`}
                maxLength={1000}
                name="description"
                id="description"
                onWheel={(e) => e.target.blur()}
              />
            </div>
            <div className="d-flex flex-column w-100 gap-1 mb-3">
              <div className={styles.nft_asset}>
                <span>NFT Asset</span>
                <span
                  style={{ alignSelf: 'start' }}
                  className="px-1"
                  data-for="file_format"
                  data-tip="show"
                >
                  <InfoIcon />
                </span>
                <Tooltip
                  id="file_format"
                  info={(
                    <div className={styles.tooltip_info}>
                      <div>
                        File types supported:
                      </div>
                      <div>JPG,PNG,GIF,SVG,MP4,WEBM,MP3,WAV,OGG,GLB,GLTF</div>
                    </div>
                  )}
                />
              </div>
              {assetsContent}
            </div>
            <div className="d-flex w-100 gap-2">
              <div className="d-flex flex-column w-50 gap-1 mb-3">
                <div className={styles.title_input}>
                  Blockchain
                </div>
                <Select
                  {...register('blockchain', { required: true })}
                  styles={inputStyles}
                  key="blockchain"
                  value={
                    (() => {
                      const value = blockChainOptions
                        .filter((blockchain) => blockchain.network === watch('blockchain'))[0];
                      return value || null;
                    })()
                  }
                  onChange={(val) => {
                    if (watch('blockchain') === val.network) {
                      return;
                    }
                    setValue('blockchain', val.network);
                  }}
                  getOptionLabel={(val) => getCoinLabel(val)}
                  name="blockchain"
                  options={blockChainOptions}
                />
              </div>
              <div className="d-flex flex-column w-50 gap-1 mb-3">
                <div className={styles.title_input}>
                  <span>Max number of NFTs</span>
                  <span>Optional</span>
                </div>
                <input
                  {...register('max_supply', {
                    valueAsNumber: true,
                  })}
                  value={watch('max_supply') || ''}
                  className={`shadow-none form-control h-100 ${styles.max_input}`}
                  onWheel={(e) => e.target.blur()}
                  min="0"
                  type="number"
                  placeholder="1000"
                  onChange={(e) => setValue('max_supply', e.target.value)}
                  onPaste={onPaste}
                  onKeyPress={(e) => preventInvalidSymbols(e)}
                />
              </div>
            </div>
            <div className="d-flex flex-column mb-2">
              <div className={styles.title_input}>
                Choose NFT Collection type
              </div>
              <div
                {...register('transferable')}
                className="d-flex gap-2 mt-2"
              >
                <button
                  type="button"
                  className={watch('transferable') === 'yes' ? 'check-button-checked text-success' : 'check-button'}
                  onClick={() => setValue('transferable', watch('transferable') === 'yes' ? null : 'yes')}
                >
                  Transferable
                </button>
                <button
                  type="button"
                  className={watch('transferable') === 'no' ? 'check-button-checked text-success' : 'check-button'}
                  onClick={() => setValue('transferable', watch('transferable') === 'no' ? null : 'no')}
                >
                  Non-Transferable
                </button>
              </div>
            </div>
            <div className="block-divider w-100" />
            <div className="d-flex justify-content-between align-items-center w-100 px-3">
              <div className={styles.advanced_options}>
                Advanced options
              </div>
              <div
                className={`${showAdvancedOptions
                  ? styles.rotate
                  : styles.back_rotate} d-flex justify-content-center align-items-center cursor-pointer`}
                role="presentation"
                onClick={() => setShowAdvancedOptions(!showAdvancedOptions)}
              >
                <Arrow />
              </div>
            </div>
            {showAdvancedOptions ? (
              <div className="d-flex flex-column w-100 my-2">
                <div className="d-flex flex-column w-100 gap-1 mb-3">
                  <div className={styles.title_input}>
                    Contract name
                  </div>
                  <div className={styles.link_area}>
                    <input
                      {...register('contract_name', { required: true })}
                      maxLength={20}
                      onChange={(e) => setValue('contract_name', e.target.value.trim())}
                      placeholder="Input contract name"
                      type="text"
                      className="w-100 p-2"
                    />
                  </div>
                </div>
                <div className="d-flex flex-column w-100 gap-1 mb-3">
                  <div className={styles.title_input}>
                    Symbol
                  </div>
                  <div className={styles.link_area}>
                    <input
                      {...register('contract_symbol', { required: true })}
                      maxLength={20}
                      onChange={(e) => setValue('contract_symbol', e.target.value.trim())}
                      placeholder="Input symbol"
                      type="text"
                      className="w-100 p-2"
                    />
                  </div>
                </div>
                {/* <div className="d-flex flex-column w-50 gap-1"> */}
                {/*  <div className={styles.title_input}> */}
                {/*    Expires after */}
                {/*  </div> */}
                {/*  <Select */}
                {/*    {...register('contract_type')} */}
                {/*    styles={inputStyles} */}
                {/*    key="blockchain" */}
                {/*    value={ */}
                {/*      (() => { */}
                {/*        const value = expiresOptions */}
                {/*          .filter((opt) => opt.value === watch('contract_type'))[0]; */}
                {/*        return value || null; */}
                {/*      })() */}
                {/*    } */}
                {/*    onChange={(val) => setValue('contract_type', val.value)} */}
                {/*    name="expires_after" */}
                {/*    options={expiresOptions} */}
                {/*  /> */}
                {/* </div> */}
              </div>
            ) : null}
            <div className={`${styles.footer} d-flex justify-content-end`}>
              {
                !isDeploying ? (
                  isConnected ? (
                    <button
                      type="submit"
                      className="regular-button mt-3"
                    >
                      Deploy
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="regular-button mt-3"
                      onClick={async () => openWeb3Modal()}
                    >
                      Connect Wallet
                    </button>
                  )
                ) : (
                  <RoundSpinner />
                )
              }
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default NFTCollectionCreationModal;
