// mixpanelEvents.js
import mixpanel from 'mixpanel-browser';

const setUserProfile = (user) => {
  const userProperties = {
    $first_name: user.first_name,
    $last_name: user.last_name,
    $email: user.email,
    team_title: user.team_title,
  };

  mixpanel.identify(user.email);
  mixpanel.people.set(userProperties);
};

export const trackLogin = (user) => {
  setUserProfile(user);
  mixpanel.track('Login', {
    user: `${user.first_name} ${user.last_name}`,
    team: user.team_title,
    email: user.email,
  });
};

export const trackPageView = (user, location) => {
  setUserProfile(user);
  mixpanel.track('Page Viewed', {
    page: location,
    user: `${user.first_name} ${user.last_name}`,
    team: user.team_title,
    email: user.email,
    permission_airdrop: user.air_drop,
    permission_audiences: user.audiences,
    permission_forms: user.forms,
    permission_flows: user.flows,
    permission_nftCreation: user.nft_creation,
    permission_websdk: user.web_sdk,
    permission_insights: user.insights,
    permission_dataExport: user.data_export,
  });
};

export const trackAudienceCreated = (user, res) => {
  setUserProfile(user);
  mixpanel.track('Audience Created', {
    user: `${user.first_name} ${user.last_name}`,
    team: user.team_title,
    email: user.email,
    audience_name: res.name,
    audience_id: res.id,
    audience_size: res.population,
    audience_created: res.created_at,
  });
};

export const trackFlowCreated = (user, res) => {
  setUserProfile(user);
  mixpanel.track('Flow Created', {
    user: `${user.first_name} ${user.last_name}`,
    team: user.team_title,
    email: user.email,
    flow_name: res.name,
    flow_id: res.id,
    flow_start_date: res.start_at,
    flow_end_date: res.end_at,
  });
};

export const trackFormCreated = (user, res) => {
  setUserProfile(user);
  mixpanel.track('Form Created', {
    user: `${user.first_name} ${user.last_name}`,
    team: user.team_title,
    email: user.email,
    form_name: res.name,
    form_id: res.id,
    form_url: res.created_at,
  });
};
