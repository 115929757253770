import React from 'react';
import { convertNumber, dividerCommaFormat } from '../../../../tools/NumberConverterTool';
import { ReactComponent as InfoSmall } from '../../../../assets/icons/info_small.svg';
import { ControlledList } from '../../modals/SearchModal/LoadingList';
import Loader from '../../../base/Loader';
import Tooltip from '../../Tooltip';
import styles from './Widget.module.scss';

const Widget = ({ item, isLoading }) => {
  if (isLoading) {
    return (
      <div className={`${styles.widget_wrapper}
       ${styles.custom_widget} d-flex align-items-center position-relative`}
      >
        <ControlledList />
      </div>
    );
  }
  return (
    <div className={`${styles.widget_wrapper} d-flex position-relative`}>
      <div className={`${styles.widget_logo} d-flex p-1`}>
        <img src={item.icon} alt="logo" width="32px" height="32px" />
      </div>
      {item.info
        ? (
          <>
            <div
              className={`position-absolute cursor-pointer ${styles.info_btn}`}
              data-for={`tooltipId${item.id}`}
              data-tip
            >
              <InfoSmall />
            </div>
            <Tooltip info={item.info} id={`tooltipId${item.id}`} position="top" />
          </>
        )
        : null}
      <div className="d-flex flex-column">
        <span className={styles.card_info_widget}>{item.name}</span>
        {item.id === 1 && (
          <span className={styles.card_info_bold_widget}>
            {item.number || item.number === 0
              ? `$${convertNumber(+item.number.toFixed(0))}`
              : '?'}
          </span>
        )}
        {item.id === 2 && (
          <span className={styles.card_info_bold_widget}>
            {item.number || item.number === 0
              ? `${convertNumber(+item.number.toFixed(0))}`
              : '?'}
          </span>
        )}
        {item.id === 3 && (
          !item.isLoading
            ? (
              <span className={styles.card_info_bold_widget}>
                {item.number || item.number === 0 ? convertNumber((+item.number).toFixed(0)) : '?'}
              </span>
            )
            : (
              <div className="d-flex mx-3 mt-1">
                <Loader />
              </div>
            )
        )}
        {item.id === 4 && (
          <span className={styles.card_info_bold_widget}>
            {item.number || item.number === 0
              ? `$${item.number > 1000
                ? convertNumber(+item.number.toFixed(0))
                : convertNumber(item.number)}`
              : '?'}
          </span>
        )}
        {item.id === 5 && (
          <>
            <span className={styles.card_info_bold_widget}>
              {(item.number.items || item.number.items === 0) && item.number.items !== '?'
                ? `${item.number.items > 1000
                  ? convertNumber(+item.number.items.toFixed(0))
                  : convertNumber(item.number.items)}`
                : '?'}
              {' items,'}
            </span>
            <span className={styles.card_info_bold_widget}>
              {(item.number.itemsPerHolder || item.number.itemsPerHolder === 0) && item.number.itemsPerHolder !== '?'
                ? `${item.number.itemsPerHolder > 1000
                  ? convertNumber(+item.number.itemsPerHolder.toFixed(0))
                  : dividerCommaFormat(+item.number.itemsPerHolder.toFixed(2))}`
                : '?'}
              {' per holder'}
            </span>
          </>
        )}
        { item.id > 5 && (
          <span className={styles.card_info_bold_widget}>{item.number}</span>
        )}
      </div>
    </div>
  );
};

export default Widget;
