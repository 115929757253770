import React from 'react';
import Title from '../../base/Title';
import Widget from './Widget';
import Price from './Price';
import Info from './Info';
import Asset from './Asset';
import { ethFormat } from '../../../utils/singleAssetPage/parseData';
import uniqueId from '../../../utils/uniqueId';
import { LoadingLines } from '../modals/SearchModal/LoadingList';
import Tooltip from '../Tooltip';
import { ReactComponent as WarningTriangle } from '../../../assets/icons/warning_triangle.svg';
import Reach from '../Reach';
import styles from './Market.module.scss';

const Market = ({
  isAssetLoading, id, marketData, widgetData, assetData, infoData, setDays, days, status,
  type, chartData, exchange, priceData, contractCount, isLoading, isHoldersLoading,
  reach, isReachLoading, holders, historyIsFetching, symbol,
}) => (
  <div className="asset-container">
    <div className="title-gap d-flex align-items-center gap-2">
      <Title>Overview</Title>
      <span
        data-for="Warning"
        data-tip
      >
        {contractCount > 1 && <WarningTriangle className="cursor-pointer" /> }
      </span>
      <Tooltip
        id="Warning"
        info="Stats related to price and trading volume are from the parent collection"
      />
    </div>
    <div className="dropdown-divider w-100" />
    <div className="info-wrapper-top d-flex justify-content-between gap-4">
      {widgetData.map((item) => (
        <Widget item={item} isLoading={isAssetLoading || isHoldersLoading} key={uniqueId('asset-widget')} />
      ))}
    </div>
    <div className="info-price-wrapper d-flex justify-content-between ">
      <Price
        id={id}
        type={type}
        setDays={setDays}
        days={days}
        chartData={chartData}
        isLoading={isAssetLoading || isLoading}
        isFetching={historyIsFetching}
        exchange={exchange}
        priceData={priceData}
        status={status}
        symbol={symbol}
      />
      {assetData && (
        <div className="d-flex flex-column info-wrapper-asset">
          <Asset assetData={assetData} />
        </div>
      )}
      {infoData && !isAssetLoading ? (
        <div className="d-flex flex-column info-wrapper-charts">
          {infoData.map((data, index) => (
            <div key={data.id}>
              {index ? <div className="divider" /> : null}
              <Info data={data} />
            </div>
          ))}
          <div className="stat-wrapper mt-4 d-grid">
            {type === 'nft' ? (
              <>
                <div className="d-flex flex-column">
                  <h6>Highest average</h6>
                  <span>
                    {marketData.highest}
                  </span>
                </div>
                <div className="d-flex flex-column">
                  <h6>7d average</h6>
                  <span>
                    {marketData.seven}
                  </span>
                </div>
                <div className="d-flex flex-column">
                  <h6>7d floor</h6>
                  <span>
                    {marketData.floor}
                  </span>
                </div>
                <div className="d-flex flex-column">
                  <h6>7d ceiling</h6>
                  <span>
                    {marketData.ceiling}
                  </span>
                </div>
              </>
            ) : (
              <>
                <div className="d-flex flex-column">
                  <h6>ATH</h6>
                  {marketData.ath
                    ? (
                      <>
                        <span>
                          $
                          {ethFormat(marketData.ath)}
                        </span>
                        <span className="dop-info">
                          (
                          {Math.round(ethFormat(
                            (marketData.current_price / marketData.ath) * 100,
                          ))}
                          % to ATH)
                        </span>
                      </>
                    ) : '-'}
                </div>
                <div className="d-flex flex-column">
                  <h6>12m Average</h6>
                  {(marketData.ath || marketData.ath === 0) && (marketData.atl || marketData.atl === 0) ? (
                    <span>
                      $
                      {ethFormat(
                        (marketData.ath + marketData.atl) / 2,
                      )}
                    </span>
                  ) : '-'}
                </div>
                <div className="d-flex flex-column">
                  <h6>12m Low</h6>
                  {marketData.atl || marketData.atl === 0 ? (
                    <span>
                      $
                      {ethFormat(marketData.atl)}
                    </span>
                  ) : '-'}
                </div>
                <div className="d-flex flex-column">
                  <h6>12m High</h6>
                  {marketData.ath || marketData.ath === 0 ? (
                    <span>
                      $
                      {ethFormat(marketData.ath)}
                    </span>
                  ) : '-'}
                </div>
              </>
            )}
          </div>
        </div>
      ) : (
        <div className="h-100 w-25 p-3">
          <LoadingLines align="start" rows={4} />
        </div>
      )}
    </div>
    <div className={styles.reach_wrap}>
      <Reach data={reach} isLoading={isReachLoading} totalCount={holders} />
    </div>
  </div>
);

export default Market;
