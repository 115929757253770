import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ChromePicker } from 'react-color';
import {
  setFormStyle,
  setFormImageFile,
  setBackgroundFile,
  selectFormStyle,
} from '../../../../../../store/reducers/forms';
import styles from './StyleSettings.module.scss';
import { ReactComponent as Upload } from '../../../../../../assets/icons/forms/upload_image.svg';
import { ReactComponent as BlueCheck } from '../../../../../../assets/icons/forms/checkedblue.svg';
import { ReactComponent as Trash } from '../../../../../../assets/icons/forms/trash.svg';
import { formsApi } from '../../../../../../api/forms';
import RoundSpinner from '../../../../../../components/base/RoundSpinner';

const StyleSettings = ({ activeSettings }) => {
  const dispatch = useDispatch();
  const formStyle = useSelector(selectFormStyle);
  const [selectedColor, setSelectedColor] = React.useState(null);
  const [formColor, setFormColor] = React.useState('#FFFFFF');
  const [textColor, setTextColor] = React.useState('#000000');
  const [buttonColor, setButtonColor] = React.useState('#109CF1');
  const [buttonTextColor, setButtonTextColor] = React.useState('#FFFFFF');
  const [backgroundColor, setBackgroundColor] = React.useState('#FFFFFF');
  const [backgroundUrl, setBackgroundUrl] = useState('');
  const [fileBackgroundName, setBackgroundFileName] = useState('');
  const [formUrl, setFormUrl] = useState('');
  const [fileFormName, setFormFileName] = useState('');
  const reduxState = useSelector((state) => state.formsReducer);
  const [uploadBackgroundImage, uploadBackgroundResult] = formsApi.useUploadImageMutation();
  const [uploadFormImage, uploadFormResult] = formsApi.useUploadImageMutation();
  const [showFormColorPicker, setShowFormColorPicker] = useState(false);
  const [showTextColorPicker, setShowTextColorPicker] = useState(false);
  const [showButtonColorPicker, setShowButtonColorPicker] = useState(false);
  const [showButtonTextcolorPicker, setShowButtonTextcolorPicker] = useState(false);
  const [showBackgroundColorPicker, setShowBackgroundColorPicker] = useState(false);

  const colorConfigurations = [
    { color: '#ECEFF3', buttonColour: '#109CF1', backgroundColour: '#ECEFF3' },
    { color: '#CED5DF', buttonColour: '#7185A3', backgroundColour: '#CED5DF' },
    { color: '#FFDD59', buttonColour: '#FFA801', backgroundColour: '#FFDD59' },
    { color: '#FF7670', buttonColour: '#FF3F34', backgroundColour: '#FF7670' },
    { color: '#0BE881', buttonColour: '#05C46B', backgroundColour: '#0BE881' },
    { color: '#34E7E4', buttonColour: '#05BDBB', backgroundColour: '#34E7E4' },
    { color: '#303B4A', buttonColour: '#445369', backgroundColour: '#303B4A' },
  ];

  const handleColorClick = (config) => {
    setSelectedColor(config.color);
    setButtonColor(config.buttonColour);
    setBackgroundColor(config.backgroundColour);

    dispatch(setFormStyle({
      formStyle: {
        colourTheme: config.color,
        formColour: '',
        formImageUrl: '',
        textColour: '',
      },
      buttonStyle: {
        buttonColour: config.buttonColour,
        textColour: '',
      },
      backgroundStyle: {
        backgroundColour: config.backgroundColour,
        backgroundImageUrl: '',
      },
    }));
  };

  const isValidHex = (hex) => /^#([0-9A-Fa-f]{3}){1,2}$/.test(hex) || /^#[0-9A-Fa-f]{0,5}$/.test(hex);

  const handleColorChange = (color, type) => {
    if (isValidHex(color)) {
      switch (type) {
        case 'form':
          setFormColor(color);
          dispatch(setFormStyle({
            formStyle: {
              ...reduxState.style.formStyle,
              formColour: color,
            },
            buttonStyle: {
              ...reduxState.style.buttonStyle,
            },
            backgroundStyle: {
              ...reduxState.style.backgroundStyle,
            },
          }));
          break;

        case 'text':
          setTextColor(color);
          dispatch(setFormStyle({
            formStyle: {
              ...reduxState.style.formStyle,
              textColour: color,
            },
            buttonStyle: {
              ...reduxState.style.buttonStyle,
            },
            backgroundStyle: {
              ...reduxState.style.backgroundStyle,
            },
          }));
          break;

        case 'button':
          setButtonColor(color);
          dispatch(setFormStyle({
            formStyle: {
              ...reduxState.style.formStyle,
            },
            buttonStyle: {
              ...reduxState.style.buttonStyle,
              buttonColour: color,
            },
            backgroundStyle: {
              ...reduxState.style.backgroundStyle,
            },
          }));
          break;

        case 'buttonText':
          setButtonTextColor(color);
          dispatch(setFormStyle({
            formStyle: {
              ...reduxState.style.formStyle,
            },
            buttonStyle: {
              ...reduxState.style.buttonStyle,
              textColour: color,
            },
            backgroundStyle: {
              ...reduxState.style.backgroundStyle,
            },
          }));
          break;

        case 'background':
          setBackgroundColor(color);
          dispatch(setFormStyle({
            formStyle: {
              ...reduxState.style.formStyle,
            },
            buttonStyle: {
              ...reduxState.style.buttonStyle,
            },
            backgroundStyle: {
              ...reduxState.style.backgroundStyle,
              backgroundColour: color,
            },
          }));
          break;

        default:
          break;
      }
    }
  };

  const handleBackgroundFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file && (file.type === 'image/jpeg' || file.type === 'image/png')) {
      const localImageUrl = URL.createObjectURL(file);
      setBackgroundUrl(localImageUrl);
      setBackgroundFileName(file.name);
      dispatch(setBackgroundFile({ fieldId: 'background_setting_image', file }));
      dispatch(setFormStyle({
        formStyle: {
          ...reduxState.style.formStyle,
        },
        buttonStyle: {
          ...reduxState.style.buttonStyle,
        },
        backgroundStyle: {
          ...reduxState.style.backgroundStyle,
          backgroundImageUrl: localImageUrl,
        },
      }));
      // Prepare the formData to send to the server
      const formData = new FormData();
      formData.append('form_image', file);
      try {
        // Upload the image using the mutation hook
        await uploadBackgroundImage(formData);
      } catch (error) {
        console.error('Failed to upload the image:', error);
      }
    }
  };

  useEffect(() => {
    if (uploadBackgroundResult.isSuccess && uploadBackgroundResult.data?.form_image) {
      setBackgroundUrl(uploadBackgroundResult.data.form_image);
      dispatch(setFormStyle({
        formStyle: {
          ...reduxState.style.formStyle,
        },
        buttonStyle: {
          ...reduxState.style.buttonStyle,
        },
        backgroundStyle: {
          ...reduxState.style.backgroundStyle,
          backgroundImageUrl: uploadBackgroundResult.data.form_image,
        },
      }));
    } else if (uploadBackgroundResult.isError) {
      console.error('Error occurred during image upload:', uploadBackgroundResult.error);
    }
  }, [uploadBackgroundResult]);

  const handleFormFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file && (file.type === 'image/jpeg' || file.type === 'image/png')) {
      const localImageUrl = URL.createObjectURL(file);
      setFormUrl(localImageUrl);
      setFormFileName(file.name);
      dispatch(setFormImageFile({ fieldId: 'form_setting_image', file }));
      dispatch(setFormStyle({
        formStyle: {
          ...reduxState.style.formStyle,
          formImageUrl: localImageUrl,
        },
        buttonStyle: {
          ...reduxState.style.buttonStyle,
        },
        backgroundStyle: {
          ...reduxState.style.backgroundStyle,
        },
      }));
      // Prepare the formData to send to the server
      const formData = new FormData();
      formData.append('form_image', file);
      try {
        // Upload the image using the mutation hook
        await uploadFormImage(formData);
      } catch (error) {
        console.error('Failed to upload the image:', error);
      }
    }
  };

  useEffect(() => {
    if (uploadFormResult.isSuccess && uploadFormResult.data?.form_image) {
      setFormUrl(uploadFormResult.data.form_image);
      dispatch(setFormStyle({
        formStyle: {
          ...reduxState.style.formStyle,
          formImageUrl: uploadFormResult.data.form_image,
        },
        buttonStyle: {
          ...reduxState.style.buttonStyle,
        },
        backgroundStyle: {
          ...reduxState.style.backgroundStyle,
        },
      }));
    } else if (uploadFormResult.isError) {
      console.error('Error occurred during image upload:', uploadFormResult.error);
    }
  }, [uploadFormResult]);

  const removeBackgroundImage = () => {
    setBackgroundUrl('');
    setBackgroundFileName('');
    dispatch(setFormStyle({
      formStyle: {
        ...reduxState.style.formStyle,
      },
      buttonStyle: {
        ...reduxState.style.buttonStyle,
      },
      backgroundStyle: {
        ...reduxState.style.backgroundStyle,
        backgroundImageUrl: '',
      },
    }));
  };

  const removeFormImage = () => {
    setFormUrl('');
    setFormFileName('');
    dispatch(setFormStyle({
      formStyle: {
        ...reduxState.style.formStyle,
        formImageUrl: '',
      },
      buttonStyle: {
        ...reduxState.style.buttonStyle,
      },
      backgroundStyle: {
        ...reduxState.style.backgroundStyle,
      },
    }));
  };

  const shortenFileName = (name) => {
    if (name.length <= 4) return name;
    return `${name.substr(0, 3)}...${name.slice(-5, -4)}`;
  };

  useEffect(() => {
    if (activeSettings && formStyle) {
      setSelectedColor(formStyle.formStyle.colourTheme);
      handleColorChange(formStyle.formStyle.formColour, 'form');
      handleColorChange(formStyle.formStyle.textColour, 'text');
      handleColorChange(formStyle.buttonStyle.buttonColour, 'button');
      handleColorChange(formStyle.buttonStyle.buttonTextColour, 'buttonText');
      handleColorChange(formStyle.backgroundStyle.backgroundColour, 'background');
      setBackgroundUrl(formStyle.backgroundStyle.backgroundImageUrl);
      setFormUrl(formStyle.formStyle.formImageUrl);
    }
  }, [activeSettings]);

  return (
    <div>
      <div className={`${styles.title} d-flex align-items-center mb-1`}>
        Style
      </div>
      <h6>Color Scheme</h6>
      <div className="d-flex align-items-center gap-2">
        <div style={{ display: 'flex', gap: '5px' }}>
          {colorConfigurations.map((config) => (
            <div
              key={config.color}
              role="button"
              tabIndex={0}
              onClick={() => handleColorClick(config)}
              onKeyDown={(event) => {
                if (event.key === 'Enter' || event.key === ' ') {
                  handleColorClick(config);
                }
              }}
              className={`${styles.colorBox} 
          ${selectedColor === config.color ? styles.selected : ''}`}
              style={{ backgroundColor: config.color }}
              aria-label={`Select color ${config.color}`}
            >
              {selectedColor === config.color && <BlueCheck className={styles.blueCheckedIcon} />}
            </div>
          ))}
        </div>
      </div>
      <div className={`${styles.title} d-flex align-items-center gap-2 mb-2 pt-3`}>
        Form style
      </div>
      <div className="row">
        {/* Form Color Setting */}
        <div className="col-6 mb-2">
          <h6>Form Color</h6>
          <div className="d-flex align-items-center border rounded p-2">
            <input
              type="text"
              value={formColor}
              onChange={(e) => handleColorChange(e.target.value, 'form')}
              className={`form-control border-0 ${styles.colorInput}`}
              aria-label="Form Color"
              onFocus={() => setShowFormColorPicker(true)}
            />
            <div
              role="presentation"
              className={`ms-2 border border-light ${styles.colorPreview}`}
              style={{ backgroundColor: formColor }}
              onClick={() => setShowFormColorPicker(!showFormColorPicker)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  setShowFormColorPicker(!showFormColorPicker);
                }
              }}
            />
          </div>
          {showFormColorPicker && (
            <div style={{ position: 'absolute', zIndex: 2 }}>
              <div
                role="presentation"
                onClick={() => setShowFormColorPicker(false)}
                style={{
                  position: 'fixed', top: '0px', right: '0px', bottom: '0px', left: '0px',
                }}
              />
              <ChromePicker
                color={formColor}
                onChangeComplete={(color) => handleColorChange(color.hex, 'form')}
              />
            </div>
          )}
        </div>
        {/* Form Image Setting */}
        <div className="col-6 mb-2">
          <h6>Form Image</h6>
          <div className="d-flex align-items-center justify-content-center border rounded p-2">
            <input
              type="file"
              accept="image/*"
              onChange={handleFormFileUpload}
              className="d-none"
              id="formImageUpload"
            />
            {uploadFormResult.isLoading ? (
              <RoundSpinner />
            ) : !formUrl ? (
              <label htmlFor="formImageUpload" className={styles.fullWidthButton}>
                <span className="mr-3">Upload</span>
                <div className="m-2" />
                <Upload className="ml-auto" />
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleFormFileUpload}
                  className="d-none"
                  id="formImageUpload"
                />
              </label>
            ) : (
              <div className="d-flex align-items-center gap-2">
                <img src={formUrl} alt="Preview" className={`${styles.imagePreview}`} />
                <span className="mx-2">{shortenFileName(fileFormName)}</span>
                <Trash onClick={removeFormImage} className="trash-icon ml-2" />
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor="formImageUpload" className="ml-2">
                  <Upload />
                </label>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="row">
        {/* Text Color Setting */}
        <div className="col-6 mb-2">
          <h6>Text Color</h6>
          <div className="d-flex align-items-center border rounded p-2">
            <input
              type="text"
              value={textColor}
              onChange={(e) => handleColorChange(e.target.value, 'text')}
              className={`form-control border-0 ${styles.colorInput}`}
              aria-label="Text Color"
            />
            <div
              role="presentation"
              className={`ms-2 border border-light ${styles.colorPreview}`}
              style={{ backgroundColor: textColor }}
              onClick={() => setShowTextColorPicker(!showTextColorPicker)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  setShowTextColorPicker(!showTextColorPicker);
                }
              }}
            />
          </div>
          {showTextColorPicker && (
            <div style={{ position: 'absolute', zIndex: 2 }}>
              <div
                role="presentation"
                onClick={() => setShowTextColorPicker(false)}
                style={{
                  position: 'fixed', top: '0px', right: '0px', bottom: '0px', left: '0px',
                }}
              />
              <ChromePicker
                color={textColor}
                onChangeComplete={(color) => handleColorChange(color.hex, 'text')}
              />
            </div>
          )}
        </div>
      </div>

      <div className={`${styles.title} d-flex align-items-center mb-2 pt-3`}>
        Button style
      </div>
      <div className="row">
        <div className="col-6 mb-2">
          <h6>Button Color</h6>
          <div className="d-flex align-items-center border rounded p-2">
            <input
              type="text"
              value={buttonColor}
              onChange={(e) => handleColorChange(e.target.value, 'button')}
              className={`form-control border-0 ${styles.colorInput}`}
              aria-label="Button Color"
            />
            <div
              role="presentation"
              className={`ms-2 border border-light ${styles.colorPreview}`}
              style={{ backgroundColor: buttonColor }}
              onClick={() => setShowButtonColorPicker(!showButtonColorPicker)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  setShowButtonColorPicker(!showButtonColorPicker);
                }
              }}
            />
          </div>
          {showButtonColorPicker && (
            <div style={{ position: 'absolute', zIndex: 2 }}>
              <div
                role="presentation"
                onClick={() => setShowButtonColorPicker(false)}
                style={{
                  position: 'fixed', top: '0px', right: '0px', bottom: '0px', left: '0px',
                }}
              />
              <ChromePicker
                color={buttonColor}
                onChangeComplete={(color) => handleColorChange(color.hex, 'button')}
              />
            </div>
          )}
        </div>
        <div className="col-6 mb-2">
          <h6>Button Text Color</h6>
          <div className="d-flex align-items-center border rounded p-2">
            <input
              type="text"
              value={buttonTextColor}
              onChange={(e) => handleColorChange(e.target.value, 'buttonText')}
              className={`form-control border-0 ${styles.colorInput}`}
              aria-label="Button Text Color"
            />
            <div
              role="presentation"
              className={`ms-2 border border-light ${styles.colorPreview}`}
              style={{ backgroundColor: buttonTextColor }}
              onClick={() => setShowButtonTextcolorPicker(!showButtonTextcolorPicker)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  setShowButtonTextcolorPicker(!showButtonTextcolorPicker);
                }
              }}
            />
          </div>
          {showButtonTextcolorPicker && (
            <div style={{ position: 'absolute', zIndex: 2 }}>
              <div
                role="presentation"
                onClick={() => setShowButtonTextcolorPicker(false)}
                style={{
                  position: 'fixed', top: '0px', right: '0px', bottom: '0px', left: '0px',
                }}
              />
              <ChromePicker
                color={buttonTextColor}
                onChangeComplete={(color) => handleColorChange(color.hex, 'buttonText')}
              />
            </div>
          )}
        </div>

      </div>

      <div className={`${styles.title} d-flex align-items-center mb-2 pt-3`}>
        Background style
      </div>
      <div className="row">
        <div className="col-6 mb-2">
          <h6>Background Color</h6>
          <div className="d-flex align-items-center border rounded p-2">
            <input
              type="text"
              value={backgroundColor}
              onChange={(e) => handleColorChange(e.target.value, 'background')}
              className={`form-control border-0 ${styles.colorInput}`}
              aria-label="Background Color"
            />
            <div
              role="presentation"
              className={`ms-2 border border-light ${styles.colorPreview}`}
              style={{ backgroundColor }}
              onClick={() => setShowBackgroundColorPicker(!showBackgroundColorPicker)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  setShowBackgroundColorPicker(!showBackgroundColorPicker);
                }
              }}
            />
          </div>
          {showBackgroundColorPicker && (
            <div style={{ position: 'absolute', zIndex: 2 }}>
              <div
                role="presentation"
                onClick={() => setShowBackgroundColorPicker(false)}
                style={{
                  position: 'fixed', top: '0px', right: '0px', bottom: '0px', left: '0px',
                }}
              />
              <ChromePicker
                color={backgroundColor}
                onChangeComplete={(color) => handleColorChange(color.hex, 'background')}
              />
            </div>
          )}
        </div>

        <div className="col-6 mb-2">
          <h6>Background Image</h6>
          <div className="d-flex align-items-center justify-content-center border rounded p-2">
            <input
              type="file"
              accept="image/*"
              onChange={handleBackgroundFileUpload}
              className="d-none"
              id="backgroundImageUpload"
            />
            {uploadBackgroundResult.isLoading ? (
              <RoundSpinner />
            ) : !backgroundUrl ? (
              <label htmlFor="backgroundImageUpload" className={styles.fullWidthButton}>
                <span className="mr-3">Upload</span>
                <div className="m-2" />
                <Upload className="ml-auto" />
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleBackgroundFileUpload}
                  className="d-none"
                  id="backgroundImageUpload"
                />
              </label>
            ) : (
              <div className="d-flex align-items-center gap-2">
                <img src={backgroundUrl} alt="Preview" className={`${styles.imagePreview}`} />
                <span className="mx-2">{shortenFileName(fileBackgroundName)}</span>
                <Trash onClick={removeBackgroundImage} className="trash-icon ml-2" />
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor="backgroundImageUpload" className="ml-2">
                  <Upload />
                </label>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StyleSettings;
