/* eslint-disable camelcase */
import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { ethers } from 'ethers';
import { NumericFormat } from 'react-number-format';
import {
  clearActiveSettings,
  onSettingsChange,
  removeErrors,
  selectConfigsList,
  selectFlowStatus,
} from '../../../../../store/reducers/flows';
import { coinApi } from '../../../../../api/coin';
import { isValidContractAddress } from '../../../../../tools/EtherTool';
import { debounce } from '../../../../../utils/debounce';
import McapLabel from '../../Components/McapLabel';
import { IconNearby } from '../../../../../components/base/SelectLabels';
import { preventSearch } from '../../../../../utils/search/preventSearch';
import EditableTitle from '../../../../../components/base/EditableTitle';
import { onPasteFloat } from '../../../../../utils/segments';
import styles from './TransactionNodeSettings.module.scss';
import { showErrorMessage } from '../../../../../components/base/Notifications';

const inputStyles = {
  valueContainer: (style) => ({
    ...style,
    padding: '0 10px',
    minHeight: '46px',
  }),
  singleValue: (style) => ({
    ...style,
    padding: 0,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    opacity: 1,
  }),
  placeholder: (style) => ({
    ...style,
    paddingLeft: 1,
    fontSize: '14px',
  }),
  option: (style) => ({
    ...style,
    padding: '12px',
    boxShadow: '1px',
    border: '1px solid #F1F4F8',
    fontSize: '14px',
  }),
  menuList: (style) => ({
    ...style,
    paddingTop: 0,
    paddingBottom: 0,
  }),
};

const blockChainOptions = [
  { label: 'Ethereum', value: 'ethereum', network: 'ethereum' },
  { label: 'Polygon', value: 'polygon', network: 'polygon' },
];

const typeOptions = [
  { label: 'ERC-721', value: 'erc721' },
  { label: 'ERC-1155', value: 'erc1155' },
];

const eventOptions = [
  { label: 'Send', value: 'Send' },
  { label: 'Receive', value: 'Receive' },
  { label: 'Mint', value: 'Mint' },
  { label: 'Other', value: 'Other', isDisabled: true },
];

const TransactionNodeSettings = ({ activeSettings }) => {
  const dispatch = useDispatch();

  const [titleValue, setTitleValue] = useState('');
  const [assetType, setAssetType] = useState('');
  const [event, setEvent] = useState('');
  const [selectedItem, setSelectedItem] = useState();
  const [selectedBlockchain, setSelectedBlockchain] = useState();
  const [selectedType, setSelectedType] = useState();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      include_mints: true,
    },
  });

  const configList = useSelector(selectConfigsList);
  const status = useSelector(selectFlowStatus);

  const disableChangeStatus = useMemo(() => status === 'running'
    || status === 'scheduled' || status === 'stopped', [status]);

  const onSubmit = (submitData) => {
    const { include_mints, ...submitDataToSet } = submitData;
    const newObj = { ...submitDataToSet, contract_address: selectedItem.contract_address || selectedItem.value };
    if (titleValue) {
      newObj.name = titleValue;
    } else {
      newObj.name = 'New transaction source';
    }
    if (selectedItem.market_cap || selectedItem.usd_market_cap) {
      newObj.market_cap = selectedItem.market_cap || selectedItem.usd_market_cap;
    }
    if (selectedItem.image || selectedItem.image_url) {
      newObj.assetImg = selectedItem.image || selectedItem.image_url;
    }
    if (selectedBlockchain) {
      newObj.blockchain = selectedBlockchain.value || selectedBlockchain;
    }
    if (selectedType) {
      newObj.contract_type = selectedType.value || selectedType;
    } else {
      delete newObj.contract_type;
    }
    if (selectedItem?.label) {
      newObj.asset = selectedItem.label;
    }
    if (submitData.event === 'Receive') {
      newObj.include_mints = include_mints;
    }
    if (assetType) {
      newObj.asset_type = assetType;
    }
    if (!watch('min_amount')) {
      delete newObj.min_amount;
    }
    if (!watch('max_amount')) {
      delete newObj.max_amount;
    }
    if (watch('min_amount') && watch('max_amount') && Number(watch('max_amount')) < Number(watch('min_amount'))) {
      showErrorMessage('Maximum amount cannot be lower than the minimum.');
      return;
    }
    dispatch(removeErrors(activeSettings.node_id));
    dispatch(onSettingsChange({ ...newObj, node_id: activeSettings.node_id }));
    dispatch(clearActiveSettings());
  };

  const {
    data: top5tokens,
    isLoading: isTop5TokensLoading,
    isFetching: isTop5TokensFetching,
  } = coinApi.useSearchERC20TokenQuery({ query: '', limit: 5 }, {
    skip: disableChangeStatus,
  });

  const {
    data: top5nfts,
    isLoading: isTop5NFTsLoading,
    isFetching: isTop5NFTsFetching,
  } = coinApi.useSearchNftQuery('', {
    skip: disableChangeStatus,
  });

  const getTokensData = useCallback(async (val) => {
    if (isValidContractAddress(val)) {
      return ([{
        value: val,
        label: val,
        description: 'Using token at address',
      }]);
    }
    const data = await dispatch(
      coinApi.endpoints.searchERC20Token.initiate({ query: val }),
    );

    if (data.status === 'fulfilled') {
      return data.data.map((item) => ({
        ...item,
        value: item.contract_address,
        label: item.name,
      }));
    }
    return null;
  }, [dispatch]);

  const getNFTsData = useCallback(async (val) => {
    if (isValidContractAddress(val)) {
      return ([{
        value: val,
        label: val,
        description: 'Using token at address',
      }]);
    }

    const data = await dispatch(
      coinApi.endpoints.searchNft.initiate(val),
    );

    if (data.status === 'fulfilled') {
      return data.data.map((item) => ({
        ...item,
        value: item.opensea_slug_contract_count > 1
          ? item.contract_name || item.contract_address
          : item.contract_address,
        label: item.opensea_slug_contract_count > 1 ? item.contract_name || item.name : item.name,
      }));
    }
    return null;
  }, [dispatch]);

  const getCoinLabel = useCallback((val) => <IconNearby val={val} />, []);
  const loadTokensData = useCallback(debounce((val) => getTokensData(val), 1000), [getTokensData]);
  const loadNFTsData = useCallback(debounce((val) => getNFTsData(val), 1000), [getNFTsData]);

  const getMcapLabel = (val) => <McapLabel val={val} type={val.blockchain ? assetType.toLowerCase() : ''} />;

  useEffect(() => {
    if (configList.length) {
      const index = configList.map((object) => object.node_id).indexOf(activeSettings.node_id);
      if (index >= 0) {
        const tempObj = {};
        if (configList[index].asset) {
          tempObj.name = configList[index].asset;
        }
        setSelectedItem({
          ...tempObj,
          value: configList[index].asset || configList[index].contract_address,
          label: configList[index].asset || configList[index].contract_address,
          image: configList[index].assetImg,
          contract_address: configList[index].contract_address || null,
          blockchain: configList[index].blockchain,
          contract_type: configList[index].contract_type,
          market_cap: configList[index].market_cap,
          include_mints: configList[index].include_mints,
        });
        setSelectedBlockchain(
          blockChainOptions.filter((blockchain) => blockchain.value === configList[index].blockchain)[0],
        );
        if (configList[index].contract_type) {
          setSelectedType(
            typeOptions.filter((type) => type.value === configList[index].contract_type)[0],
          );
        }
        if (configList[index].event) {
          setEvent({ label: configList[index].event, value: configList[index].event });
        }
        setAssetType(configList[index].asset_type);
        setTitleValue(configList[index].name);
        setValue('event', configList[index].event);
        setValue('include_mints', configList[index].include_mints);
        setValue('min_amount', configList[index].min_amount);
        setValue('max_amount', configList[index].max_amount);
      }
    }
  }, [activeSettings.node_id, configList, setValue]);

  return (
    <div className={`${styles.wrapper} h-100`}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="d-flex flex-column justify-content-between h-100"
      >
        <div>
          <div className={`${styles.title} d-flex align-items-center gap-2`}>
            <EditableTitle
              defaultTitle="New transaction source"
              titleValue={titleValue}
              setTitleValue={setTitleValue}
              maxLength={25}
              size={16}
              edit={!disableChangeStatus}
            />
          </div>
          <div className={`${styles.select} mb-3`}>
            <div className={`${styles.select_title} mb-1`}>Choose the event to track</div>
            <Select
              {...register('event')}
              placeholder="Select"
              styles={inputStyles}
              value={event}
              onKeyDown={(e) => { preventSearch(e); }}
              onChange={(val) => {
                setValue('event', val.value);
                setEvent(val);
              }}
              name="types"
              isSearchable={false}
              options={eventOptions}
              isDisabled={disableChangeStatus}
            />
            {watch('event') === 'Receive' && (
              <div className={`${styles.checkbox} mt-2 d-flex gap-2 align-items-center`}>
                <input
                  onKeyPress={(e) => { preventSearch(e); }}
                  type="checkbox"
                  {...register('include_mints')}
                  disabled={disableChangeStatus}
                />
                Include mints
              </div>
            )}
          </div>
          <div className="d-flex flex-column gap-3 mb-3">
            <div className={styles.type_title}>
              Choose the asset type
            </div>
            <div className="d-flex gap-3">
              <button
                type="button"
                className={assetType === 'Token' ? 'check-button-checked' : 'check-button'}
                onClick={() => {
                  if (assetType !== 'Token') {
                    setAssetType('Token');
                    setSelectedItem('');
                  }
                }}
                disabled={disableChangeStatus}
              >
                Tokens
              </button>
              <div>
                <button
                  type="button"
                  className={assetType === 'NFT' ? 'check-button-checked' : 'check-button'}
                  onClick={() => {
                    if (assetType !== 'NFT') {
                      setAssetType('NFT');
                      setSelectedItem('');
                    }
                  }}
                  disabled={disableChangeStatus}
                >
                  NFTs
                </button>
              </div>
            </div>
          </div>
          {assetType
            ? (
              <AsyncSelect
                className="w-100 mb-3"
                styles={inputStyles}
                maxMenuHeight={300}
                loadOptions={assetType === 'Token' ? loadTokensData : loadNFTsData}
                selectProps={selectedItem}
                getOptionLabel={(val) => getMcapLabel(val)}
                onKeyDown={(e) => { preventSearch(e); }}
                onChange={(e) => {
                  setSelectedItem(e);
                  setValue('min_amount', '');
                  setValue('max_amount', '');
                  if (e.contract_type) {
                    setSelectedType(e.contract_type);
                  } else {
                    setSelectedType(null);
                  }
                  if (e.blockchain) {
                    setSelectedBlockchain(e.blockchain);
                  } else {
                    setSelectedBlockchain(null);
                  }
                }}
                isLoading={isTop5TokensLoading || isTop5TokensFetching || isTop5NFTsLoading || isTop5NFTsFetching}
                placeholder={`${assetType} name or contract address`}
                value={selectedItem}
                defaultOptions={assetType === 'Token'
                  ? top5tokens?.map((elem) => ({ ...elem, value: elem.contract_address, label: elem.name })) || []
                  : top5nfts?.slice(0, 5).map(
                    (elem) => ({ ...elem, value: elem.contract_address, label: elem.name }),
                  ) || []}
                isDisabled={disableChangeStatus}
              />
            )
            : null}
          {selectedItem && ethers.utils.isAddress(selectedItem.value)
            && (typeof selectedItem.market_cap !== 'number' && typeof selectedItem.usd_market_cap !== 'number') && (
            <div>
              <div className={`${styles.label}`}>Select blockchain</div>
              <Select
                placeholder="Select a blockchain..."
                className="w-100"
                styles={inputStyles}
                value={selectedBlockchain}
                onKeyDown={(e) => { preventSearch(e); }}
                onChange={(val) => {
                  setSelectedBlockchain(val);
                }}
                getOptionLabel={(val) => getCoinLabel(val)}
                name="blockchains"
                options={blockChainOptions}
                isDisabled={disableChangeStatus}
              />
            </div>
          )}
          {selectedItem && ethers.utils.isAddress(selectedItem.value)
            && assetType === 'NFT' && typeof selectedItem.market_cap !== 'number' && (
            <div className="pt-4 mb-3">
              <div className={`${styles.label}`}>Select type</div>
              <Select
                placeholder="Select a contract type..."
                styles={inputStyles}
                value={selectedType}
                onKeyDown={(e) => { preventSearch(e); }}
                onChange={(val) => {
                  setSelectedType(val);
                }}
                name="types"
                options={typeOptions}
                isDisabled={disableChangeStatus}
              />
            </div>
          )}
          {selectedItem && assetType === 'Token' ? (
            <>
              <div className={`${styles.title_input} d-flex w-100 justify-content-between mb-1`}>
                <span>Minimum transfer amount</span>
                <span>Optional</span>
              </div>
              <div className={disableChangeStatus ? styles.link_area_disabled : styles.link_area}>
                <NumericFormat
                  placeholder="Input value"
                  className="w-100 p-2"
                  onPaste={(e) => {
                    onPasteFloat(e, e.clipboardData.getData('text/plain').replace(/[,]/g, ''));
                  }}
                  valueIsNumericString
                  thousandSeparator=","
                  decimalSeparator="."
                  allowNegative={false}
                  onBlur={(e) => e.target.blur()}
                  onValueChange={(values) => {
                    const { formattedValue, value } = values;
                    if (formattedValue.replace(/[,.]/g, '').length >= 18) {
                      showErrorMessage('18 numbers are already entered. You are not allowed to enter more.');
                      return;
                    }
                    if (formattedValue.replace(/[,.]/g, '').length <= 18) {
                      setValue('min_amount', value);
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.target.value.replace(/[,.]/g, '').length >= 18
                      && e.keyCode !== 8 && e.keyCode !== 37 && e.keyCode !== 39
                      && e.keyCode !== 17 && e.keyCode !== 67) {
                      e.preventDefault();
                    }
                  }}
                  value={watch('min_amount') || ''}
                  disabled={disableChangeStatus}
                />
              </div>
              <div className={`${styles.title_input} d-flex w-100 justify-content-between mt-3 mb-1`}>
                <span>Maximum transfer amount</span>
                <span>Optional</span>
              </div>
              <div className={disableChangeStatus ? styles.link_area_disabled : styles.link_area}>
                <NumericFormat
                  placeholder="Input value"
                  className="w-100 p-2"
                  onPaste={(e) => {
                    onPasteFloat(e, e.clipboardData.getData('text/plain').replace(/[,]/g, ''));
                  }}
                  valueIsNumericString
                  thousandSeparator=","
                  decimalSeparator="."
                  allowNegative={false}
                  onBlur={(e) => e.target.blur()}
                  onValueChange={(values) => {
                    const { formattedValue, value } = values;
                    if (formattedValue.replace(/[,.]/g, '').length >= 18) {
                      showErrorMessage('18 numbers are already entered. You are not allowed to enter more.');
                      return;
                    }
                    if (formattedValue.replace(/[,.]/g, '').length <= 18) {
                      setValue('max_amount', value);
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.target.value.replace(/[,.]/g, '').length >= 18
                      && e.keyCode !== 8 && e.keyCode !== 37 && e.keyCode !== 39
                      && e.keyCode !== 17 && e.keyCode !== 67) {
                      e.preventDefault();
                    }
                  }}
                  value={watch('max_amount') || ''}
                  disabled={disableChangeStatus}
                />
              </div>
            </>
          ) : null}
        </div>
        <div>
          <div className="d-flex gap-4 mt-4">
            <button
              type="button"
              className={`${styles.btn_cancel}`}
              onClick={() => dispatch(clearActiveSettings())}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="regular-button w-100"
              disabled={!selectedItem || disableChangeStatus}
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default TransactionNodeSettings;
